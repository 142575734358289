import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import styles from "./LeftSlideBar.less";
import { NavLink } from "react-router-dom";
import RoutesPaths from "../../../routes/RoutesPaths";
import {
    DEPOSIT_MERCHANT, PERM_ADD_MERCHANT,
    PERM_CREATE_PERMISSION_GROUP,
    PERM_CREATE_PERMISSION_ROLE,
    PERM_DELETE_PERMISSION_GROUP,
    PERM_DELETE_PERMISSION_ROLE,
    PERM_EDIT_PERMISSION_GROUP,
    PERM_EDIT_PERMISSION_ROLE,
    PERM_GET_ACCOUNT_LOG,
    PERM_GET_ACCOUNTS,
    PERM_GET_AGENTS,
    PERM_GET_ALL_USERS,
    PERM_GET_BALANCE,
    PERM_GET_CASHIERS,
    PERM_GET_MERCHANTS,
    PERM_GET_PERMISSION_GROUP,
    PERM_GET_PERMISSION_ROLE,
    PERM_GET_RECORDS,
    PERM_GET_SMS_REPORT,
    PERM_GET_SUMMARY_RECORDS,
    PERM_MAKE_DEPOSIT,
    PERM_MAKE_PAYMENT_B2C,
    PERM_MAKE_SETTLEMENT,
    PERM_SET_LOGO_DATA,
    PERM_UPLOAD_SMS_BATCH,
    SETTLEMENT_MERCHANT,
    SMALL_SCREEN,
} from '../../../utils/consts/global';
import { useOutsideAlerter } from "../../../utils/customHooks";
import { AppActions } from "../../../store/app/app.consts";
import { isRoot } from '../../../utils/helpers/commonHelpers';

export const LeftSideBar = (props) => {
    const sideBarRef = useRef(null);
    const subMenuSmsRef = useRef(null);
    const subMenuBalanceRef = useRef(null);
    const subMenuPaymentsRef = useRef(null);
    const subMenuAccRef = useRef(null);
    const subMenuSettingsRef = useRef(null);

    const isSidebarShown = useSelector((state) => state.app.isSidebarShown);
    // const login = useSelector((state) => state.auth.getToken.login);
    const sidebarState = useSelector((state) => state.app.sidebarState);
    const permissions = useSelector((state) => state.auth.permissions.success) || [];
    const dispatch = useDispatch();

    const [clientId, setClientId] = useState(sessionStorage.getItem("fullClientId"));
    const [loginName, setLoginName] = useState(sessionStorage.getItem("login"));


    const {
        smsExpanded,
        balanceExpanded,
        paymentsExpanded,
        accountingExpanded,
        settingsExpanded,
    } = sidebarState;

    const setSideBar = (itemState) => {
        dispatch({
            type: AppActions.APP_UI_SIDEBAR_SETSTATE,
            payload: {
                ...sidebarState,
                ...itemState,
            }
        });
    };

    const closeMobileSlideBar = () => {
        if (SMALL_SCREEN) {
            return dispatch({
                type: AppActions.APP_UI_SIDEBAR_MOB_HIDE,
                payload: false
            });
        }
    };

    useOutsideAlerter(sideBarRef, closeMobileSlideBar);

    const isRolesGroupsAllowed = () => {
        const admRoles = [
            // PERM_SET_LOGO_DATA,
            PERM_GET_PERMISSION_ROLE,
            PERM_CREATE_PERMISSION_ROLE,
            PERM_EDIT_PERMISSION_ROLE,
            PERM_DELETE_PERMISSION_ROLE,
            PERM_GET_PERMISSION_GROUP,
            PERM_CREATE_PERMISSION_GROUP,
            PERM_EDIT_PERMISSION_GROUP,
            PERM_DELETE_PERMISSION_GROUP
        ];
        return !!admRoles.map((el) => permissions.indexOf(el) !== -1).filter((el) => el).length;
    };

    const isUsersEditAllowed = () => {
        const admRoles = [PERM_GET_ALL_USERS]; //PERM_GET_PERMISSION_ROLE, PERM_GET_PERMISSION_GROUP
        return !!admRoles.map((el) => permissions.indexOf(el) !== -1).filter((el) => el).length;
    };

    const changeSmsExpanded = (e) => {
        if (!subMenuSmsRef.current.contains(e.target)) {
            setSideBar({ smsExpanded: !smsExpanded });
        }
    };

    const changeBalanceExpanded = (e) => {
        if (!subMenuBalanceRef.current.contains(e.target)) {
            setSideBar({ balanceExpanded: !balanceExpanded });
        }
    };
    const changePaymentsExpanded = (e) => {
        if (!subMenuPaymentsRef.current.contains(e.target)) {
            setSideBar({ paymentsExpanded: !paymentsExpanded });
        }
    };
    const changeAccountingExpanded = (e) => {
        if (!subMenuAccRef.current.contains(e.target)) {
            setSideBar({ accountingExpanded: !accountingExpanded });
        }
    };
    const changeSettingsExpanded = (e) => {
        if (!subMenuSettingsRef.current.contains(e.target)) {
            setSideBar({ settingsExpanded: !settingsExpanded });
        }
    };

    const allowBalance = permissions.indexOf(PERM_MAKE_DEPOSIT) !== -1 || permissions.indexOf(PERM_MAKE_SETTLEMENT) !== -1 || permissions.indexOf(PERM_GET_BALANCE) !== -1;
    const allowPayments = permissions.indexOf(PERM_GET_RECORDS) !== -1 || permissions.indexOf(PERM_MAKE_PAYMENT_B2C) !== -1 ;
    const allowAccounting = permissions.indexOf(PERM_GET_ACCOUNTS) !== -1 || permissions.indexOf(PERM_GET_ACCOUNT_LOG) !== -1;
    const allowSettings = permissions.indexOf(PERM_GET_CASHIERS) !== -1 || isUsersEditAllowed() || isRolesGroupsAllowed() || permissions.indexOf(PERM_SET_LOGO_DATA) !== -1;
    const isUserRoot = isRoot();

    return (
        <div ref={sideBarRef}
             className={cn("sidebar", "sidebar-style-2", { [styles.sideBarSmall]: !isSidebarShown })}>
            <div className={cn("sidebar-wrapper scrollbar scrollbar-inner", styles.sidebarWrapper)}>

                <div className="sidebar-content">

                    <div className="user">
                        <div className={cn(styles.logoWrapper, "avatar-sm float-left mr-2")}>
                            <i className={cn(styles.userIcon, "fas fa-user rounded-circle")}> </i>
                        </div>
                        <div className="info">
                            <a data-toggle="collapse" aria-expanded="true">
                                <span>
                                    you are logged as:
                                    <span className={cn(styles.userName, "user-level")}>{loginName}</span>
                                    {/*<span className="caret"> </span>*/}
                                </span>
                            </a>
                            <div className="clearfix"> </div>
                            <div className={styles.clientId}>
                                <span className={styles.label}>CLIENT ID:</span>
                                <span>{clientId}</span>
                            </div>
                        </div>
                    </div>

                    <ul className={styles.sideBarPrimary}>
                        <li className={styles.navSection}>
                            <h4 className={styles.textSection}>Menu</h4>
                        </li>


                        {permissions.indexOf(PERM_GET_SUMMARY_RECORDS) !== -1 && (<>

                            {isUserRoot && false && (
                                <li title="Dashboard"
                                    className={cn(styles.navItem, {
                                        [styles.active]: props.active === "Dashboard"
                                    })}
                                >
                                    <NavLink to={RoutesPaths.DASHBOARD} onClick={closeMobileSlideBar}>
                                        <i className="fas fa-chart-line"> </i>
                                        <p>Dashboard</p>
                                        {/*<span className="badge badge-count">2095</span>*/}
                                    </NavLink>
                                </li>
                            )}

                            <li title="Summary"
                                className={cn(styles.navItem, {
                                    [styles.active]: props.active === "summary"
                                })}
                            >
                                <NavLink to={RoutesPaths.SUMMARY} onClick={closeMobileSlideBar}>
                                    <i className="fas fa-file-signature"> </i>
                                    <p>Summary</p>
                                    {/*<span className="badge badge-count">-100</span>*/}
                                </NavLink>
                            </li>
                            {isUserRoot && (
                                <li title="Reports"
                                    className={cn(styles.navItem, {
                                        [styles.active]: props.active === "Reports"
                                    })}
                                >
                                    <NavLink to={RoutesPaths.REPORTS} onClick={closeMobileSlideBar}>
                                        <i className="fas fa-clipboard-list"> </i>
                                        <p>Reports</p>
                                        {/*<span className="badge badge-count">2095</span>*/}
                                    </NavLink>
                                </li>
                            )}
                        </>)}


                        {allowBalance && (
                            <li title="Balance"
                                className={cn(styles.navItem, styles.submenuHead)}
                                onClick={changeBalanceExpanded}
                            >

                                {permissions.indexOf(PERM_GET_BALANCE) !== -1 && (
                                    <NavLink to={RoutesPaths.BALANCES}
                                             onClick={closeMobileSlideBar}
                                             className={cn(styles.submenuHeadLine,  { [styles.subMenuHeadActive]:  props.active === 'balances'})}>
                                        <i className="fas fa-file-invoice-dollar"> </i>
                                        <p>Balance</p>
                                    </NavLink>
                                )}
                                {permissions.indexOf(PERM_GET_BALANCE) === -1 && (
                                    <a onClick={closeMobileSlideBar}
                                         className={cn(styles.submenuHeadLine, { [styles.subMenuHeadActive]: balanceExpanded })}>
                                        <i className="fas fa-file-invoice-dollar"> </i>
                                        <p>Balance</p>
                                    </a>
                                )}


                                <ul className={cn(styles.subMenuItemsList, { [styles.show]: balanceExpanded })}
                                    ref={subMenuBalanceRef}>

                                    {permissions.indexOf(PERM_MAKE_DEPOSIT) !== -1 && (
                                        <li title="Deposit"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === DEPOSIT_MERCHANT
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.DEPOSIT_MERCHANT}
                                                     onClick={closeMobileSlideBar}>
                                                <i className="far fa-arrow-alt-circle-down"> </i>
                                                <p>Deposit</p>
                                            </NavLink>
                                        </li>
                                    )}
                                    {permissions.indexOf(PERM_MAKE_SETTLEMENT) !== -1 && (
                                        <li title="Settlement"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === SETTLEMENT_MERCHANT
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.SETTLEMENT_MERCHANT}
                                                     onClick={closeMobileSlideBar}>
                                                <i className="far fa-arrow-alt-circle-up"> </i>
                                                <p>Settlement</p>
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}


                        {allowPayments && (
                            <li title="Payments"
                                className={cn(styles.navItem, styles.submenuHead)}
                                onClick={changePaymentsExpanded}
                            >
                                <NavLink to={RoutesPaths.PAYMENTS}
                                         className={cn(styles.submenuHeadLine, { [styles.subMenuHeadActive]:  props.active === 'payments' })}
                                         onClick={closeMobileSlideBar}>
                                    <i className={cn("fas fa-th-list", styles.menuIcon)}> </i>
                                    <p>Payments</p>
                                </NavLink>

                                <ul className={cn(styles.subMenuItemsList, { [styles.show]: paymentsExpanded })}
                                    ref={subMenuPaymentsRef}>

                                    {permissions.indexOf(PERM_MAKE_PAYMENT_B2C) !== -1 && (
                                        <li title="Create payment"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "CreatePayment"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.CREATE_PAYMENT} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-money-check-alt"> </i>
                                                <p>Create payment</p>
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {allowAccounting && (
                            <li title="Accounting"
                                className={cn(styles.navItem, styles.submenuHead)}
                                onClick={changeAccountingExpanded}
                            >
                                <div className={cn(styles.submenuHeadLine)}>
                                    <i className={cn(styles.subMenuIcon, "fas fa-list")}> </i>
                                    <p className={styles.submenuTitle}>Accounting</p>
                                </div>
                                <ul className={cn(styles.subMenuItemsList, { [styles.show]: accountingExpanded })}
                                    ref={subMenuAccRef}>

                                    {permissions.indexOf(PERM_GET_ACCOUNT_LOG) !== -1 && (
                                        <li title="Account log"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "accountLog"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ACCOUNT_LOG} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-list-ol"> </i>
                                                <p>Account log</p>
                                            </NavLink>
                                        </li>
                                    )}

                                    {permissions.indexOf(PERM_GET_ACCOUNTS) !== -1 && (
                                        // {permissions.indexOf(PERM_GET_ACCOUNTS_ADM) !== -1 && (
                                        <li title="Accounts"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "accounts"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ACCOUNTS} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-clipboard-list"> </i>
                                                <p>Accounts</p>
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {permissions.indexOf(PERM_GET_MERCHANTS) !== -1 && (
                            <li title="Merchants"
                                className={cn(styles.navItem, {
                                    [styles.active]: props.active === "MerchantsList"
                                })}
                            >
                                <NavLink to={RoutesPaths.MERCHANTS_LIST} onClick={closeMobileSlideBar}>
                                    <i className="fas fa-user-friends"> </i>
                                    <p>Merchants</p>
                                </NavLink>
                            </li>
                        )}

                        {permissions.indexOf(PERM_GET_AGENTS) !== -1 && (
                            <li title="Agents"
                                className={cn(styles.navItem, {
                                    [styles.active]: props.active === "Agents"
                                })}
                            >
                                <NavLink to={RoutesPaths.AGENTS} onClick={closeMobileSlideBar}>
                                    <i className="fas fa-user-secret"> </i>
                                    <p>Agents</p>
                                </NavLink>
                            </li>
                        )}


                        {(permissions.indexOf(PERM_GET_SMS_REPORT) !== -1 ||
                            permissions.indexOf(PERM_UPLOAD_SMS_BATCH) !== -1) && (
                            <li title="SMS Gateway"
                                className={cn(styles.navItem, styles.submenuHead)}
                                onClick={changeSmsExpanded}
                            >
                                <div className={cn(styles.submenuHeadLine)}>
                                    <i className={cn(styles.subMenuIcon, "fas fa-comments")}> </i>
                                    <p className={styles.submenuTitle}>SMS Gateway</p>
                                </div>
                                <ul className={cn(styles.subMenuItemsList, { [styles.show]: smsExpanded })}
                                    ref={subMenuSmsRef}>
                                    {permissions.indexOf(PERM_GET_SMS_REPORT) !== -1 && (
                                        <li title="SMS Stat"
                                            className={cn(styles.submenuList, {
                                                [styles.active]: props.active === "sms"
                                            })}>
                                            <NavLink to={RoutesPaths.SMS}>
                                                <i className="fas fa-comment-alt"> </i>
                                                <p>SMS Stat</p>
                                            </NavLink>
                                        </li>
                                    )}
                                    {permissions.indexOf(PERM_UPLOAD_SMS_BATCH) !== -1 && (
                                        <li title="Bulk Send"
                                            className={cn(styles.submenuList, {
                                                [styles.active]: props.active === "smsUpload"
                                            })}>
                                            <NavLink to={RoutesPaths.SMS_UPLOAD}>
                                                <i className="fas fa-upload"> </i>
                                                <p>Bulk Send</p>
                                            </NavLink>
                                        </li>
                                    )}

                                </ul>
                            </li>
                        )}

                        {allowSettings && (
                            <li title="Settings"
                                className={cn(styles.navItem, styles.submenuHead)}
                                onClick={changeSettingsExpanded}
                            >
                                <div className={cn(styles.submenuHeadLine)}>
                                    {/*className={cn(styles.submenuHeadLine, { [styles.subMenuHeadActive]: settingsExpanded })}>*/}
                                    <i className={cn(styles.subMenuIcon, "fas fa-cogs")}> </i>
                                    <p className={styles.submenuTitle}>Settings</p>
                                </div>
                                <ul className={cn(styles.subMenuItemsList, { [styles.show]: settingsExpanded })}
                                    ref={subMenuSettingsRef}>

                                    {permissions.indexOf(PERM_ADD_MERCHANT) !== -1 && false &&  (
                                        <li title="Tariffs"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "tariffs"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.TARIFFS} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-receipt"> </i>
                                                <p>Tariffs</p>
                                            </NavLink>
                                        </li>
                                    )}

                                    {permissions.indexOf(PERM_SET_LOGO_DATA) !== -1 && (
                                        <li title="Change POS logos"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "AdmBrands"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ADM_ICONS} onClick={closeMobileSlideBar}>
                                                <i className="fab fa-contao"> </i>
                                                <p>Change POS logos</p>
                                            </NavLink>
                                        </li>
                                    )}

                                    {isRolesGroupsAllowed() && (<>
                                        <li title="Groups and roles"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "AdmRolesGroups"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ADM_GROUP_ROLES} onClick={closeMobileSlideBar}>
                                                {/*<i className="fas fa-user-shield"> </i>*/}
                                                <i className="fas fa-layer-group"> </i>
                                                <p>Groups and roles</p>
                                            </NavLink>
                                        </li>

                                        <li title="Function permissions"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "rolesTable"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ROLES_TABLE} onClick={closeMobileSlideBar}>
                                                {/*<i className="fas fa-user-shield"> </i>*/}
                                                <i className="fas fa-layer-group"> </i>
                                                <p>Function permissions</p>
                                            </NavLink>
                                        </li>
                                    </>)}
                                    {isUsersEditAllowed() && (
                                        <li title="Users"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "AdmUsers"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ADM_USERS} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-users"> </i>
                                                <p>Users</p>
                                            </NavLink>
                                        </li>
                                    )}
                                    {permissions.indexOf(PERM_GET_CASHIERS) !== -1 && (
                                        <li title="Cashiers"
                                            className={cn(styles.navItem, {
                                                [styles.active]: props.active === "AdmCashiers"
                                            })}
                                        >
                                            <NavLink to={RoutesPaths.ADM_CASHIERS} onClick={closeMobileSlideBar}>
                                                <i className="fas fa-user-tie"> </i>
                                                <p>Cashiers</p>
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
