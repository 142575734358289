import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { Transactions } from './transactions.consts';
import { combineReducers } from 'redux';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getTransactions = handleActions(
    {
        [Transactions.TRA_OPERATIONS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_OPERATIONS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_OPERATIONS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_OPERATIONS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getPaymentState = handleActions(
    {
        [Transactions.GET_PAYMENT_STATE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.GET_PAYMENT_STATE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.GET_PAYMENT_STATE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.GET_PAYMENT_STATE_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getTransitLog = handleActions(
    {
        [Transactions.TRA_TRANSIT_LOG_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_TRANSIT_LOG_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_TRANSIT_LOG_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_TRANSIT_LOG_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getBalances = handleActions(
    {
        [Transactions.TRA_BALANCES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_BALANCES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_BALANCES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_BALANCES_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getBalanceAmount = handleActions(
    {
        [Transactions.TRA_BALANCE_AMOUNT_FETCH]: (state, action) => {
            return update(state, {
                [`${action.payload.strMerchantId}:${action.payload.strCurrency}`]: {
                    $set: {
                        fetching: true,
                        success: null,
                        errors: null,
                    }
                }
            });
        },
        [Transactions.TRA_BALANCE_AMOUNT_SUCCESS]: (state, action) => {
            return update(state, {
                [`${action.payload.data.merchantId}:${action.payload.data.currency}`]: {
                    $set: {
                        fetching: false,
                        success: action.payload.data,
                        errors: null,
                    }
                }
            });
        }
    },
    [],
);

export const getAccountsInfo = handleActions(
    {
        [Transactions.TRA_ACCOUNTS_INFO_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_ACCOUNTS_INFO_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_ACCOUNTS_INFO_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_ACCOUNTS_INFO_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getSummary = handleActions(
    {
        [Transactions.TRA_SUMMARY_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_SUMMARY_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_SUMMARY_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_SUMMARY_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getAccountLog = handleActions(
    {
        [Transactions.TRA_GET_ACCOUNT_LOG_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_GET_ACCOUNT_LOG_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Transactions.TRA_GET_ACCOUNT_LOG_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Transactions.TRA_GET_ACCOUNT_LOG_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const transactions = combineReducers({
    getTransactions,
    getBalances,
    getBalanceAmount,
    getSummary,
    getAccountsInfo,
    getAccountLog,
    getTransitLog,
    getPaymentState,
});
