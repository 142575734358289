const RoutesPaths = {
    LOGIN: '/',
    ACCESS_DENIED: '/access-denied',
    HOME: '/home',
    PAYMENTS: '/payments',
    SUMMARY: '/summary',
    BALANCES: '/balances',
    CREATE_PAYMENT: '/create_payment',
    MERCHANTS_LIST: '/merchants',
    MERCHANT_ADD: '/merchant_create',
    MERCHANT: '/merchant',
    CREATE_MERCHANT: '/create_merchant',
    ADM_ICONS: '/adm_icons',
    ADM_GROUP_ROLES: '/adm_group_roles',
    ADM_USERS: '/adm_users',
    ADM_CASHIERS: '/cashiers',
    AGENTS: '/agents',
    AGENT: '/agent',
    AGENT_CREATE: '/agent_create',
    AGENT_ADD: '/agent_add',     // legacy
    ACCOUNT_LOG: '/account_log',
    ACCOUNTS: '/accounts',
    USERS: '/users',
    NOT_FOUND: '/404',
    SMS: '/sms',
    DEPOSIT_MERCHANT: '/deposit_merchant',
    DEPOSIT_AGENT: '/deposit_agent',
    SETTLEMENT_MERCHANT: '/settlement_merchant',
    SETTLEMENT_AGENT: '/settlement_agent',
    SMS_UPLOAD: '/sms_upload',
    TRANSITION_LOG: '/transition_log',
    AGENT_INFO_EDIT: '/agent_info',
    ROLES_TABLE: '/roles_table',
    TARIFFS: '/tariffs',
    REPORTS: '/reports',
    DASHBOARD: '/dashboard',
};

export default RoutesPaths;
