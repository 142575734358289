export const rows = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: true },
    { name: 'merchantBrandName', title: 'merchant brand name', className: 'noWrap', options: '', isSortable: true },
    { name: 'merchantLegalName', title: 'merchant legal name', className: 'noWrap', options: '', isSortable: true },
    { name: 'country', title: 'country', className: 'noWrap', options: '', isSortable: true },
    { name: 'regDT', title: 'registration date', className: 'noWrap', options: '', isSortable: false },
];

export const menuItems = [
    { name: 'Merchant information' },
    { name: 'Payment methods' },
    { name: 'User' },
];

// const model = {
//     strMerchantBrandingName: '',
//     strContactName: '',
//     strContactPhone: '',
//     strContactEmail: '',
//     strLogin: '',
//     strPassword: '',
//     intMerchantType: '',
//     arrLegals: [
//         {
//             strMerchantLegalName: '',
//             strLegalName: '',
//             strLegalPhone: '',
//             strLegalEmail: '',
//             dtContractDate: new Date(),
//             intWhitelabel: '',
//             intBank: '',
//             intCountry: '',
//             intBranch: '',
//             arrCurrencies: [],
//             strTimeZone: timezones[13].title,
//             arrPaymentMethods: [
//                 {
//                     intPlaymentMethod: id,
//                     // comissions: .....,
//                     arrCurrencies: [],
//                 }
//             ],
//         }
//     ]
// };

export const emptyMerchant = {
    intWhitelabel: '',
    strMerchantBrandingName: '',
    strContactName: '',
    strContactPhone: '',
    strContactEmail: '',
    strLogin: '',
    strPassword: '',
    intMerchantType: '',
  //  arrLegals: [
        // {
        //     strMerchantLegalName: '',
        //     strLegalName: '',
        //     strLegalPhone: '',
        //     strLegalEmail: '',
        //     dtContractDate: new Date(),
        //     intWhitelabel: '',
        //     intBank: '',
        //     intBranch: '',
        //     intCurrency: '',
        //     strTimeZone: timezones[13].title,
        //     arrCurrencies: [],      //'840', '976', '643'
        //     // arrPaymentMethods: [
        //     //     {
        //     //         id: 1,
        //     //         account: '1234567890',
        //     //         boolAggregator: false,
        //     //         recipient_commission: 1,
        //     //         recipient_bonus: 2,
        //     //         sender_commission: 3,
        //     //         sender_bonus_instant: 0,
        //     //         boolEnabled: true,
        //     //         pin: '1234',
        //     //         token: '234',
        //     //         intTerminal: '',
        //     //         paymentMethodID: '9',
        //     //     }
        //     // ],
        // },
   // ],

//             strMerchantLegalName: '',
//             strLegalName: '',
//             strLegalPhone: '',
//             strLegalEmail: '',
//             dtContractDate: new Date(),
//             intWhitelabel: '',
//             intBank: '',
//             intCountry: '',
//             intBranch: '',
//             arrCurrencies: [],
//             strTimeZone: timezones[13].title,
    // intCurrency: '',
    // strContact: "",
    // arrPaymentMethods: [
    //     // { id
    //     //     strMerchantBrandName: "",
    //     //     acc_msisdn: "",
    //     //     isFinancialAggregator: false,
    //     //     recipient_commission: 0,
    //     //     recipient_bonus: 0,
    //     //     sender_commission: 0,
    //     //     sender_bonus_instant: 0,
    //     //     boolEnabled: true,
    //     // },
    // ]
};

export const getCurrCodeNumByCodeAlpha = (alpha3CodesArr, allCurrencies) => {
    return alpha3CodesArr?.map(codeAlpha => allCurrencies.find(curr => curr.codeAlpha === codeAlpha)?.codeNum);
};


/*

const a = {
    'strLogin': 'testMerchA',
    'strPassword': 'Test123!',
    'strMerchantLegalName': 'testMerchA',
    'strMerchantBrandingName': 'testMerchA',
    'intMerchantType': '1',
    'strCountry': '',
    'strTimeZone': '+03:00',
    'dtContractDate': '2021-08-20T13:05:17+03:00',
    'intWhitelabel': '1',
    'intBank': '1',
    'intBranch': '1',
    'strEmail': 'testMerchA@testMerchA.dd',
    'strContact': '123456789009',
    'arrPaymentMethods': [{
        'id': 1,
        'account': '1234567890',
        'boolAggregator': false,
        'recipient_commission': 1,
        'recipient_bonus': 2,
        'sender_commission': 3,
        'sender_bonus_instant': 0,
        'boolEnabled': true,
        'pin': '12345',
        'token': '234',
        'intWhitelabel': '1',
        'intBank': '1',
        'intBranch': '1',
        'intTerminal': '',
        'arrCurrencies': ['840', '976', '643'],
        'paymentMethodID': '9',
    }],
    'method': 'AddMerchant',
    'setter': true,
};
*/
