export const Dictionaries = {
    GET_ALL_DICTIONARIES: 'GET_ALL_DICTIONARIES',

    GET_ALLOWED_OPERATORS_FETCH: 'GET_ALLOWED_OPERATORS_FETCH',
    GET_ALLOWED_OPERATORS_SUCCESS: 'GET_ALLOWED_OPERATORS_SUCCESS',
    GET_ALLOWED_OPERATORS_ERROR: 'GET_ALLOWED_OPERATORS_ERROR',
    GET_ALLOWED_OPERATORS_CLEAR: 'GET_ALLOWED_OPERATORS_CLEAR',

    GET_ALL_OPERATORS_FETCH: 'GET_ALL_OPERATORS_FETCH',
    GET_ALL_OPERATORS_SUCCESS: 'GET_ALL_OPERATORS_SUCCESS',
    GET_ALL_OPERATORS_ERROR: 'GET_ALL_OPERATORS_ERROR',
    GET_ALL_OPERATORS_CLEAR: 'GET_ALL_OPERATORS_CLEAR',

    GET_CURRENCIES_FETCH: 'GET_CURRENCIES_FETCH',
    GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',
    GET_CURRENCIES_ERROR: 'GET_CURRENCIES_ERROR',

    GET_COUNTRIES_FETCH: 'GET_COUNTRIES_FETCH',
    GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
    GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',

    GET_GATE_FETCH: 'GET_GATE_FETCH',
    GET_GATE_SUCCESS: 'GET_GATE_SUCCESS',
    GET_GATE_ERROR: 'GET_GATE_ERROR',

    GET_GATE_ADM_FETCH: 'GET_GATE_ADM_FETCH',
    GET_GATE_ADM_SUCCESS: 'GET_GATE_ADM_SUCCESS',
    GET_GATE_ADM_ERROR: 'GET_GATE_ADM_ERROR',

    GET_CLIENTS_FETCH: 'GET_CLIENTS_FETCH',
    GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
    GET_CLIENTS_ERROR: 'GET_CLIENTS_ERROR',

    GET_SUBCLIENTS_FETCH: 'GET_SUBCLIENTS_FETCH',
    GET_SUBCLIENTS_SUCCESS: 'GET_SUBCLIENTS_SUCCESS',
    GET_SUBCLIENTS_ERROR: 'GET_SUBCLIENTS_ERROR',

    GET_LOGOS_FETCH: 'GET_LOGOS_FETCH',
    GET_LOGOS_SUCCESS: 'GET_LOGOS_SUCCESS',
    GET_LOGOS_ERROR: 'GET_LOGOS_ERROR',

    SET_LOGO_FETCH: 'SET_LOGO_FETCH',
    SET_LOGO_SUCCESS: 'SET_LOGO_SUCCESS',
    SET_LOGO_ERROR: 'SET_LOGO_ERROR',

    CREATE_LOGO_FETCH: 'CREATE_LOGO_FETCH',
    CREATE_LOGO_SUCCESS: 'CREATE_LOGO_SUCCESS',
    CREATE_LOGO_ERROR: 'CREATE_LOGO_ERROR',

    GET_MERCHANTS_FETCH: 'GET_MERCHANTS_FETCH',
    GET_MERCHANTS_SUCCESS: 'GET_MERCHANTS_SUCCESS',
    GET_MERCHANTS_ERROR: 'GET_MERCHANTS_ERROR',

    GET_BANKS_FETCH: 'GET_BANKS_FETCH',
    GET_BANKS_SUCCESS: 'GET_BANKS_SUCCESS',
    GET_BANKS_ERROR: 'GET_BANKS_ERROR',

    GET_MERCHANT_TYPES_FETCH: 'GET_MERCHANT_TYPES_FETCH',
    GET_MERCHANT_TYPES_SUCCESS: 'GET_MERCHANT_TYPES_SUCCESS',
    GET_MERCHANT_TYPES_ERROR: 'GET_MERCHANT_TYPES_ERROR',

    GET_WL_FETCH: 'GET_WL_FETCH',
    GET_WL_SUCCESS: 'GET_WL_SUCCESS',
    GET_WL_ERROR: 'GET_WL_ERROR',

    GET_TERMINALS_ADM_FETCH: 'GET_TERMINALS_ADM_FETCH',
    GET_TERMINALS_ADM_SUCCESS: 'GET_TERMINALS_ADM_SUCCESS',
    GET_TERMINALS_ADM_ERROR: 'GET_TERMINALS_ADM_ERROR',

    GET_BRANCHES_FETCH: 'GET_BRANCHES_FETCH',
    GET_BRANCHES_SUCCESS: 'GET_BRANCHES_SUCCESS',
    GET_BRANCHES_ERROR: 'GET_BRANCHES_ERROR',
};
