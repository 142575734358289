import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './MerchantDetailTab.less';
import { DropDown } from '../../DropDown/DropDown';
import {
    findTimeZoneByID,
    findTitleById,
    getDataForDD,
    getDataForDropDown,
} from '../../../../utils/helpers/commonHelpers';
import { emptyLegal, mapLegalData } from './MerchantDetailTab.helper';
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { DATE_FORMAT_DDMMYYYY, DATE_FORMAT_YYYYMMDD, timezones } from '../../../../utils/consts/global';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { LEGAL_IN_DRAFT, NEW_LEGAL } from '../MerchantDetails/MerchantDetails.helper';

export const MerchantDetailTab = (props) => {
    const dispatch = useDispatch();
    const { merchant, createMode } = props;
    const intWhitelabel = merchant.whitelabel;
    const [legal, setLegal] = useState(createMode ? { ...emptyLegal } : mapLegalData(props.legal, props.dict.currencies));
    const countriesDict = useSelector((state) => state.dictionaries.getCountries.success);
    const getBranches = useSelector((state) => state.dictionaries.getBranches.success) || [];
    const getWL = useSelector((state) => state.dictionaries.getWL.success) || [];
    const allBanks = useSelector((state) => state.dictionaries.getBanks.success) || [];
    const countries = getDataForDropDown(countriesDict || [], 'codeAlpha3', true);
    const wl = getDataForDD(getWL || []);
    const branches = getDataForDD(getBranches.filter(el => el.parent === intWhitelabel));
    const banks = getDataForDD(allBanks.filter(el => el.parent === intWhitelabel));
    const intMerchantID = props.merchant?.id;
    const { currencies } = props.dict;
    const formDisabled = legal.state !== LEGAL_IN_DRAFT && legal.state !== NEW_LEGAL;

    useEffect(() => {
        if (createMode) {
            setLegal({ ...emptyLegal });
        }
    }, [createMode]);

    const ddSelect = (value, name) => {
        const { id } = value;
        setLegal({ ...legal, [name]: id });
    };

    const changeInputHandler = (event) => {
        event.persist();
        const { target } = event;
        let { name, value } = target;
        let val = value;

        if (name.includes('curr_') && !formDisabled) {
            const currenciesArray = [...legal.arrCurrencies];
            const currencyName = name.split('curr_')[1];
            name = 'arrCurrencies';

            if (event.target.checked) {
                currenciesArray.push(currencyName);
            } else {
                const index = currenciesArray.indexOf(currencyName);
                if (index !== -1) {
                    currenciesArray.splice(index, 1);
                }
            }
            val = currenciesArray;
        }

        setLegal({ ...legal, [name]: val });
    };

    const onDateChanges = (date) => {
        changeInputHandler({
            target: {
                name: 'dtContractDate',
                value: new Date(date),
            },
            persist: () => {
            },
        });
    };

    const onCancelEdit = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const saveLegal = () => {
        const dataToSend = {
            ...legal,
            intMerchantID,
            intID: legal.id,
            arrCurrencies: legal.arrCurrencies.map(el => currencies.find(cur => cur.title === el)?.id),
            dtContractDate: moment(legal.dtContractDate).format(DATE_FORMAT_YYYYMMDD),
            strTimeZone: findTimeZoneByID(legal.strTimeZone || 10),
        };
        delete dataToSend.id;
        props.onConfirm({ ...dataToSend });
    };

    const dropDownSelect = (val, name) => {
        setLegal({
            ...legal,
            [name]: val.id,
        });
    };

    const isValid = () => {
        const requiredFields = [
            'intMerchantID',
            'intBranch',
            'intCountry',
            'intMerchantID',
            'intWhitelabel',
            'strContactAddress',
            'strContactName',
            'strMerchantLegalName',
            'strTimeZone',
            'dtContractDate',
        ];
        let valid = true;
        requiredFields.forEach(el => {
            if (!legal[el] || !legal[el]?.length) {
                valid = false;
            }
        });
    };

    return (<>
        <div className={styles.branchSection}>
            <div className={styles.leftSideInfo}>
                <div className={cn(styles.line, styles.spacesTop)}>
                    <div className={styles.firstColumn}>Legal name:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strMerchantLegalName"
                               name={'strMerchantLegalName'}
                               value={legal.strMerchantLegalName}
                               className={cn(styles.inputField)}
                               disabled={formDisabled}
                               onChange={changeInputHandler}
                        />
                    </div>
                </div>
                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contract date:</div>
                    <div className={styles.secondColumn}>
                        {!formDisabled ? (<Datetime
                            className={styles.calendar}
                            value={legal.dtContractDate}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={formDisabled ? null : onDateChanges}
                            closeOnSelect={true}
                            disabled={formDisabled}
                        />) : (<>{moment(legal.dtContractDate).format(DATE_FORMAT_DDMMYYYY)}</>)}
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contact name:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactName"
                               name={'strContactName'}
                               value={legal.strContactName}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contact phone:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactPhone"
                               name={'strContactPhone'}
                               value={legal.strContactPhone}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contact address:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactAddress"
                               name={'strContactAddress'}
                               value={legal.strContactAddress}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contact email:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactEmail"
                               name={'strContactEmail'}
                               value={legal.strContactEmail}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>
            </div>


            <div className={styles.rightSideInfo}>

                <div className={cn(styles.line, styles.spacesTop)}>
                    <div className={styles.firstColumn}>Country:</div>
                    <div className={styles.secondColumn}>
                        <DropDown
                            options={countries}
                            onSelect={dropDownSelect}
                            name="intCountry"
                            value={{ id: null, title: '' }}
                            disabled={formDisabled}
                        >
                            {findTitleById(countries, legal.intCountry)}&nbsp;
                        </DropDown>
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>
                        Timezone:
                    </div>
                    <div className={styles.secondColumn}>
                        <DropDown
                            id="strTimeZone"
                            color="grey"
                            options={timezones}
                            onSelect={ddSelect}
                            dropListStyle={styles.smallDropDownList}
                            disabled={formDisabled}
                            name="strTimeZone">
                            {legal?.strTimeZone}&nbsp;
                        </DropDown>
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Branch:</div>
                    <div className={styles.secondColumn}>
                        <DropDown
                            id="intBranch"
                            color="grey"
                            options={branches}
                            onSelect={ddSelect}
                            disabled={formDisabled}
                            name="intBranch">
                            {findTitleById(branches, legal?.intBranch)}&nbsp;
                        </DropDown>
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Bank:</div>
                    <div className={styles.secondColumn}>
                        <DropDown
                            id="intBank"
                            color="grey"
                            options={banks}
                            onSelect={ddSelect}
                            disabled={formDisabled}
                            name="intBank">
                            {findTitleById(banks, legal?.intBank)}&nbsp;
                        </DropDown>
                    </div>
                </div>

                <div className={cn(styles.lineFull)}>
                    <div className={styles.firstColumn}>Currencies:</div>

                    <div className={cn(styles.leftFormCheck, 'form-check')}>
                        {currencies.map((currency, idx) => (
                            <div key={String(idx)}>
                                <label htmlFor={'curr_' + currency.title}
                                       className={cn(styles.label)}>{currency.title}</label>
                                <label className="form-check-label">
                                    <input className="form-check-input"
                                           id={'curr_' + currency.title}
                                           type="checkbox"
                                           disabled={!createMode}
                                           name={'curr_' + currency.title}
                                           onChange={changeInputHandler}
                                           checked={legal.arrCurrencies.indexOf(currency.title) !== -1}
                                    />
                                    <span
                                        className={cn('form-check-sign', styles.whiteCheckbox)}>&nbsp;</span>
                                </label>
                            </div>
                        ))}
                    </div>

                </div>

            </div>
        </div>
        {!formDisabled && (
            <div className={styles.buttonsRight}>
                <ButtonsOkCancelBlock
                    onCancelEdit={onCancelEdit}
                    onConfirmEdit={saveLegal}
                    okIsDisabled={formDisabled}
                    okText="Save"
                />
{/*                <button
                    className="btn btn-primary"
                    onClick={saveLegal}
                    // disabled={!isValid()}
                >
                    Save branch
                </button>*/}
            </div>
        )}
    </>);
};
