import { call, put, takeEvery } from 'redux-saga/effects';
import { errorNotify } from '../helpers/commonHelpers';
import { onHandleErrors } from '../errors/errors.actions';
import { RoleSystem } from './roleSystem.consts';
import { fetchRoleSystem, fetchRoleSystem2 } from './roleSystem.services';
import {
    onAddDirectUserRoleError,
    onAddDirectUserRoleSuccess,
    onAddRoleToGroupError,
    onAddRoleToGroupSuccess,
    onAddUserError,
    onAddUserGroupError,
    onAddUserGroupSuccess,
    onAddUserSuccess,
    onCreateGroupError,
    onCreateGroupSuccess,
    onCreateRoleError,
    onCreateRoleSuccess,
    onDeleteGroupError,
    onDeleteGroupSuccess,
    onDeleteRoleError,
    onDeleteRoleSuccess,
    onEditGroupError,
    onEditGroupSuccess,
    onEditRoleError,
    onEditRoleSuccess,
    onEditUserError,
    onEditUserSuccess,
    onEnableUserError,
    onEnableUserSuccess, onGetAllowedRRTError, onGetAllowedRRTSuccess,
    onGetAllUsersError,
    onGetAllUsersFetch,
    onGetAllUsersSuccess,
    onGetFunctionRoleError,
    onGetFunctionRoleSuccess,
    onGetGroupRolesError,
    onGetGroupRolesSuccess,
    onGetGroupsError,
    onGetGroupsSuccess,
    onGetRolesError,
    onGetRolesSuccess,
    onGetUserGroupsError,
    onGetUserGroupsSuccess,
    onGetUserRolesError,
    onGetUserRolesSuccess,
    onRemoveRoleFromGroupError,
    onRemoveRoleFromGroupSuccess,
    onSetDirectUserRoleError,
    onSetDirectUserRoleFetch,
    onSetDirectUserRoleSuccess,
    onSetFunctionRoleError,
    onSetFunctionRoleSuccess,
    onSetUserGroupsError,
    onSetUserGroupsFetch,
    onSetUserGroupsSuccess,
} from './roleSystem.actions';
import { normalizeClients } from './roleSystem.helper';

export function* sagaGetRolesFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'GetPermissionRoles',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetRolesError(error));
        } else if (response) {
            yield put(onGetRolesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetRolesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetRolesError(error));
    }
}

export function* sagaCreateRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'CreatePermissionRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCreateRoleError(error));
        } else if (response) {
            yield put(onCreateRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCreateRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCreateRoleError(error));
    }
}

export function* sagaEditRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'EditPermissionRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEditRoleError(error));
        } else if (response) {
            yield put(onEditRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEditRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEditRoleError(error));
    }
}

export function* sagaDeleteRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'DeletePermissionRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onDeleteRoleError(error));
        } else if (response) {
            yield put(onDeleteRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onDeleteRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onDeleteRoleError(error));
    }
}

export function* sagaGetPermissionGroupsFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'GetPermissionGroups',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetGroupsError(error));
        } else if (response) {
            yield put(onGetGroupsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetGroupsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetGroupsError(error));
    }
}

export function* sagaGetGroupRolesFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'GetPermissionGroupRoles',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetGroupRolesError(error));
        } else if (response) {
            yield put(onGetGroupRolesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetGroupRolesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetGroupRolesError(error));
    }
}

export function* sagaAddRoleToGroupFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'AddPermissionGroupRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAddRoleToGroupError(error));
        } else if (response) {
            yield put(onAddRoleToGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAddRoleToGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAddRoleToGroupError(error));
    }
}

export function* sagaRemoveRoleFromGroupFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'RemovePermissionGroupRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onRemoveRoleFromGroupError(error));
        } else if (response) {
            yield put(onRemoveRoleFromGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onRemoveRoleFromGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onRemoveRoleFromGroupError(error));
    }
}

export function* sagaCreateGroupFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'CreatePermissionGroup',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCreateGroupError(error));
        } else if (response) {
            yield put(onCreateGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCreateGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCreateGroupError(error));
    }
}

export function* sagaEditGroupFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'EditPermissionGroup',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEditGroupError(error));
        } else if (response) {
            yield put(onEditGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEditGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEditGroupError(error));
    }
}

export function* sagaDeleteGroupFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'DeletePermissionGroup',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onDeleteGroupError(error));
        } else if (response) {
            yield put(onDeleteGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onDeleteGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onDeleteGroupError(error));
    }
}

export function* sagaGetAllUsersFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem2, {
            ...payload,
            method: 'UserList',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAllUsersError(error));
        } else if (response) {
            const resp = normalizeClients(response.data);
            yield put(onGetAllUsersSuccess(resp));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAllUsersError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAllUsersError(error));
    }
}

export function* sagaUsersGroupsFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'GetPermissionUserGroups',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetUserGroupsError(error));
        } else if (response) {
            yield put(onGetUserGroupsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetUserGroupsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetUserGroupsError(error));
    }
}

export function* sagaUsersRolesFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'GetPermissionDirectUserRoles',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetUserRolesError(error));
        } else if (response) {
            yield put(onGetUserRolesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetUserRolesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetUserRolesError(error));
    }
}

export function* sagaEditUserFetch(action) {
    try {
        const { payload } = action;
        const { user, userRoles, userGroups } = payload;
        const { response, error } = yield call(fetchRoleSystem, {
            ...user,
            method: 'EditUser',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEditUserError(error));
        } else if (response) {
            yield put(onEditUserSuccess(response.data));
            if (userRoles) {
                yield put(onSetDirectUserRoleFetch(userRoles));
            }
            if (userGroups) {
                yield put(onSetUserGroupsFetch(userGroups));
            }
            yield put(onGetAllUsersFetch());
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEditUserError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEditUserError(error));
    }
}

export function* sagaAddUserFetch(action) {

    try {
        const { payload } = action;
        // const { user, userRoles, userGroups } = payload;
        const { response, error } = yield call(fetchRoleSystem2, {
            ...payload,
            method: 'UserAdd',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAddUserError(error));
        } else if (response) {
            yield put(onAddUserSuccess(response.data));
            // const intUserID = response.data.data.ID;
            // userRoles.intUserID = intUserID;
            // userGroups.intUserID = intUserID;
            // yield put(onSetDirectUserRoleFetch(userRoles));
            // yield put(onSetUserGroupsFetch(userGroups));
            yield put(onGetAllUsersFetch());
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAddUserError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAddUserError(error));
    }
}

export function* sagaEnableUserFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'EnableUser',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEnableUserError(error));
        } else if (response) {
            yield put(onEnableUserSuccess(response.data));
            yield put(onGetAllUsersFetch());
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEnableUserError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEnableUserError(error));
    }
}

export function* sagaAddDirectUserRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'AddPermissionDirectUserRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAddDirectUserRoleError(error));
        } else if (response) {
            yield put(onAddDirectUserRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAddDirectUserRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAddDirectUserRoleError(error));
    }
}

export function* sagaAddUserGroup(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'AddPermissionUserGroup',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAddUserGroupError(error));
        } else if (response) {
            yield put(onAddUserGroupSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAddUserGroupError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAddUserGroupError(error));
    }
}

export function* sagaSetDirectUserRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'SetPermissionDirectUserRoles',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSetDirectUserRoleError(error));
        } else if (response) {
            yield put(onSetDirectUserRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSetDirectUserRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSetDirectUserRoleError(error));
    }
}

export function* sagaSetUserGroup(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem, {
            ...payload,
            method: 'SetPermissionUserGroups',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSetUserGroupsError(error));
        } else if (response) {
            yield put(onSetUserGroupsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSetUserGroupsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSetUserGroupsError(error));
    }
}

export function* sagaGetFunctionRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem2, {
            ...payload,
            method: 'GetFunctionRole',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetFunctionRoleError(error));
        } else if (response) {
            yield put(onGetFunctionRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetFunctionRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetFunctionRoleError(error));
    }
}

export function* sagaSetFunctionRoleFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem2, {
            ...payload,
            method: 'SetFunctionRole',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSetFunctionRoleError(error));
        } else if (response) {
            yield put(onSetFunctionRoleSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSetFunctionRoleError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSetFunctionRoleError(error));
    }
}

export function* sagaGetAllowedRRTFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchRoleSystem2, {
            ...payload,
            method: 'GetAllowedRRT',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAllowedRRTError(error));
        } else if (response) {
            yield put(onGetAllowedRRTSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAllowedRRTError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAllowedRRTError(error));
    }
}


export function* roleSystemSaga() {
    yield takeEvery(RoleSystem.GET_PERMISSION_ROLES_FETCH, sagaGetRolesFetch);
    yield takeEvery(RoleSystem.CREATE_PERMISSION_ROLE_FETCH, sagaCreateRoleFetch);
    yield takeEvery(RoleSystem.EDIT_PERMISSION_ROLE_FETCH, sagaEditRoleFetch);
    yield takeEvery(RoleSystem.DELETE_PERMISSION_ROLE_FETCH, sagaDeleteRoleFetch);

    yield takeEvery(RoleSystem.GET_GROUP_ROLES_FETCH, sagaGetGroupRolesFetch);
    yield takeEvery(RoleSystem.ADD_PERM_GROUP_ROLE_FETCH, sagaAddRoleToGroupFetch);
    yield takeEvery(RoleSystem.REMOVE_PERM_GROUP_ROLE_FETCH, sagaRemoveRoleFromGroupFetch);

    yield takeEvery(RoleSystem.GET_PERMISSION_GROUPS_FETCH, sagaGetPermissionGroupsFetch);
    yield takeEvery(RoleSystem.CREATE_PERM_GROUP_FETCH, sagaCreateGroupFetch);
    yield takeEvery(RoleSystem.EDIT_PERM_GROUP_FETCH, sagaEditGroupFetch);
    yield takeEvery(RoleSystem.DELETE_PERM_GROUP_FETCH, sagaDeleteGroupFetch);

    yield takeEvery(RoleSystem.GET_ALL_USERS_FETCH, sagaGetAllUsersFetch);
    yield takeEvery(RoleSystem.GET_USER_GROUPS_FETCH, sagaUsersGroupsFetch);
    yield takeEvery(RoleSystem.GET_DIRECT_USER_ROLES_FETCH, sagaUsersRolesFetch);

    yield takeEvery(RoleSystem.EDIT_USER_FETCH, sagaEditUserFetch);
    yield takeEvery(RoleSystem.ADD_USER_FETCH, sagaAddUserFetch);
    yield takeEvery(RoleSystem.ENABLE_USER_FETCH, sagaEnableUserFetch);

    yield takeEvery(RoleSystem.ADD_DIRECT_USER_ROLE_FETCH, sagaAddDirectUserRoleFetch);
    yield takeEvery(RoleSystem.ADD_USER_GROUP_FETCH, sagaAddUserGroup);

    yield takeEvery(RoleSystem.SET_DIRECT_USER_ROLE_FETCH, sagaSetDirectUserRoleFetch);
    yield takeEvery(RoleSystem.SET_USER_GROUPS_FETCH, sagaSetUserGroup);
    /* update saved data */
    yield takeEvery(RoleSystem.SET_DIRECT_USER_ROLE_SUCCESS, sagaUsersRolesFetch);
    yield takeEvery(RoleSystem.SET_USER_GROUPS_SUCCESS, sagaUsersGroupsFetch);

    yield takeEvery(RoleSystem.GET_FUNCTION_ROLE_FETCH, sagaGetFunctionRoleFetch);
    yield takeEvery(RoleSystem.SET_FUNCTION_ROLE_FETCH, sagaSetFunctionRoleFetch);

    yield takeEvery(RoleSystem.GET_ALLOWED_RRT_FETCH, sagaGetAllowedRRTFetch);
}
