import axios from 'axios';
import { AGENTS_URL, API_URL, FILE_STORE_URL, SMS_REPORT_URL } from '../../utils/consts/global';
import { getBusinessError } from '../helpers/commonHelpers';

export function fetchAgents(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    return axios({
        method: 'post',
        url: `${API_URL}${AGENTS_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}
export function fetchDictionaries1(payload) {
    return {
        response: { data: [] },
        error: null,
    };
}


export function sendFile(payload) {
    return axios({
        method: "post",
        url: `${API_URL}${FILE_STORE_URL}`,
        data: payload,
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(
            (response) => {
                if(response.data.dataType === 'error') {
                    return { error: response.data.data, response: null };
                }
                if ("string" === typeof response.data) {
                    return { error: response.data, response: null };
                } else {
                    return { error: null, response };
                }
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}
