import React from "react";
import { connect } from "react-redux";
import { Spinner } from "../Spinner/Spinner";
import {
    INPUT_PASSWORD_TYPE,
    INPUT_TEXT_TYPE, PERM_ADD_CASHIER, PERM_GET_CASHIERS,
    SMALL_SCREEN,PWD_CASHIER_LENGTH
} from "../../../utils/consts/global";

import {
    PWD_NUM_REGEXP
} from "../../../utils/helpers/commonHelpers";

import { emptyCashier, makeEnabled, rows } from './AdmCashiersPanelHelper';
import { ActionButton } from "../ActionButton/ActionButton";
import { Modal } from "../Modal/Modal";
import cn from "classnames";
import styles from "./AdmCashiersPanel.less";
import SweetAlert from "react-bootstrap-sweetalert";
import { TableHeader } from "../TableHeader/TableHeader";
import { TableBody } from "../TableBody/TableBody";
import { onCreateCashierFetch, onEditCashierFetch, onGetCashiersFetch } from "../../../store/agents/agents.actions";
import { ButtonsOkCancelBlock } from "../ButtonsOkCancelBlock/ButtonsOkCancelBlock";

export class AdmCashiersComponent extends React.Component {
    state = {
        refID: Number(sessionStorage.getItem("ParentRefID")),
        refType: Number(sessionStorage.getItem("ParentRefType")),

        editMode: false,
        isEditShown: false,
        isAlertShown: false,
        isDeleteShown: false,
        createError: false,

        selectedCashier: { ...emptyCashier },
        selectedCashierId: "",
        passwordType: INPUT_PASSWORD_TYPE
    };

    componentDidUpdate(prevProps) {
        const createCashier = this.props.createCashier;
        const editCashier = this.props.editCashier;

        if (createCashier.errors && JSON.stringify(prevProps.createCashier.errors) !== JSON.stringify(createCashier.errors)) {
            this.setState({
                isAlertShown: true
            });
        }

        if ( createCashier.success && JSON.stringify(prevProps.createCashier.success) !== JSON.stringify(createCashier.success) ||
            editCashier.success && JSON.stringify(prevProps.editCashier.success) !== JSON.stringify(editCashier.success) ) {
            this.getCashiers();
            this.setState({
                isEditShown: false,
                editMode: false,
            });
        }
    }

    getCashiers = () => {
        const { refID, refType } = this.state;

        if (this.props.permissions.success?.indexOf(PERM_GET_CASHIERS) !== -1) {
            this.props.onGetCashiersFetch({
                parentRefID: refID,
                parentRefType: refType
            });
        }
    };

    componentDidMount() {
        this.getCashiers();
    }

    addCashier = () => {
        this.setState({
            isEditShown: true,
            editMode: false,
            selectedCashier: { ...emptyCashier }
        });
    };

    onCloseAlert = () => {
        this.setState({
            isAlertShown: false,
            isDeleteShown: false
        });
    };

    onConfirmDisable = () => {
        const { id, } = this.state.selectedCashier;

        const query = {
            cashierUserID: Number(id),
            password: "",
            enabled: false
        };

        this.props.onEditCashierFetch(query);

        this.setState({ isDeleteShown: false });
        this.onCloseAlert();
    };

    generatePass = () => {
        let password = Math.random();
        password = password.toString();
        password = password.substr(password.length - PWD_CASHIER_LENGTH, password.length);
        this.setState((prev) => ({
            ...prev,
            selectedCashier: {
                ...emptyCashier,
                ...prev.selectedCashier,
                password,
            },
            passwordType: INPUT_TEXT_TYPE
        }));
    }

    handleInputsChange = (event) => {
        const { target } = event;
        let { name, value } = target;
        event.persist();

        this.setState((prev) => ({
            ...prev,
            selectedCashier: {
                ...emptyCashier,
                ...prev.selectedCashier,
                [name]: value
            }
        }));
    };

    onCancelEdit = () => {
        this.setState({
            ...emptyCashier,
            isEditShown: false
        });
    };

    onConfirmEdit = () => {
        const selectedCashier = this.state.selectedCashier;
        const { id, login, name, password, email, note } = selectedCashier;
        const phone = selectedCashier.phone.replace(/\D/g, "");
        const enabled = Boolean(selectedCashier.enabled);

        if (this.state.editMode) {
            this.props.onEditCashierFetch({ cashierUserID: id, login, name, password, email, note, phone, enabled });
        } else {
            this.props.onCreateCashierFetch({ login, name, password, email, note, phone, enabled: true });
        }
    };

    onEditLine = (cashier) => {
        this.setState({
            isEditShown: true,
            editMode: true,
            selectedCashier: {
                ...emptyCashier,
                ...cashier
            }
        });
    }

    onDataLineEvents = (e) => {

        if (e.type === "switch") {
            const { id, login, name, email, note, phone } = e.item;
            const password = '';
            const enabled = !(e.item?.enabled);
            this.props.onEditCashierFetch({ cashierUserID: id, login, name, password, email, note, phone, enabled });
        }

        if (e.type === "edit") {
            this.setState({
                isEditShown: true,
                editMode: true,
                selectedCashier: {
                    ...emptyCashier,
                    ...e.item
                }
            });
        }

        if (e.type === "remove") {
            this.setState({
                editMode: false,
                selectedCashier: {
                    ...e.item
                },
                isEditShown: false,
                isDeleteShown: true
            });

        }
    };

    changePasswordType = () => {
        this.setState({
            passwordType: this.state.passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE
        });
    };

    isFormValid = () => {
        const { selectedCashier } = this.state;
        let valid = true;
        if (!selectedCashier.password.match(PWD_NUM_REGEXP)) {
            valid = false;
            if(!selectedCashier.password){
                valid = true;
             }
        }
        if (!selectedCashier.login.length){
            valid = false;
        }
        return valid;
    };

    render() {
        const { selectedCashier } = this.state;
        const {
            name, password, email, login, phone,
            note
        } = selectedCashier;
        const fetching = this.props.getCashiers.fetching;
        const cashiersList = makeEnabled(this.props.getCashiers.success?.cashiers || []);
        const permissions = this.props.permissions.success || [];
        const isDisabled = !this.isFormValid();

        return (
            <div className={cn(styles.mainPanel, "main-panel")}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Users</h1>}
                        <div className="col-md-12">
                            <div className={cn(styles.card, "card")}>
                                <div className={cn("card-body", styles.topPadding)}>
                                    <div className={cn("nav-scroller d-flex", styles.borderBottomLine)}>
                                        <div className="d-flex d-md-inline ml-md-auto py-2 py-md-0">
                                            {permissions.indexOf(PERM_ADD_CASHIER) !== -1 && (
                                                <ActionButton click={this.addCashier}>add Cashier</ActionButton>)}
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={rows} hasCollapseSwich={false} />
                                                <TableBody
                                                    dataTable={cashiersList}
                                                    rowNames={rows}
                                                    hasCollapseSwich={false}
                                                    events={this.onDataLineEvents}
                                                    onSelect={this.onEditLine}
                                                />
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onCloseAlert}
                    >
                        <h1 className={styles.heading}>Unable to create {name}</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span
                                    className={styles.bolder}>{this.props.createCashier.errors?.errorMessage?.split("|")[1] || "unknown error"}</span>
                            </div>
                        </div>
                    </SweetAlert>
                )}
                {this.state.isDeleteShown && (
                    <SweetAlert
                        show={this.state.isDeleteShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmDisable}
                        onCancel={this.onCloseAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>Are you sure you want to disable {name}? </h1>
                        <div className={styles.deleteString}>
                        </div>
                    </SweetAlert>
                )}

                {this.state.isEditShown && (
                    <Modal faderClick={this.onCancelEdit} className={styles.centerModal}>
                        <div className={cn(styles.popUpPosition)}>
                            <div className="card card-primary bg-primary-gradient">
                                <div className="card-body">
                                    <h4 className={cn("mt-3 pb-3 mb-3 fw-bold")}>Cashier details</h4>
                                    <div className={styles.sideBlocks}>
                                        <div className={styles.singleColumn}>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>*login:</label>
                                                <div className="col-md-7 p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="login"
                                                        name="login"
                                                        value={login}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>**password:</label>
                                                <div className={cn(styles.relative, "col-md-7 p-0")}>
                                                    <input type="password" name="password" defaultValue=""
                                                           className={styles.hiddenLogin} />
                                                    <input
                                                        type={this.state.passwordType}
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                    <i className={cn("icon-eye", styles.iconEye, { [styles.selected]: this.state.passwordType !== INPUT_PASSWORD_TYPE })}
                                                       onClick={this.changePasswordType}>&nbsp;</i>
                                                </div>
                                                <button className={styles.genPassBtn} onClick={this.generatePass}>Generate</button>
                                            </div>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>name:</label>
                                                <div className="col-md-7 p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="name"
                                                        name="name"
                                                        value={name}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>phone:</label>
                                                <div className="col-md-7  p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="phone"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>e-mail:</label>
                                                <div className="col-md-7  p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="e-mail"
                                                        name="email"
                                                        value={email}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>note:</label>
                                                <div className="col-md-7  p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, "form-control input-full")}
                                                        placeholder="note"
                                                        name="note"
                                                        value={note}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.descr}>
                                        <p>* - required fields</p>
                                        <p>** - required fields, 8 digits</p>
                                    </div>
                                    <div className={cn(styles.paddingKeys)}>
                                        <ButtonsOkCancelBlock
                                            onCancelEdit={this.onCancelEdit}
                                            onConfirmEdit={this.onConfirmEdit}
                                            okIsDisabled={isDisabled}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}


const mapDispatchToProps = {
    onGetCashiersFetch,
    onCreateCashierFetch,
    onEditCashierFetch
};

const mapStateToProps = (state) => ({
    getCashiers: state.agents.getCashiers,
    editCashier: state.agents.editCashier,
    createCashier: state.agents.createCashier,
    permissions: state.auth.permissions
});

export const AdmCashiersPanel = connect(mapStateToProps, mapDispatchToProps)(AdmCashiersComponent);
