import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ReportsPage.less';
import * as moment from 'moment';
import { Metric } from '../../../store/metric/metric.consts';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from '../TableHeader/TableHeader';
import { TableBody } from '../TableBody/TableBody';
import { DropDown } from '../DropDown/DropDown';
import {
    fieldsToGroup,
    fillRows,
    fillTotalRows,
    fillTotals,
    getTableData, initialDates,
    initialFiltersState,
    periodOptions,
} from './ReportsPage.helper';
import { findTitleById, getDataForDD } from '../../../utils/helpers/commonHelpers';
import { Spinner } from '../Spinner/Spinner';
import { ButtonsOkCancelBlock } from '../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { SelfStorage } from '../../../store/selfStorage/selfStorage.consts';
import { Dictionaries } from '../../../store/dictionaries/dictionaries.consts';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { TimeZoneSelector } from '../TimeZoneSelector/TimeZoneSelector';
import { DAYTIME_TIMEZONE_FORMAT } from '../../../utils/consts/global';

export const ReportsPage = () => {
    const dispatch = useDispatch();

    const incomeClients = useSelector((state) => state.dictionaries.getClients.success) || [];
    const getClients = incomeClients.filter(el => el.type !== '10');
    const getGate = useSelector((state) => state.dictionaries.getGate);
    const getItem = useSelector((state) => state.selfStorage.getItem);
    const setItem = useSelector((state) => state.selfStorage.setItem);
    const getPaymentSum = useSelector((state) => state.metrics.getPaymentSum);
    const getTotals = useSelector((state) => state.metrics.getTotals);
    const [selfParams, updateSelfParams] = useState(initialFiltersState);
    const [dates, setDates] = useState(initialDates);
    const gatesList = getDataForDD(getGate.success || []);
    const clientsList = getDataForDD(getClients);
    const income = getPaymentSum.success?.data.list || [];
    const totals = getTotals.success?.data.list;
    const periods = [...new Set(totals?.map(item => item.dt))];
    const clients = selfParams.filterClients;
    const gates = selfParams.filterGates;
    const rows = fillRows(periods);
    const totalRows = fillTotalRows(periods);
    const reportsData = fillTotals(totals);
    const totalsData = [...reportsData];
    const td = getTableData({ income, periods, clients, clientsList, gates, gatesList });
    const tableData = [...td].flat();

    const storageFetching = getItem.fetching;
    const setItemFetching = setItem.fetching;
    const fetchingTotals = getTotals.fetching;
    const fetching = getPaymentSum.fetching || storageFetching;

    useEffect(() => {
        if (!getGate.success) {
            dispatch({
                type: Dictionaries.GET_GATE_FETCH,
                payload: {},
            });
        }
        dispatch({
            type: SelfStorage.GET_ITEM_FETCH,
            payload: { strKey: 'reports' },
        });
    }, []);

    useEffect(() => {
        if (getItem.success) {
            const params = getItem.success;
            let newDates = [];
            if (getItem.success.dtRange) {
                const separated = getItem.success.dtRange.split(';');
                newDates[0] = moment(separated[0]).toDate();
                newDates[1] = moment(separated[1]).toDate();
            } else {
                newDates = dates;
            }
            params.dtRange = `${moment(newDates[0]).format()};${moment(newDates[1]).format()}`;
            updateSelfParams(params);
            setDates(newDates);
        }
    }, [getItem.success]);

    useEffect(() => {
        if (dates[0] === initialDates[0] && dates[1] === initialDates[1]) {
            return;
        }

        const dtRange = `${moment(dates[0]).format()};${moment(dates[1]).format()}`;
        const payload = {
            ...selfParams,
            dtRange,
        };

        loadTotals({
            ...payload,
            fieldsToGroup: [],
        });
        loadData(payload);

    }, [dates]);

    const loadTotals = (payload) => {
        dispatch({
            type: Metric.GET_TOTALS_FETCH,
            payload,
        });
    };

    const loadData = (data) => {
        data.fieldsToGroup = [...fieldsToGroup];

        if (data.filterClients?.length) {
            data.fieldsToGroup.push('client');
        }

        if (data.filterGates?.length) {
            data.fieldsToGroup.push('gate');
        }

        const filterGatesOrMethods = [...data.filterGates];

        const payload = {
            ...data,
            filterGatesOrMethods,
        };

        delete payload.filterGates;

        dispatch({
            type: Metric.GET_PAYMENT_SUM_FETCH,
            payload,
        });
    };

    const handlerClientSelect = (client) => {
        const id = client.id;
        if (selfParams.filterClients.indexOf(id) !== -1) return;

        const payload = {
            ...selfParams,
        };

        payload.filterClients.push(id);
        updateSelfParams(payload);
        loadData(payload);
    };

    const handlerOperatorSelect = (operator) => {
        const id = operator.id;
        if (selfParams.filterGates.indexOf(id) !== -1) return;

        const payload = {
            ...selfParams,
        };
        payload.filterGates.push(id);
        updateSelfParams(payload);
        loadData(payload);
    };

    const removeClients = (clientId) => {
        const filterClients = [...selfParams.filterClients];
        const idx = filterClients.indexOf(clientId);
        filterClients.splice(idx, 1);

        const payload = {
            ...selfParams,
            filterClients,
        };

        updateSelfParams(payload);

        // if (!filterClients.length) {
        loadData(payload);
        // }
    };

    const removeGates = (id) => {
        const filterGates = [...selfParams.filterGates];
        const idx = filterGates.indexOf(id);
        filterGates.splice(idx, 1);

        const payload = {
            ...selfParams,
            filterGates,
        };

        updateSelfParams({
            ...selfParams,
            filterGates,
        });

        // if (!filterGates.length) {
        loadData(payload);
        // }
    };

    const saveOrder = () => {
        dispatch({
            type: SelfStorage.SET_ITEM_FETCH,
            payload: { strKey: 'reports', anyValue: selfParams },
        });
    };

    const periodSelect = (val) => {
        const dtGroup = val.id;
        const dtRange = `${moment(dates[0]).format()};${moment(dates[1]).format()}`;

        const payload = {
            ...selfParams,
            dtGroup,
            dtRange,
        };
        updateSelfParams(payload);

        loadTotals({
            ...selfParams,
            dtRange,
            dtGroup,
            fieldsToGroup: [],
        });
        loadData(payload);
    };
    const onDatesChanged = (incomeDates) => {
        setDates(incomeDates);
        const params = { ...selfParams };
        params.dtRange = `${moment(incomeDates[0]).format()};${moment(incomeDates[1]).format()}`;
        updateSelfParams(params);
    };

    const ddTimeZoneSelect = (value) => {
        const payload = {
            ...selfParams,
            ...value,
        };
        updateSelfParams(payload);
        loadData(payload);
    };

    return (
        <div className="main-panel">
            <div className="container">
                <div className="page-inner">
                    <div className={cn(styles.card)}>
                        <div className="col-md-12">
                            <div className={styles.filterBarItem}>
                                <div className={cn(styles.fixedDropWidth)}>
                                    <div className={styles.dropTitle}>Period:</div>
                                    <DropDown
                                        options={periodOptions}
                                        onSelect={periodSelect}
                                        value={selfParams.dtGroup}>
                                        {selfParams.dtGroup}
                                    </DropDown>

                                    <div className={styles.filterBarItem}>
                                        <DateTimeRangePicker
                                            value={dates}
                                            onChange={onDatesChanged}
                                            disableClock={true}
                                            format="dd/MM/yy HH:mm:ss"
                                            calendarClassName={styles.calendarBg}
                                            className={styles.datePickerRange}
                                            showLeadingZeros={true}
                                            calendarIcon={null}
                                            clearIcon={null}
                                            locale="en-US"
                                        />
                                    </div>
                                    <div className={styles.filterBarItem}>
                                        <TimeZoneSelector
                                            color='blue'
                                            name="outTZ"
                                            onSelect={ddTimeZoneSelect}
                                            value={selfParams.outTZ}
                                        />
                                    </div>
                                    {getGate.fetching && (
                                        <div className={styles.dropPlace}>
                                            <Spinner />
                                        </div>
                                    )}
                                    {!getGate.fetching && (
                                        <div className={styles.dropPlace}>
                                            <div className={styles.dropTitle}>Payment Method:</div>
                                            <DropDown
                                                options={gatesList}
                                                onSelect={handlerOperatorSelect}
                                                className={styles.navButton}
                                                allowFilter
                                            >
                                                select operator
                                            </DropDown>
                                        </div>
                                    )}

                                    {storageFetching && (
                                        <div className={styles.dropPlace}>
                                            <Spinner />
                                        </div>
                                    )}
                                    {!storageFetching && (
                                        <div className={styles.dropPlace}>
                                            <div className={styles.dropTitle}>Client:</div>
                                            <DropDown
                                                options={clientsList}
                                                onSelect={handlerClientSelect}
                                                className={styles.navButton}
                                                allowFilter
                                            >
                                                select client
                                            </DropDown>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={cn(styles.card, 'card')}>
                                <div className={cn('card-body', styles.topMargin)}>
                                    <h1>Outgoing operations</h1>
                                    <div className={styles.customerSelector}>
                                        <div>
                                            <div className={styles.selectorPlace}>

                                                <span>Payment Methods: </span>
                                                {!selfParams.filterGates?.length && (
                                                    <span className={styles.selectTitle}>
                                                        add a payment method.
                                                    </span>
                                                )}
                                                {!!selfParams.filterGates?.length && selfParams.filterGates?.map((el, idx) => (
                                                    <span className={cn(styles.groupEl, styles.blue)} key={String(idx)}
                                                          onClick={() => {
                                                              removeGates(el);
                                                          }}>
                                                    {findTitleById(gatesList, el)}&nbsp;
                                                </span>
                                                ))}
                                            </div>

                                            <div className={styles.selectorPlace}>
                                                <span className={cn(styles.blue)}>Clients: </span>
                                                {!selfParams.filterClients?.length && (
                                                    <span className={styles.selectTitle}>
                                                        add a client.
                                                    </span>
                                                )}
                                                {!!selfParams.filterClients?.length && selfParams.filterClients?.map((el, idx) => (
                                                    <span className={styles.groupEl} key={String(idx)} onClick={() => {
                                                        removeClients(el);
                                                    }}>
                                                    {findTitleById(clientsList, el)}&nbsp;
                                                </span>
                                                ))}
                                            </div>
                                        </div>

                                        {setItemFetching && (
                                            <div className={styles.dropPlace}>
                                                <Spinner />
                                            </div>
                                        )}
                                        {!setItemFetching && (
                                            <ButtonsOkCancelBlock
                                                onConfirmEdit={saveOrder}
                                                okText="Save options"
                                                hideCancelButton
                                                small
                                            />
                                        )}
                                    </div>

                                    <div className="table-responsive">
                                        {fetchingTotals ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader
                                                    rowNames={totalRows}
                                                    hasCollapseSwich={false}
                                                />
                                                <TableBody
                                                    dataTable={totalsData}
                                                    rowNames={totalRows}
                                                    hasCollapseSwich={false} />
                                            </table>
                                        )}
                                    </div>

                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader
                                                    rowNames={rows}
                                                    hasCollapseSwich={false}
                                                />
                                                <TableBody
                                                    dataTable={tableData}
                                                    rowNames={rows}
                                                    hasCollapseSwich={false} />
                                            </table>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
