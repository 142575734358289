import React from 'react';
import cn from 'classnames';
import styles from './ActionButton.less';

export const ActionButton = (props) => {
    const click = () => {
        if (props.click) {
            props.click();
        }
    };

    return (
        <button className={cn(styles.buttonAdd, props.className, {[styles.disabled]: props.disabled})}
                onClick={click}
                disabled={props.disabled}
        >
            {' '}
            <span className="btn-label">
                {' '}
                {!props.disableIco && (<i className="fa fa-plus"> </i>)}
            </span>{' '}
            {props.children}
        </button>
    );
};
