import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { CreatePaymentPanel } from '../../components/shared/CreatePaymentPanel/CreatePaymentPanel';

const CreatePayment = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();
    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="CreatePayment" />
            <CreatePaymentPanel history={props.history} />
        </div>
    );
};

export default CreatePayment;
