import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './LegalPaymentMethods.less';
import { makeHumanReadable, rows } from './LegalPaymentMethods.helper';
import 'react-datetime/css/react-datetime.css';
import { TableHeader } from '../../TableHeader/TableHeader';
import { TableBody } from '../../TableBody/TableBody';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { useDispatch, useSelector } from 'react-redux';
import { Merchants } from '../../../../store/merchants/merchants.consts';
import { LEGAL_IN_DRAFT } from '../MerchantDetails/MerchantDetails.helper';

const LegalPaymentMethodsComponent = (props) => {
    const dispatch = useDispatch();
    const {
        gates,
        currencies,
    } = props.dict;

    const getLegalPaymentMethods = useSelector((state) => state.merchants.getLegalPaymentMethods.success);
    const [tableData, setTableData] = useState([]);
    const disabled = props.legal.state !== LEGAL_IN_DRAFT;

    useEffect(() => {
        setTableData(makeHumanReadable({
            getLegalPaymentMethods,
            currencies,
            disabled,
            gates,
        }));

    }, [getLegalPaymentMethods]);

    const getMethods = () => {
        if (props.legal.id) {
            dispatch({
                type: Merchants.LEGAL_GET_METHODS_FETCH,
                payload: {
                    intLegalID: props.legal.id,
                    intMerchantID: props.merchant.id,
                },
            });
        }
    };

    useEffect(() => {
        getMethods();
    }, [props.legal.id]);

    const goNext = () => {
        props.onSubmit(tableData);
    };

    const onCancel = () => {
        getMethods();
    };


    const onCheckBoxChanged = ({ el, line, name }) => {
        if (name === 'currencies') {
            const idx = line[name].selected.indexOf(el);
            if (idx === -1) {
                line[name].selected.push(el);
                line.on = true;
            } else {
                line[name].selected.splice(idx, 1);
                if (line[name].selected.length === 0) {
                    line.on = false;
                }
            }
        }

        if (name === 'flag') {
            line[name].selected = [el];
        }
        setTableData([...tableData]);
    };

    const onEnableClick = ({ type, item }) => {
        if (type === 'switch') {
            const line = tableData.find(el => el.gateName === item.gateName);
            line.on = !line.on;

            if (line.on) {
                if (!line.currencies.selected.length) {
                    line.currencies.selected = [...line.currencies.all];
                }
                if (!line.flag.selected.length && line.flag.all.indexOf('FIN') !== -1) {
                    line.flag.selected = ['FIN'];
                }
            }
            setTableData([...tableData]);
        }
    };

    const onDDChanged = ({ item, line }) => {
        line.proxygate.selected = item.id;
        setTableData([...tableData]);
    };

    return (
        <div className={cn(styles.createMerch)}>
            <div className={styles.methodsListTable}>
                {!!tableData?.length && (
                    <table className="display table table-striped table-hover dataTable)">
                        <TableHeader rowNames={rows} hasCollapseSwich={false} />
                        <TableBody
                            dataTable={tableData}
                            rowNames={rows}
                            hasCollapseSwich={false}
                            onCheckBoxChanged={onCheckBoxChanged}
                            onDDChanged={onDDChanged}
                            events={onEnableClick}
                        />
                    </table>
                )}
            </div>
            <div className={styles.bottomButtonPlace}>
                {!disabled && (<>
                    <ButtonsOkCancelBlock
                        id="save"
                        onCancelEdit={onCancel}
                        okIsDisabled={false}
                        onConfirmEdit={goNext}
                        okText="Save"
                        cancelText="Reload"
                    />
                </>)}
            </div>

        </div>
    );
};

export const LegalPaymentMethods = React.memo(LegalPaymentMethodsComponent);
