import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes/AppRoutes';
import { Spinner } from '../../components/shared/Spinner/Spinner';
import { Notification } from '../../components/shared/Notification/Notification';
import { Login } from '../Login/Login';
import RoutesPaths from '../../routes/RoutesPaths';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/less/styles.less';
// import NotFound from '../NotFound/NotFound';
export const App = (props) => {
    return (
        <ConnectedRouter history={props.history}>
            <Switch>
                <Suspense fallback={<Spinner />}>
                    <Route path={RoutesPaths.LOGIN} exact={true} component={Login} />
                    <Route component={AppRoutes} />

                    {/*<Route path="*" exact={true} component={NotFound} />*/}
                </Suspense>
            </Switch>
            <Notification />
        </ConnectedRouter>
    );
};
