import axios from 'axios';
import { API_URL, SUMMARY_URL, TRANSACTIONS_URL, SMS_REPORT_URL, FPGATE_URL } from '../../utils/consts/global';
import { getBusinessError } from '../helpers/commonHelpers';

export function fetchTransactions(payload, token) {
    return axios({
        method: 'post',
        url: `${API_URL}${TRANSACTIONS_URL}`,
        data: payload,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function fetchFPGate(payload, token) {
    return axios({
        method: 'post',
        url: `${API_URL}${FPGATE_URL}`,
        data: payload,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function fetchSummary(payload) {
    return axios({
        method: 'post',
        url: `${API_URL}${SUMMARY_URL}`,
        data: payload,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function getXlsFile(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    axios({
        url: `${API_URL}${TRANSACTIONS_URL}`,
        method: 'POST',
        responseType: 'blob',
        headers,
        data: payload,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'operations.xlsx');
        document.body.appendChild(link);
        link.click();
    });
}
