import { rowNamesOptions } from "../TableBody/TableBodyHelper";

export const refTypes = [
    { id: '0', title: 'none' },
    { id: '1', title: 'client' },
    { id: '2', title: 'sub client' },
];

export const userTypes = [
    {id:'User', title:'User'},
    {id:'Unipesa', title:'Unipesa'},
    {id:'Whitelabel', title:'Whitelabel'},
    {id:'Branch', title:'Branch'},
    {id:'Merchant', title:'Merchant'},
    {id:'SuperAgent', title:'SuperAgent'},
    {id:'Agent', title:'Agent'},
    {id:'Cashier', title:'Cashier'},
];

export const getNotEmpty = (array) => {
    return array?.filter(el=>!!el) || [];
}

export const rows = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: false },
    { name: 'enabled', title: 'enabled', className: 'noWrap', options: '', isSortable: false },
    { name: 'login', title: 'login', className: 'noWrap', options: '', isSortable: false },
    { name: 'roleType', title: 'role type', className: 'smallCase', options: '', isSortable: false },
    { name: 'role', title: 'role', className: 'smallCase', options: '', isSortable: false },
    { name: 'note', title: 'note', className: 'noWrap', options: '', isSortable: false },
    { name: 'created', title: 'created', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
    {
        name: 'createdByUID',
        title: 'created by',
        className: 'noWrap',
        options: rowNamesOptions.hidden,
        isSortable: false,
    },
    { name: 'updated', title: 'updated', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
    {
        name: 'updatedByUID',
        title: 'updated by',
        className: 'noWrap',
        options: rowNamesOptions.hidden,
        isSortable: false,
    },
    {
        name: 'paramsJSON',
        title: 'paramsJSON',
        className: 'noWrap',
        options: rowNamesOptions.hidden,
        isSortable: false,
    },
    {
        name: 'privateJSON',
        title: 'privateJSON',
        className: 'noWrap',
        options: rowNamesOptions.hidden,
        isSortable: false,
    },
];


export const emptyUser = {
    strRoleType: '',
    strRole: '',
    strLogin: '',
    strPassword: '',
    strEmail: '',
    strNote: '',
    arrParams: null,
};

export const objToArray = (obj) => {
    let arr = [];

    for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
            arr.push(obj[k]);
        }
    }
    return arr;
};
