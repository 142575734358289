import React from "react";

export const accountsListHeaders = [
    { name: "account", title: "account", className: "noWrap", options: "", isSortable: true },
    { name: "balance", title: "balance", className: "noWrap", options: "", isSortable: true },
    { name: "currency", title: "currency", className: "noWrap", options: "", isSortable: true }
];

export const emptyAgent = {
    enabled: true,
    NIF: "",
    RC: "",
    city: "",
    country: "",
    email: "",
    agentName: "",
    name: "",
    agentLogin: "",
    password: "",
    phone: "",
    postCode: "",
    streetAddress: "",
    description: '',
};

export const commissionFields = [
    "sender_commission",
    "sender_bonus_instant",
    "recipient_commission",
    "recipient_bonus",
    "payer_commission_top",
    "payer_commission_external"
];

export const requiredFields = [
    "name",
    "legalName",
    "city",
    "streetAddress",
];

export const noErrorsFields = () => {
    const allFields = { ...emptyAgent };
    for (const property in allFields) {
        if( allFields.hasOwnProperty( property ) ) {
            allFields[property] = true;
        }
    }
    return allFields;
};

export const addCurrenciesName = (arr, currencies) => {
    return arr.map((el) => {
        return {
            ...el,
            currency: currencies.find((cur) => Number(cur.codeNum) === el.currency)?.codeAlpha || el.currency
        };
    });
};

export const tabNames = {
    AGENT_TAB: "Agent",
    ACCOUNT_TAB: "Accounts",
    CASHIERS_TAB: "Cashiers"
};
