export const Agents = {
    GET_CASHIERS_FETCH: 'GET_CASHIERS_FETCH',
    GET_CASHIERS_SUCCESS: 'GET_CASHIERS_SUCCESS',
    GET_CASHIERS_ERROR: 'GET_CASHIERS_ERROR',
    GET_CASHIERS_CLEAR: 'GET_CASHIERS_CLEAR',

    CREATE_CASHIER_FETCH:'CREATE_CASHIER_FETCH',
    CREATE_CASHIER_SUCCESS:'CREATE_CASHIER_SUCCESS',
    CREATE_CASHIER_ERROR:'CREATE_CASHIER_ERROR',

    EDIT_CASHIER_FETCH:'EDIT_CASHIER_FETCH',
    EDIT_CASHIER_SUCCESS:'EDIT_CASHIER_SUCCESS',
    EDIT_CASHIER_ERROR:'EDIT_CASHIER_ERROR',

    GET_AGENTS_FETCH: 'GET_AGENTS_FETCH',
    GET_AGENTS_SUCCESS: 'GET_AGENTS_SUCCESS',
    GET_AGENTS_ERROR: 'GET_AGENTS_ERROR',
    GET_AGENTS_CLEAR: 'GET_AGENTS_CLEAR',

    CREATE_AGENT_FETCH:'CREATE_AGENT_FETCH',
    CREATE_AGENT_SUCCESS:'CREATE_AGENT_SUCCESS',
    CREATE_AGENT_ERROR:'CREATE_AGENT_ERROR',
    CREATE_AGENT_CLEAR:'CREATE_AGENT_CLEAR',

    EDIT_AGENT_FETCH:'EDIT_AGENT_FETCH',
    EDIT_AGENT_SUCCESS:'EDIT_AGENT_SUCCESS',
    EDIT_AGENT_ERROR:'EDIT_AGENT_ERROR',
    EDIT_AGENT_CLEAR:'EDIT_AGENT_CLEAR',

    DELETE_AGENT_FETCH:'DELETE_AGENT_FETCH',
    DELETE_AGENT_SUCCESS:'DELETE_AGENT_SUCCESS',
    DELETE_AGENT_ERROR:'DELETE_AGENT_ERROR',

    GET_AGENT_INFO_FETCH: 'GET_AGENT_INFO_FETCH',
    GET_AGENT_INFO_SUCCESS: 'GET_AGENT_INFO_SUCCESS',
    GET_AGENT_INFO_ERROR: 'GET_AGENT_INFO_ERROR',
    GET_AGENT_INFO_CLEAR: 'GET_AGENT_INFO_CLEAR',

    GET_AGENT_DETAILS_FETCH: 'GET_AGENT_DETAILS_FETCH',
    GET_AGENT_DETAILS_SUCCESS: 'GET_AGENT_DETAILS_SUCCESS',
    GET_AGENT_DETAILS_ERROR: 'GET_AGENT_DETAILS_ERROR',
    GET_AGENT_DETAILS_CLEAR: 'GET_AGENT_DETAILS_CLEAR',

    FILE_SEND_FETCH: 'FILE_SEND_FETCH',
    FILE_SEND_SUCCESS: 'FILE_SEND_SUCCESS',
    FILE_SEND_ERROR: 'FILE_SEND_ERROR',
    FILE_SEND_CLEAR: 'FILE_SEND_CLEAR',
};
