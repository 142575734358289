import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AgentSummaryTab.less';
import { NavLink } from 'react-router-dom';
import RoutesPaths from '../../../../routes/RoutesPaths';
import { PERM_GET_ACCOUNTS, PERM_GET_BALANCE } from '../../../../utils/consts/global';
import { useDispatch, useSelector } from 'react-redux';
import { getSummaryBalances } from './AgentSummaryTab.helper';

import * as moment from 'moment';
import { Metric } from '../../../../store/metric/metric.consts';
import { Spinner } from '../../Spinner/Spinner';
import { ActionButton } from '../../ActionButton/ActionButton';
import { legalStatuses, PUBLISHED } from '../../Merchant/MerchantDetails/MerchantDetails.helper';
import { AppActions } from '../../../../store/app/app.consts';

export const AgentSummaryTab = (props) => {
    const SHOW_LAST_DAYS = 30;
    const dispatch = useDispatch();
    const currencies = props.currencies;
    const { agent, legal } = props;
    const permissions = useSelector((state) => state.auth.permissions.success) || [];
    const getPaymentSum = useSelector((state) => state.metrics.getPaymentSum) || [];
    const metric = getPaymentSum.success?.data.list || [];
    const currencyMetric = {};

    useEffect(() => {
        return () => {
            // dispatch({
            //     type: AppActions.APP_SET_ADM_CLI_ID,
            //     payload: null,
            // });
        };
    }, []);

    useEffect(() => {
        if (agent?.id) {
            const payload = {
                'filterClients': [agent?.id],
                'fieldsToSum': ['fee'],
                'fieldsToGroup': ['currency'],
                dtRange: `${moment().subtract(SHOW_LAST_DAYS, 'days').format()};${moment().format()}`,
            };

            dispatch({
                type: Metric.GET_PAYMENT_SUM_FETCH,
                payload,
            });
            dispatch({
                type: AppActions.APP_SET_ADM_CLI_ID,
                payload: agent?.id,
            });
        }
    }, [agent]);

    if (metric.length) {
        metric.forEach(el => {
            currencyMetric[el.currency ? el.currency : '-'] = {
                transactionsCount: el.count,
                commissions: el.feeSum,
            };
        });
    }

    const balances = getSummaryBalances({ accounts: agent?.accounts || [], currencies, currencyMetric });

    return (<>
        <div className={styles.infoBlock}>
            <div className={styles.topBlock}>
                <div className={styles.mainTop}>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Agent name</div>
                        <div className={styles.secondColumn}>{agent?.name}</div>
                        <div className={styles.thirdColumn}>&nbsp;</div>
                        <div className={styles.thirdColumn}><span className={styles.padRight}>id:</span> {agent?.id}
                        </div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Agent login</div>
                        <div className={styles.secondColumn}>{agent?.login}</div>
                        <div className={cn(styles.thirdColumn,
                            { [styles.active]: agent?.enabled },
                            { [styles.disabled]: !agent?.enabled },
                        )}>{agent?.enabled ? 'Enabled' : 'Disabled'}</div>
                    </div>
                </div>
            </div>

            {balances?.map((cur, id) => (
                <div className={styles.branchSection} key={String(id)}>
                    <div className={styles.leftSideInfo}>
                        <div className={cn(styles.line, styles.spacesTop)}>
                            <div className={styles.firstColumn}>Currency:</div>
                            <div className={styles.secondColumn}>{cur.currency}</div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Balance total</div>
                            <div className={styles.secondColumn}>{cur.balance}</div>
                            {permissions.indexOf(PERM_GET_BALANCE) !== -1 && (<div className={styles.thirdColumn}>
                                <NavLink to={RoutesPaths.BALANCES + '?clientId=' + agent?.id}
                                         className={cn(styles.link)}>
                                    balance
                                </NavLink>
                            </div>)}
                        </div>
                        {getPaymentSum.fetching ? (
                            <div className={styles.center}>
                                <Spinner />
                            </div>
                        ) : (<>
                                <div className={styles.line}>
                                    <div className={styles.firstColumn}>Transactions last {SHOW_LAST_DAYS}d</div>
                                    <div className={styles.secondColumn}>{cur.transactionsCount}</div>

                                    <div className={styles.thirdColumn}>
                                        <NavLink to={RoutesPaths.PAYMENTS + '?clientId=' + agent?.id}
                                                 className={cn(styles.link)}>
                                            operations
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={styles.line}>
                                    <div className={styles.firstColumn}>Commission last {SHOW_LAST_DAYS}d</div>
                                    <div className={styles.secondColumn}>{cur.commissions}</div>
                                    {id === 0 && (<div className={cn(styles.thirdColumn, styles.disabled)}>
                                        dashboard
                                    </div>)}
                                </div>
                            </>
                        )}
                    </div>


                    <div className={styles.rightSideInfo}>
                        <div className={styles.infoLine}>
                            Status: <span
                            className={cn(styles.active2, styles.padBefore, { [styles.active]: agent?.enabled },
                            )}>{legalStatuses[legal?.legalStatus]}</span>
                        </div>

                        {permissions.indexOf(PERM_GET_ACCOUNTS) !== -1 && (
                            <div className={styles.infoLine}>
                                <NavLink to={RoutesPaths.ACCOUNTS} className={cn(styles.link)}>
                                    accounts list
                                </NavLink>
                            </div>
                        )}
                        <div className={styles.infoLine}>
                            {legal?.legalStatus !== PUBLISHED && (
                                <ActionButton click={props.onPublish} disableIco>
                                    Publish
                                </ActionButton>
                            )}
                        </div>
                    </div>

                </div>
            ))}


            <div className={styles.commentPlace}>
                <div className={styles.line}>
                    <div className={styles.firstColumn}>Comments</div>
                    <div className={styles.secondColumn}>
                        <p>No comments yet</p>
                    </div>
                </div>
            </div>
        </div>

    </>);
};
