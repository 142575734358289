import { SelfStorage } from "./selfStorage.consts";

/*
SET ITEM
*/
export const onSetStorageFetch = (payload) => ({
    type: SelfStorage.SET_ITEM_FETCH,
    payload,
});

export const onSetStorageSuccess = (payload) => ({
    type: SelfStorage.SET_ITEM_SUCCESS,
    payload,
});

export const onSetStorageError = (payload) => ({
    type: SelfStorage.SET_ITEM_ERROR,
    payload,
});

export const onSetStorageClear = (payload) => ({
    type: SelfStorage.SET_ITEM_CLEAR,
    payload,
});

/*
GET ITEM
*/
export const onGetStorageFetch = (payload) => ({
    type: SelfStorage.GET_ITEM_FETCH,
    payload,
});

export const onGetStorageSuccess = (payload) => ({
    type: SelfStorage.GET_ITEM_SUCCESS,
    payload,
});

export const onGetStorageError = (payload) => ({
    type: SelfStorage.GET_ITEM_ERROR,
    payload,
});

export const onGetStorageClear = (payload) => ({
    type: SelfStorage.GET_ITEM_CLEAR,
    payload,
});

/*
REMOVE ITEM
*/
export const onRemoveStorageFetch = (payload) => ({
    type: SelfStorage.REMOVE_ITEM_FETCH,
    payload,
});

export const onRemoveStorageSuccess = (payload) => ({
    type: SelfStorage.REMOVE_ITEM_SUCCESS,
    payload,
});

export const onRemoveStorageError = (payload) => ({
    type: SelfStorage.REMOVE_ITEM_ERROR,
    payload,
});

export const onRemoveStorageClear = (payload) => ({
    type: SelfStorage.REMOVE_ITEM_CLEAR,
    payload,
});
