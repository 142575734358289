import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { Merchants } from "./merchants.consts";

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};


export const getMerchantsList = handleActions(
    {
        [Merchants.MERCHANTS_LIST_GET_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANTS_LIST_GET_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANTS_LIST_GET_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANTS_LIST_GET_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);


export const addMerchant = handleActions(
    {
        [Merchants.MERCHANT_ADD_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_ADD_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const addMerchantLegal = handleActions(
    {
        [Merchants.MERCHANT_ADD_LEGAL_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_LEGAL_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_LEGAL_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_ADD_LEGAL_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const editMerchantLegal = handleActions(
    {
        [Merchants.MERCHANT_EDIT_LEGAL_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_EDIT_LEGAL_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_EDIT_LEGAL_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_EDIT_LEGAL_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getMerchantLegal = handleActions(
    {
        [Merchants.MERCHANT_GET_LEGAL_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_GET_LEGAL_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_GET_LEGAL_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_GET_LEGAL_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getLegalPaymentMethods = handleActions(
    {
        [Merchants.LEGAL_GET_METHODS_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_GET_METHODS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_GET_METHODS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const setLegalPaymentMethods = handleActions(
    {
        [Merchants.LEGAL_SET_METHODS_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_SET_METHODS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_SET_METHODS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.LEGAL_SET_METHODS_CLEAR]: (state) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const addMerchantCard = handleActions(
    {
        [Merchants.MERCHANT_ADD_CARD_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_CARD_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_ADD_CARD_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_ADD_CARD_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getMerchantCard = handleActions(
    {
        [Merchants.MERCHANT_GET_CARD_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_GET_CARD_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.MERCHANT_GET_CARD_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.MERCHANT_GET_CARD_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const publishLegal = handleActions(
    {
        [Merchants.LEGAL_PUBLISH_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_PUBLISH_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Merchants.LEGAL_PUBLISH_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Merchants.LEGAL_PUBLISH_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const merchants = combineReducers({
    addMerchant,
    addMerchantCard,
    getMerchantCard,
    addMerchantLegal,
    editMerchantLegal,
    getMerchantLegal,
    getLegalPaymentMethods,
    setLegalPaymentMethods,
    publishLegal,
    getMerchantsList,
});
