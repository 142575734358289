import { Accounts } from './accounts.consts';
/*
 ACCOUNTS LIST
*/
export const onAccFetch = (payload) => ({
    type: Accounts.ACC_ACCOUNTS_FETCH,
    payload,
});

export const onAccSuccess = (payload) => ({
    type: Accounts.ACC_ACCOUNTS_SUCCESS,
    payload,
});

export const onAccError = (payload) => ({
    type: Accounts.ACC_ACCOUNTS_ERROR,
    payload,
});

export const onAccClear = (payload) => ({
    type: Accounts.ACC_ACCOUNTS_CLEAR,
    payload,
});
