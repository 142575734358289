import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { AccountLogPanel } from "../../components/shared/AccountLogPanel/AccountLogPanel";
import { DepositMerchantPanel } from "../../components/shared/DepositMerchantPanel/DepositMerchantPanel";
import { DEPOSIT_AGENT, DEPOSIT_MERCHANT } from "../../utils/consts/global";

const DepositAgent = () => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active={DEPOSIT_AGENT} />
            <DepositMerchantPanel operation={DEPOSIT_AGENT}/>
        </div>
    );
};

export default DepositAgent;
