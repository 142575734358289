import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../../Spinner/Spinner';
import {
    defaultSortParams,
    findCrumbsPath,
    findLevel,
    getBalancesRowNames,
    getCountersObject,
    getHumanReadable,
    HOME_ID,
    initialBreadCrumbs,
    searchLevelByIdName,
} from './AgentsListHelper';
import { SMALL_SCREEN } from '../../../../utils/consts/global';
import cn from 'classnames';
import styles from './AgentsList.less';
import {
    onCreateAgentFetch,
    onDeleteAgentFetch,
    onEditAgentFetch,
    onGetAgentsFetch,
} from '../../../../store/agents/agents.actions';
import { ActionButton } from '../../ActionButton/ActionButton';
import { emptyAgent } from '../AgentsEdit/AgentsEditHelper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { onCurrenciesFetch } from '../../../../store/dictionaries/dictionaries.actions';
import RoutesPaths from '../../../../routes/RoutesPaths';
import { withRouter } from 'react-router';
import { BreadCrumbs } from '../../BreadCrumbs/BreadCrumbs';
import { TableHeader } from '../../TableHeader/TableHeader';
import { TableBody } from '../../TableBody/TableBody';
import { onClearAppData, onStoreAppData } from '../../../../store/app/app.actions';
import { rowNamesOptions } from '../../TableBody/TableBodyHelper';

export class AgentsPanelComponent extends React.Component {

    state = {
        refID: Number(sessionStorage.getItem('ParentRefID')),
        refType: Number(sessionStorage.getItem('ParentRefType')),
        searchID: '',
        searchName: '',
        openedId: null,
        createAgent: false,
        isAlertShown: false,
        showSuccess: false,
        currentAgent: {},
        showDisabled: false,
        breadCrumbs: [...initialBreadCrumbs(this.props.getToken.login)],
        sortParams: { ...defaultSortParams },
    };

    componentDidMount() {
        const { refID, refType } = this.state;

        if (!this.props.dictionaries.getCurrencies.success?.length) {
            this.props.onCurrenciesFetch();
        }

        this.props.onGetAgentsFetch({
            parentRefID: refID, //0
            parentRefType: refType,
        });
    }

    loadAgents = () => {
        const { refID, refType } = this.state;

        this.props.onGetAgentsFetch({
            parentRefID: refID,
            parentRefType: refType,
        });

        this.setState({
            openedId: null,
            createAgent: false,
            isAlertShown: false,
            showSuccess: true,
            currentAgent: {},
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.createAgent.success !== this.props.createAgent.success && !this.props.createAgent.errors) {
            this.loadAgents();
        }

        if (prevProps.editAgent.success !== this.props.editAgent.success && !this.props.editAgent.errors) {
            this.loadAgents();
        }

        if (prevProps.deleteAgent.success !== this.props.deleteAgent.success && !this.props.deleteAgent.errors) {
            this.loadAgents();
        }
    }


    treeClick = (obj) => {
        const treeArrayIds = obj.key.split('/');
        const openedId = treeArrayIds[treeArrayIds.length - 1] || obj.key;

        this.props.history.push({
            pathname: RoutesPaths.AGENT,
            search: '?agentId=' + openedId,
        });
    };

    addAgent = () => {
        this.props.history.push({
            pathname: RoutesPaths.AGENT_CREATE,
            search: '',
        });
    };

    updateAgent = ({ agent }) => {
        if (agent === null) {   // cancel pressed
            this.setState({ createAgent: false, openedId: null });
            return;
        }

        const { refID, refType } = this.state;
        const income = {
            ...agent,
        };
        const { password, enabled, agentUserID } = agent;

        delete income.id;
        delete income.agentUserID;
        delete income.password;
        delete income.accounts;
        delete income.payer_commission_external;
        delete income.payer_commission_top;
        delete income.recipient_bonus;
        delete income.recipient_commission;
        delete income.sender_bonus_instant;
        delete income.sender_commission;

        //TODO: изменить контракты после бэка
        const request = {
            agentLogin: income.agentLogin,
            agentName: income.name,
            password,
            enabled,
            refType,
            refID,
            agentUserID,
            email: agent.email,
            description: agent.description,
            agentInfo: {
                ...income,
            },
            rates: {
                deposit: {
                    payer_commission_external: Number(agent.payer_commission_external || 0),
                    payer_commission_top: Number(agent.payer_commission_top || 0),
                    recipient_bonus: Number(agent.recipient_bonus || 0),
                    recipient_commission: Number(agent.recipient_commission || 0),
                    sender_bonus_instant: Number(agent.sender_bonus_instant || 0),
                    sender_commission: Number(agent.sender_commission || 0),
                },
            },
        };

        this.setState({
            currentAgent: agent,
        });
        if (this.state.createAgent) {
            this.props.onCreateAgentFetch(request);
        } else {
            this.props.onEditAgentFetch(request);
        }

    };

    deleteAgent = (agent) => {
        this.setState({
            currentAgent: agent,
            isAlertShown: true,
        });
    };

    onConfirmDelete = () => {
        this.setState({
            isAlertShown: false,
        });

        const request = {
            agentUserID: this.state.currentAgent.agentUserID,
            parentRefID: this.state.refID,
            // parentRefType: this.state.refType,
        };

        this.props.onDeleteAgentFetch(request);
    };

    onCloseAlert = () => {
        this.setState({
            currentAgent: { ...emptyAgent },
            isAlertShown: false,
        });
    };

    showHideEnabled = (val) => {
        const { showDisabled } = this.state;
        this.setState({
            showDisabled: !showDisabled,
        });
    };

    onDataLineEvents = (element) => {
        const agent = searchLevelByIdName(this.props.getAgents.success?.agents || [], element.item.id, '')[0] || element.item;
        this.props.onStoreAppData(agent);

        this.props.history.push({
            pathname: RoutesPaths.AGENT,
            search: '?id=' + (element.item?.id || 0) + '&tabId=0&createMode=false&useStore=false',
        });
    };


    onAgentClick = (e) => {
        const homePath = [...initialBreadCrumbs(this.props.getToken.login)];

        let openedId = e.id;
        let breadCrumbs = openedId ? findCrumbsPath(this.props.getAgents.success?.agents || [], openedId, homePath) || homePath : [...this.state.breadCrumbs];

        if (e.title === '..' && breadCrumbs.length > 1) {
            breadCrumbs.pop();
            openedId = breadCrumbs[breadCrumbs.length - 1].id;

            this.setState({
                openedId: breadCrumbs.length === 1 ? null : openedId,
                breadCrumbs: [...breadCrumbs],
            });
            return;
        }

        if (e.sub?.length) {
            breadCrumbs.push({ id: openedId, title: e.name });
        }

        this.setState({
            openedId: e.sub?.length ? openedId : this.state.openedId,
            breadCrumbs,
        });
    };

    closeSuccess = () => this.setState({ showSuccess: false });

    crumbsClick = (crumb) => {
        let { openedId, breadCrumbs } = this.state;

        if (crumb.id === HOME_ID) {
            openedId = null;
            breadCrumbs = [...initialBreadCrumbs(this.props.getToken.login)];
        } else {
            openedId = crumb.id;
            const elem = breadCrumbs.find(el => el.id === crumb.id);
            const idx = breadCrumbs.indexOf(elem);
            breadCrumbs.length = idx + 1;
        }

        this.setState({
            searchID: '',
            searchName: '',
            openedId,
            breadCrumbs,
        });

    };

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    clearSearch = () => {
        this.setState({
            searchID: '',
            searchName: '',
        });
    };

    setSortParams = (sortParams) => {
        this.setState((prev) => ({
            ...prev,
            sortParams,
        }));
    };

    render() {
        const { openedId, createAgent, currentAgent, showSuccess, showDisabled, breadCrumbs, searchID, searchName, sortParams } = this.state;
        const fetching = this.props.getAgents.fetching || this.props.createAgent.fetching;
        const currencies = this.props.dictionaries.getCurrencies.success || [];
        const agentsList = this.props.getAgents.success?.agents || [];

        let found = null;

        if (searchID || (searchName && searchName.length > 2)) {
            found = searchLevelByIdName(agentsList, searchID, searchName);
        }
        const showedAgents = found ? found : (openedId ? findLevel(agentsList, openedId)?.sub : agentsList);
        const counters = getCountersObject(showedAgents);
        const level = getHumanReadable(showedAgents, counters, showDisabled, sortParams) || [];

        if (openedId) {
            level.unshift({ id: '', title: '..', options: rowNamesOptions.noEdit });
        }

        const rowNames = getBalancesRowNames(level || [], currencies);
        const newAgentName = this.props.createAgent.success?.login || '';

        return (
            <div className={cn(styles.mainPanel, 'main-panel')}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Agents list</h1>}
                        <div className="col-md-12">
                            <div className={cn(styles.card, 'card')}>
                                <div className={cn('card-body', styles.topPadding)}>
                                    <div className={cn('nav-scroller d-flex', styles.borderBottomLine)}>
                                        <div className={cn('form-group form-inline', styles.formInline)}>
                                            <input type="text"
                                                   name={'searchID'}
                                                   value={searchID}
                                                   placeholder="Agent Id"
                                                   className={cn(styles.inputField, styles.short)}
                                                   onChange={this.changeInputHandler}
                                            />
                                        </div>
                                        <div className={cn('form-group form-inline', styles.formInline)}>
                                            <input type="text"
                                                   name={'searchName'}
                                                   value={searchName}
                                                   placeholder="Agent Name"
                                                   className={cn(styles.inputField, styles.long)}
                                                   onChange={this.changeInputHandler}
                                            />
                                        </div>
                                        <ActionButton click={this.clearSearch} disableIco>clear</ActionButton>
                                        <div className="d-flex d-md-inline ml-md-auto py-2 py-md-0">

                                            <ActionButton click={this.addAgent}>create agent</ActionButton>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            (!!agentsList.length || createAgent) && (
                                                <div className={styles.sidesWrapper}>
                                                    {!!agentsList.length && (
                                                        <div className={styles.leftSide}>

                                                            <div className="table-responsive">
                                                                {fetching ? (
                                                                    <div className={styles.center}>
                                                                        <Spinner />
                                                                    </div>
                                                                ) : (<>
                                                                        <div className={styles.breadCrumbs}>
                                                                            <BreadCrumbs
                                                                                crumbs={breadCrumbs}
                                                                                onClick={this.crumbsClick}
                                                                            />
                                                                        </div>
                                                                        <table
                                                                            className="display table table-striped table-hover dataTable">
                                                                            <TableHeader
                                                                                rowNames={rowNames}
                                                                                hasCollapseSwich={false}
                                                                                onSoftSortChange={this.setSortParams}
                                                                            />
                                                                            <TableBody
                                                                                dataTable={level}
                                                                                rowNames={rowNames}
                                                                                hasCollapseSwich={false}
                                                                                events={this.onDataLineEvents}
                                                                                onSelect={this.onAgentClick}
                                                                            />
                                                                        </table>
                                                                    </>
                                                                )}
                                                            </div>


                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input className="form-check-input"
                                                                           type="checkbox"
                                                                           onChange={this.showHideEnabled}
                                                                           checked={showDisabled || false}
                                                                    />
                                                                    <span
                                                                        className="form-check-sign">Show disabled</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/*
                                                    {(openedId || createAgent) && (
                                                        <div className={styles.rightSide}>
                                                            <AgentsEdit
                                                                createAgent={createAgent}
                                                                agent={agent}
                                                                onChange={this.updateAgent}
                                                                onDelete={this.deleteAgent}
                                                            />
                                                        </div>
                                                    )}
*/}
                                                </div>
                                            )
                                        )}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmDelete}
                        onCancel={this.onCloseAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>Are you sure you want to delete?</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>Agent</span>
                                <span className={styles.bolder}>{this.state.currentAgent.name}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>country</span>
                                <span className={styles.bolder}>{this.state.currentAgent.country}</span>
                            </div>
                            {/*                            <div className={styles.line}>
                                <span className={styles.leftTitle}>balance</span>
                                <span className={styles.bolder}>{this.state.currentAgent.balance}</span>
                            </div>
*/}
                        </div>
                    </SweetAlert>
                )}
                {showSuccess && (
                    <SweetAlert
                        show={showSuccess}
                        title="Success!"
                        type="success"
                        text={'Agent ' + newAgentName + ' saved'}
                        confirmButtonColor="#1572E8"
                        onConfirm={this.closeSuccess}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetAgentsFetch,
    onCreateAgentFetch,
    onEditAgentFetch,
    onDeleteAgentFetch,
    onCurrenciesFetch,
    onStoreAppData,
    onClearAppData,
};

const mapStateToProps = (state) => ({
    getToken: state.auth.getToken,
    dictionaries: state.dictionaries,
    getAgents: state.agents.getAgents,
    createAgent: state.agents.createAgent,
    editAgent: state.agents.editAgent,
    deleteAgent: state.agents.deleteAgent,
});
export const AgentsList = connect(mapStateToProps, mapDispatchToProps)(withRouter(AgentsPanelComponent));

