import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import { TableBody } from '../TableBody/TableBody';
import { onSummaryFetch } from '../../../store/transactions/transactions.actions';
import { rows, selectedItem } from './IconsHelper';
import { TableHeader } from '../TableHeader/TableHeader';
import { Modal } from '../Modal/Modal';
import { onCreateLogoFetch, onGetAllLogosFetch, onSetLogoFetch } from '../../../store/dictionaries/dictionaries.actions';
import { sortingClass, sortTable } from '../../../utils/helpers/commonFunctions';
import { SMALL_SCREEN } from '../../../utils/consts/global';
import cn from 'classnames';
import styles from './IconsPanel.less';
import SweetAlert from 'react-bootstrap-sweetalert';

export class IconsPanelComponent extends React.Component {
    state = {
        filterParams: {},
        isAlertShown: false,
        isEditShown: false,
        selectedItem: {},
        sortParams: {
            sortType: sortingClass.asc,
            sortingByColumnName: { title: '' },
        },
        requestParams: null,
    };

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.createLogo.success) !== JSON.stringify(this.props.createLogo.success) ||
            JSON.stringify(prevProps.updateLogo.success) !== JSON.stringify(this.props.updateLogo.success)
        ) {
            this.props.onGetAllLogosFetch();
        }
    }

    componentDidMount() {
        this.props.onGetAllLogosFetch();
    }

    onSoftSortChange = (sortType) => {
        this.setState((prev) => ({
            ...prev,
            sortParams: {
                ...sortType,
            },
        }));
    };

    onDataLineEvents = (e) => {
        this.setState((prev) => ({
            ...prev,
            isAlertShown: e.type === 'remove',
            isEditShown: e.type === 'edit',
            selectedItem: {
                ...e.item,
            },
        }));
    };

    addIcon = () => {
        this.setState({
            isEditShown: true,
            selectedItem: { ...selectedItem },
        });
    };

    onCancelDelete = () => {
        this.setState({
            ...selectedItem,
            isAlertShown: false,
        });
    };

    onConfirmDelete = (e) => {
        const { id, enabled, imageBase64, title } = this.state.selectedItem;
        this.setState({ isAlertShown: false });
        this.props.onSetLogoFetch({
            intID: id,
            strTitle: title,
            strImageBase64: imageBase64,
            boolEnabled: 'false',
        });
    };

    onCancelEdit = () => {
        this.setState({
            ...selectedItem,
            isEditShown: false,
        });
    };

    onConfirmEdit = (e) => {
        const { id, enabled, imageBase64, title } = this.state.selectedItem;
        this.setState({ isEditShown: false });
        if (!id) {
            this.props.onCreateLogoFetch({
                strTitle: title,
                strImageBase64: imageBase64,
                boolEnabled: 'true',
            });
        } else {
            this.props.onSetLogoFetch({
                intID: id,
                strTitle: title,
                strImageBase64: imageBase64,
                boolEnabled: 'true',
            });
        }
    };

    handleNameChange = (event) => {
        const title = event.target.value;

        this.setState((prev) => ({
            ...prev,
            selectedItem: {
                ...prev.selectedItem,
                title,
            },
        }));
    };

    getBase64 = (file) => {
        if (!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            this.setState((prev) => ({
                ...prev,
                selectedItem: {
                    ...prev.selectedItem,
                    imageBase64: reader.result,
                },
            }));
        };

        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    onFileAdded = (e) => {
        this.getBase64(e.target.files[0]);
    };

    render() {
        const fetching = false;
        const icons = (this.props.getLogos.success || []).filter((el) => el.enabled === 'true');
        // const icons = this.props.getLogos.success || [];

        return (
            <div className={cn(styles.mainPanel, 'main-panel')}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Icons</h1>}
                        <div className="page-header"> </div>
                        <div className="col-md-12">
                            <div className={cn(styles.card, 'card')}>
                                <div className={cn('card-body', styles.topPadding)}>
                                    <button className={'btn btn-primary'} onClick={this.addIcon}>
                                        add new
                                    </button>
                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={rows} hasCollapseSwich={false} />
                                                <TableBody
                                                    dataTable={icons}
                                                    rowNames={rows}
                                                    hasCollapseSwich={false}
                                                    events={this.onDataLineEvents}
                                                />
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmDelete}
                        onCancel={this.onCancelDelete}
                        showCancel
                    >
                        <h1 className={styles.heading}>Are you sure you want to delete?</h1>
                        <div className={styles.deleteString}>
                            <h1>
                                <img src={this.state.selectedItem.imageBase64} alt="" width="100" />
                            </h1>
                        </div>
                    </SweetAlert>
                )}
                {this.state.isEditShown && (
                    <Modal faderClick={this.onCancelEdit}>
                        <div className={cn(styles.popUpPosition, 'col-md-4')}>
                            <div className="card card-primary bg-primary-gradient">
                                <div className="card-body">
                                    <h4 className="mt-3 b-b1 pb-2 mb-4 fw-bold">{this.state.selectedItem.title}</h4>
                                    <div id="activeUsersChart"> </div>
                                    <h4 className={cn('mt-2 pb-3 mb-0 fw-bold')}>
                                        <div className={styles.loaderPlace}>
                                            <div className={styles.centerImg}>
                                                <img src={this.state.selectedItem.imageBase64} alt="" width="100" />
                                            </div>

                                            <input
                                                className={cn(styles.hiddenInput, styles.cursorPointer, 'form-control form-control-file')}
                                                type="file"
                                                name="file"
                                                onChange={this.onFileAdded}
                                            />
                                        </div>
                                    </h4>
                                    <ul className={cn(styles.mt2em, 'list-unstyled')}>
                                        <li className="d-flex justify-content-between pb-1 pt-1">
                                            <input
                                                className={cn('form-control')}
                                                type="text"
                                                value={this.state.selectedItem.title}
                                                onChange={this.handleNameChange}
                                                placeholder="enter name"
                                            />
                                        </li>
                                    </ul>
                                    <div className="d-flex justify-content-between pb-4 pt-1">
                                        <button className={styles.cancelBtn} onClick={this.onCancelEdit}>
                                            Cancel
                                        </button>
                                        <button className={styles.okBtn} onClick={this.onConfirmEdit}>
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onSummaryFetch,
    onCreateLogoFetch,
    onSetLogoFetch,
    onGetAllLogosFetch,
};

const mapStateToProps = (state) => ({
    transactions: state.transactions,
    app: state.app,
    getLogos: state.dictionaries.getLogos,
    createLogo: state.dictionaries.createLogo,
    updateLogo: state.dictionaries.updateLogo,
});

export const IconsPanel = connect(mapStateToProps, mapDispatchToProps)(IconsPanelComponent);
