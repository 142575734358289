import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { Transactions } from './transactions.consts';
import { fetchFPGate, fetchSummary, fetchTransactions } from './transactions.services';
import { errorNotify } from '../helpers/commonHelpers';
import {
    onAccountsError,
    onAccountsSuccess,
    onBalancesError,
    onBalancesSuccess,
    onBalanceAmountSuccess,
    onGetAccountLogError,
    onGetAccountLogSuccess,
    onGetPaymentStateError,
    onGetPaymentStateSuccess,
    onSummaryError,
    onSummarySuccess,
    onTransactionsError,
    onTransactionsSuccess,
    onTransitLogError,
    onTransitLogSuccess,
} from './transactions.actions';
import { onHandleErrors } from '../errors/errors.actions';
import { makeHumanReadable, setHumanDateFormat } from './transactions.helper';
import { PERM_GET_RECORDS_ADM } from '../../utils/consts/global';
import { onGetGateFetch } from '../dictionaries/dictionaries.actions';
import { Dictionaries } from '../dictionaries/dictionaries.consts';

export function* sagaTransactionsFetch(action) {
    try {

        yield put(onGetGateFetch());
        const getGates = yield take(Dictionaries.GET_GATE_SUCCESS);
        const gates = getGates.payload.data;

        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetRecords',
        };

        const permissions = yield select((state) => state.auth.permissions?.success);

        if (permissions && permissions?.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            const { clientId, subClientId } = yield select((state) => state.app);
            if (clientId) {
                params.method = 'GetRecordsAdm';
                params.intClientID = clientId;
                if (subClientId) {
                    params.intSubClientID = subClientId;
                }
            }
        }
        const { response, error } = yield call(fetchTransactions, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onTransactionsError(error));
        } else if (response) {
            yield put(onTransactionsSuccess(makeHumanReadable(response.data, gates)));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onTransactionsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onTransactionsError(error));
    }
}

export function* sagaTransitLogFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchTransactions, {
            ...payload,
            method: 'GetTransitLogAdm',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onTransitLogError(error));
        } else if (response) {
            yield put(onTransitLogSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onTransitLogError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onTransitLogError(error));
    }
}

export function* sagaSummaryFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetSummaryRecords',
        };
        const permissions = yield select((state) => state.auth.permissions?.success);

        if (permissions && permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            const { clientId, subClientId } = yield select((state) => state.app);
            if (clientId) {
                params.method = 'GetSummaryRecordsAdm';
                params.intClient = clientId;
                params.intSubClientID = subClientId;
            }
        }

        const { response, error } = yield call(fetchSummary, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSummaryError(error));
        } else if (response) {
            yield put(onSummarySuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSummaryError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSummaryError(error));
    }
}

export function* sagaBalancesFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetBalance',
        };
        const permissions = yield select((state) => state.auth.permissions?.success);

        if (permissions && permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            const { clientId, subClientId } = yield select((state) => state.app);
            if (clientId) {
                params.method = 'GetBalanceAdm';
                params.intClient = clientId;
                params.intSubClient = subClientId;
            }
        }
        const { response, error } = yield call(fetchSummary, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onBalancesError(error));
        } else if (response) {
            yield put(onBalancesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onBalancesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onBalancesError(error));
    }
}

export function* sagaBalanceAmountFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetBalanceAmount',
        };

        const permissions = yield select((state) => state.auth.permissions?.success);

        if (permissions && permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            const { clientId, subClientId } = yield select((state) => state.app);
            if (clientId) {
                params.intClient = clientId;
                params.intSubClient = subClientId;
            }
        }

        const { response, error } = yield call(fetchSummary, params);

        if (error) {
            yield put(onHandleErrors(error));
        } else if (response) {
            yield put(onBalanceAmountSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
        }
    } catch (error) {
        yield call(errorNotify, error);
    }
}

export function* sagaGetAccountsFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAccounts',
        };
        const permissions = yield select((state) => state.auth.permissions?.success);

        if (permissions && permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            const { clientId, subClientId } = yield select((state) => state.app);
            if (clientId) {
                params.method = 'GetAccountsAdm';
                params.intClient = clientId;
                params.intSubClientID = subClientId;
            }
        }

        const { response, error } = yield call(fetchSummary, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAccountsError(error));
        } else if (response) {
            yield put(onAccountsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAccountsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAccountsError(error));
    }
}

export function* sagaGetAccountsLogFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAccountsLog',
        };

        const { response, error } = yield call(fetchSummary, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAccountLogError(error));
        } else if (response) {
            yield put(
                onGetAccountLogSuccess(
                    {
                        data: setHumanDateFormat(response.data.data, 'createDate'),
                        paginator: response.data.paginator,
                    }
                ),
            );
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAccountLogError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAccountLogError(error));
    }
}

export function* sagaGetPaymentStateFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetPaymentState',
        };

        const { response, error } = yield call(fetchFPGate, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetPaymentStateError(error));
        } else if (response) {
            yield put(onGetPaymentStateSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetPaymentStateError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetPaymentStateError(error));
    }
}

export function* transactionsSaga() {
    yield takeEvery(Transactions.TRA_OPERATIONS_FETCH, sagaTransactionsFetch);
    yield takeEvery(Transactions.TRA_SUMMARY_FETCH, sagaSummaryFetch);
    yield takeEvery(Transactions.TRA_BALANCES_FETCH, sagaBalancesFetch);
    yield takeEvery(Transactions.TRA_BALANCE_AMOUNT_FETCH, sagaBalanceAmountFetch);
    yield takeEvery(Transactions.TRA_ACCOUNTS_INFO_FETCH, sagaGetAccountsFetch);
    yield takeEvery(Transactions.TRA_GET_ACCOUNT_LOG_FETCH, sagaGetAccountsLogFetch);
    yield takeEvery(Transactions.TRA_TRANSIT_LOG_FETCH, sagaTransitLogFetch);
    yield takeEvery(Transactions.GET_PAYMENT_STATE_FETCH, sagaGetPaymentStateFetch);
}
