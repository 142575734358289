import cn from 'classnames';
import styles from './ButtonsOkCancelBlock.less';
import React from 'react';

/*
*  PROPS:
*  hideOkButton: bool
*  hideCancelButton: bool
*  onDelete: ()=>{} // delete (shown if props.onDelete present)
*  onConfirmEdit: ()=>{}  //ok
*  onCancelEdit: ()=>{} // cancel
*  okIsDisabled: bool // disable ok button
*  isDisabled: bool // disable cancel/delete
*  okText: text
*  cancelText: text
*  deleteText: text
*/
export const ButtonsOkCancelBlock = (props) => {

    return (
        <div className={cn(styles.buttonsPlace, props.className)}>
            {!!props.onDelete && (
                <button
                    className={cn(styles.cancelBtn, 'btn btn-danger', { ['btn-sm']: props.small })}
                    disabled={props.isDisabled}
                    onClick={props.onDelete}
                    data-idx={props.idx}
                >
                    {props.deleteText || 'Delete'}
                </button>
            )}
            {!props.hideCancelButton && (
                <button className={cn(styles.cancelBtn, 'btn btn-info', { ['btn-sm']: props.small })}
                        disabled={props.isDisabled}
                        onClick={props.onCancelEdit}>
                    {props.cancelText || 'Cancel'}
                </button>
            )}

            {!props.hideOkButton && (<button
                    className={cn(styles.okBtn, 'btn btn-primary', props.className, { ['btn-sm']: props.small })}
                    onClick={props.onConfirmEdit}
                    disabled={props.okIsDisabled}
                >
                    {props.okText || 'Ok'}
                </button>
            )}
        </div>
    );
};
