import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import { TableBody } from '../TableBody/TableBody';
import { onSummaryFetch } from '../../../store/transactions/transactions.actions';
import { SummaryFilter } from '../SummaryFilter/SummaryFilter';
import { summaryRowNamesDict } from './SummaryHelper';
import { TableHeader } from '../TableHeader/TableHeader';
import cn from 'classnames';
import styles from './SummaryPanel.less';
import { sortingClass, sortTable } from '../../../utils/helpers/commonFunctions';
import { SMALL_SCREEN } from '../../../utils/consts/global';
import { isParamsTheSame } from '../Operations/OperationsHelper';

export class SummaryPanelComponent extends React.Component {
    state = {
        filterParams: {},
        sortParams: {
            sortType: sortingClass.asc,
            sortingByColumnName: { name: '' },
        },
        requestParams: null,
    };

    componentDidUpdate(prevProps) {
        const { subClientId, clientId } = this.props.app;
        if (prevProps.app.clientId !== clientId || prevProps.app.subClientId !== subClientId) {
            this.props.onSummaryFetch(this.state.requestParams);
        }
    }

    loadTableData = (requestParams) => {
        if (!isParamsTheSame(requestParams, this.state.requestParams)) {
            this.setState(
                (prev) => ({
                    ...prev,
                    requestParams,
                }),
                () => {
                    this.props.onSummaryFetch(requestParams);
                },
            );
        }
    };

    onSoftSortChange = (sortType) => {
        this.setState((prev) => ({
            ...prev,
            sortParams: {
                ...sortType,
            },
        }));
    };

    render() {
        const { filterParams, sortParams } = this.state;
        const { fetching, success } = this.props.transactions.getSummary;
        const dataForTable = success ? success.data : {};

        let dArr = Object.keys(dataForTable).map((el) => {
            if (dataForTable[el].main === 1) {
                dataForTable[el].className = styles.bgGreen;
            }
            return { merchantAccount: el, ...dataForTable[el] };
        });

        const dataTable = sortTable({
            dataTable: [...dArr],
            sortParams,
        });

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Summary</h1>}
                        <div className="page-header">
                            <div className={cn(styles.cardHeader)}>
                                <SummaryFilter onChange={this.loadTableData} filterParams={filterParams} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader
                                                    rowNames={summaryRowNamesDict}
                                                    hasCollapseSwich={true}
                                                    onSoftSortChange={dataTable.length > 1 && this.onSoftSortChange}
                                                />
                                                <TableBody dataTable={dataTable} rowNames={summaryRowNamesDict}
                                                           hasCollapseSwich={true} />
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onSummaryFetch,
};

const mapStateToProps = (state) => ({
    transactions: state.transactions,
    app: state.app,
});

export const SummaryPanel = connect(mapStateToProps, mapDispatchToProps)(SummaryPanelComponent);
