import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Хук поддержки клика снаружи ref, вызывает arg.outsideClickHandler в случае если не попали по ref области.
 *
 */

export function useOutsideAlerter(ref, outsideClickHandler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.offsetX > (document.documentElement.clientWidth || document.body.clientWidth)) return;
            if (ref.current && !ref.current.contains(event.target) && outsideClickHandler) {
                outsideClickHandler(event);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const getSideBarVisibility = () => ({
    isSidebarShown: useSelector((state) => state.app.isSidebarShown),
    isSidebarMobShown: useSelector((state) => state.app.isSidebarMobShown),
});
