import React from "react";
import cn from "classnames";
import styles from "./AccountsPanel.less";
import { connect } from "react-redux";
import { Spinner } from "../Spinner/Spinner";
import { TableBody } from "../TableBody/TableBody";
import { accountsHeaders, emptyFilter, formatData } from "./AccountsPanelHelper";
import { TableHeader } from "../TableHeader/TableHeader";
import { SMALL_SCREEN } from "../../../utils/consts/global";
import { onAccFetch } from "../../../store/accounts/accounts.actions";
import { DropDown } from "../DropDown/DropDown";
import { rowCount } from "../Operations/OperationsHelper";
import { onCurrenciesFetch, onGetCountriesFetch } from "../../../store/dictionaries/dictionaries.actions";
import { Paginator } from "../Paginator/Paginator";
import { getNotNullableFieldsFromObj } from "../../../utils/helpers/commonFunctions";
import { getDataForDropDown } from "../../../utils/helpers/commonHelpers";

export class AccountsPanelComponent extends React.Component {
    state = {
        countryReset: false,
        currencyReset: false,
        filterParams: {
            ...emptyFilter
        }
    };

    loadAccounts = () => {
        const query = getNotNullableFieldsFromObj(this.state.filterParams);
        this.props.onAccFetch(query);
    };

    componentDidMount() {
        this.loadAccounts();

        if (!this.props.getCountries.success) {
            this.props.onGetCountriesFetch();
        }
        if (!this.props.getCurrencies.success) {
            this.props.onCurrenciesFetch();
        }
    }



    clearFilter = () => {
        const {countryReset, currencyReset} = this.state;
        this.setState((prev) => ({
            ...prev,
            countryReset: !countryReset,
            currencyReset: !currencyReset,
            filterParams: {
                ...emptyFilter
            }
        }), () => {
            this.loadAccounts();
        });
    };

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            ...prev,
            filterParams: {
                ...this.state.filterParams,
                [event.target.name]: event.target.value
            }
        }));
    };

    changePage = (page) => {
        const params = {
            ...this.state.filterParams,
            intCurrentPage: page.selected + 1
        };
        this.setState((prev) => ({
                ...prev,
                filterParams: {
                    ...this.state.filterParams,
                    intCurrentPage: page.selected + 1
                }
            }),
            () => {
                this.loadAccounts(params);
            });
    };

    dropDownSelect = (val, name) => {
        this.setState((prev) => ({
            ...prev,
            filterParams: {
                ...this.state.filterParams,
                [name]: val.id
            }
        }));
    };

    render() {
        const { filterParams, countryReset, currencyReset } = this.state;
        const {
            numberAccount,
            numberOwner,
            intState,
            intWhiteLabel,
            numberHoster
        } = filterParams;
        const { fetching, success } = this.props.accounts;
        const countriesDict = this.props.getCountries.success;
        const currenciesDict = this.props.getCurrencies.success;

        const accounts = formatData(success?.data, countriesDict, currenciesDict);
        const countries = getDataForDropDown(countriesDict, "codeAlpha3");
        const currencies = getDataForDropDown(currenciesDict, "codeAlpha");

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Accounts</h1>}
                        <div className="page-header">

                            <div className={cn(styles.cardHeader)}>
                                <div className={cn(styles.fixedDropWidth, styles.normal)}>
                                    <DropDown options={rowCount}
                                              name="intItemsPerPage"
                                              onSelect={this.dropDownSelect}
                                              value={rowCount[0]}
                                    >
                                        Show 10 rows
                                    </DropDown>
                                </div>
                                <input type="text"
                                       className={cn(styles.marginInline, styles.filterInputLong, "form-control")}
                                       id="numberAccount"
                                       name="numberAccount"
                                       value={numberAccount}
                                       placeholder="account number"
                                       onChange={this.changeInputHandler}
                                />
                                <input type="text"
                                       className={cn(styles.marginInline, styles.filterInputMedium, "form-control")}
                                       id="numberOwner"
                                       name="numberOwner"
                                       value={numberOwner}
                                       placeholder="owner"
                                       onChange={this.changeInputHandler}
                                />
                                <input type="text"
                                       className={cn(styles.marginInline, styles.filterInputMedium, "form-control")}
                                       id="intState"
                                       name="intState"
                                       value={intState}
                                       placeholder="state"
                                       onChange={this.changeInputHandler}
                                />
                                <input type="text"
                                       className={cn(styles.marginInline, styles.filterInputShort, "form-control")}
                                       id="intWhiteLabel"
                                       name="intWhiteLabel"
                                       value={intWhiteLabel}
                                       placeholder="WL"
                                       onChange={this.changeInputHandler}
                                />
                                <input type="text"
                                       className={cn(styles.marginInline, styles.filterInputMedium, "form-control")}
                                       id="numberHoster"
                                       name="numberHoster"
                                       value={numberHoster}
                                       placeholder="hoster"
                                       onChange={this.changeInputHandler}
                                />
                                <div className={cn(styles.fixedDropWidth, styles.short)}>
                                    <DropDown
                                        options={countries}
                                        onSelect={this.dropDownSelect}
                                        name="intCountry"
                                        value={{ id: null, title: "" }}
                                        reset={countryReset}
                                    >
                                        Country
                                    </DropDown>
                                </div>
                                <div className={cn(styles.fixedDropWidth, styles.short)}>
                                    <DropDown
                                        reset={currencyReset}
                                        options={currencies}
                                        onSelect={this.dropDownSelect}
                                        name="intCurrency"
                                        value={{ id: null, title: "" }}

                                    >
                                        Currency
                                    </DropDown>
                                </div>
                                <button className={cn("btn btn-info", styles.marginButton)}
                                        onClick={this.clearFilter}>Clear
                                </button>
                                <button
                                    className={cn("btn btn-primary", styles.marginButton)}
                                    onClick={this.loadAccounts}>Apply
                                </button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader
                                                    rowNames={accountsHeaders}
                                                    hasCollapseSwich={true}
                                                />
                                                <TableBody
                                                    dataTable={accounts}
                                                    rowNames={accountsHeaders}
                                                    hasCollapseSwich={true} />
                                            </table>
                                        )}
                                    </div>
                                    <div className={styles.paginatorPlace}>
                                        <Paginator pagesData={success?.paginator} changePage={this.changePage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onAccFetch,
    onCurrenciesFetch,
    onGetCountriesFetch
};

const mapStateToProps = (state) => ({
    accounts: state.accounts.getAccounts,
    getCountries: state.dictionaries.getCountries,
    getCurrencies: state.dictionaries.getCurrencies,
});

export const AccountsPanel = connect(mapStateToProps, mapDispatchToProps)(AccountsPanelComponent);
