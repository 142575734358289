import React from 'react';
import cn from 'classnames';
import styles from './Operations.less';
import { TransactionsFilter } from '../TransactionsFilter/TransactionsFilter';
import { Spinner } from '../Spinner/Spinner';
import { TableHeader } from '../TableHeader/TableHeader';
import { connect } from 'react-redux';
import { onGetPaymentStateFetch, onTransactionsFetch } from '../../../store/transactions/transactions.actions';
import { isParamsTheSame, operationsRowNamesDict } from './OperationsHelper';
import { TableBody } from '../TableBody/TableBody';
import { Paginator } from '../Paginator/Paginator';
import { PERM_GET_RECORDS_ADM, SMALL_SCREEN, timezones } from '../../../utils/consts/global';
import { TransitLogPanel } from '../TransitLogPanel/TransitLogPanel';
import SweetAlert from 'react-bootstrap-sweetalert/dist';


export class OperationsComponent extends React.Component {
    state = {
        rowNames: [],
        filterParams: {
            strTimeZone: localStorage.getItem('timezone'),
            intCurrentPage: 1,
        },
        requestParams: null,
        transitLogId: null,
        showTransitLog: false,
        showStatusPupUp: false,
    };

    componentDidUpdate(prevProps) {
        const { subClientId, clientId } = this.props.app;
        const { getPaymentState } = this.props.transactions;

        if (prevProps.app.clientId !== clientId || prevProps.app.subClientId !== subClientId) {
            this.props.onTransactionsFetch(this.state.requestParams);
        }

        if (getPaymentState.success && prevProps.transactions.getPaymentState.success !== getPaymentState.success) {
            this.setState({ showStatusPupUp: true });
        }
    }

    changePage = (page) => {
        const params = {
            ...this.state.requestParams,
            intCurrentPage: page.selected + 1,
        };
        this.loadTableData(params);
    };

    loadTableData = (filterParams, sortParams) => {
        if (!filterParams) return;

        const requestParams = {
            ...this.state.filterParams,
            ...filterParams,
            ...sortParams,
        };
        if (!isParamsTheSame(requestParams, this.state.requestParams)) {
            this.setState(
                (prev) => ({
                    ...prev,
                    requestParams,
                }),
                () => {
                    this.props.onTransactionsFetch(requestParams);
                },
            );
        }
    };

    onSoftSortChange = (sortParams) => {
        const sortingOrderRequest = {
            strOrderBy: sortParams.sortingByColumnName.name,
            strOrderDirection: sortParams.sortType,
        };
        this.loadTableData(this.state.requestParams, sortingOrderRequest);
    };

    showLogDetails = (line) => {
        this.setState({
            showTransitLog: true,
            transitLogId: line.id,
        });
    };

    onGetPaymentState = (line) => {
        const req = {
            'subClientID': line.subClientID,
            'orderID': line.merchantTrnxID,
        };
        this.props.onGetPaymentStateFetch(req);
    };

    hideLogDetails = () => {
        this.setState({
            showTransitLog: false,
        });
    };
    closePopUp = () => {
        this.setState({ showStatusPupUp: false });
    };

    render() {
        const { filterParams, showTransitLog, showStatusPupUp } = this.state;
        const { fetching, success } = this.props.transactions.getTransactions;
        const dataForTable = success?.data || [];
        const permissions = this.props.permissions.success || [];
        const tablesColumns = permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1 ? operationsRowNamesDict(true) : operationsRowNamesDict();
        const statusData = this.props.transactions.getPaymentState.success?.data?.response?.result || '';

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Operations</h1>}
                        <div className="page-header">
                            <div className={cn(styles.cardHeader)}>
                                <TransactionsFilter onChange={this.loadTableData} filterParams={filterParams} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className={cn('card', { [styles.hide]: showTransitLog })}>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="display table table-striped table-hover dataTable">
                                            <TableHeader
                                                rowNames={tablesColumns}
                                                hasCollapseSwich={true}
                                                onSoftSortChange={dataForTable.length > 1 && this.onSoftSortChange}
                                            />
                                            {fetching ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={tablesColumns.length + 1}>
                                                        <div className={styles.center}>
                                                            <Spinner />
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <TableBody
                                                    dataTable={dataForTable}
                                                    rowNames={tablesColumns}
                                                    hasCollapseSwich={true}
                                                    onDetailsClick={this.showLogDetails}
                                                    onClickCustomLink={this.onGetPaymentState}
                                                />
                                            )}
                                        </table>
                                    </div>

                                    <div className={styles.paginatorPlace}>
                                        <Paginator pagesData={success?.paginator} changePage={this.changePage} />
                                    </div>
                                </div>
                            </div>
                            {showTransitLog && (<>
                                <TransitLogPanel id={this.state.transitLogId} />
                                <div className={styles.exitButtonPlace}>
                                    <button className={styles.button} onClick={this.hideLogDetails}>Return to
                                        operations
                                    </button>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
                {showStatusPupUp && (
                    <SweetAlert
                        show={showStatusPupUp}
                        title={'Status: ' + statusData?.status}
                        type="info"
                        confirmButtonColor="#1d8d12"
                        onConfirm={this.closePopUp}
                    >
                        <div>
                            <p className={styles.heading}>
                                code: {statusData?.provider_result?.code} <br />
                                message: {statusData?.provider_result?.message}
                            </p>
                        </div>
                    </SweetAlert>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onTransactionsFetch,
    onGetPaymentStateFetch,
};

const mapStateToProps = (state) => ({
    transactions: state.transactions,
    app: state.app,
    permissions: state.auth.permissions,
});

export const Operations = connect(mapStateToProps, mapDispatchToProps)(OperationsComponent);
