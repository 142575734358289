import axios from 'axios';
import { API_URL, DICTIONARIES_URL, headers, SUMMARY_URL, TRANSACTIONS_URL } from '../../utils/consts/global';
import { getBusinessError } from '../helpers/commonHelpers';

export function fetchDictionaries(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    return axios({
        method: 'post',
        url: `${API_URL}${DICTIONARIES_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}
export function fetchDictionaries1(payload) {
    return {
        response: { data: [] },
        error: null,
    };
}
