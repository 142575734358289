import styles from './TimeZoneSelector.less';
import React from 'react';
import { DropDown } from '../DropDown/DropDown';
import { timezones } from '../../../utils/consts/global';

export const TimeZoneSelector = (props) => {

    const ddTimeZoneSelect = (value, name) => {
        const { title } = value;
        localStorage.setItem('timezone', (timezones.find(el => el.title === title)).id.toString());
        props.onSelect({
            [props.name]: title,
        });
    };

    return (
        <div className={styles.fixedDropWidth}>
            <DropDown
                name={props.name}
                color={props.color || 'grey'}
                options={timezones}
                onSelect={ddTimeZoneSelect}
            >
                {props.value}
            </DropDown>
        </div>
    );
};
