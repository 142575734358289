export const rowNamesOptions = {
    hidden: 'hidden',
    hiddenJSX: 'hiddenJSX',
    image: 'image',
    enableBlock: 'enableBlock',             // REQUIRED line.on field (boolean)
    editBlock: 'editBlock',
    editOnlyBlock: 'editOnlyBlock',
    removeBlock: 'removeBlock',
    arrayBlock: 'arrayBlock',
    jsonString: 'jsonString',
    radioBlock: 'radioBlock',
    checkBoxBlock: 'checkBoxBlock',         // Required object: { all: [1,2,3], selected: [2,3], disabled: false }
    singleCheckBox: 'singleCheckBox',
    dropDown: 'dropDown',                   // Required object  { all: [{id, title}], selected: id, disabled: false, hidden: true };
    noEdit: 'noEdit',                   // Required object  { all: [{id, title}], selected: id, disabled: false, hidden: true };
    mergeUp: 'mergeUp',
};

export const rowNamesTypes = {
    link: 'link',
    custom: 'custom',
};

export const getVisible = (rows) => {
    return rows.filter((el) => (el.options !== rowNamesOptions.hidden && el.options !== rowNamesOptions.hiddenJSX));
};
