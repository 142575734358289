import { Metric } from "./metric.consts";

/*
GET DASHBOARD DATA FETCH
*/
export const onGetDashboardDataFetch = (payload) => ({
    type: Metric.GET_DASHBOARD_DATA_FETCH,
    payload,
});

export const onGetDashboardDataSuccess = (payload) => ({
    type: Metric.GET_DASHBOARD_DATA_SUCCESS,
    payload,
});

export const onGetDashboardDataError = (payload) => ({
    type: Metric.GET_DASHBOARD_DATA_ERROR,
    payload,
});

export const onGetDashboardDataClear = (payload) => ({
    type: Metric.GET_DASHBOARD_DATA_CLEAR,
    payload,
});

/*
GET PAYMENT SUM
*/
export const onGetPaymentSumFetch = (payload) => ({
    type: Metric.GET_PAYMENT_SUM_FETCH,
    payload,
});

export const onGetPaymentSumSuccess = (payload) => ({
    type: Metric.GET_PAYMENT_SUM_SUCCESS,
    payload,
});

export const onGetPaymentSumError = (payload) => ({
    type: Metric.GET_PAYMENT_SUM_ERROR,
    payload,
});

export const onGetPaymentSumClear = (payload) => ({
    type: Metric.GET_PAYMENT_SUM_CLEAR,
    payload,
});

/*
GET TOTALS SUM
*/
export const onGetTotalsFetch = (payload) => ({
    type: Metric.GET_TOTALS_FETCH,
    payload,
});

export const onGetTotalsSuccess = (payload) => ({
    type: Metric.GET_TOTALS_SUCCESS,
    payload,
});

export const onGetTotalsError = (payload) => ({
    type: Metric.GET_TOTALS_ERROR,
    payload,
});

export const onGetTotalsClear = (payload) => ({
    type: Metric.GET_TOTALS_CLEAR,
    payload,
});
