export const BASE_URL = './';

export const API_URL = process.env.REACT_APP_API_URL;
export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const API_DATA_TOKEN_URL = process.env.REACT_APP_API_DATA_TOKEN_URL;
export const API_MODULES_URL = process.env.REACT_APP_API_MODULES_URL;
export const TRANSACTIONS_URL = process.env.REACT_APP_TRANSACTIONS_URL;
export const DICTIONARIES_URL = process.env.REACT_APP_DICTIONARIES_URL;
export const ROLESYSTEM_URL = process.env.REACT_APP_ROLESYSTEM_URL;
export const ROLESYSTEM_V2_URL = process.env.REACT_APP_ROLESYSTEM_V2_URL;
export const PAYMENTS_URL = process.env.REACT_APP_PAYMENTS_URL;
export const SUMMARY_URL = process.env.REACT_APP_SUMMARY_URL;
export const SMS_REPORT_URL = process.env.REACT_APP_SMS_REPORT_URL;
export const AGENTS_URL = process.env.REACT_APP_AGENTS_URL;
export const PAYMENTS___URL = process.env.REACT_APP_PAYMENTS___URL;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const MERCHANTS_URL = process.env.REACT_APP_MERCHANTS_URL;
export const FPGATE_URL = process.env.REACT_APP_FPGATE_URL;
export const FILE_STORE_URL = process.env.REACT_APP_FILE_STORE_URL;
export const METRIC_URL = process.env.REACT_APP_METRIC_URL;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
export const GET_FILES_URL = process.env.REACT_APP_GET_FILES_URL;


export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const TIME_FORMAT_HHMMSS = 'HH:mm:ss';
export const DAYTIME_FORMAT_HUMAN = 'DD/MM/YYYY HH:mm:ss';
export const DAYTIME_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DAYTIME_FORMAT_HUMAN_WS = 'DD/MM/YYYY HH:mm';
export const DAYTIME_FORMAT_HUMAN_SHORT = 'DD/MM/YY HH:mm';
export const NOTIFICATION_HIDE_DELAY = 5000;
export const WAIT_TIMEOUT = 10000;
export const STATUS_REQUEST_COUNT = 6;
export const SMALL_SCREEN = window.innerWidth <= 800 && window.innerHeight <= 860;
export const headers = { 'Content-Type': 'application/json;charset=utf-8' };
export const INPUT_PASSWORD_TYPE = 'password';
export const INPUT_TEXT_TYPE = 'text';
export const LOGIN_MIN_LENGTH = 4;

export const DEPOSIT_MERCHANT = 'depositMerchant';
export const DEPOSIT_AGENT = 'depositAgent';
export const SETTLEMENT_MERCHANT = 'SettlementMerchant';
export const SETTLEMENT_AGENT = 'SettlementAgent';

export const STATUS_PENDING = 'pending';
export const TXT_SUCCESS_TEXT = 'Transaction was successfully completed';
export const TXT_PENDING_TEXT = 'Transaction was accepted';
export const GATE_CODE = '3';
export const GATE_CODE1 = '11'; // cash || superagent

export const CODE_UNKNOWN = -1;
export const CODE_INITIATED = 0;
export const CODE_IN_PROGRESS = 1;
export const CODE_SUCCESS = 2;
export const CODE_FAIL = 3;
export const CODE_SUCCESS_HOLD = 6;
export const CODE_IN_TRANSIT = 7;

export const ROLES_TAB_NAME = 'Roles';
export const GROUPS_TAB_NAME = 'Groups';
export const DEFAULT_LOCALE = 'en-US';

export const ALLOWED_GATE_FOR_MERCH = '8';
export const PROXY_GATE_TYPES = ['10', '11', '16', '17', '18', '19'];
/*
 * PERMISSIONS
 */
export const PERM_GET_TOKEN = 'GetToken';
export const PERM_LOGOUT = 'Logout';
export const PERM_GET_ALLOWEDMODULES = 'GetAllowedModules';
export const PERM_GET_RECORDS = 'GetRecords';
export const PERM_GET_RECORDS_ADM = 'GetRecordsAdm';
export const PERM_DOWNLOAD_EXCEL = 'DownloadExcel';
export const PERM_GET_TRANSIT_LOG_ADM = 'GetTransitLogAdm';
export const PERM_GET_OPERATION = 'GetOperation';
export const PERM_GET_OPERATION_STATE = 'GetOperationState';
export const PERM_GET_GATE = 'GetGate';
export const PERM_GET_GATE_ADM = 'GetGateAdm';
export const PERM_GET_PROTOCOL = 'GetProtocol';
export const PERM_GET_CLIENT = 'GetClient';
export const PERM_GET_CLIENT_ADM = 'GetClientAdm';
export const PERM_GET_MERCHANTS = 'GetMerchants';
export const PERM_ADD_MERCHANT = 'AddMerchant';
export const PERM_GET_ALLOWED_OPERATORS = 'GetAllowedOperators';
export const PERM_GET_ALL_OPERATORS = 'GetAllOperators';
export const PERM_SET_OPERATOR = 'SetOperator';
export const PERM_GET_SUMMARY_RECORDS = 'GetSummaryRecords';
export const PERM_GET_BALANCE = 'GetBalance';
export const PERM_GET_BALANCE_ADM = 'GetBalanceAdm';
export const PERM_GET_ACCOUNTS_SUMMARY = 'GetAccounts';
export const PERM_GET_ACCOUNTS = 'GetAccountsPage';
export const PERM_GET_ACCOUNTS_ADM = 'GetAccountsPageAdm';
export const PERM_CREATE_ACCOUNT = 'CreateAccount';
export const PERM_SET_ACCOUNT_STATE = 'SetAccountState';
export const PERM_GET_PAYMENT_STATUS = 'GetPaymentStatus';
export const PERM_MAKE_PAYMENT_C2B = 'MakePaymentC2B';
export const PERM_MAKE_PAYMENT_B2C = 'MakePaymentB2C';
export const PERM_SET_LOGO_DATA = 'SetLogoData';
export const PERM_GET_ACCOUNT_LOG = 'GetAccountsLog';


export const PERM_USER_ADD = 'UserAdd';
export const PERM_GET_ALL_USERS = 'UserList';
export const PERM_GET_DIRECT_USER_ROLES = 'GetPermissionDirectUserRoles';
export const PERM_GET_USER_GROUPS = 'GetPermissionUserGroups';
export const PERM_GET_COMBINED_USER_ROLES = 'GetPermissionCombinedUserRoles';

export const PERM_GET_SMS_REPORT = 'GetSmsReport';
export const PERM_UPLOAD_SMS_BATCH = 'UploadSmsBatch';

export const PERM_GET_PERMISSION_ROLE = 'GetPermissionRoles';
export const PERM_CREATE_PERMISSION_ROLE = 'CreatePermissionRole';
export const PERM_EDIT_PERMISSION_ROLE = 'EditPermissionRole';
export const PERM_DELETE_PERMISSION_ROLE = 'DeletePermissionRole';

export const PERM_GET_PERMISSION_GROUP = 'GetPermissionGroups';
export const PERM_CREATE_PERMISSION_GROUP = 'CreatePermissionGroup';
export const PERM_EDIT_PERMISSION_GROUP = 'EditPermissionGroup';
export const PERM_DELETE_PERMISSION_GROUP = 'DeletePermissionGroup';

export const PERM_GET_GROUP_RULES = 'GetPermissionGroupRoles';
export const PERM_GET_AGENTS = 'GetAgents';
export const PERM_EDIT_AGENT = 'EditAgent';
export const PERM_ADD_AGENT = 'AddAgent';
export const PERM_ADD_CASHIER = 'AddCashier';
export const PERM_GET_CASHIERS = 'GetCashiers';

export const PERM_MAKE_DEPOSIT = 'MakeEmission';
export const PERM_MAKE_SETTLEMENT = 'MakeSettlement';

export const PWD_REGEXP = /^(?=.*\d)(?=.*[@#\-_\$%\^\&\+=\!\?\)\(\[\];\.\,\:\>\<\{\}])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_\$%\^\&\+=\!\?\)\(\[\];\.\,\:\>\<\{\}]{8,30}$/i;
export const BALANCE_ACCOUNT_START_WITH = '101150';
export const timezones = [
    { id: 0, title: '-10:00' },
    { id: 1, title: '-09:00' },
    { id: 2, title: '-08:00' },
    { id: 3, title: '-07:00' },
    { id: 4, title: '-06:00' },
    { id: 5, title: '-05:00' },
    { id: 6, title: '-04:00' },
    { id: 7, title: '-03:00' },
    { id: 8, title: '-02:00' },
    { id: 9, title: '-01:00' },
    { id: 10, title: '+00:00' },
    { id: 11, title: '+01:00' },
    { id: 12, title: '+02:00' },
    { id: 13, title: '+03:00' },
    { id: 14, title: '+04:00' },
    { id: 15, title: '+05:00' },
    { id: 16, title: '+06:00' },
    { id: 17, title: '+07:00' },
    { id: 18, title: '+08:00' },
    { id: 19, title: '+09:00' },
    { id: 20, title: '+10:00' },
    { id: 21, title: '+11:00' },
    { id: 22, title: '+12:00' },
    { id: 23, title: '+13:00' },
    { id: 24, title: '+14:00' },
];

export const PWD_CASHIER_LENGTH = 8;
