import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { SelfStorage } from './selfStorage.consts';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getItem = handleActions(
    {
        [SelfStorage.GET_ITEM_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [SelfStorage.GET_ITEM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [SelfStorage.GET_ITEM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [SelfStorage.GET_ITEM_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const setItem = handleActions(
    {
        [SelfStorage.SET_ITEM_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [SelfStorage.SET_ITEM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [SelfStorage.SET_ITEM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [SelfStorage.SET_ITEM_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const removeItem = handleActions(
    {
        [SelfStorage.REMOVE_ITEM_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [SelfStorage.REMOVE_ITEM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [SelfStorage.REMOVE_ITEM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [SelfStorage.REMOVE_ITEM_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);


export const selfStorage = combineReducers({
    getItem,
    setItem,
    removeItem,
});
