import { emptyMerchant } from '../MerchantsList/MerchantsListHelper';

export const emptyValues = {
    ...emptyMerchant,
};

export const NEW_LEGAL = '0';
export const LEGAL_IN_DRAFT = '1';
export const PUBLISHED = '2';

export const legalStatuses = {
    0: 'New',
    1: 'Draft',
    2: 'Published',
};

export const menuItems = [
    { name: 'Summary', id: 0 },
    { name: 'Merchant details', id: 1 },
    { name: 'Payment methods', id: 2 },
];

export const availableMethods = [
    { gate: 10, currencies: ['USD', 'CDF', 'EUR', 'RUB'], flag: ['fin', 'tech'] },
    { gate: 10, currencies: ['CDF', 'EUR'], flag: ['fin'] },
    { gate: 10, currencies: ['USD', 'CDF', 'EUR', 'RUB'], flag: ['tech'] },
];
