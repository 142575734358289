export const emptyAgentInfo = {
    NIF: '',
    RC: '',
    balance: '',
    city: '',
    country: '',
    email: '',
    legalName: '',
    name: '',
    password: '',
    phone: '',
    postCode: '',
    streetAddress: '',
    timeZone: '',
};
