export const AuthActions = {
    AUTH_LOGIN_FETCH: 'AUTH_LOGIN_FETCH',
    AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    AUTH_LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
    AUTH_SET_USERID: 'AUTH_SET_USERID',

    AUTH_EJECT_USER: 'AUTH_ERROR',

    AUTH_LOGOUT_START: 'AUTH_LOGOUT_START',
    AUTH_LOGOUT_FETCH: 'AUTH_LOGOUT_FETCH',
    AUTH_UPDATE_TOKEN: 'AUTH_UPDATE_TOKEN',

    AUTH_PERMISSIONS_FETCH: 'AUTH_PERMISSIONS_FETCH',
    AUTH_PERMISSIONS_SUCCESS: 'AUTH_PERMISSIONS_SUCCESS',
    AUTH_PERMISSIONS_ERROR: 'AUTH_PERMISSIONS_ERROR',
    AUTH_PERMISSIONS_CLEAR: 'AUTH_PERMISSIONS_CLEAR',
};

export const AUTH_KEY = 'bearer-authenticated';
export const TOKEN = 'bearer-token';
