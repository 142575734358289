import React, { useState } from 'react';
import cn from 'classnames';
import styles from './FiltesMenu.less';
import { emptyValues } from './FilterMenu.helper';
import { useSelector } from 'react-redux';
import { PERM_GET_RECORDS_ADM } from '../../../utils/consts/global';

export const FiltersMenu = (props) => {
    const initialValues = { ...emptyValues, ...props.initialValues };
    const [values, setValues] = useState(initialValues);
    const gates = useSelector((state) => state.dictionaries.getGate.success) || [];
    const permissions = useSelector((state) => state.auth.permissions.success) || [];

    const handleChange = (event) => {
        const { target } = event;
        let { name, value } = target;
        event.persist();

        setValues({ ...values, [name]: value });
    };

    const handleSubmit = () => {
        Object.keys(values).forEach((name) => {
            if (name === 'floatAmount' || name === 'intType') {
                values[name] = isNaN(parseFloat(values[name])) ? '' : parseFloat(values[name]);
            }
        });

        props.onSubmit(values);
    };

    const clearValues = () => {
        setValues(emptyValues);
        props.onClear({ ...emptyValues });
    };

    const allowAdmFilters = permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1;

    return (
        <div className={cn(styles.dropDownMenu, 'quick-actions')}>
            <div className="quick-actions-header">
                <span className="title mb-1">More Filtes</span>
                <span className="subtitle op-8">There are no required fields here</span>
            </div>
            <div className="scroll-wrapper quick-actions-scroll scrollbar-outer">
                <div className="quick-actions-scroll scrollbar-outer scroll-content">
                    <div className="quick-actions-items">
                        <div className="form-group">
                            <div className="form-group form-floating-label">
                                <input
                                    type="text"
                                    required
                                    className="form-control input-solid"
                                    name="strUnipesaTransactionID"
                                    value={values.strUnipesaTransactionID}
                                    onChange={handleChange}
                                />
                                <label className={cn(styles.noPointer, 'placeholder')}>Unipesa Id:</label>
                            </div>

                            <div className="form-group form-floating-label">
                                <input
                                    required
                                    type="text"
                                    className="form-control input-solid"
                                    name="strCustomerAccount"
                                    value={values.strCustomerAccount}
                                    onChange={handleChange}
                                />
                                <label className={cn(styles.noPointer, 'placeholder')}>Customer Account:</label>
                            </div>

                            <div className="form-group">
                                <div className="form-group form-floating-label">
                                    <select
                                        className="form-control input-border-bottom nput-solid input-solid"
                                        name="intType"
                                        value={values.intType}
                                        onChange={handleChange}
                                    >
                                        <option> </option>
                                        {allowAdmFilters && (<option value="10">status</option>)}
                                        {allowAdmFilters && (<option value="24">callback</option>)}
                                        {allowAdmFilters && (<option value="23">balance</option>)}
                                        <option value="16">withdraw</option>
                                        <option value="17">deposit</option>
                                        <option value="20">deposit cash</option>
                                        <option value="21">withdrawal сash</option>
                                        <option value="32">paybill</option>
                                    </select>
                                    <label className="placeholder">Type:</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-group form-floating-label">
                                    <select
                                        className="form-control input-border-bottom nput-solid input-solid"
                                        name="intPaymentMethod"
                                        value={values.intPaymentMethod}
                                        onChange={handleChange}
                                    >
                                        <option value={''}> </option>
                                        {gates.map((el, id) => (
                                            <option value={el.id} key={String(id)}>
                                                {el.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="placeholder">Payment Method:</label>
                                </div>
                            </div>

                            <div className="form-group form-floating-label">
                                <input
                                    required
                                    type="text"
                                    className="form-control input-solid"
                                    name="strMerchantAccount"
                                    value={values.strMerchantAccount}
                                    onChange={handleChange}
                                />
                                <label className={cn(styles.noPointer, 'placeholder')}>Merchant Account:</label>
                            </div>

                            <div className="form-group form-floating-label">
                                <input
                                    type="text"
                                    className="form-control input-solid"
                                    name="floatAmount"
                                    value={values.floatAmount}
                                    onChange={handleChange}
                                    required
                                />
                                <label className={cn(styles.noPointer, 'placeholder')}>Amount:</label>
                            </div>

                            <div className={cn(styles.buttoBottomPlace, 'form-group')}>
                                <button className="btn btn-primary" onClick={clearValues}>
                                    Clear
                                </button>
                                &nbsp;
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
