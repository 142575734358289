import React, { useEffect } from 'react';
import cn from 'classnames';
import styles from './MerchantCard.less';
import { DropDown } from '../../DropDown/DropDown';
import { findTitleById } from '../../../../utils/helpers/commonHelpers';
import { ActionButton } from '../../ActionButton/ActionButton';


export const MerchantCard = (props) => {
    const { merchant, selectedLegal, legalsList, onSelectLegal, dict } = props;
    const Contact = merchant?.params?.Contact;
    const { wl } = dict;

    const onCreate = () => {
        if (props.onCreate) {
            props.onCreate();
        }
    };

    const createText = props.legalName || 'Please fill form below';
    const wlName = wl.find((el => el?.id === merchant.whitelabel))?.title || 'Avada Pay';

    return (
        <div className={styles.infoBlock}>
            <div className={styles.leftSideInfo}>
                <div className={styles.line}>
                    <div className={styles.firstColumn}>Merchant name:</div>
                    <div className={styles.secondColumn}>{merchant.merchantBrandName}</div>
                </div>
                <div className={styles.line}>
                    <div className={styles.firstColumn}>Merchant id:</div>
                    <div className={styles.secondColumn}>{merchant.id}</div>
                </div>
                <div className={styles.line}>
                    <div className={styles.firstColumn}>Creation date:</div>
                    <div className={styles.secondColumn}>01.02.2001</div>
                </div>

                <div className={styles.line}>
                    <div className={styles.firstColumn}>WhiteLabel:</div>
                    <div
                        className={styles.secondColumn}>{wlName}</div>
                </div>

                <div className={cn(styles.line)}>

                    <div className={styles.firstColumn}>Company:</div>
                    <div>
                        <div className={cn(styles.fixedDropWidth)}>
                            {(!!props.legalsList.length && !props.createMode) && (
                                <DropDown
                                    color="grey"
                                    options={legalsList}
                                    onSelect={onSelectLegal}
                                    name="id"
                                    className={styles.dropWrapper}
                                >
                                    {findTitleById(legalsList, selectedLegal?.id)}&nbsp;
                                </DropDown>
                            )}
                            {props.createMode ? (<>{createText}</>) : (<>
                                <ActionButton
                                    click={onCreate}
                                    className={styles.addWidth}
                                >
                                    Add
                                </ActionButton>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.rightSideInfo}>
                <div className={styles.mainTop}>
                    <div className={cn(styles.line, styles.topline)}>
                        <div className={styles.firstColumn}>Contact name:</div>
                        <div className={styles.secondColumn}>{Contact?.Name}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Contact phone:</div>
                        <div className={styles.secondColumn}>{Contact?.Phone}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Contact email:</div>
                        <div className={styles.secondColumn}>{Contact?.EMail}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Status:</div>
                        <div className={styles.secondColumn}>{merchant.state}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
