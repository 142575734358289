import React from "react";
import { connect } from "react-redux";
import { Spinner } from "../Spinner/Spinner";
import { TableBody } from "../TableBody/TableBody";
import { onGetAccountLogFetch } from "../../../store/transactions/transactions.actions";
import { accountLogHeaders, emptyFilter } from "./AccountLogPanelHelper";
import { TableHeader } from "../TableHeader/TableHeader";
import cn from "classnames";
import styles from "./AccountLogPanelPanel.less";
import { SMALL_SCREEN } from "../../../utils/consts/global";
import { Paginator } from '../Paginator/Paginator';

export class AccountLogPanelComponent extends React.Component {
    state = {
        filterParams: {
            ...emptyFilter
        },
    };

    componentDidMount() {
        this.props.onGetAccountLogFetch({});
    }

    updateLog = () => {
        this.props.onGetAccountLogFetch(this.state.filterParams);
    };

    clearFilter = () => {
        this.setState((prev) => ({
            ...prev,
            filterParams: {
                ...emptyFilter
            }
        }));
        this.props.onGetAccountLogFetch({  });
    };

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            ...prev,
            filterParams: {
                ...this.state.filterParams,
                [event.target.name]: event.target.value
            }
        }));
    };

    changePage = (page) => {
        const params = {
            ...this.state.filterParams,
            intCurrentPage: page.selected + 1
        };
        this.setState((prev) => ({
                ...prev,
                filterParams: {
                    ...this.state.filterParams,
                    intCurrentPage: page.selected + 1
                }
            }),
            () => {
                this.updateLog();
            });
    };

    render() {
        const { filterParams } = this.state;
        const { intID, intPayment } = filterParams;

        const {fetching, success} = this.props.transactions.getAccountLog;
        const accountLog = success?.data || [];
        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Summary</h1>}
                        <div className="page-header">
                            <div className={cn(styles.cardHeader)}>
                                    <label htmlFor="intID" className={styles.inlineItem}>Client Id:</label>
                                    <input type="number"
                                           className={cn(styles.inlineItem,styles.filterInput, "form-control")}
                                           id="intID"
                                           name="intID"
                                           value={intID}
                                           placeholder="Enter clientId"
                                           onChange={this.changeInputHandler}
                                    />
                                    <label htmlFor="intPayment" className={styles.inlineItem}>Operation Id:</label>
                                    <input type="number"
                                           className={cn(styles.inlineItem, styles.filterInput, "form-control")}
                                           id="intPayment"
                                           name="intPayment"
                                           value={intPayment}
                                           placeholder="Enter operation Id"
                                           onChange={this.changeInputHandler}
                                    />
                                <button className={cn("btn btn-info", styles.marginButton)} onClick={this.clearFilter}>Clear</button>
                                <button className="btn btn-primary" onClick={this.updateLog}>Apply</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader
                                                    rowNames={accountLogHeaders}
                                                    hasCollapseSwich={false}
                                                />
                                                <TableBody dataTable={accountLog} rowNames={accountLogHeaders}
                                                    hasCollapseSwich={false} />
                                            </table>
                                        )}
                                    </div>
                                    <div className={styles.paginatorPlace}>
                                        <Paginator pagesData={success?.paginator} changePage={this.changePage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetAccountLogFetch
};

const mapStateToProps = (state) => ({
    transactions: state.transactions,
    app: state.app
});

export const AccountLogPanel = connect(mapStateToProps, mapDispatchToProps)(AccountLogPanelComponent);
