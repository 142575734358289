import { BALANCE_ACCOUNT_START_WITH } from '../../../../utils/consts/global';
import { findCurrencyByNum } from '../AgentsList/AgentsListHelper';

export const legalState = {
    1: 'Draft',
    2: 'Pending',
    3: 'Published',
};

export const getSummaryBalances = ({ accounts, currencies, currencyMetric }) => {
    if (!accounts && !accounts?.length) return null;
    const neededAccounts = accounts.filter(el => el.account?.startsWith(BALANCE_ACCOUNT_START_WITH));
    const balancesArray = neededAccounts.map(el => {
        const curr = findCurrencyByNum({ num: el.currency, currencies })
        return  {
            currency: curr,
            balance: el.balance,
            transactionsCount: currencyMetric[curr]?.transactionsCount || 0,
            commissions: currencyMetric[curr]?.commissions || 0,
        };
        // + ' ' + findCurrencyByNum({ num: el.currency, currencies });
    });

    return balancesArray;
};
