import { call, put, takeEvery } from 'redux-saga/effects';
import { errorNotify } from '../helpers/commonHelpers';
import { onHandleErrors } from '../errors/errors.actions';
import { Dictionaries } from './dictionaries.consts';
import { fetchDictionaries } from './dictionaries.services';
import {
    onBranchesError, onBranchesFetch, onBranchesSuccess,
    onCreateLogoError,
    onCreateLogoSuccess,
    onCurrenciesError,
    onCurrenciesFetch,
    onCurrenciesSuccess,
    onGetAllLogosError,
    onGetAllLogosSuccess,
    onGetAllowedOperatorsError,
    onGetAllowedOperatorsSuccess,
    onGetBanksError, onGetBanksFetch,
    onGetBanksSuccess,
    onGetClientsError,
    onGetClientsSuccess,
    onGetCountriesError,
    onGetCountriesFetch,
    onGetCountriesSuccess, onGetGateAdmError, onGetGateAdmSuccess,
    onGetGateError,
    onGetGateFetch,
    onGetGateSuccess,
    onGetMerchantsError,
    onGetMerchantsSuccess,
    onGetMerchantTypesError,
    onGetMerchantTypesSuccess,
    onGetSubClientsError,
    onGetSubClientsSuccess, onGetTerminalsAdmError, onGetTerminalsAdmFetch, onGetTerminalsAdmSuccess,
    onSetLogoError,
    onSetLogoSuccess,
    onWLError, onWLFetch,
    onWLSuccess,
} from './dictionaries.actions';

export function* sagaAllowedOperatorsFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetAllowedOperators',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAllowedOperatorsError(error));
        } else if (response) {
            yield put(onGetAllowedOperatorsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAllowedOperatorsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAllowedOperatorsError(error));
    }
}

export function* sagaCountriesFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetCountry',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetCountriesError(error));
        } else if (response) {
            yield put(onGetCountriesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetCountriesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetCountriesError(error));
    }
}

export function* sagaCurrenciesFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetCurrency',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCurrenciesError(error));
        } else if (response) {
            yield put(onCurrenciesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCurrenciesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCurrenciesError(error));
    }
}

export function* sagaGetGateFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetGate',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetGateError(error));
        } else if (response) {
            yield put(onGetGateSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetGateError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetGateError(error));
    }
}

export function* sagaGetGateAdmFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetGateAdm',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetGateAdmError(error));
        } else if (response) {
            yield put(onGetGateAdmSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetGateAdmError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetGateAdmError(error));
    }
}

export function* sagaGetTerminalsAdmFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetTerminalAdm',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetTerminalsAdmError(error));
        } else if (response) {
            yield put(onGetTerminalsAdmSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetTerminalsAdmError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetTerminalsAdmError(error));
    }
}

export function* sagaGetClientsFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetClient',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetClientsError(error));
        } else if (response) {
            yield put(onGetClientsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetClientsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetClientsError(error));
    }
}

export function* sagaGetSubClientsFetch() {
    try {
        const { response, error } = yield call(fetchDictionaries, {
            method: 'GetSubClient',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetSubClientsError(error));
        } else if (response) {
            yield put(onGetSubClientsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetSubClientsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetSubClientsError(error));
    }
}

export function* sagaGetAllDict() {
    yield put(onGetGateFetch());
    yield put(onCurrenciesFetch());
    yield put(onGetCountriesFetch());
    // yield put(onWLFetch());
    // yield put(onBranchesFetch());
    // yield put(onGetBanksFetch());
    // yield put(onGetTerminalsAdmFetch());
}

export function* sagaGetAllLogosFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetAllLogos',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAllLogosError(error));
        } else if (response) {
            yield put(onGetAllLogosSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAllLogosError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAllLogosError(error));
    }
}

export function* sagaUpdateLogo(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'SetLogoData',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSetLogoError(error));
        } else if (response) {
            yield put(onSetLogoSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSetLogoError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSetLogoError(error));
    }
}

export function* sagaCreateLogo(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'AddLogo',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCreateLogoError(error));
        } else if (response) {
            yield put(onCreateLogoSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCreateLogoError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCreateLogoError(error));
    }
}

export function* sagaGetMerchantsFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetMerchant',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetMerchantsError(error));
        } else if (response) {
            yield put(onGetMerchantsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetMerchantsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetMerchantsError(error));
    }
}

export function* sagaGetBanksFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetBank',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetBanksError(error));
        } else if (response) {
            yield put(onGetBanksSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetBanksError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetBanksError(error));
    }
}

export function* sagaGetMerchantTypesFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetMerchantTypes',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetMerchantTypesError(error));
        } else if (response) {
            yield put(onGetMerchantTypesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetMerchantTypesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetMerchantTypesError(error));
    }
}

export function* sagaGetWLFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetWhitelabels',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onWLError(error));
        } else if (response) {
            yield put(onWLSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onWLError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onWLError(error));
    }
}

export function* sagaGetBrancesFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchDictionaries, {
            ...payload,
            method: 'GetBranches',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onBranchesError(error));
        } else if (response) {
            yield put(onBranchesSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onBranchesError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onBranchesError(error));
    }
}

export function* dictionariesSaga() {
    yield takeEvery(Dictionaries.GET_ALL_DICTIONARIES, sagaGetAllDict);
    yield takeEvery(Dictionaries.GET_ALLOWED_OPERATORS_FETCH, sagaAllowedOperatorsFetch);
    yield takeEvery(Dictionaries.GET_COUNTRIES_FETCH, sagaCountriesFetch);
    yield takeEvery(Dictionaries.GET_CURRENCIES_FETCH, sagaCurrenciesFetch);
    yield takeEvery(Dictionaries.GET_GATE_FETCH, sagaGetGateFetch);
    yield takeEvery(Dictionaries.GET_GATE_ADM_FETCH, sagaGetGateAdmFetch);
    yield takeEvery(Dictionaries.GET_TERMINALS_ADM_FETCH, sagaGetTerminalsAdmFetch);
    yield takeEvery(Dictionaries.GET_CLIENTS_FETCH, sagaGetClientsFetch);
    yield takeEvery(Dictionaries.GET_SUBCLIENTS_FETCH, sagaGetSubClientsFetch);
    yield takeEvery(Dictionaries.SET_LOGO_FETCH, sagaUpdateLogo);
    yield takeEvery(Dictionaries.GET_LOGOS_FETCH, sagaGetAllLogosFetch);
    yield takeEvery(Dictionaries.CREATE_LOGO_FETCH, sagaCreateLogo);
    yield takeEvery(Dictionaries.GET_MERCHANTS_FETCH, sagaGetMerchantsFetch);
    yield takeEvery(Dictionaries.GET_BANKS_FETCH, sagaGetBanksFetch);
    yield takeEvery(Dictionaries.GET_MERCHANT_TYPES_FETCH, sagaGetMerchantTypesFetch);
    yield takeEvery(Dictionaries.GET_WL_FETCH, sagaGetWLFetch);
    yield takeEvery(Dictionaries.GET_BRANCHES_FETCH, sagaGetBrancesFetch);
}
