import React, { useEffect, useRef, useState } from 'react';
import { DropDown } from '../DropDown/DropDown';
import { getCurrentDayEnd, getWeekBack } from '../Operations/OperationsHelper';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { useOutsideAlerter } from '../../../utils/customHooks';
import { periodOptions } from './SummaryFilter.helper';
import cn from 'classnames';
import * as moment from 'moment';
import styles from './SummaryFilter.less';

export const SummaryFilter = (props) => {
    const initialFiltersState = {
        strPeriod: periodOptions[0].id,
    };
    const filtersDropDownRef = useRef(null);
    const [isCalendarShowing, switchCalendar] = useState(false);
    const [selfParams, updateSelfParams] = useState(initialFiltersState);
    const [dates, onDateRangeChange] = useState([getWeekBack(), getCurrentDayEnd()]); // moment(new Date()).subtract(7, 'days')
    const CUSTOM = periodOptions[periodOptions.length - 1].id;

    const callUpdate = () => {
        if (props.onChange) {
            props.onChange({
                ...selfParams,
            });
        }
    };

    useEffect(() => {
        if (selfParams.strPeriod !== CUSTOM) {
            callUpdate();
        } else {
            const dtCreatedRange = `${moment(dates[0]).format()};${moment(dates[1]).format()}`; // 2020-01-01T00:00:00-08:00;2021-02-23T23:59:59-08:00
            if (selfParams.dtCreatedRange === dtCreatedRange) return;
            props.onChange({
                ...selfParams,
                dtDatetimeRange: dtCreatedRange,
            });
        }
    }, [selfParams, dates]);

    const closeUserFilters = () => {
        switchCalendar(false);
    };

    const onDateRangeChanges = (dates) => {
        onDateRangeChange(dates);
    };

    const periodSelect = (val) => {
        updateSelfParams({
            strPeriod: val.id,
        });

        if (CUSTOM === val.id) {
            switchCalendar(true);
        } else {
            switchCalendar(false);
        }
    };

    useOutsideAlerter(filtersDropDownRef, closeUserFilters);

    return (
        <div className={cn(styles.filtersPane)}>
            <div className={styles.filterBarItem}>
                <div className={cn(styles.fixedDropWidth)}>
                    <DropDown options={periodOptions} onSelect={periodSelect} value={periodOptions[0]}>
                        Per day
                    </DropDown>
                </div>
            </div>

            {isCalendarShowing && (
                <div className={styles.filterBarItem}>
                    <DateTimeRangePicker
                        value={dates}
                        onChange={onDateRangeChanges}
                        disableClock={true}
                        format="dd/MM/yy HH:mm:ss"
                        calendarClassName={styles.calendarBg}
                        className={styles.datePickerRange}
                        showLeadingZeros={true}
                        calendarIcon={null}
                        clearIcon={null}
                        locale="en-US"
                    />
                </div>
            )}
        </div>
    );
};
