import React from 'react';
import { loginFetch } from '../../store/auth/auth.actions';
import { connect } from 'react-redux';
import { showNotify } from '../../store/app/app.actions';
import styles from './Login.less';

export class LoginComponent extends React.Component {
    constructor(props) {
        super(props);

        if (process.env.NODE_ENV === 'development') {
            this.state = {
                login: 'test',
                password: 'Test123!',
                passwdType: 'password',
            };
        } else {
            this.state = {
                login: null,
                password: null,
                passwdType: 'password',
            };
        }
    }

    INPUT_PASSWD_TYPE = 'password';
    INPUT_TEXT_TYPE = 'text';

    changePasswdType = () => {
        this.setState({
            passwdType: this.state.passwdType === this.INPUT_PASSWD_TYPE ? this.INPUT_TEXT_TYPE : this.INPUT_PASSWD_TYPE,
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signIn();
        }
    };

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            ...prev,
            ...{ [event.target.name]: event.target.value },
        }));
    };

    signIn = () => {
        const { login, password } = this.state;
        this.props.loginFetch({ login, password });
    };

    render() {
        const { passwdType } = this.state;
        return (
            <div className="login">
                <div className="wrapper wrapper-login">
                    <div className="container container-login animated fadeIn">
                        <h3 className="text-center">Sign in</h3>
                        <div className="login-form">
                            <div className="form-group">
                                <label htmlFor="username" className="placeholder">
                                    <b>Username</b>
                                </label>
                                <input
                                    id="username"
                                    name="login"
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={this.changeInputHandler}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password" className="placeholder">
                                    <b>Password</b>
                                </label>
                                {/*<a href="#" className="link float-right">Forget Password ?</a>*/}
                                <div className="position-relative">
                                    <input
                                        id="password"
                                        name="password"
                                        type={passwdType}
                                        className="form-control"
                                        required
                                        onChange={this.changeInputHandler}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <div className="show-password">
                                        <i className="icon-eye" onClick={this.changePasswdType}>
                                            {' '}
                                        </i>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.center}>
                                {/* "form-group form-action-d-flex mb-3"
                                                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="rememberme" />
                                    <label className="custom-control-label m-0" htmlFor="rememberme">
                                        Remember Me
                                    </label>
                                </div>*/}
                                <button onClick={this.signIn} className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold">
                                    Sign In
                                </button>
                            </div>

                            {/*                            <div className="login-account">
                                <span className="msg">Don't have an account yet? </span>
                                <a href="#" id="show-signup" className="link">
                                    Sign Up
                                </a>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    loginFetch,
    showNotify,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
