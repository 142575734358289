/*
 *   API DB data types fix  >:(
 *   income: it can be: undef or '1' or ['1'] or [1,2,3]
 *   convert it to String[] because all other formats ID in string, and API receives strings
 *   it mutate income data!
 *
 * */
export const normalizeClients = (clients) => {
    const { ...data } = clients.data;
    for (let user in data) {
        let clientlist = data[user].client;

        if (clientlist) {
            if (Array.isArray(clientlist)) {
                clientlist = clientlist.map((el) => String(el));
            } else {
                clientlist = [clientlist];
            }
        } else {
            clientlist = [];
        }
        data[user].client = clientlist;
    }
    return clients;
};
