export const removeMsg = (messages, message) => {
    return messages.filter(el => !(el.text === message.text));
};

export const initialSidebar = {
    smsExpanded:  false,
    balanceExpanded:  false,
    paymentsExpanded:  false,
    accountingExpanded:  false,
    settingsExpanded:  false,
}
