import { call, put, takeEvery } from "redux-saga/effects";
import { errorNotify } from "../helpers/commonHelpers";
import { onHandleErrors } from "../errors/errors.actions";
import { Merchants } from "./merchants.consts";
import {
    onLegalPublishError,
    onLegalPublishSuccess,
    onLegalSetMethodsError,
    onLegalSetMethodsSuccess,
    onMerchantAddCardError,
    onMerchantAddCardSuccess,
    onMerchantAddError,
    onMerchantAddLegalError,
    onMerchantAddLegalSuccess,
    onMerchantAddSuccess, onMerchantEditLegalError, onMerchantEditLegalSuccess,
    onMerchantGetCardError,
    onMerchantGetCardFetch,
    onMerchantGetCardSuccess,
    onMerchantGetLegalError,
    onMerchantGetLegalSuccess,
    onMerchantGetMethodsError,
    onMerchantGetMethodsFetch,
    onMerchantGetMethodsSuccess,
    onMerchantsGetError, onMerchantsGetSuccess,
} from './merchants.actions';
import { merchantService } from "./merchants.services";


export function* sagaAddMerchantFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "AddMerchant",
            setter: true
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantAddError(error));
        } else if (response) {
            yield put(onMerchantAddSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantAddError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantAddError(error));
    }
}

export function* sagaAddMerchantCardFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "AddMerchantCard",
            setter: true
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantAddCardError(error));
        } else if (response) {
            yield put(onMerchantAddCardSuccess(response.data));
            yield put(onMerchantGetCardFetch({intID:response.data?.data?.id}));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantAddCardError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantAddCardError(error));
    }
}

export function* sagaGetMerchantCardFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetMerchantCard"
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantGetCardError(error));
        } else if (response) {
            yield put(onMerchantGetCardSuccess(response.data?.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantGetCardError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantGetCardError(error));
    }
}

export function* sagaAddMerchantLegalFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "AddMerchantLegal",
            setter: true,
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantAddLegalError(error));
        } else if (response) {
            const data = response.data?.data;
            // const legalRequest = {
            //     intLegalID: data.id,
            //     intMerchantID: payload.intMerchantID,
            // };
            // yield put(onMerchantGetMethodsFetch(legalRequest));
            yield put(onMerchantAddLegalSuccess(data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantAddLegalError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantAddLegalError(error));
    }
}

export function* sagaEditMerchantLegalFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "EditMerchantLegal",
            setter: true,
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantEditLegalError(error));
        } else if (response) {
            const data = response.data?.data;
            yield put(onMerchantEditLegalSuccess(data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantEditLegalError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantEditLegalError(error));
    }
}

export function* sagaGetMerchantLegalFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetMerchantLegal"
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantGetLegalError(error));
        } else if (response) {
            yield put(onMerchantGetLegalSuccess(response.data?.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantGetLegalError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantGetLegalError(error));
    }
}

export function* sagaGetLegalMethodsFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetLegalPaymentMethods"
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantGetMethodsError(error));
        } else if (response) {
            yield put(onMerchantGetMethodsSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantGetMethodsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantGetMethodsError(error));
    }
}

export function* sagaSetLegalMethodsFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "SetLegalPaymentMethods",
            setter: true,
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onLegalSetMethodsError(error));
        } else if (response) {
            yield put(onLegalSetMethodsSuccess(response.data?.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onLegalSetMethodsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onLegalSetMethodsError(error));
    }
}

export function* sagaPublishLegalFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "SetMerchantLegalPublish",
            setter: true,
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onLegalPublishError(error));
        } else if (response) {
            yield put(onLegalPublishSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onLegalPublishError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onLegalPublishError(error));
    }
}

export function* sagaGetMerchantsListFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetMerchants",
        };
        const { response, error } = yield call(merchantService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMerchantsGetError(error));
        } else if (response) {
            yield put(onMerchantsGetSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onMerchantsGetError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMerchantsGetError(error));
    }
}


export function* merchantsSaga() {
    yield takeEvery(Merchants.MERCHANT_ADD_FETCH, sagaAddMerchantFetch);
    yield takeEvery(Merchants.MERCHANT_ADD_CARD_FETCH, sagaAddMerchantCardFetch);
    yield takeEvery(Merchants.MERCHANT_GET_CARD_FETCH, sagaGetMerchantCardFetch);
    yield takeEvery(Merchants.MERCHANT_ADD_LEGAL_FETCH, sagaAddMerchantLegalFetch);
    yield takeEvery(Merchants.MERCHANT_EDIT_LEGAL_FETCH, sagaEditMerchantLegalFetch);
    yield takeEvery(Merchants.MERCHANT_GET_LEGAL_FETCH, sagaGetMerchantLegalFetch);
    yield takeEvery(Merchants.LEGAL_GET_METHODS_FETCH, sagaGetLegalMethodsFetch);
    yield takeEvery(Merchants.LEGAL_SET_METHODS_FETCH, sagaSetLegalMethodsFetch);
    yield takeEvery(Merchants.LEGAL_PUBLISH_FETCH, sagaPublishLegalFetch);
    yield takeEvery(Merchants.MERCHANTS_LIST_GET_FETCH, sagaGetMerchantsListFetch);
}
