export const AppActions = {
    APP_NOTIFY_SHOW: 'APP_NOTIFY_SHOW',
    APP_NOTIFY_HIDE: 'APP_NOTIFY_HIDE',

    APP_MODAL_SHOW: 'APP_MODAL_SHOW',
    APP_MODAL_HIDE: 'APP_MODAL_HIDE',

    APP_UI_SIDEBAR_SHOW: 'APP_UI_SIDEBAR_SHOW',
    APP_UI_SIDEBAR_HIDE: 'APP_UI_SIDEBAR_HIDE',
    APP_UI_SIDEBAR_MOB_SHOW: 'APP_UI_SIDEBAR_MOB_SHOW',
    APP_UI_SIDEBAR_MOB_HIDE: 'APP_UI_SIDEBAR_MOB_HIDE',

    APP_UI_SIDEBAR_SETSTATE: 'APP_UI_SIDEBAR_SETSTATE',

    APP_SET_ADM_CLI_ID: 'APP_SET_ADM_CLI_ID',
    APP_SET_ADM_SUB_CLI_ID: 'APP_SET_ADM_SUB_CLI_ID',

    APP_STORE_DATA: 'APP_STORE_DATA',
    APP_CLEAR_DATA: 'APP_CLEAR_DATA',
};
