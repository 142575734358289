import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { CreateAgent } from '../../components/shared/Agent/CreateAgent/CreateAgent';

const AgentCreateNew = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="Agents" />
            <CreateAgent />
        </div>
    );
};

export default AgentCreateNew;
