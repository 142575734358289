import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./MerchantSummaryTab.less";
import { NavLink } from "react-router-dom";
import RoutesPaths from "../../../../routes/RoutesPaths";
import { ActionButton } from '../../ActionButton/ActionButton';
import { legalState } from './MerchantSummaryTab.helper';
import { NEW_LEGAL } from '../MerchantDetails/MerchantDetails.helper';

export const MerchantSummaryTab = (props) => {
    const { legal } = props;
    const { Contact } = legal?.params || {};
    const showAll = !!Object.keys(props.legal)?.length && legal.state !== NEW_LEGAL;

    return (<>
        {showAll && (
            <div className={styles.infoBlock}>
                <div className={styles.topBlock}>
                    <div className={styles.mainTop}>
                        <div className={styles.line}>
                            <div className={styles.firstColumn}>Legal name</div>
                            <div className={styles.secondColumn}>{legal?.merchantLegalName}</div>
                            <div className={styles.thirdColumn}><span className={styles.padRight}>id:</span> {legal?.id}
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.firstColumn}>Contract date</div>
                            <div className={styles.secondColumn}>{legal?.contractDate}</div>
                            <div className={styles.thirdColumn}><span className={styles.padRight}>Contact name:</span>
                                {Contact?.Name}
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.firstColumn}>&nbsp;</div>
                            <div className={styles.secondColumn}>&nbsp;</div>
                            <div className={styles.thirdColumn}><span
                                className={styles.padRight}>Contact phone:</span>
                                {Contact?.Phone}
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.firstColumn}>&nbsp;</div>
                            <div className={styles.secondColumn}>&nbsp;</div>
                            <div className={styles.thirdColumn}>
                                <span className={styles.padRight}>contact e-mail:</span>
                                {Contact?.EMail}
                            </div>
                        </div>
                    </div>
                </div>
                {legal?.summary?.map((cur, id) => (
                    <div className={styles.branchSection} key={String(id)}>
                        <div className={styles.leftSideInfo} >
                            <div className={cn(styles.line, styles.spacesTop)}>
                                <div className={styles.firstColumn}>Currency:</div>
                                <div className={styles.secondColumn}>{cur.currency}</div>
                            </div>
                            <div className={cn(styles.line)}>
                                <div className={styles.firstColumn}>Balance total</div>
                                <div className={styles.secondColumn}>{cur.balance}</div>
                                {id === 0 && (<div className={styles.thirdColumn}>
                                    <NavLink to={RoutesPaths.BALANCES}
                                             className={cn(styles.link)}>
                                        balance
                                    </NavLink>
                                </div>)}
                            </div>
                            <div className={styles.line}>
                                <div className={styles.firstColumn}>Transactions last 30d</div>
                                <div className={styles.secondColumn}>{cur.transactions30d}</div>
                                {id === 0 && (<div className={styles.thirdColumn}>
                                    <NavLink to={RoutesPaths.PAYMENTS}
                                             className={cn(styles.link)}>
                                        transactions
                                    </NavLink>
                                </div>)}
                            </div>
                            <div className={styles.line}>
                                <div className={styles.firstColumn}>Commission last 30d</div>
                                <div className={styles.secondColumn}>{cur.commissions30d}</div>
                                {id === 0 && (<div className={cn(styles.thirdColumn, styles.disabled)}>
                                     dashboard
                                </div>)}
                            </div>
                        </div>

                        {id === 0 && (
                            <div className={styles.rightSideInfo}>
                                <div className={styles.infoLine}>
                                    Status: <span className={cn(styles.active, styles.padBefore)}>{legalState[legal.state]}</span>
                                </div>

                                <div className={styles.infoLine}>
                                    <NavLink to={RoutesPaths.ACCOUNTS} className={cn(styles.link)}>
                                        accounts list
                                    </NavLink>
                                </div>
                                <div className={styles.infoLine}>
                                    {legal.state === '1' && (
                                        <ActionButton click={props.onPublish} disableIco>
                                            Publish
                                        </ActionButton>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                <div className={styles.commentPlace}>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Comments</div>
                        <div className={styles.secondColumn}>
                            <p>No comments yet</p>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>);
};
