import { timezones } from "../../../../utils/consts/global";

export const rows = [
    { name: "id", title: "Id", className: "noWrap", options: "", isSortable: false },
    { name: "merchantBrandName", title: "Merchant brand name", className: "noWrap", options: "", isSortable: false },
    { name: "typeTitle", title: "Type", className: "noWrap", options: "", isSortable: false },
    { name: "stateTitle", title: "State", className: "noWrap", options: "", isSortable: false },
    { name: "regDT", title: "Registration date", className: "noWrap", options: "", isSortable: false }
];

export const menuItems = [
    { name: "Merchant information" },
    { name: "Payment methods" },
    { name: "User" }
];

export const emptyMerchant = {
    strLogin: "",
    strPassword: "",
    strMerchantLegalName: "",
    strMerchantBrandingName: "",
    intMerchantType: "",
    strCountry: "",
    strTimeZone: timezones[13].title,
    dtContractDate: new Date(),
    intWhitelabel: "",
    intBank: "",
    intBranch: "",
    // intCurrency: '',
    strEmail: "",
    strContact: "",
    arrPaymentMethods: [
        // { id
        //     strMerchantBrandName: "",
        //     acc_msisdn: "",
        //     isFinancialAggregator: false,
        //     recipient_commission: 0,
        //     recipient_bonus: 0,
        //     sender_commission: 0,
        //     sender_bonus_instant: 0,
        //     boolEnabled: true,
        // },
    ]
};

export const getCurrCodeNumByCodeAlpha = (alpha3CodesArr, allCurrencies) => {
    return alpha3CodesArr?.map(codeAlpha => allCurrencies.find(curr => curr.codeAlpha === codeAlpha)?.codeNum);
};
