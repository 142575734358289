export const Metric = {
    GET_DASHBOARD_DATA_FETCH: 'GET_DASHBOARD_DATA_FETCH',
    GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
    GET_DASHBOARD_DATA_ERROR: 'GET_DASHBOARD_DATA_ERROR',
    GET_DASHBOARD_DATA_CLEAR: 'GET_DASHBOARD_DATA_CLEAR',

    GET_PAYMENT_SUM_FETCH: 'GET_PAYMENT_SUM_FETCH',
    GET_PAYMENT_SUM_SUCCESS: 'GET_PAYMENT_SUM_SUCCESS',
    GET_PAYMENT_SUM_ERROR: 'GET_PAYMENT_SUM_ERROR',
    GET_PAYMENT_SUM_CLEAR: 'GET_PAYMENT_SUM_CLEAR',

    GET_TOTALS_FETCH: 'GET_TOTALS_FETCH',
    GET_TOTALS_SUCCESS: 'GET_TOTALS_SUCCESS',
    GET_TOTALS_ERROR: 'GET_TOTALS_ERROR',
    GET_TOTALS_CLEAR: 'GET_TOTALS_CLEAR',
};
