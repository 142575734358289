import { AuthActions } from './auth.consts';

export const loginFetch = (payload) => ({
    type: AuthActions.AUTH_LOGIN_FETCH,
    payload,
});

export const onLoginSuccess = (payload) => ({
    type: AuthActions.AUTH_LOGIN_SUCCESS,
    payload,
});

export const onLoginError = (payload) => ({
    type: AuthActions.AUTH_LOGIN_ERROR,
    payload,
});

export const onLogoutFetch = (payload) => ({
    type: AuthActions.AUTH_LOGOUT_FETCH,
    payload,
});

export const onAuthEjectUser = (payload) => ({
    type: AuthActions.AUTH_EJECT_USER,
    payload,
});

export const setTokenFromStorage = (payload) => ({
    type: AuthActions.AUTH_UPDATE_TOKEN,
    payload,
});

/*
 * Get permissions
 */
export const onPermissionsFetch = (payload) => ({
    type: AuthActions.AUTH_PERMISSIONS_FETCH,
    payload,
});

export const onPermissionsSuccess = (payload) => ({
    type: AuthActions.AUTH_PERMISSIONS_SUCCESS,
    payload,
});

export const onPermissionsError = (payload) => ({
    type: AuthActions.AUTH_PERMISSIONS_ERROR,
    payload,
});

export const onPermissionsClear = (payload) => ({
    type: AuthActions.AUTH_PERMISSIONS_CLEAR,
    payload,
});
