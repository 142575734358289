import React from 'react';
import { rowNamesOptions } from "../TableBody/TableBodyHelper";

export const rows = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: false },
    { name: 'code', title: 'code', className: 'noWrap', options: '', isSortable: false },
    { name: 'title', title: 'title', className: 'noWrap', options: '', isSortable: false },
    { name: 'description', title: 'description', className: 'noWrap', options: '', isSortable: false },
    { name: 'created', title: 'created', className: 'noWrap', options: '', isSortable: false },
    { name: 'createdByUID', title: 'created by', className: 'noWrap', options: '', isSortable: false },
    { name: 'updated', title: 'updated', className: 'noWrap', options: '', isSortable: false },
    { name: 'updatedByUID', title: 'updated by', className: 'noWrap', options: '', isSortable: false },
    { name: 'operations', title: 'actions', className: 'rightAlign', options: rowNamesOptions.editBlock, isSortable: false },
];

export const emptyItem = {
    id: '',
    code: '',
    title: '',
    description: '',
};

export const objToArray = (obj) => {
    let arr = [];

    for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
            arr.push(obj[k]);
        }
    }
    return arr;
};
