import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AgentInfoForm.less';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import { Agents } from '../../../../store/agents/agents.consts';
import { AgentFields } from '../AgentFields/AgentFields';
import { noErrorsFields } from '../AgentsEdit/AgentsEditHelper';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import { emptyAgentInfo } from './AgentInfoForm.helper';

export const AgentInfoForm = (props) => {
    const history = useHistory();


    const dispatch = useDispatch();
    const getAgentInfo = useSelector((state) => state.agents.getAgentInfo);
    const editAgent = useSelector((state) => state.agents.editAgent.success);
    const [agentInfo, setAgentInfo] = useState({ ...emptyAgentInfo, ...getAgentInfo.success?.agentInfo || {} });
    const [showSuccess, setShowSuccess] = useState(false);
    const errorFields = noErrorsFields();

    useEffect(() => {
        dispatch({
            type: Agents.GET_AGENT_INFO_FETCH,
            payload: {},
        });
    }, []);

    useEffect(() => {
        setAgentInfo({ ...emptyAgentInfo, ...getAgentInfo.success?.agentInfo || {} });
    }, [getAgentInfo]);

    useEffect(() => {
        if (editAgent) {
            setShowSuccess(true);
            dispatch({
                type: Agents.EDIT_AGENT_CLEAR,
                payload: null,
            });
        }

    }, [editAgent]);

    const changeInputHandler = (event) => {
        event.persist();
        const name = event.target.name.split(':')[0];
        const value = event.target.value;

        setAgentInfo({
            ...agentInfo,
            [name]: value,
        });

    };

    const params = {
        ...agentInfo,
        errorFields,
        changeInputHandler,
        selfEdit: true,
    };

    const onCancelEdit = () => {
        history.goBack();
    };

    const onConfirmEdit = () => {
        const payload = {
            agentUserID: sessionStorage.getItem('UserID'),
            agentLogin: sessionStorage.getItem('login'),
            agentInfo,
            enabled: true,
        };

        dispatch({
            type: Agents.EDIT_AGENT_FETCH,
            payload,
        });
    };

    const onGoOut = () => {
        setShowSuccess(false);
        // history.push({
        //     pathname: RoutesPaths.HOME,
        //     search: '',
        // });
    };

    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className="container">
                <div className="page-inner">
                    <div className="col-md-12">
                        <div className={cn(styles.card, 'card')}>
                            <div className={cn(styles.agentPlace)}>
                                <h2 className={styles.bordered}>Agent info</h2>
                                <div className={styles.inputs}>
                                    <AgentFields {...params} />
                                    <ButtonsOkCancelBlock
                                        onCancelEdit={onCancelEdit}
                                        onConfirmEdit={onConfirmEdit}
                                        okIsDisabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSuccess && (
                <SweetAlert
                    show={showSuccess}
                    title="Success!"
                    type="success"
                    text="Saved successful"
                    confirmButtonColor="#1572E8"
                    onConfirm={onGoOut}
                />
            )}
        </div>
    );
};

