import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { MerchantDetails } from "../../components/shared/Merchant/MerchantDetails/MerchantDetails";

const Merchant = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="Merchants" />
            <MerchantDetails history={props.history}/>
        </div>
    );
};

export default Merchant;
