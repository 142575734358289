import React from 'react';
import { TableHeader } from '../TableHeader/TableHeader';
import { rows } from '../AdmRolesGroupPanel/AdmRolesGroupPanelHelper';
import { TableBody } from '../TableBody/TableBody';

export const AdmRolesTable = (props) => {
    return (
        <table className="display table table-striped table-hover dataTable">
            <TableHeader rowNames={rows} hasCollapseSwich={false} />
            <TableBody dataTable={props.roles} rowNames={props.rows} hasCollapseSwich={false} events={props.onDataLineEvents} />
        </table>
    );
};
