import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import { onMakePaymentB2CFetch } from '../../../store/paymentOperations/paymentOperations.actions';

import cn from 'classnames';
import styles from './CreatePaymentPanel.less';
import { DropDown } from '../DropDown/DropDown';
import { getReadableOperators } from './CreatePaymentPanelHelper';
import { onGetAllowedOperatorsFetch } from '../../../store/dictionaries/dictionaries.actions';
import { ConfirmPopUp } from '../ConfirmPopUp/CotfirmPopUp';
import { Modal } from '../Modal/Modal';
import SweetAlert from 'sweetalert2-react';
import { CODE_IN_PROGRESS, CODE_IN_TRANSIT, CODE_INITIATED, CODE_SUCCESS, CODE_SUCCESS_HOLD } from '../../../utils/consts/global';

export class CreatePaymentPanelComponent extends React.Component {
    state = {
        account: '',
        amount: 0,
        currency: null,
        internalComment: '',
        currencyList: [],
        operator: null,
        showConfirm: false,
        showSuccess: false,
        showError: false,
        showProcess: false,
        statusMsg: '',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { success, errors } = this.props.payments.makePaymentB2C;
        if (errors && JSON.stringify(errors) !== JSON.stringify(prevProps.payments.makePaymentB2C.errors)) {
            this.setState({
                showError: true,
                statusMsg: errors?.errorMessage || errors,
            });
        }

        if (success && JSON.stringify(success) !== JSON.stringify(prevProps.payments.makePaymentB2C.success)) {
            if (parseInt(success.status) === CODE_SUCCESS || parseInt(success.status) === CODE_SUCCESS_HOLD) {
                this.setState({
                    showError: false,
                    showProcess: false,
                    showSuccess: true,
                    statusMsg: success?.provider_result?.message || '',
                });
            }
            if (
                parseInt(success.status) === CODE_INITIATED ||
                parseInt(success.status) === CODE_IN_PROGRESS ||
                parseInt(success.status) === CODE_IN_TRANSIT
            ) {
                this.setState({
                    showError: false,
                    showProcess: true,
                    showSuccess: false,
                    statusMsg: success?.provider_result?.message || '',
                });
            }
        }

        if (this.props.operators.success && prevProps.operators.success?.length !== this.props.operators.success?.length) {
            const operatorsList = getReadableOperators(this.props.operators.success);
            if(operatorsList.length) {
                const operator = operatorsList[0];
                const currencyList = operator?.currencyList;
                const currency = currencyList[0];
                this.setState({
                    operator,
                    currencyList,
                    currency,
                });
            }

        }
    }

    componentDidMount() {
        if (!this.props.operators?.success?.length) {
            this.props.onGetAllowedOperatorsFetch();
        }
    }

    handlerCurrencies = (val) => {
        this.setState((prev) => ({
            ...prev,
            currency: val,
        }));
    };

    handlerOperatorChange = (val) => {
        this.setState((prev) => ({
            ...prev,
            operator: val,
            currencyList: val.currencyList,
        }));
    };

    handleText = (event) => {
        event.persist();
        const internalComment = event.target.value;
        this.setState((prev) => ({
            ...prev,
            internalComment,
        }));
    };

    handleAmount = (event) => {
        event.persist();
        const amount = event.target.value;
        this.setState((prev) => ({
            ...prev,
            amount,
        }));
    };

    handleAccount = (event) => {
        event.persist();
        const account = event.target.value;
        this.setState((prev) => ({
            ...prev,
            account,
        }));
    };

    proceed = () => {
        const { account, amount, internalComment, currency } = this.state;
        if (!account || !amount || !currency?.id) return;
        this.setState({ showConfirm: true });
    };

    closeModal = () => {
        this.setState({ showConfirm: false });
    };

    onConfirm = () => {
        this.closeModal();
        const { account, amount, currency, operator, internalComment } = this.state;
        const payload = {
            strOperator: operator.title,
            strCustomerMobile: account,
            strCurrency: currency.id,
            numberAmount: Number(amount).toFixed(2),
            strOrderID: '2000000' + Date.now(),
            strInternalComment: internalComment || ' ', /*   API BUG FOR EMPTY FIELD:)   */
            boolLegacy: 'true',
        };
        this.props.onMakePaymentB2CFetch(payload);
    };

    render() {
        const { operators } = this.props;
        const {
            showSuccess,
            showError,
            showProcess,
            textValue,
            currencyList,
            showConfirm,
            account,
            amount,
            currency,
            statusMsg,
        } = this.state;
        const operatorsList = getReadableOperators(operators.success);
        const { fetching } = this.props.payments.makePaymentB2C;

        return (
            <div className={cn(styles.relative, 'main-panel')}>
                {fetching && (
                    <div className={styles.spinnerPlace}>
                        <Spinner />
                    </div>
                )}
                <div className="container ">
                    <div className="page-inner">
                        {!fetching && (
                            <div className={styles.wizardContainer}>
                                <div className={styles.headerBlock}>
                                    <h3 className={styles.mainHeaderText}>
                                        <b>Payment</b> creation
                                    </h3>
                                    <p className={styles.descr}>
                                        Now let's make the payment. Let's transfer some money to the account. Fill in the fields and click
                                        proceed. Limits are described in you agreement. So, let's do it!
                                    </p>
                                </div>

                                <div className="card-body">
                                    <div className={styles.inputLine}>
                                        <label htmlFor="name" className={styles.leftBlock}>
                                            Payment method&nbsp;<span className="required-label">*</span>
                                        </label>
                                        {operators.fetch ? (
                                            <Spinner />
                                        ) : (
                                            <div className={styles.rightBlock}>
                                                <DropDown
                                                    options={operatorsList}
                                                    onSelect={this.handlerOperatorChange}
                                                    value={operatorsList[0]}
                                                    className={styles.dropDown}
                                                >
                                                    Select payment method
                                                </DropDown>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.inputLine}>
                                        <label htmlFor="account" className={styles.leftBlock}>
                                            Transfer to&nbsp;<span className="required-label">*</span>
                                        </label>
                                        <div className={styles.rightBlock}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="account"
                                                name="account"
                                                placeholder="Enter Account Number"
                                                value={account}
                                                onChange={this.handleAccount}
                                                maxLength="10"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.inputLine}>
                                        <label htmlFor="amount" className={styles.leftBlock}>
                                            Amount&nbsp;<span className="required-label">*</span>
                                        </label>
                                        <div className={styles.rightBlock}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="amount"
                                                name="amount"
                                                placeholder="Amount"
                                                value={amount}
                                                onChange={this.handleAmount}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.inputLine}>
                                        <label htmlFor="name" className={styles.leftBlock}>
                                            Currency&nbsp;<span className="required-label">*</span>
                                        </label>
                                        <div className={styles.rightBlock}>
                                            <DropDown
                                                options={currencyList}
                                                onSelect={this.handlerCurrencies}
                                                value={currency || currencyList[0]}
                                                className={styles.dropDown}
                                            >
                                                Select currency
                                            </DropDown>
                                        </div>
                                    </div>

                                    <div className={styles.inputLine}>
                                        <label htmlFor="name" className={styles.leftBlock}>
                                            Сomment
                                        </label>
                                        <div className={styles.rightBlock}>
                                            <textarea
                                                name="InternalСomment"
                                                className="form-control success"
                                                rows="5"
                                                aria-invalid="false"
                                                value={textValue}
                                                onChange={this.handleText}
                                                maxLength={255}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.inputLine}>
                                        <label htmlFor="name" className={styles.leftBlock}>
                                            {' '}
                                        </label>
                                        <div className={cn(styles.rightBlock, styles.textRight)}>
                                            <button
                                                disabled={fetching}
                                                className={cn('btn btn-primary', styles.submitButton)}
                                                onClick={this.proceed}
                                            >
                                                Proceed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {showConfirm && (
                    <Modal faderClick={this.closeModal}>
                        <ConfirmPopUp
                            values={this.state}
                            onConfirm={this.onConfirm}
                            onCancel={this.closeModal}
                            className={styles.modalCenter}
                        />
                    </Modal>
                )}
                {showSuccess && (
                    <SweetAlert
                        show={showSuccess}
                        title="Success!"
                        type="success"
                        text="Transaction successful"
                        confirmButtonColor="#1572E8"
                        onConfirm={() => this.setState({ showSuccess: false })}
                    />
                )}

                {showError && (
                    <SweetAlert
                        show={showError}
                        title="Error :("
                        type="error"
                        confirmButtonColor="#1572E8"
                        text={'Transaction was unsuccessful ' + statusMsg}
                        onConfirm={() => this.setState({ showError: false })}
                    />
                )}
                {showProcess && (
                    <SweetAlert
                        show={showProcess}
                        title="In progress"
                        type="info"
                        confirmButtonColor="#1d8d12"
                        text={'Transaction in progress ' + statusMsg}
                        onConfirm={() => this.setState({ showProcess: false })}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onMakePaymentB2CFetch,
    onGetAllowedOperatorsFetch,
};

const mapStateToProps = (state) => ({
    payments: state.payments,
    operators: state.getAllowedOperators,
});

export const CreatePaymentPanel = connect(mapStateToProps, mapDispatchToProps)(CreatePaymentPanelComponent);
