export const defaultFiter = {
    // intItemsPerPage: 10,
    // intCurrentPage: 1,
    // strOrderBy: 'created',
    // strOrderDirection: 'asc',
    // intClientID: '78', // required, no default value.
    // dtCreatedRange: '2020-01-01T00:00:00-08:00;2021-02-23T23:59:59-08:00',
    // Description: 'Return records',
    // intStatus: 'initiated',
    // intID: '123',
    // strCustomerAccount:'23432',
    // strMerchantAccount:'123aaa',
    // intType:'Withdraw',
    // floatAmount: '123',
    // strCurrency:'USD',
    // intPaymentMethod:10, // 'Orange',
    // strUnipesaTransactionID: '123',
    // strMerchantTransactionID:'123',
};

export const removeEmpties = (values) => {
    const newObj = {};

    for (let name in values) {
        if (values[name]) {
            newObj[name] = values[name];
        }
    }
    return newObj;
};
