import { AppActions } from './app.consts';

export const showNotify = (payload) => ({
    type: AppActions.APP_NOTIFY_SHOW,
    payload,
});

export const hideNotify = (payload) => ({
    type: AppActions.APP_NOTIFY_HIDE,
    payload,
});

export const showModal = (payload) => ({
    type: AppActions.APP_MODAL_SHOW,
    payload,
});

export const hideModal = (payload) => ({
    type: AppActions.APP_MODAL_HIDE,
    payload,
});

export const isSideBarShowAction = (payload) => {
    const type = payload ? AppActions.APP_UI_SIDEBAR_SHOW : AppActions.APP_UI_SIDEBAR_HIDE;

    return {
        type,
        payload,
    };
};

export const sideBarSetState = (payload) => ({
    type: AppActions.APP_UI_SIDEBAR_SETSTATE,
    payload,
});



export const onStoreAppData = (payload) => ({
    type: AppActions.APP_STORE_DATA,
    payload,
});

export const onClearAppData = (payload) => ({
    type: AppActions.APP_CLEAR_DATA,
    payload,
});
