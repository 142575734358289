import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { RoleSystem } from './roleSystem.consts';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getRoles = handleActions(
    {
        [RoleSystem.GET_PERMISSION_ROLES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_PERMISSION_ROLES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_PERMISSION_ROLES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_PERMISSION_ROLES_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const createRole = handleActions(
    {
        [RoleSystem.CREATE_PERMISSION_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.CREATE_PERMISSION_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.CREATE_PERMISSION_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.CREATE_PERMISSION_ROLE_CLEAR]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: null },
                errors: { $set: null },
            });
        },
    },
    defaultState,
);

export const editRole = handleActions(
    {
        [RoleSystem.EDIT_PERMISSION_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_PERMISSION_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_PERMISSION_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const deleteRole = handleActions(
    {
        [RoleSystem.DELETE_PERMISSION_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.DELETE_PERMISSION_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.DELETE_PERMISSION_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getPermissionGroups = handleActions(
    {
        [RoleSystem.GET_PERMISSION_GROUPS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_PERMISSION_GROUPS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_PERMISSION_GROUPS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_PERMISSION_GROUPS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getGroupsRoles = handleActions(
    {
        [RoleSystem.GET_GROUP_ROLES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_GROUP_ROLES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_GROUP_ROLES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_GROUP_ROLES_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const addRoleToGroup = handleActions(
    {
        [RoleSystem.ADD_PERM_GROUP_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_PERM_GROUP_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_PERM_GROUP_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const removeRoleFromGroup = handleActions(
    {
        [RoleSystem.REMOVE_PERM_GROUP_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.REMOVE_PERM_GROUP_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.REMOVE_PERM_GROUP_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const createGroup = handleActions(
    {
        [RoleSystem.CREATE_PERM_GROUP_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.CREATE_PERM_GROUP_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.CREATE_PERM_GROUP_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const editGroup = handleActions(
    {
        [RoleSystem.EDIT_PERM_GROUP_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_PERM_GROUP_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_PERM_GROUP_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const deleteGroup = handleActions(
    {
        [RoleSystem.DELETE_PERM_GROUP_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.DELETE_PERM_GROUP_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.DELETE_PERM_GROUP_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getAllUsers = handleActions(
    {
        [RoleSystem.GET_ALL_USERS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_ALL_USERS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_ALL_USERS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_ALL_USERS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getUsersGroups = handleActions(
    {
        [RoleSystem.GET_USER_GROUPS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_USER_GROUPS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_USER_GROUPS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_USER_GROUPS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getDirectUsersRoles = handleActions(
    {
        [RoleSystem.GET_DIRECT_USER_ROLES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_DIRECT_USER_ROLES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_DIRECT_USER_ROLES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_DIRECT_USER_ROLES_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const editUser = handleActions(
    {
        [RoleSystem.EDIT_USER_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_USER_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.EDIT_USER_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const addUser = handleActions(
    {
        [RoleSystem.ADD_USER_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_USER_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_USER_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const enableUser = handleActions(
    {
        [RoleSystem.ENABLE_USER_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.ENABLE_USER_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.ENABLE_USER_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const addDirectUserRole = handleActions(
    {
        [RoleSystem.ADD_DIRECT_USER_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_DIRECT_USER_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_DIRECT_USER_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const addUserGroup = handleActions(
    {
        [RoleSystem.ADD_USER_GROUP_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_USER_GROUP_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.ADD_USER_GROUP_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const setDirectUserRole = handleActions(
    {
        [RoleSystem.SET_DIRECT_USER_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_DIRECT_USER_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_DIRECT_USER_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const setUserGroups = handleActions(
    {
        [RoleSystem.SET_USER_GROUPS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_USER_GROUPS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_USER_GROUPS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getFuncionRole = handleActions(
    {
        [RoleSystem.GET_FUNCTION_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_FUNCTION_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_FUNCTION_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_FUNCTION_ROLE_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getAllowedRRT = handleActions(
    {
        [RoleSystem.GET_ALLOWED_RRT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_ALLOWED_RRT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.GET_ALLOWED_RRT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.GET_ALLOWED_RRT_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const setFuncionRole = handleActions(
    {
        [RoleSystem.SET_FUNCTION_ROLE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_FUNCTION_ROLE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [RoleSystem.SET_FUNCTION_ROLE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [RoleSystem.SET_FUNCTION_ROLE_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const roleSystem = combineReducers({
    getPermissionRoles: getRoles,
    createRole,
    editRole,
    deleteRole,
    getPermissionGroups,
    getGroupsRoles,
    addRoleToGroup,
    removeRoleFromGroup,
    createGroup,
    editGroup,
    deleteGroup,
    getAllUsers,
    getUsersGroups,
    getDirectUsersRoles,
    editUser,
    addDirectUserRole,
    addUserGroup,
    setDirectUserRole,
    setUserGroups,
    addUser,
    enableUser,
    setFuncionRole,
    getFuncionRole,
    getAllowedRRT,
});
