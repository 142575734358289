import React from 'react';
import { rowNamesOptions } from "../TableBody/TableBodyHelper";


export const rows = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: true },
    { name: 'title', title: 'name', className: 'noWrap', options: '', isSortable: true },
    { name: 'imageBase64', title: 'image', className: 'noWrap', options: rowNamesOptions.image, isSortable: false },
    { name: 'operations', title: 'actions', className: 'rightAlign', options: rowNamesOptions.editBlock, isSortable: false },
];

export const selectedItem = {
    id: null,
    enabled: true,
    imageBase64: '',
    title: '',
};
