import { call, put, select, takeEvery } from 'redux-saga/effects';
import { errorNotify } from '../helpers/commonHelpers';
import { onHandleErrors } from '../errors/errors.actions';
import { PaymentOperations } from './paymentOperations.consts';
import { makePayment, paymentsService } from './paymentOperations.services';
import {
    onDepositMerchantError,
    onDepositMerchantSuccess,
    onGetPaymentC2BStatusError,
    onGetPaymentC2BStatusFetchProcess,
    onGetPaymentC2BStatusSuccess,
    onMakePaymentB2CError,
    onMakePaymentB2CSuccess, onSettlementMerchantError, onSettlementMerchantSuccess,
} from './paymentOperations.actions';
import { delay } from '@redux-saga/core/effects';
import {
    CODE_FAIL,
    STATUS_PENDING,
    CODE_SUCCESS,
    CODE_UNKNOWN,
    STATUS_REQUEST_COUNT,
    WAIT_TIMEOUT,
} from '../../utils/consts/global';
import { fetchSummary } from '../transactions/transactions.services';
import { onGetAccountLogError, onGetAccountLogSuccess } from '../transactions/transactions.actions';
import { setHumanDateFormat } from '../transactions/transactions.helper';

//TODO: убрать после тестов
/*
Результат выполнения success операции b2c
            // response.data?.data = {
            //     "nonce": "1619092806962285",
            //     "serviceID": 11,
            //     "serviceVersion": "11.1",
            //     "serviceDateTime": "2021-04-22 15:00:16.314473",
            //     "result": {"code": 0, "message": "OK"},
            //     "outData": {
            //         "response": {
            //             "code": 2,
            //             "message": "Depot effectue. Montant: 200.00 CDF. Beneficiaire: 0851000983. Nouveau solde: 3364073.43 CDF. Ref: CI210422.1300.C23243.",
            //             "code2": 200,
            //             "message2": ""
            //         },
            //         "transactionID": "20000001619092806878",
            //         "transactionID2": "004291914540"
            //     }
            // }
            // if (response.data?.data?.outData?.response?.code === 0) {
            //     yield put(onMakePaymentB2CSuccess(response.data));
            // } else {
            //     const err = response.data?.data?.outData?.response?.message || response.data;
            //     yield put(onMakePaymentB2CError(err));
            // }

 */

// aaa = {
//     'dataType': 'PaymentsInternal',
//     'data': {
//         'order_id': '20000001633594243136',
//         'transaction_id': '132201373730',
//         'transaction_ref': 'CI211007.0910.D11071.',
//         'status': 2,
//         'result': { 'code': 0, 'message': 'OK' },
//         'provider_result': {
//             'code': '200',
//             'message': 'TEST Depot effectue. Montant: 612000.00 CDF. Beneficiaire: 0892539355. Nouveau solde: 17294681.38 CDF. Ref: CI211007.0910.D11071.',
//         },
//         'service_id': 1,
//         'service_version': '1.03\\\/1.14|1.0\\\/1.26|1.0\\\/1.0|1.01\\\/1.11|1.01\\\/1.11||1.01\\\/1.27',
//         'service_date_time': '2021-10-07 11:10:44.282541',
//     },
// };

export function* sagaMakePaymentB2CFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(makePayment, {
            ...payload,
            method: 'MakePaymentB2C',
            setter: 'true',
        });

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onMakePaymentB2CError(error));
        } else if (response) {
            if (response.data?.data === 'DISABLED') {
                yield put(onMakePaymentB2CError('DISABLED'));
            }
            const outData = response.data?.data;

            if (response.data?.data?.status) {
                switch (outData?.status) {
                    case 0:
                    case 1:
                    case 2:
                    case 6:
                    case 7:
                        yield put(onMakePaymentB2CSuccess(outData));
                        break;
                    case 3:
                    case 4:
                    case 5:
                        const err = outData?.provider_result?.message || response.data;
                        yield put(onMakePaymentB2CError(err));
                        break;
                }
            } else {
                console.error(response);
                yield put(onMakePaymentB2CError('Unknown error(. Call support'));
            }

        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onMakePaymentB2CError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onMakePaymentB2CError(error));
    }
}

export function* operationB2CSuccessSaga(action) {
}

export function* operationErrorSaga(action) {
}

export function* operationSuccessSaga(action) {
}

export function* pendingOperationStatusSaga(action) {
    let counter = STATUS_REQUEST_COUNT;
    let answer = {};
    let pending = true;
    const { payload } = action;

    while (counter > 0) {
        yield delay(WAIT_TIMEOUT);
        counter--;
        answer = yield call(makePayment, {
            ...payload,
            method: 'GetPaymentStatus',
        });

        yield put(onGetPaymentC2BStatusFetchProcess({ counter: counter }));

        if (answer.error) {
            counter = 0;
            pending = false;
            yield put(onHandleErrors(answer.error));
            yield put(onGetPaymentC2BStatusError(answer.error));
        } else if (answer.response) {
            const { response } = answer;
            const code = response.data?.data?.outData?.response?.code | 0;

            if (code === CODE_SUCCESS) {
                counter = 0;
                pending = false;
                yield put(onGetPaymentC2BStatusSuccess(response.data));
            }

            if (code === CODE_FAIL || code === CODE_UNKNOWN) {
                counter = 0;
                pending = false;
                yield put(onGetPaymentC2BStatusError(response.data?.data));
            }
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetPaymentC2BStatusError(answer.error));
        }
    }

    if (pending) {
        yield put(
            onGetPaymentC2BStatusSuccess({
                response: { status: STATUS_PENDING, payload },
                error: null,
            }),
        );
    }
}

export function* sagaMakeDepositMerchantFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'MakeEmission',
        };

        const { response, error } = yield call(paymentsService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onDepositMerchantError(error));
        } else if (response) {

            if (response.data.errorCode) {
                yield put(onDepositMerchantError(response.data));
            } else {
                yield put(onDepositMerchantSuccess(response.data));
            }

        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onDepositMerchantError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onDepositMerchantError(error));
    }
}

export function* sagaMakeSettlementMerchantFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'MakeSettlement',
        };

        const { response, error } = yield call(paymentsService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSettlementMerchantError(error));
        } else if (response) {

            if (response.data.errorCode) {
                yield put(onSettlementMerchantError(response.data));
            } else {
                yield put(onSettlementMerchantSuccess(response.data));
            }

        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onSettlementMerchantError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSettlementMerchantError(error));
    }
}

export function* paymentOperationsSaga() {
    yield takeEvery(PaymentOperations.MAKE_PAYMENT_B2C_FETCH, sagaMakePaymentB2CFetch);
    yield takeEvery(PaymentOperations.MAKE_PAYMENT_B2C_SUCCESS, operationB2CSuccessSaga);
    yield takeEvery(PaymentOperations.MAKE_PAYMENT_B2C_ERROR, operationErrorSaga);

    yield takeEvery(PaymentOperations.GET_PAYMENT_STATUS_FETCH, pendingOperationStatusSaga);
    yield takeEvery(PaymentOperations.GET_PAYMENT_STATUS_SUCCESS, operationSuccessSaga);
    yield takeEvery(PaymentOperations.GET_PAYMENT_STATUS_ERROR, operationErrorSaga);

    yield takeEvery(PaymentOperations.MAKE_DEPOSIT_MERCHANT_FETCH, sagaMakeDepositMerchantFetch);
    yield takeEvery(PaymentOperations.MAKE_SETTLEMENT_MERCHANT_FETCH, sagaMakeSettlementMerchantFetch);
}
