import * as moment from 'moment';
import {rowNamesOptions} from '../Operations/OperationsHelper';
import {DAYTIME_FORMAT_HUMAN_WS} from '../../../utils/consts/global';


export const rowsActive = [
    {name: 'valid', title: 'Valid', className: 'noWrap', options: '', isSortable: true},
    {name: 'provider', title: 'Provider', className: 'noWrap', options: '', isSortable: true},
    {name: 'country', title: 'Country', className: 'noWrap', options: '', isSortable: true},
    {name: 'type', title: 'Type', className: 'noWrap', options: '', isSortable: true},
    {name: 'amount', title: 'Amount of sms', className: 'noWrap', options: '', isSortable: true},
    {name: 'used', title: 'Sms used', className: 'noWrap', options: '', isSortable: true},
    {name: 'usedPercent', title: '% used', className: 'noWrap', options: '', isSortable: true},
];

export const summaryForTable = (income) => {
    return income.map(el => {
        const from = el.valid_from ? moment(el.valid_from).format(DAYTIME_FORMAT_HUMAN_WS) : '';
        const to = el.valid_to ? moment(el.valid_to).format(DAYTIME_FORMAT_HUMAN_WS) : '';
        return {
            ...el,
            valid: `${from} - ${to}`,
        };
    });
};

export const formatIncomeDate = (income) => {
    return income.map(el => {
        return {
            ...el,
            datetime: `${moment(el.datetime).format(DAYTIME_FORMAT_HUMAN_WS)}`,
        };
    });
};

export const rowsUndelivered = [
    {title: 'Date time sent', name: 'datetime', className: 'noWrap', options: '', isSortable: true},
    {title: 'Phone number ', name: 'number', className: 'noWrap', options: '', isSortable: true},
    {title: 'Country', name: 'country', className: 'noWrap', options: '', isSortable: true},
    {title: 'Reason', name: 'status', className: 'noWrap', options: '', isSortable: true},
];

export const getRows = (data) => {
    const statData = [
        {name: 'Requested'},
        {name: 'Sent'},
        {name: 'Delivered'},
        {name: '% delivered'},
    ];
    let statRows = []
    if (!data) {
        return {statRows, statData};
    }

    statRows = data.days.map((el, idx) => {
        statData[0][el] = (data.requested || [])[idx] || 0;        // Requested msgs
        statData[1][el] = data.sent[idx];                          // Sent msgs
        statData[2][el] = data.delivered[idx];                     // Delivered msgs
        statData[3][el] = data.deliveredPercent[idx] || 0;         // Percent delivered from sent msgs

        return {
            name: el, title: el, className: 'noWrap', options: '', isSortable: false,
        };
    });

    statRows.push({title: '', name: 'name', className: 'noWrap', options: '', isSortable: false});
    statRows.reverse();
    return {statRows, statData};
};
