import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { BalancesPanel } from '../../components/shared/BalancesPanel/BalancesPanel';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';

const Balances = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="balances" />
            <BalancesPanel />
        </div>
    );
};

export default Balances;
