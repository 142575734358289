import { rowNamesOptions } from '../TableBody/TableBodyHelper';

export const refTypes = [
    { id: '0', title: 'none' },
    { id: '1', title: 'client' },
    { id: '2', title: 'sub client' },
];

export const getNotEmpty = (array) => {
    return array?.filter(el => !!el) || [];
};

export const rows = [
    { name: 'login', title: 'login', className: 'noWrap', options: '', isSortable: false },
    { name: 'name', title: 'name', className: 'noWrap', options: '', isSortable: false },
    { name: 'phone', title: 'phone', className: 'noWrap', options: '', isSortable: false },
    { name: 'email', title: 'e-mail', className: 'noWrap', options: '', isSortable: false },
    { name: 'note', title: 'note', className: 'noWrap', options: '', isSortable: false },
    { name: 'enabled', title: 'enabled', className: 'center', options: rowNamesOptions.enableBlock, isSortable: false },
];

export const emptyCashier = {
    login: '',
    name: '',
    password: '',
    email: '',
    note: '',
    phone: '',
};

export const objToArray = (obj) => {
    let arr = [];

    for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
            arr.push(obj[k]);
        }
    }
    return arr;
};

export const makeEnabled = (data) => {
    return data.map(el => {
        return {
            ...el,
            on: el.enabled,
        };
    });
};
