import { NEW_LEGAL } from '../../Merchant/MerchantDetails/MerchantDetails.helper';

export const menuItems = [
    { name: 'Summary', id: 0 },
    { name: 'Details', id: 1 },
    { name: 'Tariffs', id: 2 },
];

export const emptyLegal = {
    intID: null,
    strLegalName: '',
    intCountry: '',
    // strContactName: '',
    strContactPhone: '',
    strContactAddress: '',
    strContactEmail: '',
    licenceNumber: '',
    regNumber: '',
    dtContractDate: new Date(),
    state: NEW_LEGAL,
    legalStatus: NEW_LEGAL,
    contacts: null,
    documents: null,
    receipt: {
        strVatNumber: '',
        strAgentName: '',
        strReceiptText: '',
    },
};

export const switchNames = (data, id) => {
    return {
        ...data,
        id,
        name: data?.agentName,
        managerName: data?.name,
    };
};

