import React from 'react';
import cn from 'classnames';
import styles from './NumberBlock.less';

export const NumberBlock = (props) => {
    const currency = props.currency === ' ' ? 'USD' : props.currency;
    return (
        <div className={styles.pieWidget}>
            <div className={styles.left}>
                <i className={cn('fas', props.icon, styles.balanceIcon, styles[props.color])}> </i>
            </div>
            <div className={styles.right}>
                <div className={cn(styles.widgetName)}>{props.name}</div>
                <div className={styles.amountSum}>
                    <span>{currency}</span>&nbsp;{props.amount}
                </div>
            </div>
        </div>

    );
};
