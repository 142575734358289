import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { Sms } from './sms.consts';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getSmsReport = handleActions(
    {
        [Sms.SMS_REPORT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_REPORT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_REPORT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.SMS_REPORT_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getSmsReportHistory = handleActions(
    {
        [Sms.SMS_REPORT_HISTORY_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_REPORT_HISTORY_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_REPORT_HISTORY_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.SMS_REPORT_HISTORY_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getSmsScheduled = handleActions(
    {
        [Sms.SMS_SCHEDULED_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_SCHEDULED_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_SCHEDULED_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.SMS_SCHEDULED_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const cancelScheduledBulk = handleActions(
    {
        [Sms.SMS_CANCEL_SCHEDULED_BATCHES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_CANCEL_SCHEDULED_BATCHES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_CANCEL_SCHEDULED_BATCHES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getSmsReportSummary = handleActions(
    {
        [Sms.GET_SMS_REPORT_SUMMARY_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.GET_SMS_REPORT_SUMMARY_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.GET_SMS_REPORT_SUMMARY_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.GET_SMS_REPORT_SUMMARY_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getSmsReportUndelivered = handleActions(
    {
        [Sms.GET_SMS_REPORT_UNDELIVERED_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.GET_SMS_REPORT_UNDELIVERED_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.GET_SMS_REPORT_UNDELIVERED_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.GET_SMS_REPORT_UNDELIVERED_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const checkBulkFile = handleActions(
    {
        [Sms.SMS_BULK_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_BULK_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_BULK_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                success: { $set: null },
                errors: { $set: action.payload },
            }),
        [Sms.SMS_BULK_CLEAR]: (state, action) => {
            return update(state, {
                fetching: { $set: null },
                success: { $set: null },
                errors: { $set: null },
            });
        },
    },
    defaultState,
);

export const sendBulkFile = handleActions(
    {
        [Sms.SMS_FILE_SEND_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Sms.SMS_FILE_SEND_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Sms.SMS_FILE_SEND_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Sms.SMS_FILE_SEND_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const sms = combineReducers({
    getSmsReport,
    checkBulkFile,
    sendBulkFile,
    getSmsReportUndelivered,
    getSmsReportSummary,
    getSmsScheduled,
    cancelScheduledBulk,
    getSmsReportHistory,
});
