export const Merchants = {
    MERCHANTS_LIST_GET_FETCH: 'MERCHANTS_LIST_GET_FETCH',
    MERCHANTS_LIST_GET_SUCCESS: 'MERCHANTS_LIST_GET_SUCCESS',
    MERCHANTS_LIST_GET_ERROR: 'MERCHANTS_LIST_GET_ERROR',
    MERCHANTS_LIST_GET_CLEAR: 'MERCHANTS_LIST_GET_CLEAR',

    MERCHANT_ADD_FETCH: 'MERCHANT_ADD_FETCH',
    MERCHANT_ADD_SUCCESS: 'MERCHANT_ADD_SUCCESS',
    MERCHANT_ADD_ERROR: 'MERCHANT_ADD_ERROR',
    MERCHANT_ADD_CLEAR: 'MERCHANT_ADD_CLEAR',

    MERCHANT_ADD_LEGAL_FETCH: 'MERCHANT_ADD_LEGAL_FETCH',
    MERCHANT_ADD_LEGAL_SUCCESS: 'MERCHANT_ADD_LEGAL_SUCCESS',
    MERCHANT_ADD_LEGAL_ERROR: 'MERCHANT_ADD_LEGAL_ERROR',
    MERCHANT_ADD_LEGAL_CLEAR: 'MERCHANT_ADD_LEGAL_CLEAR',

    MERCHANT_EDIT_LEGAL_FETCH: 'MERCHANT_EDIT_LEGAL_FETCH',
    MERCHANT_EDIT_LEGAL_SUCCESS: 'MERCHANT_EDIT_LEGAL_SUCCESS',
    MERCHANT_EDIT_LEGAL_ERROR: 'MERCHANT_EDIT_LEGAL_ERROR',
    MERCHANT_EDIT_LEGAL_CLEAR: 'MERCHANT_EDIT_LEGAL_CLEAR',

    MERCHANT_GET_LEGAL_FETCH: 'MERCHANT_GET_LEGAL_FETCH',
    MERCHANT_GET_LEGAL_SUCCESS: 'MERCHANT_GET_LEGAL_SUCCESS',
    MERCHANT_GET_LEGAL_ERROR: 'MERCHANT_GET_LEGAL_ERROR',
    MERCHANT_GET_LEGAL_CLEAR: 'MERCHANT_GET_LEGAL_CLEAR',

    MERCHANT_ADD_CARD_FETCH: 'MERCHANT_ADD_CARD_FETCH',
    MERCHANT_ADD_CARD_SUCCESS: 'MERCHANT_ADD_CARD_SUCCESS',
    MERCHANT_ADD_CARD_ERROR: 'MERCHANT_ADD_CARD_ERROR',
    MERCHANT_ADD_CARD_CLEAR: 'MERCHANT_ADD_CARD_CLEAR',

    MERCHANT_GET_CARD_FETCH: 'MERCHANT_GET_CARD_FETCH',
    MERCHANT_GET_CARD_SUCCESS: 'MERCHANT_GET_CARD_SUCCESS',
    MERCHANT_GET_CARD_ERROR: 'MERCHANT_GET_CARD_ERROR',
    MERCHANT_GET_CARD_CLEAR: 'MERCHANT_GET_CARD_CLEAR',

    LEGAL_SET_METHODS_FETCH: 'LEGAL_SET_METHODS_FETCH',
    LEGAL_SET_METHODS_SUCCESS: 'LEGAL_SET_METHODS_SUCCESS',
    LEGAL_SET_METHODS_ERROR: 'LEGAL_SET_METHODS_ERROR',
    LEGAL_SET_METHODS_CLEAR: 'LEGAL_SET_METHODS_CLEAR',

    LEGAL_PUBLISH_FETCH: 'LEGAL_PUBLISH_FETCH',
    LEGAL_PUBLISH_SUCCESS: 'LEGAL_PUBLISH_SUCCESS',
    LEGAL_PUBLISH_ERROR: 'LEGAL_PUBLISH_ERROR',
    LEGAL_PUBLISH_CLEAR: 'LEGAL_PUBLISH_CLEAR',

    LEGAL_GET_METHODS_FETCH: 'LEGAL_GET_METHODS_FETCH',
    LEGAL_GET_METHODS_SUCCESS: 'LEGAL_GET_METHODS_SUCCESS',
    LEGAL_GET_METHODS_ERROR: 'LEGAL_GET_METHODS_ERROR',
};
