import * as moment from 'moment';
import { getCurrentDayEnd } from '../Operations/OperationsHelper';
import { rowNamesOptions } from '../TableBody/TableBodyHelper';
import { DATE_FORMAT_DDMMYYYY } from '../../../utils/consts/global';

export const days = {
    day: 7,
    week: 30,
    month: 183,   /// in 6 month
    year: 365,
};

export const periodOptions = [
    { id: 'day', title: 'Day' },
    { id: 'week', title: 'Week' },
    { id: 'month', title: 'Month' },
    { id: 'year', title: 'Year' },
];

export const type = ['totals', 'transactions', 'profit', 'dayStat'];
export const dtGroup = periodOptions[2].id;
export const period = [moment().subtract(days[dtGroup], 'days').toDate(), getCurrentDayEnd()];

export const initialFiltersState = {
    wlId: null,
    merchantId: null,                                                   // optional когда не передается, показывать текущего (мерчанта, aгента, wl партнера).
    branchId: null,                                                     // optional
    methodId: null,                                                     // optional
    currencyId: null,                                                   // optional. если у клиента только одна валюта.
    period,                                                             // required
    chartStep: 'month',                                                 // required. enum, default 'month'. Can be: ('year', 'month', 'week', 'day', ?'hour')
    dayStatTo: new Date(),                                              // optional. date, используется для статистики по дням. Default = today

    type: [                                                             // required. запрашиваемый тип данных. все типы указаны тут.
        'totals',                                                       // например, Если указан только один элемент totals - возвращаем только totals
        'transactions',                                                 // кол-во транзакций
        'profit',
        'dayStat',
    ],

};


export const normalizeParams = (params) => {
    const { wlId, merchantId, branchId, methodId, currencyId, period, chartStep, dayStatTo } = params;

    return {
        wlId: Number(wlId),
        merchantId: Number(merchantId),
        branchId: Number(branchId),
        methodId: Number(methodId),
        currencyId: Number(currencyId),
        period: `${moment(period[0]).format()};${moment(period[1]).format()}`,
        chartStep: 'month',
        dayStatTo: '',
    };

};


export const dashboardResp = {
    totals: {
        currency: 'CDF',
        balance: 1000,              // Общий баланс
        deposit: 150,               // Общая сумма депозитов
        withdrawal: 200,            // Общая сумма выводов
        margin: 10,                 // Маржа
        maxDeposit: 300,            // Максимальный депозит
        maxWithdrawal: 9999,        // Максимальный вывод
        conversion: 10,             // Конверсия
        commission: 150,            // Комиссия
    },

    transactions: {
        counts: {          // график по депозитам/выводам/
            data: [
                { name: 'Jan', deposit: 4000, withdrawal: 1000, profit: 1400 },
                { name: 'Feb', deposit: 2000, withdrawal: 2000, profit: 1500 },
                { name: 'Mar', deposit: 3000, withdrawal: 2400, profit: 1600 },
                { name: 'Apr', deposit: 3500, withdrawal: 3000, profit: 1400 },
                { name: 'May', deposit: 3700, withdrawal: 2500, profit: 800 },
                { name: 'Jun', deposit: 4200, withdrawal: 3900, profit: 100 },
                { name: 'Jul', deposit: 4000, withdrawal: 2000, profit: -300 },

                { name: 'Aug', deposit: 4100, withdrawal: 1000, profit: 1000 },
                { name: 'Sep', deposit: 4000, withdrawal: 1500, profit: 1500 },
                { name: 'Oct', deposit: 2750, withdrawal: 100, profit: 2000 },
                { name: 'Nov', deposit: 2850, withdrawal: 50, profit: 2500 },
                { name: 'Dec', deposit: 2150, withdrawal: 10, profit: 2763 },
            ],
        },
        deposit: 2000,               // Кол-во депозитов
        withdrawal: 1200,            // Кол-во выводов
        avgDeposit: 1500,             // Средний депозит
        avgWithdrawal: 1000,          // Средний вывод
    },

    profit: {                       // Профит для мерчанта = депозиты-выводы-комиссия
        data: [
            { name: 'Jan', deposit: 100, withdrawal: 100 },
            { name: 'Feb', deposit: 200, withdrawal: 200 },
            { name: 'Mar', deposit: 300, withdrawal: 240 },
            { name: 'Apr', deposit: 350, withdrawal: 300 },
            { name: 'May', deposit: 370, withdrawal: 250 },
            { name: 'Jun', deposit: 420, withdrawal: 390 },
            { name: 'Jul', deposit: 400, withdrawal: 200 },
        ],
    },

    dayStat: [                                  // Статистика по дням за месяц от даты dayStatTo. Если dayStatTo не указана, то от текущего дня.
        {
            date: '2021-10-03T00:00:00+03:00',
            deposits: '100 CDF (+10%)',         // Сумма депозитов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            withdrawals: '100 CDF (-2%)',       // Сумма выводов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            fee: '100',                         // Комиссия
            rawProfit: '2555 CDF (+15%)',       // Профит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            margin: '3%',                       // Маржа
            avgDeposit: '1222 CDF (+2%)',        // Средний депозит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            avgWithdraw: '1001 CDF (-5%)',       // Средний вывод (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
        },
        {
            date: '2021-10-03T00:00:00+03:00',
            deposits: '100 CDF (+10%)',         // Сумма депозитов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            withdrawals: '100 CDF (-2%)',       // Сумма выводов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            fee: '200',                         // Комиссия
            rawProfit: '2555 CDF (+15%)',       // Профит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            margin: '3%',                       // Маржа
            avgDeposit: '1220 CDF (+2%)',        // Средний депозит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            avgWithdraw: '1002 CDF (-5%)',       // Средний вывод (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
        },
        {
            date: '2021-10-03T00:00:00+03:00',
            deposits: '100 CDF (+10%)',         // Сумма депозитов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            withdrawals: '100 CDF (-2%)',       // Сумма выводов (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            fee: '300',                         // Комиссия
            rawProfit: '2555 CDF (+15%)',       // Профит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            margin: '3%',                       // Маржа
            avgDeposit: '122 CDF (+2%)',        // Средний депозит (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
            avgWithdraw: '100 CDF (-5%)',       // Средний вывод (сравнение с предыдущим днем, сравнение с таким же днем недели прошлой недели)
        },
    ],
};

export const rows = [
    { name: 'date', title: 'Date', className: 'noWrap', options: '', isSortable: false },
    { name: 'deposits', title: 'Deposits', className: 'noWrap', options: '', isSortable: false },
    { name: 'withdrawals', title: 'Withdrawals', className: 'noWrap', options: '', isSortable: false },
    { name: 'fee', title: 'Fee', className: 'noWrap', options: '', isSortable: false },
    { name: 'rawProfit', title: 'Raw profit', className: 'noWrap', options: '', isSortable: false },
    { name: 'margin', title: 'Margin', className: 'noWrap', options: '', isSortable: false },
    { name: 'avgDeposit', title: 'Avg deposit', className: '', options: '', isSortable: false },
    { name: 'avgWithdraw', title: 'Avg withdraw', className: '', options: '', isSortable: false },
];

export const formatForTable = (data) => {
    if (!data) return [];

    return data?.map(el => {
        return {
            ...el,
            date: moment(el.date).format(DATE_FORMAT_DDMMYYYY),
        };
    }) || [];
};

