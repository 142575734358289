import React, { useState } from 'react';
import cn from 'classnames';
import styles from './AddAgent.less';
import { AgentsEdit } from '../AgentsEdit/AgentsEdit';
import { emptyAgent } from '../AgentsEdit/AgentsEditHelper';
import { SMALL_SCREEN } from '../../../../utils/consts/global';

export const AddAgent = (props) => {
    const agent = { ...emptyAgent };
    const chaneAgent = (agent) => {
    };
    const deleteAgent = (agent) => {
    };
    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className="container">
                <div className="page-inner">
                    {SMALL_SCREEN && <h1>Agents list</h1>}
                    <div className="col-md-12">
                        <div className={cn(styles.card, 'card')}>
                            <div className={cn('card-body')}>
                                <AgentsEdit
                                    createAgent={true}
                                    agent={agent}
                                    onChange={chaneAgent}
                                    onDelete={deleteAgent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};
