import React, { useEffect, useRef, useState } from "react";
import { FiltersMenu } from "../FiltersMenu/FiltersMenu";
import { DropDown } from "../DropDown/DropDown";
import {
    getCurrentDayEnd,
    getWeekBack,
    getWeekBackFromNow,
    operationsStatus,
    rowCount
} from "../Operations/OperationsHelper";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import cn from "classnames";
import styles from "./TransactionFilter.less";
import { useOutsideAlerter } from "../../../utils/customHooks";
import * as moment from "moment";
import { removeEmpties } from "./TransactionsFilter.helper";
import { getXlsFile } from "../../../store/transactions/transactions.services";
import {
    DAYTIME_TIMEZONE_FORMAT,
    PERM_DOWNLOAD_EXCEL,
    PERM_GET_RECORDS_ADM,
    timezones,
} from '../../../utils/consts/global';
import { useSelector } from "react-redux";
import { TimeZoneSelector } from '../TimeZoneSelector/TimeZoneSelector';
import { contentContainer } from 'react-bootstrap-sweetalert/dist/styles/SweetAlertStyles';

export const TransactionsFilter = (props) => {
    const timeZone = props.strTimeZone || timezones[localStorage.getItem("timezone") | 0 || 13].title;
    const initialFiltersState = {
        intItemsPerPage: "10",
        dtCreatedRange: getWeekBackFromNow(),
        strTimeZone: timeZone,
    };
    const filtersDropDownRef = useRef(null);
    const [isFiltersShown, switchFilters] = useState(false);
    const [childParams, updateChildParams] = useState({});
    const [selfParams, updateSelfParams] = useState(initialFiltersState);
    const [searchValue, updateSearchValue] = useState({ strSearch: "" });
    const [dates, onDateRangeChange] = useState([getWeekBack(), getCurrentDayEnd()]);
    const permissions = useSelector((state) => state.auth.permissions.success) || [];
    const { clientId, subClientId } = useSelector((state) => state.app);

    const callUpdate = () => {
        if (props.onChange) {
            props.onChange({
                ...searchValue,
                ...selfParams,
                ...childParams,
                intCurrentPage: 1
            });
        }
    };

    useEffect(() => {
        callUpdate();
    }, [selfParams, childParams]);

    const toggleUserFilters = () => {
        switchFilters(!isFiltersShown);
    };

    const closeUserFilters = () => {
        switchFilters(false);
    };

    const onDateRangeChanges = (dates) => {
        const dtCreatedRange = `${moment(dates[0]).format('YYYY-MM-DDTHH:mm:ss')+timeZone};${moment(dates[1]).format('YYYY-MM-DDTHH:mm:ss')+timeZone}`;
        if (selfParams.dtCreatedRange === dtCreatedRange) return;

        onDateRangeChange(dates);
        updateSelfParams({
            ...selfParams,
            dtCreatedRange
        });
    };

    const ddTimeZoneSelect = (value) => {
        const title = value.strTimeZone;
        const dtCreatedRange = `${moment(dates[0]).format(DAYTIME_TIMEZONE_FORMAT)+title};${moment(dates[1]).format(DAYTIME_TIMEZONE_FORMAT)+title}`;

        updateSelfParams({
            ...selfParams,
            ...value,
            dtCreatedRange,
        });
    };

/*
    const ddTimeZoneSelect = (value, name) => {
        const { title } = value;
        localStorage.setItem("timezone", (timezones.find(el => el.title === title)).id.toString());
        // const dtCreatedRange = `${moment(dates[0]).utcOffset(title).format()};${moment(dates[1]).utcOffset(title).format()}`; // 2020-01-01T00:00:00-08:00;2021-02-23T23:59:59-08:00
        const dtCreatedRange = `${moment(dates[0]).format('YYYY-MM-DDTHH:mm:ss')+title};${moment(dates[1]).format('YYYY-MM-DDTHH:mm:ss')+title}`;

        updateSelfParams({
            ...selfParams,
            [name]: title,
            dtCreatedRange,
        });
    };*/

    const handlerCounts = (val) => {
        updateSelfParams({
            ...selfParams,
            intItemsPerPage: val.id
        });
    };

    const onOperationsStatusChange = (val) => {
        updateSelfParams({
            ...selfParams,
            intStatus: val.id
        });
    };

    const clearFilter = () => {
        updateChildParams({});
    };

    const filtersSubmit = (values) => {
        updateChildParams({
            ...removeEmpties(values)
        });
        closeUserFilters();
    };

    const getExcelFile = () => {
        const params = {
            method: "DownloadExcel",
            ...searchValue,
            ...selfParams,
            ...childParams
        };

        // if (permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
        if (clientId) {
            params.method = "DownloadExcelAdm";
            params.intClientID = clientId;
            params.intSubClientID = subClientId;
        }
        // }

        getXlsFile(params);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            startSearch();
        }
    };

    const changeSearchHandler = (event) => {
        event.persist();
        updateSearchValue({ strSearch: event.target.value });
    };

    const startSearch = () => {
        if (props.onChange) {
            props.onChange({
                ...searchValue,
                ...selfParams,
                ...childParams,
                intCurrentPage: 1
            });
        }
    };


    useOutsideAlerter(filtersDropDownRef, closeUserFilters);

    return (
        <div className={cn(styles.filtersPane)}>
            <div className={styles.filterBarItem}>
                <div id="search-nav">
                    <div className="navbar-left navbar-form nav-search">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button type="submit" className="btn btn-search pr-1" onClick={startSearch}>
                                    <i className="fa fa-search search-icon"> </i>
                                </button>
                            </div>
                            <input
                                type="text"
                                placeholder="Search for ids..."
                                className="form-control"
                                name="strSearch"
                                value={searchValue.strSearch}
                                onChange={changeSearchHandler}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.filterBarItem}>
                <div className={styles.fixedDropWidth}>
                    <DropDown
                        color="grey"
                        options={rowCount}
                        onSelect={handlerCounts}
                        value={rowCount[0]}
                    >
                        Show 10 rows
                    </DropDown>
                </div>
            </div>

            <div className={styles.filterBarItem}>
                <DateTimeRangePicker
                    value={dates}
                    onChange={onDateRangeChanges}
                    disableClock={true}
                    format="dd/MM/yy HH:mm:ss"
                    calendarClassName={styles.calendarBg}
                    className={styles.datePickerRange}
                    showLeadingZeros={true}
                    calendarIcon={null}
                    clearIcon={null}
                    locale="en-US"
                />
            </div>

            <div className={styles.filterBarItem}>
{/*                <div className={styles.fixedDropWidth}>
                    <DropDown
                        id="strTimeZone"
                        color="grey"
                        options={timezones}
                        onSelect={ddTimeZoneSelect}
                        name="strTimeZone">
                        {selfParams.strTimeZone}&nbsp;
                    </DropDown>
                </div>
*/}

                <TimeZoneSelector
                    name="strTimeZone"
                    onSelect={ddTimeZoneSelect}
                    value={selfParams.strTimeZone}
                />

            </div>

            <div className={styles.filterBarItem}>
                <div className={cn(styles.fixedDropWidth)}>
                    <DropDown
                        color="grey"
                        options={operationsStatus}
                        onSelect={onOperationsStatusChange}
                        value={operationsStatus[0]}
                    >
                        Operation Status
                    </DropDown>
                </div>
            </div>

            <div
                className={cn(styles.filterBarItem, styles.roundButtonWrapper, "nav-item dropdown hidden-caret submenu show")}>
                <button className="btn btn-primary" onClick={toggleUserFilters}>
                    <i className="fas fa-filter"> </i>
                    <span> Filters</span>
                </button>
                {isFiltersShown && (
                    <div ref={filtersDropDownRef}>
                        <FiltersMenu onSubmit={filtersSubmit} onClear={clearFilter} initialValues={childParams} />
                    </div>
                )}
            </div>

            {permissions.indexOf(PERM_DOWNLOAD_EXCEL) !== -1 && (
                <div className={cn(styles.filterBarItem, styles.hideInMobile, "nav-item")}>
                    <div className={styles.roundIcon} onClick={getExcelFile}>
                        <i className={cn(styles.excelIcon, "fa fa-file-excel")}> </i>
                    </div>
                </div>
            )}
        </div>
    );
};
