export const RoleSystem = {
    GET_GROUP_ROLES_FETCH: 'GET_GROUP_ROLES_FETCH',
    GET_GROUP_ROLES_SUCCESS: 'GET_GROUP_ROLES_SUCCESS',
    GET_GROUP_ROLES_ERROR: 'GET_GROUP_ROLES_ERROR',
    GET_GROUP_ROLES_CLEAR: 'GET_GROUP_ROLES_CLEAR',

    ADD_PERM_GROUP_ROLE_FETCH: 'ADD_PERM_GROUP_ROLE_FETCH',
    ADD_PERM_GROUP_ROLE_SUCCESS: 'ADD_PERM_GROUP_ROLE_SUCCESS',
    ADD_PERM_GROUP_ROLE_ERROR: 'ADD_PERM_GROUP_ROLE_ERROR',

    REMOVE_PERM_GROUP_ROLE_FETCH: 'REMOVE_PERM_GROUP_ROLE_FETCH',
    REMOVE_PERM_GROUP_ROLE_SUCCESS: 'REMOVE_PERM_GROUP_ROLE_SUCCESS',
    REMOVE_PERM_GROUP_ROLE_ERROR: 'REMOVE_PERM_GROUP_ROLE_ERROR',

    CREATE_PERM_GROUP_FETCH: 'CREATE_PERM_GROUP_FETCH',
    CREATE_PERM_GROUP_SUCCESS: 'CREATE_PERM_GROUP_SUCCESS',
    CREATE_PERM_GROUP_ERROR: 'CREATE_PERM_GROUP_ERROR',

    EDIT_PERM_GROUP_FETCH: 'EDIT_PERM_GROUP_FETCH',
    EDIT_PERM_GROUP_SUCCESS: 'EDIT_PERM_GROUP_SUCCESS',
    EDIT_PERM_GROUP_ERROR: 'EDIT_PERM_GROUP_ERROR',

    DELETE_PERM_GROUP_FETCH: 'DELETE_PERM_GROUP_FETCH',
    DELETE_PERM_GROUP_SUCCESS: 'DELETE_PERM_GROUP_SUCCESS',
    DELETE_PERM_GROUP_ERROR: 'DELETE_PERM_GROUP_ERROR',

    GET_PERMISSION_GROUPS_FETCH: 'GET_PERMISSION_GROUPS_FETCH',
    GET_PERMISSION_GROUPS_SUCCESS: 'GET_PERMISSION_GROUPS_SUCCESS',
    GET_PERMISSION_GROUPS_ERROR: 'GET_PERMISSION_GROUPS_ERROR',
    GET_PERMISSION_GROUPS_CLEAR: 'GET_PERMISSION_GROUPS_CLEAR',

    GET_PERMISSION_ROLES_FETCH: 'GET_PERMISSION_ROLES_FETCH',
    GET_PERMISSION_ROLES_SUCCESS: 'GET_PERMISSION_ROLES_SUCCESS',
    GET_PERMISSION_ROLES_ERROR: 'GET_PERMISSION_ROLES_ERROR',
    GET_PERMISSION_ROLES_CLEAR: 'GET_PERMISSION_ROLES_CLEAR',

    CREATE_PERMISSION_ROLE_FETCH: 'CREATE_PERMISSION_ROLE_FETCH',
    CREATE_PERMISSION_ROLE_SUCCESS: 'CREATE_PERMISSION_ROLE_SUCCESS',
    CREATE_PERMISSION_ROLE_ERROR: 'CREATE_PERMISSION_ROLE_ERROR',
    CREATE_PERMISSION_ROLE_CLEAR: 'CREATE_PERMISSION_ROLE_CLEAR',

    EDIT_PERMISSION_ROLE_FETCH: 'EDIT_PERMISSION_ROLE_FETCH',
    EDIT_PERMISSION_ROLE_SUCCESS: 'EDIT_PERMISSION_ROLE_SUCCESS',
    EDIT_PERMISSION_ROLE_ERROR: 'EDIT_PERMISSION_ROLE_ERROR',
    EDIT_PERMISSION_ROLE_CLEAR: 'EDIT_PERMISSION_ROLE_CLEAR',

    DELETE_PERMISSION_ROLE_FETCH: 'DELETE_PERMISSION_ROLE_FETCH',
    DELETE_PERMISSION_ROLE_SUCCESS: 'DELETE_PERMISSION_ROLE_SUCCESS',
    DELETE_PERMISSION_ROLE_ERROR: 'DELETE_PERMISSION_ROLE_ERROR',

    GET_ALL_USERS_FETCH: 'GET_ALL_USERS_FETCH',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    GET_ALL_USERS_CLEAR: 'GET_ALL_USERS_CLEAR',

    GET_USER_GROUPS_FETCH: 'GET_USER_GROUPS_FETCH',
    GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',
    GET_USER_GROUPS_ERROR: 'GET_USER_GROUPS_ERROR',
    GET_USER_GROUPS_CLEAR: 'GET_USER_GROUPS_CLEAR',

    ADD_USER_GROUP_FETCH: 'ADD_USER_GROUP_FETCH',
    ADD_USER_GROUP_SUCCESS: 'ADD_USER_GROUP_SUCCESS',
    ADD_USER_GROUP_ERROR: 'ADD_USER_GROUP_ERROR',

    SET_USER_GROUPS_FETCH: 'SET_USER_GROUPS_FETCH',
    SET_USER_GROUPS_SUCCESS: 'SET_USER_GROUPS_SUCCESS',
    SET_USER_GROUPS_ERROR: 'SET_USER_GROUPS_ERROR',

    GET_DIRECT_USER_ROLES_FETCH: 'GET_DIRECT_USER_ROLES_FETCH',
    GET_DIRECT_USER_ROLES_SUCCESS: 'GET_DIRECT_USER_ROLES_SUCCESS',
    GET_DIRECT_USER_ROLES_ERROR: 'GET_DIRECT_USER_ROLES_ERROR',
    GET_DIRECT_USER_ROLES_CLEAR: 'GET_DIRECT_USER_ROLES_CLEAR',

    ADD_DIRECT_USER_ROLE_FETCH: 'ADD_DIRECT_USER_ROLE_FETCH',
    ADD_DIRECT_USER_ROLE_SUCCESS: 'ADD_DIRECT_USER_ROLE_SUCCESS',
    ADD_DIRECT_USER_ROLE_ERROR: 'ADD_DIRECT_USER_ROLE_ERROR',

    SET_DIRECT_USER_ROLE_FETCH: 'SET_DIRECT_USER_ROLE_FETCH',
    SET_DIRECT_USER_ROLE_SUCCESS: 'SET_DIRECT_USER_ROLE_SUCCESS',
    SET_DIRECT_USER_ROLE_ERROR: 'SET_DIRECT_USER_ROLE_ERROR',

    EDIT_USER_FETCH: 'EDIT_USER_FETCH',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_ERROR: 'EDIT_USER_ERROR',

    ADD_USER_FETCH: 'ADD_USER_FETCH',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_ERROR: 'ADD_USER_ERROR',

    ENABLE_USER_FETCH: 'ENABLE_USER_FETCH',
    ENABLE_USER_SUCCESS: 'ENABLE_USER_SUCCESS',
    ENABLE_USER_ERROR: 'ENABLE_USER_ERROR',

    GET_FUNCTION_ROLE_FETCH: 'GET_FUNCTION_ROLE_FETCH',
    GET_FUNCTION_ROLE_SUCCESS: 'GET_FUNCTION_ROLE_SUCCESS',
    GET_FUNCTION_ROLE_ERROR: 'GET_FUNCTION_ROLE_ERROR',
    GET_FUNCTION_ROLE_CLEAR: 'GET_FUNCTION_ROLE_CLEAR',

    GET_ALLOWED_RRT_FETCH: 'GET_ALLOWED_RRT_FETCH',
    GET_ALLOWED_RRT_SUCCESS: 'GET_ALLOWED_RRT_SUCCESS',
    GET_ALLOWED_RRT_ERROR: 'GET_ALLOWED_RRT_ERROR',
    GET_ALLOWED_RRT_CLEAR: 'GET_ALLOWED_RRT_CLEAR',

    SET_FUNCTION_ROLE_FETCH: 'SET_FUNCTION_ROLE_FETCH',
    SET_FUNCTION_ROLE_SUCCESS: 'SET_FUNCTION_ROLE_SUCCESS',
    SET_FUNCTION_ROLE_ERROR: 'SET_FUNCTION_ROLE_ERROR',
    SET_FUNCTION_ROLE_CLEAR: 'SET_FUNCTION_ROLE_CLEAR',
};
