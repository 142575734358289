import React from 'react';
import * as moment from 'moment';
import { emptyAgent } from '../AgentsEdit/AgentsEditHelper';
import { BALANCE_ACCOUNT_START_WITH } from '../../../../utils/consts/global';
import { sortingClass, sortTable } from '../../../../utils/helpers/commonFunctions';
import { rowNamesOptions } from '../../TableBody/TableBodyHelper';

export const HOME_ID = 'Home';

export const initialBreadCrumbs = (title) => {
    return [{ id: HOME_ID, title: title || sessionStorage.getItem('login') || HOME_ID }];
};

export const defaultSortParams = {
    sortType: sortingClass.asc,
    sortingByColumnName: { name: '' },
};

export const rows = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: true },
    { name: 'title', title: 'name', className: 'noWrap', options: '', isSortable: true },
];

export const lastRows = [
    { name: 'enabled', title: 'status', className: 'noWrap', options: '', isSortable: true },
    { name: 'creationDT', title: 'creation date', className: 'noWrap', options: '', isSortable: true },
    {
        name: 'operations',
        title: 'actions',
        className: 'rightAlign',
        options: rowNamesOptions.editOnlyBlock,
        isSortable: false,
    },
];

//[{
// account: "10115010803081081000001017000000000002001"
// balance: 0
// country: 108
// created: "2021-06-30 20:08:16"
// createdBy: "0"
// currency: 108
// description: "Агентский счет"
// group1: 101
// group2: 15
// holdIn: 0
// holdOut: 0
// hoster: 8
// hosterType: 8
// id: 1200
// inOut: 0
// method: 0
// methodType: 0
// number: 1
// owner: 1017
// ownerType: 10
// paramsJSON: ""
// publicID: "227873d637b1554cefa8e019177ee43e72fed15e9fd10873efc10f719475ad91035ad3512f66a27c1b7062d496a04655ab46"
// reserved: 0
// state: 1
// updated: null
// whiteLabel: 3
//}, ]

export const findCurrencyByNum = ({ num, currencies }) => {
    return currencies.find((cur) => Number(cur.codeNum) === num)?.codeAlpha || num;
};

export const getBalances = ({ accounts, currencies }) => {
    if (!accounts && !accounts?.length) return '';
    const neededAccounts = accounts.filter(el => el.account?.startsWith(BALANCE_ACCOUNT_START_WITH));
    const balancesArray = neededAccounts.map(el => {
        return el.balance + ' ' + findCurrencyByNum({ num: el.currency, currencies });
    }).join(',');

    return ` (${balancesArray})`;
};


export const getBalancesRowNames = (level, currencies) => {
    const allCurrencies = level.map(lvl => {
        if (!lvl.accounts && !lvl.accounts?.length) return;
        return lvl.accounts.filter(el => el.group1 === 101).map(el => el.currency);
    });

    const reducedCurrencies = [...new Set(allCurrencies.flat())].filter(el => el);  //[111];
    const filledRows = [...rows];

    reducedCurrencies.forEach(el => {
        filledRows.push({
            name: el,
            title: findCurrencyByNum({ num: el, currencies }),
            className: 'noWrap',
            options: '',
            isSortable: true,
        });
    });
    return [...filledRows, ...lastRows];
};


export const mapLevel = ({ level, showDisabled, currencies }) => {
    return level.map((el) => {
        return {
            key: el.id,
            label: el.name + getBalances({ accounts: el.accounts, currencies }),
            enabled: Boolean(Number(el.enabled)),
            nodes: el.sub.length ? mapLevel({ level: el.sub, showDisabled, currencies }) : [],
        };
    }).filter(agent => showDisabled ? true : agent.enabled);
};


export const findLevel = (level, searchId) => {
    let found = null;
    const id = Number(searchId);
    for (let el of level) {
        if (el.id === id) {
            found = el;
            break;
        }
        if (el.id !== id && el.sub && el.sub.length) {
            found = findLevel(el.sub, id);
            if (found) break;
        }
    }
    return found;
};


export const searchLevelByIdName = (level, searchId, name) => {
    let found = [];

    const findNames = name;
    const searchVal = name.toLocaleLowerCase();
    const id = Number(searchId);

    for (let el of level) {
        if (el.id === id || (findNames && el.name.toLocaleLowerCase()?.includes(searchVal))) {
            found.push(el);
        }
        if (el.sub && el.sub.length) {
            const internal = searchLevelByIdName(el.sub, id, name);
            internal.length && found.push(internal);
        }
    }
    return found.length ? found.flat() : [];
};


export const findCrumbsPath = (level, id, path) => {
    let localPath = null;

    srch: for (let el of level) {
        if (el.id === id) {
            localPath = path;
            break srch;
        } else {
            if (!localPath && el.sub && el.sub.length) {
                localPath = findCrumbsPath(el.sub, id, [...path, { id: el.id, title: el.name }]);
            }
        }
    }
    return localPath;
};


export const findCount = (level, counter) => {
    counter += level.length;

    for (let el of level) {
        if (el.sub && el.sub.length) {
            counter = findCount(el.sub, counter);
        }
    }
    return counter;
};


export const getCountersObject = (agentsList) => {
    const counters = {};
    agentsList.forEach(el => {
        counters[el.id] = el.sub?.length ? findCount(el.sub, 0) : 0;
    });

    return counters;
};


export const getHumanReadable = (data, counters, showDisabled, sortParams) => {

    const tar = data?.map((el) => {
        const accounts = el.accounts?.filter(el => el.group1 === 101) || [];
        const curr = {};
        accounts.forEach(acc => {
            curr[acc.currency] = acc.balance?.toFixed(2);
        });

        return {
            ...el,
            ...curr,
            enabled: el.enabled ? 'enabled' : 'disabled',
            creationDT: moment(el.creationDT).format('DD/MM/YY HH:MM'),
            title: el.name + (!!counters[el.id] ? '  (sub-agents: ' + counters[el.id] + ')' : ''),
            name: el.name,
        };
    }).filter(agent => showDisabled ? true : agent.enabled === 'enabled');

    return sortTable({
        dataTable: [...tar],
        sortParams,
    });
};

export const normaliseLevel = (data) => {
    return data.map((el) => {
        const depositRates = el.paramsJSON?.financialController?.rates?.deposit || emptyAgent;
        const ag = {
            ...el.agentInfo,
            ...depositRates,
            enabled: el.enabled,
            name: el.name,
            description: el.description,
            agentLogin: el.login || el.name,
            agentUserID: el.agentUserID,
            creationDT: moment(el.creationDT).format('DD/MM/YY HH:MM'),
            accounts: el.accounts ? [...el.accounts] : [],
        };
        return ag;
    });
};
