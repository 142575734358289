import { put } from 'redux-saga/effects';
import { showNotify } from '../app/app.actions';

export function* errorNotify(error) {
    yield put(
        showNotify({
            type: 'error',
            text: `Error. ${error}`,
        }),
    );
}

export const getBusinessError = (response) => {
    if ((typeof response?.data) === 'object') {
        if (response?.data.dataType === 'error') {
            return { response: null, error: response?.data?.data };
        } else {
            return { response, error: null };
        }
    } else {
        console.error(response);
        return { response: null, error: response.data || response };
    }
};


export const getBusinessSMSError = (response) => {
    if (response?.data.dataType === 'error') {
        return { response: null, error: response?.data?.data };
    }
    if (response?.data.data.error) {
        return { response: null, error: response?.data.data.error.code };
    } else {
        return { response, error: null };
    }
};
