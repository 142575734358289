import React from 'react';
import cn from 'classnames';
import styles from '../Notification.less';
import { NOTIFICATION_HIDE_DELAY } from '../../../../utils/consts/global';

export const Message = (props) => {
    window.setTimeout(props.hideNotify, NOTIFICATION_HIDE_DELAY);
    return (
        <div className={cn(styles.notify, styles[props.type])}>
            {props.text}
            <i
                className={styles.closeLink}
                onClick={() => {
                    props.hideNotify();
                }}
            >
                ×
            </i>
        </div>
    );
};
