import { Dictionaries } from './dictionaries.consts';

/*
 GET ALL DICT
*/
export const onGetAllDictionaries = () => ({
    type: Dictionaries.GET_ALL_DICTIONARIES,
});

/*
 ALLOWED OPERATORS
*/
export const onGetAllowedOperatorsFetch = () => ({
    type: Dictionaries.GET_ALLOWED_OPERATORS_FETCH,
});

export const onGetAllowedOperatorsSuccess = (payload) => ({
    type: Dictionaries.GET_ALLOWED_OPERATORS_SUCCESS,
    payload,
});

export const onGetAllowedOperatorsError = (payload) => ({
    type: Dictionaries.GET_ALLOWED_OPERATORS_ERROR,
    payload,
});

export const onGetAllowedOperatorsClear = (payload) => ({
    type: Dictionaries.GET_ALLOWED_OPERATORS_CLEAR,
    payload,
});
/*
 GET COUNTRIES
*/
export const onGetCountriesFetch = () => ({
    type: Dictionaries.GET_COUNTRIES_FETCH,
});

export const onGetCountriesSuccess = (payload) => ({
    type: Dictionaries.GET_COUNTRIES_SUCCESS,
    payload,
});

export const onGetCountriesError = (payload) => ({
    type: Dictionaries.GET_COUNTRIES_ERROR,
    payload,
});

/*
 GET CURRENCIES
*/
export const onCurrenciesFetch = () => ({
    type: Dictionaries.GET_CURRENCIES_FETCH,
});

export const onCurrenciesSuccess = (payload) => ({
    type: Dictionaries.GET_CURRENCIES_SUCCESS,
    payload,
});

export const onCurrenciesError = (payload) => ({
    type: Dictionaries.GET_CURRENCIES_ERROR,
    payload,
});

/*
 GET PAYMENT GATE
*/
export const onGetGateFetch = () => ({
    type: Dictionaries.GET_GATE_FETCH,
});

export const onGetGateSuccess = (payload) => ({
    type: Dictionaries.GET_GATE_SUCCESS,
    payload,
});

export const onGetGateError = (payload) => ({
    type: Dictionaries.GET_GATE_ERROR,
    payload,
});

/*
 GET PAYMENT GATE ADM
*/
export const onGetGateAdmFetch = () => ({
    type: Dictionaries.GET_GATE_ADM_FETCH,
});

export const onGetGateAdmSuccess = (payload) => ({
    type: Dictionaries.GET_GATE_ADM_SUCCESS,
    payload,
});

export const onGetGateAdmError = (payload) => ({
    type: Dictionaries.GET_GATE_ADM_ERROR,
    payload,
});

/*
 GET TERMINAL ADM
*/
export const onGetTerminalsAdmFetch = () => ({
    type: Dictionaries.GET_TERMINALS_ADM_FETCH,
});

export const onGetTerminalsAdmSuccess = (payload) => ({
    type: Dictionaries.GET_TERMINALS_ADM_SUCCESS,
    payload,
});

export const onGetTerminalsAdmError = (payload) => ({
    type: Dictionaries.GET_TERMINALS_ADM_ERROR,
    payload,
});

/*
 GET CLIENTS FETCH
*/
export const onGetClientsFetch = () => ({
    type: Dictionaries.GET_CLIENTS_FETCH,
});

export const onGetClientsSuccess = (payload) => ({
    type: Dictionaries.GET_CLIENTS_SUCCESS,
    payload,
});

export const onGetClientsError = (payload) => ({
    type: Dictionaries.GET_CLIENTS_ERROR,
    payload,
});

/*
 GET SUB_CLIENTS FETCH
*/
export const onGeSubClientsFetch = () => ({
    type: Dictionaries.GET_SUBCLIENTS_FETCH,
});

export const onGetSubClientsSuccess = (payload) => ({
    type: Dictionaries.GET_SUBCLIENTS_SUCCESS,
    payload,
});

export const onGetSubClientsError = (payload) => ({
    type: Dictionaries.GET_SUBCLIENTS_ERROR,
    payload,
});

/*
  GET LOGOS
*/
export const onGetAllLogosFetch = (payload) => ({
    type: Dictionaries.GET_LOGOS_FETCH,
    payload,
});

export const onGetAllLogosSuccess = (payload) => ({
    type: Dictionaries.GET_LOGOS_SUCCESS,
    payload,
});

export const onGetAllLogosError = (payload) => ({
    type: Dictionaries.GET_LOGOS_ERROR,
    payload,
});

/*
  UPDATE LOGOS
*/
export const onSetLogoFetch = (payload) => ({
    type: Dictionaries.SET_LOGO_FETCH,
    payload,
});

export const onSetLogoSuccess = (payload) => ({
    type: Dictionaries.SET_LOGO_SUCCESS,
    payload,
});

export const onSetLogoError = (payload) => ({
    type: Dictionaries.SET_LOGO_ERROR,
    payload,
});

/*
  Create LOGO
*/
export const onCreateLogoFetch = (payload) => ({
    type: Dictionaries.CREATE_LOGO_FETCH,
    payload,
});

export const onCreateLogoSuccess = (payload) => ({
    type: Dictionaries.CREATE_LOGO_SUCCESS,
    payload,
});

export const onCreateLogoError = (payload) => ({
    type: Dictionaries.CREATE_LOGO_ERROR,
    payload,
});

/*
  GET MERCHANTS
*/
export const onGetMerchantsFetch = (payload) => ({
    type: Dictionaries.GET_MERCHANTS_FETCH,
    payload,
});

export const onGetMerchantsSuccess = (payload) => ({
    type: Dictionaries.GET_MERCHANTS_SUCCESS,
    payload,
});

export const onGetMerchantsError = (payload) => ({
    type: Dictionaries.GET_MERCHANTS_ERROR,
    payload,
});

/*
  GET BANKS
*/
export const onGetBanksFetch = (payload) => ({
    type: Dictionaries.GET_BANKS_FETCH,
    payload,
});

export const onGetBanksSuccess = (payload) => ({
    type: Dictionaries.GET_BANKS_SUCCESS,
    payload,
});

export const onGetBanksError = (payload) => ({
    type: Dictionaries.GET_BANKS_ERROR,
    payload,
});

/*
  GET MERCHANT TYPES
*/
export const onGetMerchantTypesFetch = (payload) => ({
    type: Dictionaries.GET_MERCHANT_TYPES_FETCH,
    payload,
});

export const onGetMerchantTypesSuccess = (payload) => ({
    type: Dictionaries.GET_MERCHANT_TYPES_SUCCESS,
    payload,
});

export const onGetMerchantTypesError = (payload) => ({
    type: Dictionaries.GET_MERCHANT_TYPES_ERROR,
    payload,
});

/*
  GET White Labels
*/
export const onWLFetch = (payload) => ({
    type: Dictionaries.GET_WL_FETCH,
    payload,
});

export const onWLSuccess = (payload) => ({
    type: Dictionaries.GET_WL_SUCCESS,
    payload,
});

export const onWLError = (payload) => ({
    type: Dictionaries.GET_WL_ERROR,
    payload,
});

/*
  GET BRANCEHS
*/
export const onBranchesFetch = (payload) => ({
    type: Dictionaries.GET_BRANCHES_FETCH,
    payload,
});

export const onBranchesSuccess = (payload) => ({
    type: Dictionaries.GET_BRANCHES_SUCCESS,
    payload,
});

export const onBranchesError = (payload) => ({
    type: Dictionaries.GET_BRANCHES_ERROR,
    payload,
});
