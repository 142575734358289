import React from 'react';
import styles from './CotfirmPopUp.less';
import cn from 'classnames';

export const ConfirmPopUp = (props) => {
    const { values } = props;

    return (
        <div className={cn(styles.popUpPosition, 'col-md-4')}>
            <div className="card card-primary bg-primary-gradient">
                <div className="card-body">
                    <h4 className="mt-3 b-b1 pb-2 mb-4 fw-bold">Please confirm transfer operation</h4>
                    <h1 className="mb-4 fw-bold">
                        Amount: {Number(values?.amount).toFixed(2)} {values?.currency?.id}
                    </h1>
                    <h4 className="mt-3 b-b1 pb-2 fw-bold">Payment provider: {values?.operator?.title}</h4>
                    <h4 className="b-b1 pb-2 fw-bold">Account: {values?.account}</h4>
                    <div id="activeUsersChart"> </div>
                    {values?.internalComment && <h4 className="mt-2 pb-3 mb-0 fw-bold">Internal comment:</h4>}
                    <ul className={cn(styles.mt2em, 'list-unstyled')}>
                        <li className="d-flex justify-content-between pb-1 pt-1">
                            <small>{values?.internalComment}</small>
                        </li>
                    </ul>
                    <div className="d-flex justify-content-between pb-4 pt-1">
                        <button className={styles.cancelBtn} onClick={props.onCancel}>
                            Cancel
                        </button>
                        <button className={styles.okBtn} onClick={props.onConfirm}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
