export const Sms = {
    SMS_REPORT_FETCH: 'SMS_REPORT_FETCH',
    SMS_REPORT_SUCCESS: 'SMS_REPORT_SUCCESS',
    SMS_REPORT_ERROR: 'SMS_REPORT_ERROR',
    SMS_REPORT_CLEAR: 'SMS_REPORT_CLEAR',

    SMS_REPORT_HISTORY_FETCH: 'SMS_REPORT_HISTORY_FETCH',
    SMS_REPORT_HISTORY_SUCCESS: 'SMS_REPORT_HISTORY_SUCCESS',
    SMS_REPORT_HISTORY_ERROR: 'SMS_REPORT_HISTORY_ERROR',
    SMS_REPORT_HISTORY_CLEAR: 'SMS_REPORT_HISTORY_CLEAR',

    SMS_BULK_FETCH: 'SMS_BULK_FETCH',
    SMS_BULK_SUCCESS: 'SMS_BULK_SUCCESS',
    SMS_BULK_ERROR: 'SMS_BULK_ERROR',
    SMS_BULK_CLEAR: 'SMS_BULK_CLEAR',

    SMS_SCHEDULED_FETCH: 'SMS_SCHEDULED_FETCH',
    SMS_SCHEDULED_SUCCESS: 'SMS_SCHEDULED_SUCCESS',
    SMS_SCHEDULED_ERROR: 'SMS_SCHEDULED_ERROR',
    SMS_SCHEDULED_CLEAR: 'SMS_SCHEDULED_CLEAR',

    SMS_CANCEL_SCHEDULED_BATCHES_FETCH: 'SMS_CANCEL_SCHEDULED_BATCHES_FETCH',
    SMS_CANCEL_SCHEDULED_BATCHES_SUCCESS: 'SMS_CANCEL_SCHEDULED_BATCHES_SUCCESS',
    SMS_CANCEL_SCHEDULED_BATCHES_ERROR: 'SMS_CANCEL_SCHEDULED_BATCHES_ERROR',

    SMS_FILE_SEND_FETCH: 'SMS_FILE_SEND_FETCH',
    SMS_FILE_SEND_SUCCESS: 'SMS_FILE_SEND_SUCCESS',
    SMS_FILE_SEND_ERROR: 'SMS_FILE_SEND_ERROR',
    SMS_FILE_SEND_CLEAR: 'SMS_FILE_SEND_CLEAR',

    GET_SMS_REPORT_SUMMARY_FETCH: 'GET_SMS_REPORT_SUMMARY_FETCH',
    GET_SMS_REPORT_SUMMARY_SUCCESS: 'GET_SMS_REPORT_SUMMARY_SUCCESS',
    GET_SMS_REPORT_SUMMARY_ERROR: 'GET_SMS_REPORT_SUMMARY_ERROR',
    GET_SMS_REPORT_SUMMARY_CLEAR: 'GET_SMS_REPORT_SUMMARY_CLEAR',

    GET_SMS_REPORT_UNDELIVERED_FETCH: 'GET_SMS_REPORT_UNDELIVERED_FETCH',
    GET_SMS_REPORT_UNDELIVERED_SUCCESS: 'GET_SMS_REPORT_UNDELIVERED_SUCCESS',
    GET_SMS_REPORT_UNDELIVERED_ERROR: 'GET_SMS_REPORT_UNDELIVERED_ERROR',
    GET_SMS_REPORT_UNDELIVERED_CLEAR: 'GET_SMS_REPORT_UNDELIVERED_CLEAR',

};
