import cn from 'classnames';
import styles from './RadioButton.less';
import React from 'react';

export const RadioButton = (props) => {

    const handleChange = () => {
        if (props.onChange) {
            props.onChange({
                ...props,
                checked: true,
            });
        }
    };

    return (
        <div className={cn('custom-radio', styles.radioElement)}>
            <input
                id={props.name}
                value={props.name}
                name={props.name}
                className='custom-control-input'
                type='radio'
                checked={props.checked}
                onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor={props.name}>
                {props.title}
            </label>
        </div>);
};
