import { NEW_LEGAL } from '../MerchantDetails/MerchantDetails.helper';

export const emptyBranch = {
    regNumber: '',
    strCountry: 'Russia',
    strLegalName: '',
    strName: '',
    strCurrency: 'USD',
    strLegalAddress: '',
    strRegNumber: '',
    strLicenceNumber: '',
    dtRegDate: new Date(),
};

export const emptyLegal = {
    intMerchantID: '',
    strMerchantLegalName: '',
    intCountry: '',
    strContactName: '',
    strContactPhone: '',
    strContactAddress: '',
    strContactEmail: '',
    dtContractDate: new Date(),
    intWhitelabel: '',
    intBranch: '',
    intBank: '',
    arrCurrencies: [],
    strTimeZone: '',
    state: NEW_LEGAL,
};
/*

'Params' => [
        'intMerchantID' => [
          'dataType' => 'integer',
          'desc'     => 'Merchant ID',
          'required' => true,
        ],
        'strMerchantLegalName' => [
          'dataType' => 'string',
          'desc'     => 'Merchant legal name',
          'regexp'   => '/^[a-zA-Z0-9_-]+/',
          'length'   => '3,30',
          'required' => true,
        ],
        'strContactName' => [
          'dataType' => 'string',
          'desc'     => 'Merchant contact name',
          'length'   => '3,50',
          'required' => true,
        ],
        'strContactPhone' => [
          'dataType' => 'string',
          'desc'     => 'Merchant contact phone',
          'length'   => '3,30',
          'required' => false,
        ],
        'strContactEmail' => [
          'dataType' => 'email',
          'desc'     => 'Merchant contact email',
          'length'   => '3,50',
          'required' => false,
        ],
        'dtContractDate' => [
          'dataType' => 'date',
          'desc'=>'Contract date',
          'required'=>true
        ],
        'intCountry' => [
          'dataType' => 'integer',
          'desc'     => 'Whitelabel',
          'required' => true,
        ],
        'intWhitelabel' => [
          'dataType' => 'integer',
          'desc'     => 'Whitelabel',
          'required' => true,
        ],
        'intBranch' => [
          'dataType' => 'integer',
          'desc'     => 'Branch',
          'required' => true,
        ],
        'intBank' => [
          'dataType' => 'integer',
          'desc'     => 'Bank',
          'required' => false,
        ],
        'arrCurrencies' => [
          'dataType' => 'integer[]',
          'desc'     => 'Currencies',
          'required' => false,
        ],
        'strTimeZone' => [
          'dataType' => 'string',
          'desc'     => 'Description',
          'length'   => '0,10',
          'required' => true,
        ],
      ]
    ],

*/
/*
bank: "1"
branch: "1"
contractDate: "2021-08-27"
country: "180"
currencies: "976,840,643"
defaultTimezone: "-07:00"
id: "5"
merchantID: "41"
merchantLegalName: "TEST LEGAL DRC"
params:[
    Contact: {
    Address: "Soeftr utry uganda 10/11"
    EMail: "testlegal@drc.xom"
    Name: "Olllololo"
    Phone: "+1234567890"
}
]
paramsJSON: "{\"Contact\":{\"Name\":\"Olllololo\",\"Phone\":\"+1234567890\",\"EMail\":\"testlegal@drc.xom\",\"Address\":\"Soeftr utry uganda 10\\/11\"}}"
summary: Array(2)
    [
    {currency: "USD", balance: 1234567890, transactions30d: 12345, commissions30d: 12345},
        {currency: "CDF", balance: 1234567890, transactions30d: 12345, commissions30d: 12345},
    ]

 */

export const mapLegalData = (legal, currencies) => {
    const arrCurrencies = legal.currencies?.length ? legal.currencies : [];; //curArr.map(el => currencies.find(cur => cur.id === el)?.title);
    const mapped = {
        id: legal.id,
        intMerchantID: legal.merchantID,
        strMerchantLegalName: legal.merchantLegalName,
        intCountry: legal.country,
        strContactName: legal.params.Contact.Name,
        strContactPhone: legal.params.Contact.Phone,
        strContactAddress: legal.params.Contact.Address,
        strContactEmail: legal.params.Contact.EMail,
        dtContractDate: legal.contractDate,
        intWhitelabel: legal.whitelabel,
        intBranch: legal.branch,
        intBank: legal.bank,
        strTimeZone: legal.defaultTimezone,
        state: legal.state,
        arrCurrencies,
    };
    return { ...mapped };
};
