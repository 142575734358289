import { timezones } from '../consts/global';
import { Dictionaries } from '../../store/dictionaries/dictionaries.consts';
import { Merchants } from '../../store/merchants/merchants.consts';

export const rowCount = [
    { id: '10', title: 'Show 10 rows' },
    { id: '20', title: 'Show 20 rows' },
    { id: '50', title: 'Show 50 rows' },
    { id: '100', title: 'Show 100 rows' },
];


export const getCountriesForDD = (data) => {
    return (
        data.map((el) => {
            return {
                id: el.codeAlpha3,
                title: el.name ? el.name : el.title,
            };
        }) || []
    );
};

export const getDataForDD = (data, columnTitle, showEmpty, idName) => {
    const dropDownData = data?.map((el) => {
        return {
            id: idName ? el[idName] : el.id,
            title: columnTitle ? el[columnTitle] : (el.name ? el.name : el.title),
        };
    }) || [];

    showEmpty && dropDownData.unshift({ id: '', title: '-' });
    return dropDownData;
};

export const getFilterDataForDD = ({ data, columnTitle, showEmpty, idName }) => {
    return getDataForDD(data, columnTitle, showEmpty, idName);
};

export const getCurrenciesDD = (arr) => {
    if (!arr) return [];
    return arr.map(el => {
        return { id: el.codeNum, title: el.codeAlpha };
    });
};

export const getDataForDropDown = (arr, columnTitle, hideEmpty) => {
    if (!arr) return [];
    const dropDownData = arr.map(el => {
        return { id: el.codeNum, title: el[columnTitle] };
    });
    !hideEmpty && dropDownData.unshift({ id: '', title: '-' });
    return dropDownData;
};

export const PWD_NUM_REGEXP = /^[0-9]*^\d{8,8}$/;

export const findTitleById = (arr, id) => {
    return arr?.find(el => el.id === id)?.title || ' ';
};

export const findTimeZoneByID = (id) => {
    if (typeof id === 'string') return id;
    return timezones.find(el => el.id === id)?.title || '';
};

/*
* params:
* @idsArr: [123,456,434]
* @currDict: [{id:'123', title:'RUB'},...]
*/
export const getCurNamesByIds = (idsArr, currDict) => {
    if (!idsArr?.length) return [];
    return idsArr.map(id => currDict.find(el => el.id === String(id))?.title);
};

export const emptyDepositMerchantState = {
    countryReset: false,
    currencyReset: false,
    account: '',
    comment: '',
    amount: '',
    bank_id: '',
    isAlertShown: false,
    isSuccessShown: false,
    isErrorShown: false,
    clearDropDown: false,
};

export const getCurrentRights = () => {
    const str = sessionStorage.getItem('RRT');
    const obj = JSON.parse(str || {});
    return (obj.role === 'admin' && obj.roleType === 'headquarter') || obj.roleType === 'root';
};



export const loadDict = ({ dict, dispatch }) => {
    const names = {
        'getGate': Dictionaries.GET_GATE_FETCH,
        'getBranches': Dictionaries.GET_BRANCHES_FETCH,
        'getWL': Dictionaries.GET_WL_FETCH,
        'getCurrencies': Dictionaries.GET_CURRENCIES_FETCH,
        'getBanks': Dictionaries.GET_BANKS_FETCH,
        'getCountries': Dictionaries.GET_COUNTRIES_FETCH,
        'getMerchants': Dictionaries.GET_MERCHANTS_FETCH,
        'getMerchantsList': Merchants.MERCHANTS_LIST_GET_FETCH,
    };

    dict.forEach(el => {
        dispatch({
            type: names[el],
            payload: {},
        });
    });
};

export const isRoot = () => {
    const str = sessionStorage.getItem('RRT');
    if (str && str !== 'undefined') {
        const obj = JSON.parse(str || {});
        return obj?.role === 'admin' && obj?.userID === '1' && obj?.roleType === 'root';
    } else {
        return false;
    }
};
