import React, { useEffect, useState } from 'react';
import styles from './SmsUploadPanel.less';
import * as moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { Sms } from '../../../store/sms/sms.consts';
import { AppActions } from '../../../store/app/app.consts';
import Datetime from 'react-datetime';
import { TableHeader } from '../TableHeader/TableHeader';
import { Spinner } from '../Spinner/Spinner';
import { TableBody } from '../TableBody/TableBody';
import { Paginator } from '../Paginator/Paginator';
import {
    historyFilterInitialState,
    historyMapper,
    historyRows,
    oneManyRadios,
    scheduledMapper,
    scheduleRows,
    sendDelayRadios,
} from './SmsUploadHelper';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import cn from 'classnames';

export const SmsUploadPanel = () => {
    const CHARLIMIT = 1600;
    const allowedExtension = ['.csv', '.txt'];
    const dispatch = useDispatch();
    const checkBulkFile = useSelector((state) => state.sms.checkBulkFile);
    const sendBulkFile = useSelector((state) => state.sms.sendBulkFile);
    const getSmsScheduled = useSelector((state) => state.sms.getSmsScheduled);
    const cancelScheduledBulk = useSelector((state) => state.sms.cancelScheduledBulk);
    const getSmsReportHistory = useSelector((state) => state.sms.getSmsReportHistory);
    const [allowNTFY, setAllowNTFY] = useState(checkBulkFile.success === null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAlertShown, alertToggle] = useState(false);
    const [isAlert2Shown, alert2Toggle] = useState(false);
    const [delayedDate, setDelayedDate] = useState(new Date());
    const [historyFilter, setHistoryFilter] = useState(historyFilterInitialState);
    const disableSend = !checkBulkFile.success;
    const scheduledData = scheduledMapper(getSmsScheduled.success?.data.batches || []);
    const historyData = historyMapper(getSmsReportHistory.success?.data.batches || []);
    const historyPaginator = getSmsReportHistory.success?.data.paginator;
    const historyTotals = getSmsReportHistory.success?.data.totals || {};

    const [oneMany, changeOneManyRadio] = useState(oneManyRadios[0]);
    const [sendNow, changeDelayedRadio] = useState(sendDelayRadios[0]);
    const [oneMessageToAll, changeOneMessageToAll] = useState('');
    const [errorFields, setErrorField] = useState(null);

    const reloadLists = () => {
        dispatch({
            type: Sms.SMS_SCHEDULED_FETCH,
            payload: null,
        });

        dispatch({
            type: Sms.SMS_REPORT_HISTORY_FETCH,
            payload: historyFilter,
        });
    };

    useEffect(() => {
        dispatch({
            type: Sms.SMS_SCHEDULED_FETCH,
            payload: null,
        });
        dispatch({
            type: Sms.SMS_REPORT_HISTORY_FETCH,
            payload: historyFilter,
        });

    }, []);

    useEffect(() => {
        dispatch({
            type: Sms.SMS_REPORT_HISTORY_FETCH,
            payload: historyFilter,
        });
    }, [historyFilter]);

    useEffect(() => {
            dispatch({
                type: Sms.SMS_SCHEDULED_FETCH,
                payload: null,
            });
        },
        [cancelScheduledBulk]);

    useEffect(() => {

        if (sendBulkFile.success) {
            showStatusPopUp({ type: 'success', text: 'File sent success.' });
            dispatch({
                type: Sms.SMS_FILE_SEND_CLEAR,
                payload: null,
            });
            dispatch({
                type: Sms.SMS_BULK_CLEAR,
                payload: null,
            });

            reloadLists();
            setSelectedFile(null);
        }

        if (sendBulkFile.errors) {
            showStatusPopUp({
                type: 'error',
                text: sendBulkFile.errors?.message,
            });
        }

    }, [sendBulkFile]);

    useEffect(() => {
        if (allowNTFY) {
            if (checkBulkFile.success) {
                showStatusPopUp({ type: 'success', text: 'Upload success.' });
                setAllowNTFY(false);
            }

            if (checkBulkFile.errors) {
                showStatusPopUp({ type: 'error', text: 'Upload error.' });
                setAllowNTFY(false);
            }
        }
    }, [checkBulkFile]);

    const isExtensionsValid = (target) => {
        let valid = false;

        for (let i = 0; i < target.files?.length; i++) {
            let file = target.files[i];

            if (file.name.endsWith(allowedExtension[0]) || file.name.endsWith(allowedExtension[1])) {
                valid = true;
            }
        }
        return valid;
    };

    const changeHandler = (event) => {
        setAllowNTFY(true);

        if (!isExtensionsValid(event.target)) {
            setSelectedFile(null);

            const fileInput = document.getElementById('file-upload');
            fileInput.value = '';
            showStatusPopUp({ type: 'error', text: 'Unsupported file selected.' });

        } else {
            if (event.target.files[0]) {
                setSelectedFile(event.target.files[0]);
                const fd = new FormData();
                fd.append('upload', event.target.files[0]);
                fd.append('method', 'UploadSmsBatch');

                if (oneMessageToAll && oneMany.name === oneManyRadios[0].name) {
                    fd.append('oneMessageToAll', oneMessageToAll);
                }
                dispatch({
                    type: Sms.SMS_BULK_FETCH,
                    payload: fd,
                });
            }
        }
    };

    const handleSubmission = () => {
        const order_id = checkBulkFile.success?.data;
        const payload = {
            order_id,
        };

        if (oneMessageToAll && oneMany.name === oneManyRadios[0].name) {
            payload.oneMessageToAll = oneMessageToAll;
        }

        if (sendNow.name === sendDelayRadios[1].name) {
            payload.scheduled_at = moment(delayedDate).format();
        }

        if (order_id) {
            dispatch({
                type: Sms.SMS_FILE_SEND_FETCH,
                payload,
            });
        } else {
            console.error('unable to obtain order_id from sms service :(, bulkFileStatus:', checkBulkFile.success);
        }

        setSelectedFile(null);
    };

    const showStatusPopUp = ({ type, text }) => {
        dispatch({
            type: AppActions.APP_NOTIFY_SHOW,
            payload: { type, text },
        });
    };

    const changePage = (intCurrentPage) => {
        const { selected } = intCurrentPage;
        const filter = {
            ...historyFilter,
            intCurrentPage: selected + 1,
        };
        setHistoryFilter(filter);
    };

    const showAlert = () => {
        alertToggle(true);
    };

    const show2Alert = () => {
        alert2Toggle(true);
    };

    const onCloseAlert = () => {
        alertToggle(false);
        alert2Toggle(false);
    };

    const deletePress = ({ item }) => {
        dispatch({
            type: Sms.SMS_CANCEL_SCHEDULED_BATCHES_FETCH,
            payload: { batchId: item.batch_id },
        });
    };

    const onDateChanges = (dateFrom, dateTo) => {
        const dateFilter = dateFrom ? { dateFrom } : { dateTo };
        const newFilter = {
            ...historyFilter,
        };

        newFilter.dateFrom = moment(historyFilter.dateFrom).format();
        newFilter.dateTo = moment(historyFilter.dateTo).format();

        const payload = {
            ...newFilter,
            ...dateFilter,
        };

        setHistoryFilter(payload);
    };
    const onFromDateChanges = (date) => {
        onDateChanges(moment(date).startOf('day').format(), null);
    };

    const onToDateChanges = (date) => {
        onDateChanges(null, moment(date).endOf('day').format());
    };

    const onSetDelayed = (date) => {
        setDelayedDate(date < new Date ? new Date : date);
    };

    const nowAndYearForward = (current) => {
        return current.isAfter(moment()) && current.isBefore(moment().add(1, 'Y'));
    };

    const changeInputHandler = (event) => {
        event.persist();
        const value = event.target.value;
        if (value.length < CHARLIMIT) {
            changeOneMessageToAll(value);
        }
    };

    const {
        dateFrom,
        dateTo,
    } = historyFilter;

    const {
        requested,
        sent,
        delivered,
        deliveredPercent,
    } = historyTotals;

    const disableTextarea = oneMany.name !== oneManyRadios[0].name;

    oneManyRadios[0].title = (<>One message to all numbers (<span className={styles.link} onClick={showAlert}>phone list format</span>)</>);
    oneManyRadios[1].title = (<>Personal message to every phone (<span className={styles.link} onClick={show2Alert}>phone list format</span>)</>);

    return (
        <div className="main-panel">
            <div className="container">
                <div className="page-inner">
                    <div className="card">
                        <div className={styles.cardPadding}>
                            <h1>SMS Gateway - bulk sms send</h1>
                            <div className={styles.addFileText}>
                                1. Choose file format
                                <div className={styles.radioPlace}>
                                    <RadioButtonGroup
                                        radios={oneManyRadios}
                                        onChange={changeOneManyRadio}
                                        className={styles.radios}
                                    />
                                </div>
                            </div>
                            <div className={styles.addFileText}>
                                <span className={cn({ [styles.disabled]: disableTextarea })}>2. Input message</span>
                                <div className={styles.textareaWrapper}>
                                    <textarea
                                        disabled={disableTextarea}
                                        id="description"
                                        name="oneMessageToAll"
                                        value={oneMessageToAll}
                                        className={cn(styles.textarea, { [styles.error]: !errorFields })}
                                        onChange={changeInputHandler}
                                    />
                                    <div className={cn({ [styles.disabled]: disableTextarea }, styles.counter)}>Total
                                        chars: {oneMessageToAll.length}/{CHARLIMIT}</div>
                                </div>
                            </div>
                            <div className={styles.addFileText}>
                                3. Add file with phone list
                                <div className={styles.uploadsLine}>
                                    <label htmlFor="file-upload" className={styles.customUpload}>
                                        <i className="fas fa-upload"> </i> Add file
                                    </label>
                                    <input
                                        id="file-upload"
                                        name="file"
                                        type="file"
                                        accept=".csv, text/plain"
                                        onChange={changeHandler}
                                        className={styles.hide} />
                                </div>
                                <div className={styles.uploadsLine}>
                                    {selectedFile && (<>
                                        <p>
                                            Filename: {selectedFile.name} <br />
                                            Filetype: {selectedFile.type} <br />
                                            Size in bytes: {selectedFile.size} <br />
                                        </p>
                                        <p>Status:</p>
                                    </>)}
                                    <p>
                                        {selectedFile && checkBulkFile.success && (
                                            <span className={styles.successString}>Format correct</span>)}
                                        {selectedFile && checkBulkFile.errors && (
                                            <span className={styles.errorString}>Wrong file format</span>)}
                                    </p>
                                </div>
                            </div>
                            <div className={cn(styles.addFileText, styles.moreMargin)}>
                                4. Select schedule
                                <div className={styles.radioPlace}>
                                    <div className={styles.delayedFilter}>
                                        <RadioButtonGroup
                                            radios={sendDelayRadios}
                                            onChange={changeDelayedRadio}
                                            className={styles.radios}
                                        />
                                        <div className={styles.calendarPlace}>
                                            {sendNow.name === 'delayed' && (
                                                <Datetime
                                                    className={styles.calendar}
                                                    value={delayedDate}
                                                    isValidDate={nowAndYearForward}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat="HH:mm"
                                                    onChange={onSetDelayed}
                                                    closeOnSelect={true}
                                                    input
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bulkButtonWrapper}>
                                <button
                                    onClick={handleSubmission}
                                    className={styles.buttonOk}
                                    disabled={disableSend}
                                >Send Bulk
                                </button>
                            </div>

                            {getSmsScheduled.success && (
                                <div className={styles.schedule}>
                                    <h3 className={styles.h3long}><span>Schedule</span>&nbsp;
                                        <button
                                            onClick={reloadLists}
                                            className={styles.buttonOk}
                                        >Refresh
                                        </button>
                                    </h3>
                                    <div className="table-responsive)">
                                        {getSmsScheduled.fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (<>
                                            <table className="display table table-striped table-hover">
                                                <TableHeader
                                                    rowNames={scheduleRows}
                                                    hasCollapseSwich={false}
                                                />
                                                <TableBody
                                                    rowNames={scheduleRows}
                                                    dataTable={scheduledData}
                                                    hasCollapseSwich={false}
                                                    events={deletePress}
                                                />
                                            </table>
                                        </>)}
                                    </div>
                                </div>
                            )}

                            <h3 className={styles.bolder}>History</h3>
                            <div className={styles.smallPadd}>

                                <div className={styles.calendar}>
                                    <div className={styles.caption}>
                                        Date from:
                                    </div>
                                    <div className={styles.calendarWrap}>
                                        <Datetime
                                            className={styles.calendar}
                                            value={dateFrom}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            onChange={onFromDateChanges}
                                            closeOnSelect={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.calendar}>
                                    <div className={styles.caption}>
                                        Date to:
                                    </div>
                                    <div className={styles.calendarWrap}>
                                        <Datetime
                                            className={styles.calendar}
                                            value={dateTo}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            onChange={onToDateChanges}
                                            closeOnSelect={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {getSmsReportHistory.success && (<>
                                {getSmsReportHistory.fetching ? (
                                    <div className={styles.center}>
                                        <Spinner />
                                    </div>
                                ) : (<>
                                    <div className={styles.smsInfoline}>
                                        <div className={styles.stats}>
                                            <span>Total requested: {requested}</span>
                                            <span>Total sent: {sent}</span>
                                            <span>Total delivered: {delivered}</span>
                                            <span>{deliveredPercent}% delivered</span>
                                        </div>
                                    </div>
                                    <table className="display table table-striped table-hover dataTable">
                                        <TableHeader
                                            rowNames={historyRows}
                                            hasCollapseSwich={false}
                                        />
                                        <TableBody
                                            rowNames={historyRows}
                                            dataTable={historyData}
                                            hasCollapseSwich={false}
                                        />
                                    </table>
                                    {historyPaginator && (
                                        <div className={styles.paginatorPlace}>
                                            <Paginator pagesData={historyPaginator} changePage={changePage} />
                                        </div>
                                    )}
                                </>)}
                            </>)}

                        </div>
                    </div>
                </div>
            </div>

            {isAlert2Shown && (
                <SweetAlert
                    show={isAlert2Shown}
                    title=""
                    type="info"
                    cancelBtnBsStyle="primary"
                    onConfirm={onCloseAlert}
                >
                    <h1 className={styles.heading}>Correct format description</h1>
                    <div className={styles.ContentString}>
                        <p>
                            File must contains 2 columns: <br />
                            First column for mobile phone number (example “254700123456”) <br />
                            Second column should contain text to send.
                        </p>
                        <p>delimiter is comma. example:</p>
                        <div className={styles.exampleText}>
                            "357000000","This is a test message"<br />
                            "357000001","Welcome home!"<br />
                        </div>
                        <br />
                        <p>Note: The character limit for a single SMS
                            message is 160 characters. Concatenation SMS: up to 1600 characters (Each part of
                            concatenated SMS will be calculated as a single SMS). Messages not using GSM-7 encoding are
                            limited to 70 characters.
                        </p>

                    </div>
                </SweetAlert>
            )}
            {isAlertShown && (
                <SweetAlert
                    show={isAlertShown}
                    title=""
                    type="info"
                    cancelBtnBsStyle="primary"
                    onConfirm={onCloseAlert}
                >
                    <h1 className={styles.heading}>Correct format description</h1>
                    <div className={styles.ContentString}>
                        <p>
                            File must contain only phone numbers, one phone number in row. <br />
                            Phone number must be in an international format. <br />
                            Like this “254700123456”.
                        </p>
                        <p>Example:</p>
                        <div className={styles.exampleText}>
                            254700123456<br />254700123457<br />254700123458 <br />
                        </div>
                    </div>
                </SweetAlert>
            )}
        </div>
    );
};
