import axios from "axios";
import { ACCOUNTS_URL, API_URL } from "../../utils/consts/global";
import { getBusinessError } from "../helpers/commonHelpers";

export function getAccounts(payload) {
    return axios({
        method: "post",
        url: `${API_URL}${ACCOUNTS_URL}`,
        data: payload
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            }
        )
        .catch((error) => {
            return { error, response: null };
        });
}
