import React from 'react';
import cn from 'classnames';
import styles from './CustomCheckbox.less';

export const CustomCheckbox = (props) => {
    const {
        checked,
        line,
        name,
        el,
        onChange,
        noLabel,
    } = props;

    const change = () => {
        onChange({ line, name, el });
    };

    return (
        <div className={cn('form-check', { [styles.single]: noLabel })}>
            {!noLabel && (<label className={cn(styles.label)}>{el}</label>)}
            <label className={cn(styles.formCheckLabel, { [styles.single]: noLabel })}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name={el}
                    onChange={change}
                    checked={checked}
                    disabled={props.disabled}
                />
                <span
                    className={cn('form-check-sign', { [styles.single]: noLabel })}>&nbsp;</span>
            </label>
        </div>
    );
};
