import axios from "axios";
import { API_URL, SMS_REPORT_URL, TRANSACTIONS_URL } from "../../utils/consts/global";
import { getBusinessSMSError } from "../helpers/commonHelpers";

export function fetchSmsService(payload) {
    return axios({
        method: "post",
        url: `${API_URL}${SMS_REPORT_URL}`,
        data: payload
    })
        .then(
            (response) => {
                if(response.data?.data?.error?.code){
                    return { response: null, error: response.data?.data?.error };
                } else {
                    return { response: response, error: null };
                }
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function sendBulkSmsFile(payload) {
    return axios({
        method: "post",
        url: `${API_URL}${SMS_REPORT_URL}`,
        data: payload,
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(
            (response) => {
                if(response.data.dataType === 'error') {
                    return { error: response.data.data, response: null };
                }
                if ("string" === typeof response.data) {
                    return { error: response.data, response: null };
                } else {
                    return { error: null, response };
                }
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function getCsvFile(payload) {
    // header('Content-type: application/vnd.ms-excel');
    // header('Content-Disposition: attachment; filename="'.($dt->format('Ymd-Hi')).'.xlsx"');
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    axios({
        url: `${API_URL}${SMS_REPORT_URL}`,
        method: 'POST',
        responseType: 'blob',
        headers,
        data: payload,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'rejected.csv');
        document.body.appendChild(link);
        link.click();
    });
}

