import * as moment from 'moment';
import { DAYTIME_FORMAT_HUMAN } from '../../utils/consts/global';

export const operationTypes = {
    2: 'hold',
    3: 'confirm',
    4: 'refund',
    5: 'hold completion',
    6: 'card to card',
    7: 'card to business',
    8: 'business to card',
    9: 'business to card limit',
    10: 'status',
    11: 'business day finish',
    12: 'card to business completion',
    13: 'sbpQRC',
    14: 'sbp status',
    15: 'sbp refund',
    16: 'withdraw', //'paymentB2C',
    17: 'deposit', //'paymentC2B',
    18: 'cardToken',
    19: 'check account',
    20: 'payment cash',
    21: 'withdraw cash',
    22: 'complete withdraw cash',
    23: 'balance',
    24: 'callback',
    32: 'paybill',
};

export const paymentMethods = {
    0: 'Unknown',
    9: 'Vodacom (Africa)',
    10: 'Orange (Africa)',
    11: 'Ecocash (Africa)',
    12: 'Safaricom (Africa)',
    13: 'MelBet',
    14: 'Unipesa Simulator',
    15: 'Unipesa',
    16: 'Airtel (Kenya)',
};

export const operationStatuses = {
    0: 'initiated',
    1: 'in progress',
    2: 'success',
    3: 'fail',
    4: 'cancelled',
    5: 'cancelled partially',
    6: 'success hold',
};

export const protocol = {
    9: 'Vodacom (Africa)',
    10: 'Orange (Africa)',
};

export const makeHumanReadable = (income, gates) => {
    const { data } = income;
    income.data = data.map((el) => {
        return {
            ...el,
            created: moment(el.created).format(DAYTIME_FORMAT_HUMAN),
            operationType: operationTypes[el.operationType] || el.operationType,
            paymentMethod: gates.find((gate) => gate.id === el.paymentMethod)?.name || el.paymentMethod, //paymentMethods[el.paymentMethod] || el.paymentMethod,
            status: operationStatuses[el.status] || el.status,
            initiator: el.initiator === '0' ? '' : el.initiator,
            internalComment: el.paramsJSON ? JSON.parse(el.paramsJSON)?.note || '' : '',
        };
    });
    return income;
};

export const setHumanDateFormat = (income, column) => {
    return income.map((el) => {
        return {
            ...el,
            [column] :  moment(el[column]).format(DAYTIME_FORMAT_HUMAN)
        }
    });
}

export const getHeaders = (token) => {
    if (token) {
        return { authorization: 'Bearer ' + token };
    }
};
