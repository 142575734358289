import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { PaymentOperations } from './paymentOperations.consts';

const defaultState = {
    request: null,
    fetching: false,
    success: null,
    errors: null
};

export const makePaymentC2B = handleActions(
    {
        [PaymentOperations.MAKE_PAYMENT_C2B_FETCH]: (state, action) => {
            return update(state, {
                request: { $set: action.payload },
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_PAYMENT_C2B_SUCCESS]: (state, action) => {
            return update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_PAYMENT_C2B_ERROR]: (state, action) =>
            update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null }
            }),
        [PaymentOperations.GET_PAYMENT_STATUS_CLEAR]: (state) => {
            return update(state, {
                request: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null }
            });
        }
    },
    defaultState
);

export const makePaymentB2C = handleActions(
    {
        [PaymentOperations.MAKE_PAYMENT_B2C_FETCH]: (state, action) => {
            return update(state, {
                request: { $set: action.payload },
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_PAYMENT_B2C_SUCCESS]: (state, action) => {
            return update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_PAYMENT_B2C_ERROR]: (state, action) =>
            update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null }
            }),
        [PaymentOperations.GET_PAYMENT_STATUS_CLEAR]: (state) => {
            return update(state, {
                request: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null }
            });
        }
    },
    defaultState
);

export const getPaymentStatus = handleActions(
    {
        [PaymentOperations.GET_PAYMENT_STATUS_FETCH]: (state, action) => {
            return update(state, {
                request: { $set: action.payload },
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.GET_PAYMENT_STATUS_FETCH_PROCESS]: (state, action) => {
            return update(state, {
                // request: { $set: action.payload },
                fetching: { $set: action.payload },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.GET_PAYMENT_STATUS_SUCCESS]: (state, action) => {
            return update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null }
            });
        },
        [PaymentOperations.GET_PAYMENT_STATUS_ERROR]: (state, action) => {
            return update(state, {
                request: { $set: state.request },
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null }
            });
        },
        [PaymentOperations.GET_PAYMENT_STATUS_CLEAR]: (state) => {
            return update(state, {
                request: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null }
            });
        }
    },
    defaultState
);


export const makeDepositMerchant = handleActions(
    {
        [PaymentOperations.MAKE_DEPOSIT_MERCHANT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_DEPOSIT_MERCHANT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_DEPOSIT_MERCHANT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                success: { $set: null },
                errors: { $set: action.payload },
            }),
        [PaymentOperations.MAKE_DEPOSIT_MERCHANT_CLEAR]: (state) => {
            return update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null }
            });
        }
    },
    defaultState
);


export const makeSettlementMerchant = handleActions(
    {
        [PaymentOperations.MAKE_SETTLEMENT_MERCHANT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_SETTLEMENT_MERCHANT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null }
            });
        },
        [PaymentOperations.MAKE_SETTLEMENT_MERCHANT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                success: { $set: null },
                errors: { $set: action.payload },
            }),
        [PaymentOperations.MAKE_SETTLEMENT_MERCHANT_CLEAR]: (state) => {
            return update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null }
            });
        }
    },
    defaultState
);


export const payments = combineReducers({
    makePaymentC2B,
    makePaymentB2C,
    getPaymentStatus,
    makeDepositMerchant,
    makeSettlementMerchant
});
