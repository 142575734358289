import axios from "axios";
import { API_URL, METRIC_URL } from '../../utils/consts/global';
import { getBusinessError } from "../helpers/commonHelpers";

export function metricService(payload) {
    return axios({
        method: "post",
        url: `${API_URL}${METRIC_URL}`,
        data: payload
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            }
        )
        .catch((error) => {
            return { error, response: null };
        });
}
