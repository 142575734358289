import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AgentDetails.less';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SMALL_SCREEN } from '../../../../utils/consts/global';
import { TabMenu } from '../../TabMenu/TabMenu';
import { getSearchParams } from '../../../../utils/helpers/commonFunctions';
import { emptyLegal, menuItems, switchNames } from './AgentDetailsHelper';
import { AgentSummaryTab } from '../AgentSummaryTab/AgentSummaryTab';
import { Agents } from '../../../../store/agents/agents.consts';
import { AgentDetailTab } from '../AgentDetailTab/AgentDetailTab';
import { Dictionaries } from '../../../../store/dictionaries/dictionaries.consts';
import { AgentCard } from '../AgentCard/AgentCard';
import RoutesPaths from '../../../../routes/RoutesPaths';
import { PUBLISHED } from '../../Merchant/MerchantDetails/MerchantDetails.helper';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import { getDataForDD } from '../../../../utils/helpers/commonHelpers';

export const AgentDetails = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showSuccess, setShowSuccess] = useState(false);
    const getCurrencies = useSelector((state) => state.dictionaries.getCurrencies) || [];
    const getAgentInfo = useSelector((state) => state.agents.getAgentInfo) || [];
    const editAgent = useSelector((state) => state.agents.editAgent.success);
    const currencies = getCurrencies.success || [];
    const [createMode, setCreateMode] = useState(getSearchParams(history.location.search)?.createMode === 'true');
    const newAgent = getSearchParams(history.location.search)?.newAgent === 'true';
    const getCountries = useSelector((state) => state.dictionaries.getCountries.success);

    const agentId = getSearchParams(history.location.search)?.id || null;
    const agentUserID = getSearchParams(history.location.search)?.agentUserID || null;
    const tabId = getSearchParams(history.location.search)?.tabId || 0;
    // const useStore = getSearchParams(history.location.search)?.useStore || false;
    const storeData = useSelector((state) => state.app.data);
    const [activeTab, setActiveTab] = useState(menuItems[tabId].name);
    const getAgents = useSelector((state) => state.agents.getAgents);
    const agentsList = getAgents.success?.agents || [];
    const normalizeData = newAgent ? switchNames(storeData, agentId) : storeData;
    const [agent, setAgent] = useState(agentId ? (agentsList.filter(el => el.id === agentId)[0] || normalizeData) : getAgentInfo.success?.clients[agentId]);

    const legalsList = agent?.agentInfo?.legals || [];
    const [selectedLegal, setSelectedLegal] = useState(createMode ? { ...emptyLegal } : { ...legalsList[0] });
    const [legalsForDD, setLegalsForDD] = useState(getDataForDD(legalsList, 'strLegalName', false, 'intID'));

    useEffect(() => {
        setLegalsForDD(getDataForDD(agent?.agentInfo?.legals || [], 'strLegalName', false, 'intID'));
    }, [agent, selectedLegal]);

    useEffect(() => {

        if (!agentId || !agent) {
            history.push({
                pathname: RoutesPaths.AGENTS,
                search: '',
            });
        }

        if (agentId) {
            dispatch({
                type: Agents.GET_AGENT_INFO_FETCH,
                payload: {
                    refID: agentId,
                },
            });
        }

        if (!getCurrencies.success) {
            dispatch({
                type: Dictionaries.GET_CURRENCIES_FETCH,
                payload: {},
            });
        }
        if (!getCountries) {
            dispatch({
                type: Dictionaries.GET_COUNTRIES_FETCH,
                payload: {},
            });
        }

        return () => {
            // dispatch({
            //     type: Agents.EDIT_AGENT_CLEAR,
            //     payload: null,
            // });
        };
    }, []);

    /*
    * Clear success status:
    */
    useEffect(() => {
        if (editAgent) {
            setShowSuccess(true);
            dispatch({
                type: Agents.EDIT_AGENT_CLEAR,
                payload: null,
            });
        }

    }, [editAgent]);

    const findLegalById = (id) => {
        return legalsList.find(el => el.intID === id);
    };

    const onSelectLegal = (id) => {
        const curLegal = findLegalById(id);
        setSelectedLegal({ ...curLegal });
    };

    const addLegal = () => {
        setSelectedLegal({ ...emptyLegal });
        setCreateMode(true);
        setActiveTab(menuItems[1].name);
    };


    const onCancelDetail = (e) => {
        const curLegal = legalsList.find(el => el.intID === selectedLegal.intID) || legalsList[0] || { ...emptyLegal };
        setCreateMode(false);
        setSelectedLegal({ ...curLegal });
    };

    const getLegalIdx = (agent, intID) => {
        const legals = agent.agentInfo?.legals;
        if (!legals || !legals.length) return null;

        const foundLegal = legals.find(el => el.intID === intID);
        return legals.indexOf(foundLegal);
    };

    const updateAgent = (agent) => {
        const newAgent = {
            ...agent,
            agentLogin: agent.agentLogin || agent.login,
        };

        if(!newAgent.agentUserID && agentUserID) {
            newAgent.agentUserID = agentUserID;
        }

        setAgent(newAgent);
        const selectedLegalID = selectedLegal.intID;

        if (findLegalById(selectedLegalID)) {
            onSelectLegal(selectedLegalID);
        } else {
            setSelectedLegal(legalsList.length ? { ...legalsList[0] } : { ...emptyLegal });
        }

        dispatch({
            type: Agents.EDIT_AGENT_FETCH,
            payload: newAgent,
        });
    };

    const onDeleteLegal = (legal) => {
        const newAgent = { ...agent };
        const editedLegalIdx = getLegalIdx(newAgent, legal.intID);
        if ((editedLegalIdx || editedLegalIdx === 0) && editedLegalIdx !== -1) {
            newAgent.agentInfo.legals.splice(editedLegalIdx, 1);
        }
        updateAgent(newAgent);
    };

    const onConfirmLegal = (legal) => {
        const newAgent = { ...agent };
        const editedLegalIdx = getLegalIdx(newAgent, legal.intID);
        const arrLegals = newAgent.agentInfo?.legals || [];

        if (!arrLegals.length) {
            if (!newAgent.agentInfo) {
                newAgent.agentInfo = {};
            }
            newAgent.agentInfo.legals = arrLegals;
        }

        if ((editedLegalIdx || editedLegalIdx === 0) && editedLegalIdx !== -1) {
            arrLegals[editedLegalIdx] = legal;
        } else {
            arrLegals.push(legal);
        }

        setSelectedLegal(legal);
        setCreateMode(false);
        updateAgent(newAgent);
    };

    const onConfirmPublish = (a) => {
        const legal = { ...selectedLegal };
        legal.legalStatus = PUBLISHED;
        onConfirmLegal(legal);
    };

    const onGoOut = () => {
        setShowSuccess(false);
    };

    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className="container">
                <div className="page-inner">
                    {SMALL_SCREEN && <h1>Merchants list</h1>}
                    <div className="col-md-12">
                        <div className={cn(styles.card, 'card')}>

                            <div className={cn(styles.cardWrapper)}>
                                <AgentCard
                                    agent={agent}
                                    newAgent={newAgent}
                                    onChangeCreate={false}
                                    legalsList={legalsForDD}
                                    onSelectLegal={onSelectLegal}
                                    selectedLegal={selectedLegal}
                                    onCreate={addLegal}
                                    createMode={createMode}
                                    onChangeEnabled={updateAgent}
                                />
                                {(agent?.agentInfo?.legals?.length || createMode) && (<div>
                                    <div className={styles.borderBottomLine}>
                                        <TabMenu
                                            className={styles.tabMargin}
                                            menuItems={menuItems}
                                            active={activeTab}
                                            onChange={setActiveTab}
                                            disabled={createMode}
                                        />
                                    </div>

                                    {activeTab === menuItems[0].name && (
                                        <AgentSummaryTab
                                            legal={selectedLegal}
                                            agent={agent}
                                            currencies={currencies}
                                            onPublish={onConfirmPublish}
                                        />
                                    )}
                                    {activeTab === menuItems[1].name && (
                                        <AgentDetailTab
                                            createMode={createMode}
                                            agent={agent}
                                            legal={selectedLegal}
                                            currencies={currencies}
                                            onCancel={onCancelDetail}
                                            onConfirm={onConfirmLegal}
                                            onDelete={onDeleteLegal}
                                        />
                                    )}
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSuccess && (
                <SweetAlert
                    show={showSuccess}
                    title="Success!"
                    type="success"
                    text="Saved successful"
                    confirmButtonColor="#1572E8"
                    onConfirm={onGoOut}
                />
            )}
        </div>
    );
};
