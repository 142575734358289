import { rowNamesOptions } from '../TableBody/TableBodyHelper';
export const FIRST_ROW_NAME = 'system name';

export const menuItems = [
    { name: 'Agent', id: 'agent' },
    { name: 'Branch', id: 'branch' },
    { name: 'Headquarter', id: 'headquarter' },
    { name: 'Merchant', id: 'merchant' },
    { name: 'Whitelabel', id: 'whitelabel' },
    { name: 'Superagent', id: 'superagent' },
];

export const getColumns = (tableData) => {
    tableData = tableData || {};
    const lineNames = Object.keys(tableData || {});
    const columnNames = [FIRST_ROW_NAME, ...Object.keys(tableData[lineNames[0]] || {})];

    return columnNames.map(el => {
        return el === FIRST_ROW_NAME ? {
            name: el,
            title: el,
            className: '',
            options: '',
            isSortable: false,
        } : { name: el, title: el, className: 'vertical', options: rowNamesOptions.singleCheckBox, isSortable: false };
    });
};

export const getData = (tableData) => {
    tableData = tableData || {};
    const lineNames = Object.keys(tableData || {});

    return lineNames.map((el) => {
        return { [FIRST_ROW_NAME]: el, ...tableData[el] };
    });
};
