import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { Dictionaries } from './dictionaries.consts';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getAllowedOperators = handleActions(
    {
        [Dictionaries.GET_ALLOWED_OPERATORS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_ALLOWED_OPERATORS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_ALLOWED_OPERATORS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Dictionaries.GET_ALLOWED_OPERATORS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getCountries = handleActions(
    {
        [Dictionaries.GET_COUNTRIES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_COUNTRIES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_COUNTRIES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getCurrencies = handleActions(
    {
        [Dictionaries.GET_CURRENCIES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_CURRENCIES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_CURRENCIES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getGate = handleActions(
    {
        [Dictionaries.GET_GATE_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_GATE_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_GATE_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getGateAdm = handleActions(
    {
        [Dictionaries.GET_GATE_ADM_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_GATE_ADM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_GATE_ADM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getTerminalsAdm = handleActions(
    {
        [Dictionaries.GET_TERMINALS_ADM_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_TERMINALS_ADM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_TERMINALS_ADM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getClients = handleActions(
    {
        [Dictionaries.GET_CLIENTS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_CLIENTS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_CLIENTS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getSubClients = handleActions(
    {
        [Dictionaries.GET_SUBCLIENTS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_SUBCLIENTS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_SUBCLIENTS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const createLogo = handleActions(
    {
        [Dictionaries.CREATE_LOGO_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.CREATE_LOGO_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.CREATE_LOGO_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getLogos = handleActions(
    {
        [Dictionaries.GET_LOGOS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_LOGOS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_LOGOS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);
export const updateLogo = handleActions(
    {
        [Dictionaries.SET_LOGO_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.SET_LOGO_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.SET_LOGO_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getMerchants = handleActions(
    {
        [Dictionaries.GET_MERCHANTS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_MERCHANTS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_MERCHANTS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getBanks = handleActions(
    {
        [Dictionaries.GET_BANKS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_BANKS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_BANKS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getMerchantTypes = handleActions(
    {
        [Dictionaries.GET_MERCHANT_TYPES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_MERCHANT_TYPES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_MERCHANT_TYPES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getWL = handleActions(
    {
        [Dictionaries.GET_WL_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_WL_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_WL_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getBranches = handleActions(
    {
        [Dictionaries.GET_BRANCHES_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_BRANCHES_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Dictionaries.GET_BRANCHES_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);
export const dictionaries = combineReducers({
    getAllowedOperators,
    getCountries,
    getCurrencies,
    getGate,
    getGateAdm,
    getSubClients,
    getClients,
    createLogo,
    getLogos,
    updateLogo,
    getMerchants,
    getBanks,
    getMerchantTypes,
    getWL,
    getBranches,
    getTerminalsAdm,
});
