import { Sms } from './sms.consts';

/** SMS Report */
export const onSmsReportFetch = (payload) => ({
    type: Sms.SMS_REPORT_FETCH,
    payload,
});

export const onSmsReportSuccess = (payload) => ({
    type: Sms.SMS_REPORT_SUCCESS,
    payload,
});

export const onSmsReportError = (payload) => ({
    type: Sms.SMS_REPORT_ERROR,
    payload,
});

export const onSmsReportClear = (payload) => ({
    type: Sms.SMS_REPORT_CLEAR,
    payload,
});

/** SMS Report history*/
export const onSmsReportHistoryFetch = (payload) => ({
    type: Sms.SMS_REPORT_HISTORY_FETCH,
    payload,
});

export const onSmsReportHistorySuccess = (payload) => ({
    type: Sms.SMS_REPORT_HISTORY_SUCCESS,
    payload,
});

export const onSmsReportHistoryError = (payload) => ({
    type: Sms.SMS_REPORT_HISTORY_ERROR,
    payload,
});

export const onSmsReportHistoryClear = (payload) => ({
    type: Sms.SMS_REPORT_HISTORY_CLEAR,
    payload,
});

/** SMS scheduled */
export const onSmsScheduledFetch = (payload) => ({
    type: Sms.SMS_SCHEDULED_FETCH,
    payload,
});

export const onSmsScheduledSuccess = (payload) => ({
    type: Sms.SMS_SCHEDULED_SUCCESS,
    payload,
});

export const onSmsScheduledError = (payload) => ({
    type: Sms.SMS_SCHEDULED_ERROR,
    payload,
});

export const onSmsScheduledClear = (payload) => ({
    type: Sms.SMS_SCHEDULED_CLEAR,
    payload,
});

/** SMS scheduled cancel */
export const onCancelScheduledFetch = (payload) => ({
    type: Sms.SMS_CANCEL_SCHEDULED_BATCHES_FETCH,
    payload,
});

export const onCancelScheduledSuccess = (payload) => ({
    type: Sms.SMS_CANCEL_SCHEDULED_BATCHES_SUCCESS,
    payload,
});

export const onCancelScheduledError = (payload) => ({
    type: Sms.SMS_CANCEL_SCHEDULED_BATCHES_ERROR,
    payload,
});


/** SMS Report SUMMARY */
export const onSmsReportSummaryFetch = (payload) => ({
    type: Sms.GET_SMS_REPORT_SUMMARY_FETCH,
    payload,
});

export const onSmsReportSummarySuccess = (payload) => ({
    type: Sms.GET_SMS_REPORT_SUMMARY_SUCCESS,
    payload,
});

export const onSmsReportSummaryError = (payload) => ({
    type: Sms.GET_SMS_REPORT_SUMMARY_ERROR,
    payload,
});

export const onSmsReportSummaryClear = (payload) => ({
    type: Sms.GET_SMS_REPORT_SUMMARY_CLEAR,
    payload,
});

/** SMS Report UNDELIVERED */
export const onSmsReportUndeliveredFetch = (payload) => ({
    type: Sms.GET_SMS_REPORT_UNDELIVERED_FETCH,
    payload,
});

export const onSmsReportUndeliveredSuccess = (payload) => ({
    type: Sms.GET_SMS_REPORT_UNDELIVERED_SUCCESS,
    payload,
});

export const onSmsReportUndeliveredError = (payload) => ({
    type: Sms.GET_SMS_REPORT_UNDELIVERED_ERROR,
    payload,
});

export const onSmsReportUndeliveredClear = (payload) => ({
    type: Sms.GET_SMS_REPORT_UNDELIVERED_CLEAR,
    payload,
});

/** SMS Bulk upload */
export const onSmsBulkFetch = (payload) => ({
    type: Sms.SMS_BULK_FETCH,
    payload,
});

export const onSmsBulkSuccess = (payload) => ({
    type: Sms.SMS_BULK_SUCCESS,
    payload,
});

export const onSmsBulkError = (payload) => ({
    type: Sms.SMS_BULK_ERROR,
    payload,
});

/** SMS File send */
export const onSmsFileSendFetch = (payload) => ({
    type: Sms.SMS_FILE_SEND_FETCH,
    payload,
});

export const onSmsFileSendSuccess = (payload) => ({
    type: Sms.SMS_FILE_SEND_SUCCESS,
    payload,
});

export const onSmsFileSendError = (payload) => ({
    type: Sms.SMS_FILE_SEND_ERROR,
    payload,
});
