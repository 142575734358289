import React from 'react';
import styles from './Modal.less';
import cn from 'classnames';

export const Modal = (props) => {
    const faderClick = () => {
        if (props.faderClick) {
            props.faderClick();
        }
    };

    return (
        <>
            <div className={cn(styles.modal, props.className)}>
                <div onClick={faderClick} className={styles.fader}>
                    &nbsp;
                </div>
                {props.children}
            </div>
        </>
    );
};
