import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AgentCard.less';
import { DropDown } from '../../DropDown/DropDown';
import { findTitleById } from '../../../../utils/helpers/commonHelpers';
import { ActionButton } from '../../ActionButton/ActionButton';
import * as moment from 'moment';

export const AgentCard = (props) => {
    const { agent, createMode } = props;
    const contacts = agent?.agentInfo?.contacts || {};

    const [selectedLegal, setSelectedLegal] = useState(createMode ? null : props.legalsList[0]);

    const onCreate = () => {
        if (props.onCreate) {
            props.onCreate();
        }
    };

    const onSelectLegal = (value, name) => {
        const { id } = value;
        setSelectedLegal({ [name]: id });
        props.onSelectLegal(id);
    };

    const onChangeEnabled = () => {
        if (props.onChangeEnabled) {
            const newAgent = { ...agent };
            newAgent.enabled = !newAgent.enabled;
            props.onChangeEnabled(newAgent);
        }
    };

    const creationDate = moment(agent?.creationDT).format('DD/MM/YY HH:MM') || '';
    const createText = props.legalName || 'Please fill form below';

    return (
        <div className={styles.infoBlock}>
            <div className={styles.leftSideInfo}>
                <div className={styles.mainTop}>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Agent name:</div>
                        <div className={styles.secondColumn}>{agent?.name}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Agent login:</div>
                        <div className={styles.secondColumn}>{agent?.login}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Agent id:</div>
                        <div className={styles.secondColumn}>{agent?.id}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Creation date:</div>
                        <div className={styles.secondColumn}>{creationDate}</div>
                    </div>

                    <div className={cn(styles.line)}>

                        <div className={styles.firstColumn}>Company:</div>
                        <div className={styles.secondColumn}>
                            <div className={cn(styles.fixedDropWidth)}>
                                {(!!props.legalsList.length && !props.createMode) && (
                                    <DropDown
                                        color="grey"
                                        options={props.legalsList}
                                        onSelect={onSelectLegal}
                                        reset={props.legalsList}
                                        name="id"
                                        className={styles.dropWrapper}
                                    >
                                        {findTitleById(props.legalsList, selectedLegal?.id)}&nbsp;
                                    </DropDown>
                                )}
                                {props.createMode ? (<>{createText}</>) : (<>
                                    <div>
                                        <ActionButton
                                            click={onCreate}
                                            className={styles.addWidth}
                                        >
                                            Add
                                        </ActionButton>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.rightSideInfo}>
                <div className={styles.mainTop}>
                    <div className={cn(styles.line, styles.topline)}>
                        <div className={styles.firstColumn}>Contact name:</div>
                        <div className={styles.secondColumn}>{contacts?.managerName}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Contact phone:</div>
                        <div className={styles.secondColumn}>{contacts?.managerPhone}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Contact email:</div>
                        <div className={styles.secondColumn}>{contacts?.managerEmail}</div>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.firstColumn}>Status:</div>
                        <div className={styles.secondColumn}>
                            {agent?.enabled ? 'enabled' : 'disabled'}
                        </div>
                        <div className={styles.thirdColumn}>
                            <ActionButton
                                click={onChangeEnabled}
                                disableIco
                            >
                                {agent?.enabled ? 'Disable' : 'Enable'}
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
