import React, { useEffect, useState } from 'react';
import styles from './RolesTable.less';
import cn from 'classnames';
import { TabMenu } from '../TabMenu/TabMenu';
import { Spinner } from '../Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { FIRST_ROW_NAME, getColumns, getData, menuItems } from './RolesTableHelper';
import { RoleSystem } from '../../../store/roleSystem/roleSystem.consts';
import { TableHeader } from '../TableHeader/TableHeader';
import { TableBody } from '../TableBody/TableBody';
import { ActionButton } from '../ActionButton/ActionButton';

export const RolesTable = () => {
    const dispatch = useDispatch();
    const getFuncionRole = useSelector((state) => state.roleSystem.getFuncionRole);
    const setFuncionRole = useSelector((state) => state.roleSystem.setFuncionRole);
    const [activeTab, setActiveTab] = useState(menuItems[0].name);
    const [tableData, setTableData] = useState([]);
    const columns = getColumns(getFuncionRole.success);


    const loadData = (payload) => {
        dispatch({
            type: RoleSystem.GET_FUNCTION_ROLE_FETCH,
            payload,
        });
    };

    useEffect(() => {
        const strRoleType = menuItems[0].id;
        loadData({ strRoleType });
    }, []);

    useEffect(() => {
        setTableData(getData(getFuncionRole.success));
    }, [getFuncionRole.success]);


    const tabSelected = (name) => {
        const selected = menuItems.find(el => el.name === name);
        const strRoleType = selected.id;
        setActiveTab(selected.name);
        loadData({ strRoleType });
    };

    const onCheckBoxChanged = ({ el: name, line }) => {
        line[name] = !line[name];
        const newData = [...tableData];
        setTableData(newData);
    };

    const onSave = () => {
        const arrFunctionRole = {};
        const strRoleType = menuItems.find(el => el.name === activeTab).id;

        tableData.map(el => {
            const newLine = { ...el };
            delete newLine[FIRST_ROW_NAME];
            arrFunctionRole[el[FIRST_ROW_NAME]] = newLine;
        });

        const payload = {
            strRoleType,
            arrFunctionRole,
        };

        dispatch({
            type: RoleSystem.SET_FUNCTION_ROLE_FETCH,
            payload,
        });
    };


    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className={styles.borderBottomLine}>
                <TabMenu
                    className={styles.tabMargin}
                    menuItems={menuItems}
                    active={activeTab}
                    onChange={tabSelected}
                />
            </div>
            {getFuncionRole.fetching && (
                <div className={styles.spinnerPlace}>
                    <Spinner />
                </div>
            )}
            {!getFuncionRole.fetching && (<div className={styles.tabContent}>
                <table className={cn("display table table-striped table-hover dataTable", styles.whiteBg)}>
                    <TableHeader rowNames={columns} hasCollapseSwich={false} />
                    <TableBody dataTable={tableData} rowNames={columns} hasCollapseSwich={false}
                               onCheckBoxChanged={onCheckBoxChanged} />
                </table>
                <div>
                    {setFuncionRole.fetching && (<Spinner />)}
                    {!setFuncionRole.fetching && (<ActionButton
                        click={onSave}
                        disableIco
                    >
                        Save
                    </ActionButton>)}
                </div>
            </div>)}
        </div>
    );
};
