import { fork } from '@redux-saga/core/effects';
import { authSaga } from './auth/auth.saga';
import { appSagas } from './app/app.saga';
import { transactionsSaga } from './transactions/transactions.saga';
import { errorsSaga } from './errors/errors.saga';
import { dictionariesSaga } from './dictionaries/dictionaries.saga';
import { paymentOperationsSaga } from './paymentOperations/paymentOperations.saga';
import { roleSystemSaga } from './roleSystem/roleSystem.saga';
import { agentsSaga } from './agents/agents.saga';
import { smsSaga } from './sms/sms.saga';
import { accountsSaga } from "./accounts/accounts.saga";
import { merchantsSaga } from "./merchants/merchants.saga";
import { metricsSaga } from './metric/metric.saga';
import { selfStorageSaga } from './selfStorage/selfStorage.saga';

export function* rootSaga() {
    yield fork(appSagas);
    yield fork(authSaga);
    yield fork(transactionsSaga);
    yield fork(smsSaga);
    yield fork(errorsSaga);
    yield fork(dictionariesSaga);
    yield fork(paymentOperationsSaga);
    yield fork(roleSystemSaga);
    yield fork(agentsSaga);
    yield fork(accountsSaga);
    yield fork(merchantsSaga);
    yield fork(metricsSaga);
    yield fork(selfStorageSaga);
}
