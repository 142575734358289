export const defaultFilter = {
    dtCreatedRange: '2020-01-01T00:00:00-08:00;2021-02-23T23:59:59-08:00',
};

export const periodOptions = [
    { id: 'day', title: 'Per day' },
    { id: 'week', title: 'Last 7 days' },
    { id: '2week', title: 'Last 2 weeks' },
    { id: '3week', title: 'Last 3 weeks' },
    { id: 'month', title: 'Last Month' },
    { id: '6month', title: 'Last 6 Months' },
    { id: 'year', title: 'Last Year' },
    { id: 'custom', title: 'Custom period' },
];
