import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './DashboardPanel.less';
import { SMALL_SCREEN } from '../../../utils/consts/global';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    BarChart,
    ResponsiveContainer,
    Cell,
    Bar, Legend,
} from 'recharts';
import {
    dashboardResp,
    formatForTable,
    initialFiltersState,
    normalizeParams,
    period,
    rows,
} from './DashboardPanel.helper';
import { DropDown } from '../DropDown/DropDown';
import { getWL } from '../../../store/dictionaries/dictionaries.reducers';
import { findTitleById, getCurrenciesDD, getDataForDD, loadDict } from '../../../utils/helpers/commonHelpers';
import * as moment from 'moment';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { NumberBlock } from '../Widgets/NumberBlock/NumberBlock';
import { SquareBlock } from '../Widgets/SquareBlock/SquareBlock';
import { Spinner } from '../Spinner/Spinner';
import { TableHeader } from '../TableHeader/TableHeader';
import { TableBody } from '../TableBody/TableBody';
import { Agents } from '../../../store/agents/agents.consts';
import { Metric } from '../../../store/metric/metric.consts';

export const DashboardPanel = (props) => {
    const dispatch = useDispatch();
    const [selfParams, updateSelfParams] = useState(initialFiltersState);
    const [activeIndex, setActiveIndex] = useState(0);

    // const getMerchants = useSelector((state) => state.dictionaries.getMerchants.success);
    const getMerchantsList = useSelector((state) => state.merchants.getMerchantsList.success);
    const getWL = useSelector((state) => state.dictionaries.getWL.success);
    const getBranches = useSelector((state) => state.dictionaries.getBranches.success);
    const getCurrencies = useSelector((state) => state.dictionaries.getCurrencies.success);
    const getGate = useSelector((state) => state.dictionaries.getGate.success);

    const { data } = dashboardResp.transactions.counts;
    const dayStat = formatForTable(dashboardResp.dayStat);

    const wl = getDataForDD(getWL || []);
    const merchantsList = getDataForDD(getMerchantsList?.data || [], 'merchantBrandName', true);
    const branchesList = getDataForDD(getBranches);
    const currencies = getCurrenciesDD(getCurrencies);
    const gatesList = getDataForDD(getGate);

    const loadDashboard = (params) => {
        const payload = normalizeParams(selfParams);
        console.log('payload', payload);
        
        // dispatch({
        //     type: Metric.GET_DASHBOARD_DATA_FETCH,
        //     payload,
        // });
    };

    useEffect(() => {
        const dict = [];
        if (!getWL) dict.push('getWL');
        if (!getBranches) dict.push('getBranches');
        if (!getCurrencies) dict.push('getCurrencies');
        if (!getMerchantsList) dict.push('getMerchantsList');
        if (!getGate) dict.push('getGate');

        loadDict({ dict, dispatch });
        loadDashboard(selfParams);

    }, []);

    useEffect(() => {
        loadDashboard(selfParams);
    }, [selfParams]);


    const ddSelect = (value, name) => {
        const { id } = value;
        updateSelfParams({ ...selfParams, [name]: id });
    };

    const onDatesChanged = (period) => {
        updateSelfParams({
            ...selfParams,
            period,
        });
    };

    const barClick = (data, index) => {
        setActiveIndex(index);
    };

    const activeItem = data[activeIndex];
    const fetching = false;

    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className="container">
                <div className={cn(styles.maxTable, 'page-inner')}>
                    {SMALL_SCREEN && <h1>Tariffs list</h1>}
                    <div className={cn('page-header')}>
                        {/* <TariffsFilter onChange={filtersChange} dict={dict} />*/}
                    </div>
                    <div className="col-md-12">
                        <div className={styles.filterBarItem}>
                            <div className={cn(styles.fixedDropWidth)}>
                                <div className={styles.dropTitle}>WL:</div>
                                <DropDown
                                    options={wl}
                                    onSelect={ddSelect}
                                    name="wlId">
                                    {findTitleById(wl, selfParams.wlId)}&nbsp;
                                </DropDown>
                            </div>
                            <div className={cn(styles.fixedDropWidth)}>
                                <div className={styles.dropTitle}>Branch:</div>
                                <DropDown
                                    options={branchesList}
                                    onSelect={ddSelect}
                                    name="branchId">
                                    {findTitleById(branchesList, selfParams.branchId)}&nbsp;
                                </DropDown>
                            </div>
                            <div className={cn(styles.fixedDropWidth)}>
                                <div className={styles.dropTitle}>Merchant:</div>
                                <DropDown
                                    options={merchantsList}
                                    onSelect={ddSelect}
                                    name="merchantId">
                                    {findTitleById(merchantsList, selfParams.merchantId)}&nbsp;
                                </DropDown>
                            </div>
                            <div className={cn(styles.fixedDropWidth)}>
                                <div className={styles.dropTitle}>Currency:</div>
                                <DropDown
                                    options={currencies}
                                    onSelect={ddSelect}
                                    name="currencyId">
                                    {findTitleById(currencies, selfParams.currencyId)}&nbsp;
                                </DropDown>
                            </div>
                            <div className={cn(styles.fixedDropWidth)}>
                                <div className={styles.dropTitle}>Period:</div>
                                <DateTimeRangePicker
                                    value={selfParams.period}
                                    onChange={onDatesChanged}
                                    disableClock={true}
                                    format="dd/MM/yy HH:mm:ss"
                                    showLeadingZeros={true}
                                    calendarIcon={null}
                                    clearIcon={null}
                                    locale="en-US"
                                />
                            </div>
                        </div>

                        <div className={cn(styles.card, 'card')}>
                            <section className={styles.section}>
                                <h2>Profit chart</h2>
                                <div className={styles.chartPlace}>

                                    <div className={styles.container}>
                                        <BarChart width={600} height={300} data={data}>
                                            <CartesianGrid stroke="#e2e2e2" strokeDasharray="3 3" />
                                            <Tooltip />

                                            <Bar dataKey="profit" onClick={barClick}>
                                                {data.map((entry, index) => (
                                                    <Cell cursor="pointer"
                                                          fill={index === activeIndex ? '#82ca9d' : '#8884d8'}
                                                          key={`cell-${index}`} />
                                                ))}
                                            </Bar>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                        </BarChart>
                                        <p className="content">{`Profit: of "${activeItem.name}": ${activeItem.profit}`}</p>
                                    </div>

                                    <NumberBlock
                                        icon="fa-chart-pie"
                                        color="orange"
                                        name="Total Balance"
                                        currency={findTitleById(currencies, selfParams.currencyId)}
                                        amount="120 123.33"
                                    />
                                </div>
                            </section>

                            <section className={styles.section}>
                                <div className={styles.sideBlocks}>
                                    <h2>Deposit/withdrawal chart</h2>
                                    <div className={cn(styles.fixedDropWidth)}>
                                        <div className={styles.dropTitle}>Payment method:</div>
                                        <DropDown
                                            options={gatesList}
                                            onSelect={ddSelect}
                                            name="methodId">
                                            {findTitleById(gatesList, selfParams.methodId)}&nbsp;
                                        </DropDown>
                                    </div>
                                </div>
                                <div className={styles.blocks}>
                                    <NumberBlock
                                        icon="fa-sign-in-alt"
                                        color="green"
                                        name="Total deposit"
                                        currency={findTitleById(currencies, selfParams.currencyId)}
                                        amount="120 000.00"
                                    />

                                    <NumberBlock
                                        icon="fa-sign-out-alt"
                                        color="red"
                                        name="Total withdrawal"
                                        currency={findTitleById(currencies, selfParams.currencyId)}
                                        amount="20 000.00"
                                    />
                                    <NumberBlock
                                        // icon="fa-level-up-alt"
                                        icon="fa-code"
                                        color="blue"
                                        name="Margin"
                                        currency={findTitleById(currencies, selfParams.currencyId)}
                                        amount="100 000.00"
                                    />
                                </div>
                                <div className={styles.sideBlocks}>
                                    <div className={styles.container}>
                                        <LineChart width={620} height={320} data={data}>
                                            <Line type="monotone" dataKey="deposit" stroke="#28a745" strokeWidth={2} />
                                            <Line type="monotone" dataKey="withdrawal" stroke="#dc3545"
                                                  strokeWidth={2} />
                                            {/*<Line type="monotone" dataKey="profit" stroke="#1572E8" strokeWidth={2} />*/}
                                            <CartesianGrid stroke="#e2e2e2" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                        </LineChart>
                                        <div className={styles.bottomBlocks}>
                                            <NumberBlock
                                                icon="fa-external-link-square-alt"
                                                color="green"
                                                name="Success rate"
                                                currency={findTitleById(currencies, selfParams.currencyId)}
                                                amount="10 000.00"
                                            />

                                            <NumberBlock
                                                icon="fa-money-check-alt"
                                                color="orange"
                                                name="Commissions paid"
                                                currency={findTitleById(currencies, selfParams.currencyId)}
                                                amount="300.00"
                                            />
                                        </div>

                                    </div>
                                    <div className={styles.sideColumn}>
                                        <SquareBlock
                                            positive="6%"
                                            value="43"
                                            text="number of deposit"
                                        />
                                        <SquareBlock
                                            negative="-2%"
                                            value="17"
                                            text="number of withdrawal"
                                        />
                                        <SquareBlock
                                            positive="3%"
                                            value="27.3"
                                            text="average deposit"
                                        />
                                        <SquareBlock
                                            negative="-2%"
                                            value="17"
                                            text="average withdrawal"
                                        />
                                        <SquareBlock
                                            positive="6%"
                                            value="27.3"
                                            text="highest deposit"
                                        />
                                        <SquareBlock
                                            negative="-1%"
                                            value="27.3"
                                            text="max withdrawal"
                                        />
                                    </div>

                                </div>

                            </section>

                            <section className={styles.section}>
                                <h2>Daily statistics</h2>

                                <div className="table-responsive">
                                    {fetching ? (
                                        <div className={styles.center}>
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <table className="display table table-striped table-hover dataTable">
                                            <TableHeader rowNames={rows} hasCollapseSwich={false} />
                                            <TableBody
                                                dataTable={dayStat}
                                                rowNames={rows}
                                                hasCollapseSwich={false}
                                            />
                                        </table>
                                    )}
                                </div>
                            </section>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
};
