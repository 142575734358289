import axios from 'axios';
import { API_URL, PAYMENTS___URL, PAYMENTS_URL } from "../../utils/consts/global";
import { getBusinessError } from '../helpers/commonHelpers';

export function makePayment(payload, token) {
    // const headers = getHeaders(token);
    return axios({
        method: 'post',
        url: `${API_URL}${PAYMENTS_URL}`,
        data: payload,
        // headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function paymentsService(payload, token) {
    // const headers = getHeaders(token);
    return axios({
        method: 'post',
        url: `${API_URL}${PAYMENTS___URL}`,
        data: payload,
        // headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}
