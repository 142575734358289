import { call, put, takeEvery } from "redux-saga/effects";
import { errorNotify } from "../helpers/commonHelpers";
import { onHandleErrors } from "../errors/errors.actions";
import { Metric } from "./metric.consts";
import {
    onGetDashboardDataError,
    onGetDashboardDataSuccess,
    onGetPaymentSumError,
    onGetPaymentSumSuccess,
    onGetTotalsError,
    onGetTotalsSuccess,
} from './metric.actions';
import { metricService } from './metric.services';

export function* sagaGetDashboardDataFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetDashboard",
            // setter: true
        };
        const { response, error } = yield call(metricService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetDashboardDataError(error));
        } else if (response) {
            yield put(onGetDashboardDataSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onGetDashboardDataError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetDashboardDataError(error));
    }
}

export function* sagaGetPaymentSumFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetPaymentSum",
            // setter: true
        };
        const { response, error } = yield call(metricService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetPaymentSumError(error));
        } else if (response) {
            yield put(onGetPaymentSumSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onGetPaymentSumError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetPaymentSumError(error));
    }
}

export function* sagaGetTotalsFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            filterClients: [],
            method: "GetPaymentSum",
        };
        const { response, error } = yield call(metricService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetTotalsError(error));
        } else if (response) {
            yield put(onGetTotalsSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onGetTotalsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetTotalsError(error));
    }
}

export function* metricsSaga() {
    yield takeEvery(Metric.GET_DASHBOARD_DATA_FETCH, sagaGetDashboardDataFetch);
    yield takeEvery(Metric.GET_PAYMENT_SUM_FETCH, sagaGetPaymentSumFetch);
    yield takeEvery(Metric.GET_TOTALS_FETCH, sagaGetTotalsFetch);
}
