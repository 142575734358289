import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useOutsideAlerter } from '../../../utils/customHooks';
import styles from './DropDown.less';

export const DropDown = (props) => {
    const dropDownRef = useRef(null);
    const [isMenuShown, switchMenu] = useState(false);
    const [selectedItem, changeSelectedItem] = useState(props.children);
    const [options, setOptions] = useState(props.options || []);
    const [inputVal, setInputVal] = useState('');
    const resetDropDown = () => {
        changeSelectedItem(null);
    };

    useEffect(() => {
        resetDropDown();
    }, [props.reset]);

    if (JSON.stringify(props.options) !== JSON.stringify(options)) {
        setOptions(props.options);
        resetDropDown();
    }

    const toggleUserMenu = () => {
        setInputVal('');
        if (!props.disabled) {
            switchMenu(!isMenuShown);
        }
    };

    const closeMenu = () => {
        switchMenu(false);
    };

    const onSelectItem = (item) => {
        const { name, line, onSelect } = props;

        setInputVal(item.title);
        changeSelectedItem(item);

        closeMenu();

        if (line) {
            onSelect({ item, name, line });
        } else {
            if (name) {
                onSelect(item, name);
            } else {
                onSelect(item);
            }
        }

    };

    const setFilter = (event) => {
        event.persist();
        const value = event.target.value;
        setInputVal(value);
    };

    useOutsideAlerter(dropDownRef, closeMenu);

    let blue = props.color !== 'white';

    const searchVal = inputVal?.toLocaleLowerCase();
    const filteredOptions = options.filter(el => el?.title?.toLocaleLowerCase()?.includes(searchVal));
    const showOptions = filteredOptions?.length ? filteredOptions : options;

    return (
        <div className={cn(styles.dropWrapper, props.className, 'input-group) input-group-append show')}>
            {!props.allowFilter && (<button
                className={cn(
                    styles.dropDownDefaults,
                    [props.buttonStyles],
                    {
                        [styles.blue]: blue,
                        [styles.grey]: props.color === 'grey',
                        [styles.white]: !blue,
                        [styles.disabled]: props.disabled,
                    },
                    'btn btn-border',
                )}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={toggleUserMenu}
            >
                {selectedItem?.title || props.value?.title || props.children}
            </button>)}

            {props.allowFilter && (
                <input
                    type="text"
                    className={cn(
                        styles.dropDownDefaults,
                        styles.inputSearch,
                        [props.buttonStyles],
                        {
                            [styles.blue]: blue,
                            [styles.grey]: props.color === 'grey',
                            [styles.white]: !blue,
                            [styles.disabled]: props.disabled,
                        },
                        'btn btn-border',
                    )}
                    value={inputVal}
                    onChange={setFilter}
                    onClick={toggleUserMenu}
                />)}

            <div ref={dropDownRef}
                 className={cn(styles.dropDownList, 'dropdown-menu animated fadeIn ', { ['show']: isMenuShown }, props.dropListStyle)}>
                {showOptions.map((el, idx) => {
                    return (
                        <span
                            key={String(idx)}
                            onClick={onSelectItem.bind(this, el)}
                            className={cn(styles.dropDownItem, { [el.style]: el.style })}
                        >
                            {el.title}
                            {/*
                            <div role="separator" className="dropdown-divider">
                                {' '}
                            </div>
                            */}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};
