import base64 from "base-64";
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    onAuthEjectUser,
    onLoginError,
    onLoginSuccess,
    onLogoutFetch,
    onPermissionsClear,
    onPermissionsError,
    onPermissionsFetch,
    onPermissionsSuccess,
} from './auth.actions';
import { fetchToken, getPermissions, removeHeaderToken, setHeaderToken } from './auth.service';
import { showNotify } from '../app/app.actions';
import { AuthActions, TOKEN } from './auth.consts';
import RoutesPaths from '../../routes/RoutesPaths';
import { errorNotify } from '../helpers/commonHelpers';
import { onHandleErrors } from '../errors/errors.actions';
import { onBalancesClear, onSummaryClear, onTransactionsClear, onTransactionsError } from '../transactions/transactions.actions';
import { getLKPermissionsFilter } from './auth.helper';

export function* loginFetchSaga(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(fetchToken, {
            ...payload,
            method: 'GetToken',
        });
        if (error) {
            yield put(onHandleErrors(error));
            yield put(onLoginError(error));
        } else if (response) {
            yield put(onLoginSuccess(response));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onLoginError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onLoginError(error));
    }
}

export function* loginSuccessSaga(action) {
    const authToken = action.payload.data.data;

    try {
        // let tkn = JSON.parse(Base64.decode(authToken).split('}')[1] + '}');
        const parsedToken = JSON.parse(base64.decode(authToken.split('.')[1]));
        const { ClientID, Login, wl, UserType, UserID, RefID, ParentRefType, ParentRefID, RRT } = parsedToken;
        yield call([sessionStorage, 'setItem'], 'UserType', UserType);
        yield call([sessionStorage, 'setItem'], 'UserID', UserID);
        yield call([sessionStorage, 'setItem'], 'clientId', ClientID);
        yield call([sessionStorage, 'setItem'], 'ParentRefType', ParentRefType);
        yield call([sessionStorage, 'setItem'], 'ParentRefID', ParentRefID);
        yield call([sessionStorage, 'setItem'], 'fullClientId', RefID);
        yield call([sessionStorage, 'setItem'], 'login', Login);
        yield call([sessionStorage, 'setItem'], 'wl', wl);
        yield call([sessionStorage, 'setItem'], 'RRT', JSON.stringify(RRT));
    } catch (e) {
        console.error(e);
    }

    yield call([sessionStorage, 'setItem'], TOKEN, authToken);
    yield call(setHeaderToken, authToken);
    const currentRoute = yield select((state) => state.router.location.pathname);
    yield put(onPermissionsFetch());

    if (currentRoute === RoutesPaths.LOGIN) {
        yield put(push(RoutesPaths.HOME));
    }
}

export function* logoutFetchSaga(action) {
    const { login } = yield select((state) => state.auth.getToken);
    yield put(onAuthEjectUser({ error: null }));

    if (login) {
        const { response, error } = yield call(fetchToken, { method: 'Logout', login });

        if (response?.data.dataType === 'error') {
            const error = response.data.data;
            yield put(
                showNotify({
                    type: 'error',
                    text: `Error. ${error.errorCode}, ${error.errorMessage}`,
                }),
            );
        }

        if (error) {
            yield call(errorNotify, error);
        }
    }

    yield put(onLogoutFetch({}));
}

export function* ejectUser(action) {
    yield put(onPermissionsClear(null));
    yield put(onTransactionsClear(null));
    yield put(onBalancesClear(null));
    yield put(onSummaryClear(null));

    yield call([sessionStorage, 'removeItem'], TOKEN);
    yield call([sessionStorage, 'removeItem'], 'clientId');
    yield call([sessionStorage, 'removeItem'], 'fullClientId');
    yield call([sessionStorage, 'removeItem'], 'login');
    yield call([sessionStorage, 'removeItem'], 'ParentRefID');
    yield call([sessionStorage, 'removeItem'], 'ParentRefType');
    yield call([sessionStorage, 'removeItem'], 'agent');
    yield call([sessionStorage, 'removeItem'], 'UserType');
    yield call([sessionStorage, 'removeItem'], 'wl');
    yield call([sessionStorage, 'removeItem'], 'UserID');

    yield call(removeHeaderToken);
    yield put(push(RoutesPaths.LOGIN));
}

export function* permissionsFetchSaga() {
    try {
        const { response, error } = yield call(getPermissions, {
            method: 'GetAllowedModules',
        });
        if (error) {
            yield put(onHandleErrors(error));
            yield put(onPermissionsError(error));
        } else if (response) {
            yield put(onPermissionsSuccess(getLKPermissionsFilter(response.data.data)));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onPermissionsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onPermissionsError(error));
    }
}

export function* authSaga() {
    yield takeEvery(AuthActions.AUTH_LOGIN_SUCCESS, loginSuccessSaga);
    yield takeEvery(AuthActions.AUTH_LOGIN_FETCH, loginFetchSaga);
    yield takeEvery(AuthActions.AUTH_LOGOUT_START, logoutFetchSaga);
    yield takeEvery(AuthActions.AUTH_EJECT_USER, ejectUser);
    yield takeEvery(AuthActions.AUTH_PERMISSIONS_FETCH, permissionsFetchSaga);
    yield takeEvery(AuthActions.AUTH_UPDATE_TOKEN, permissionsFetchSaga);
}
