import axios from 'axios';
import { API_AUTH_URL, API_MODULES_URL, API_URL } from '../../utils/consts/global';
import { AUTH_KEY } from './auth.consts';
import { getBusinessError } from '../helpers/commonHelpers';

export function fetchToken(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };

    return axios({
        method: 'post',
        url: `${API_URL}${API_AUTH_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function getPermissions(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };

    return axios({
        method: 'post',
        url: `${API_URL}${API_MODULES_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export const isLoggedBefore = () => sessionStorage.getItem(AUTH_KEY) === 'true';

export const setHeaderToken = (token) => {
    axios.defaults.headers.common['Authorization'] = token;
};

export const removeHeaderToken = () => {
    delete axios.defaults.headers.common['Authorization'];
};
