import React from 'react';
import cn from 'classnames';
import styles from './SmsPanel.less';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import { TableBody } from '../TableBody/TableBody';
import {
    formatIncomeDate, getRows,
    rowsActive,
    rowsUndelivered,
    summaryForTable,
} from './SmsHelper';
import { TableHeader } from '../TableHeader/TableHeader';
import { sortingClass } from '../../../utils/helpers/commonFunctions';
import { onSmsReportSummaryFetch, onSmsReportUndeliveredFetch } from '../../../store/sms/sms.actions';
import Datetime from 'react-datetime';
import { Paginator } from '../Paginator/Paginator';
import { getCsvFile } from '../../../store/sms/sms.services';

export class SmsPanelComponent extends React.Component {
    state = {
        filterParams: {},
        sortParams: {
            sortType: sortingClass.asc,
            sortingByColumnName: { name: '' },
        },
        rejectedFilter: {
            dateFrom: moment().startOf('day'),
            dateTo: moment().endOf('day'),
            intCurrentPage: 1,
            intItemsPerPage: 15,
        },
    };

    componentDidMount() {
        const { rejectedFilter } = this.state;
        // this.props.onSmsReportFetch({ strPeriod: 'week' });
        this.props.onSmsReportSummaryFetch({});
        this.props.onSmsReportUndeliveredFetch({
            ...rejectedFilter,
            dateFrom: moment(rejectedFilter.dateFrom).format(),
            dateTo: moment(rejectedFilter.dateTo).format(),
        });
    }

    changePage = (intCurrentPage) => {
        const { rejectedFilter } = this.state;
        const { selected } = intCurrentPage;

        this.setState((prev) => ({
            ...prev,
            rejectedFilter: {
                ...rejectedFilter,
                intCurrentPage: selected + 1,
            },
        }), () => {
            this.props.onSmsReportUndeliveredFetch(this.state.rejectedFilter);
        });
    };

    onDateChanges = (dateFrom, dateTo) => {
        const dateFilter = dateFrom ? { dateFrom } : { dateTo };
        const rejectedFilter = {
            ...this.state.rejectedFilter,
        };

        rejectedFilter.dateFrom = moment(rejectedFilter.dateFrom).format();
        rejectedFilter.dateTo = moment(rejectedFilter.dateTo).format();

        this.setState((prev) => ({
            ...prev,
            rejectedFilter: {
                ...rejectedFilter,
                ...dateFilter,
            },
        }), () => {
            this.props.onSmsReportUndeliveredFetch(this.state.rejectedFilter);
        });
    };

    onFromDateChanges = (date) => {
        this.onDateChanges(moment(date).startOf('day').format(), null);
    };

    onToDateChanges = (date) => {
        this.onDateChanges(null, moment(date).endOf('day').format());
    };

    onDownloadCsv = () => {
        const params = {
            ...this.state.rejectedFilter,
            method: 'GetSmsReportV2Undelivered',
            format: 'csv',
        };

        getCsvFile(params);
    };

    render() {
        const { rejectedFilter } = this.state;
        const { dateFrom, dateTo } = rejectedFilter;
        const { getSmsReportSummary } = this.props;
        const {statRows, statData} = getRows(getSmsReportSummary.success?.data?.lastDaysStat);

        const {
            rejected
        } = this.props.getSmsReportUndelivered.success?.data.totals || {};
        const dataSummary = summaryForTable(this.props.getSmsReportSummary.success?.data.activePackages || []);
        const dataUndelivered = formatIncomeDate(this.props.getSmsReportUndelivered.success?.data.messages || []);
        const paginator = this.props.getSmsReportUndelivered.success?.data.paginator;

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        <div className="card">
                            <div className="card-body">
                                <div className={cn('col-md-12', styles.mainPadding)}>

                                    <div className={styles.padBottom}>
                                        <h4 className={styles.bolder}>Active packages</h4>
                                        {this.props.onSmsReportSummaryFetch.fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={rowsActive} hasCollapseSwich={false} />
                                                <TableBody
                                                    dataTable={dataSummary}
                                                    rowNames={rowsActive}
                                                    hasCollapseSwich={false}
                                                />
                                            </table>
                                        )}
                                    </div>

                                    <div className={styles.padBottom}>
                                        <h4 className={styles.bolder}>Last 7 days stat</h4>
                                        <div className="table-responsive">
                                            {getSmsReportSummary.fetching ? (
                                                <div className={styles.center}>
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <table className="display table table-striped table-hover dataTable">
                                                    <TableHeader
                                                        rowNames={statRows}
                                                        hasCollapseSwich={false}
                                                    />
                                                    <TableBody
                                                        rowNames={statRows}
                                                        dataTable={statData}
                                                        hasCollapseSwich={false}
                                                    />
                                                </table>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles.padBottom}>
                                        <h4 className={styles.bolder}>SMS Rejected</h4>
                                        <div className={styles.smallPadd}>

                                            <div className={styles.calendar}>
                                                <div className={styles.caption}>
                                                    Date from:
                                                </div>
                                                <div className={styles.calendarWrap}>
                                                    <Datetime
                                                        className={styles.calendar}
                                                        value={dateFrom}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat={false}
                                                        onChange={this.onFromDateChanges}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.calendar}>
                                                <div className={styles.caption}>
                                                    Date to:
                                                </div>
                                                <div className={styles.calendarWrap}>
                                                    <Datetime
                                                        className={styles.calendar}
                                                        value={dateTo}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat={false}
                                                        onChange={this.onToDateChanges}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.smsInfoline}>
                                            <div className={styles.stats}>
                                                <span>Total rejected: {rejected}</span>
                                            </div>
                                            {/*<span className={styles.likeLink} onClick={this.onDownloadCsv}>Download in csv</span>*/}
                                        </div>

                                        <div className="table-responsive">
                                            {this.props.getSmsReportUndelivered.fetching ? (
                                                <div className={styles.center}>
                                                    <Spinner />
                                                </div>
                                            ) : (<>
                                                <table className="display table table-striped table-hover dataTable">
                                                    <TableHeader
                                                        rowNames={rowsUndelivered}
                                                        hasCollapseSwich={false}
                                                    />
                                                    <TableBody
                                                        rowNames={rowsUndelivered}
                                                        dataTable={dataUndelivered}
                                                        hasCollapseSwich={false}
                                                    />
                                                </table>
                                                <div className={styles.paginatorPlace}>
                                                    <Paginator pagesData={paginator}
                                                               changePage={this.changePage} />
                                                </div>
                                            </>)}
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onSmsReportSummaryFetch,
    onSmsReportUndeliveredFetch,
};

const mapStateToProps = (state) => ({
    getSmsReportSummary: state.sms.getSmsReportSummary,
    getSmsReportUndelivered: state.sms.getSmsReportUndelivered,
});

export const SmsPanel = connect(mapStateToProps, mapDispatchToProps)(SmsPanelComponent);
