import React from 'react';
import styles from './Notification.less';
import { connect } from 'react-redux';
import { hideNotify, showNotify } from '../../../store/app/app.actions';
import { Message } from './Message/Message';

/*
MSG FORMAT:
{
    text: string
    type: enum: "success"|"error"|"msg"
}
*/

export class NotificationComponent extends React.Component {
    state = {
        hideTimer: 1000,
    };

    hideNotify = (msg) => {
        this.props.hideNotify(msg);
    };

    render() {
        const {
            app: { messages },
        } = this.props;
        return (
            <div className={styles.messages}>
                {!!messages.length &&
                    messages.map((message, idx) => (
                        <Message
                            key={String(idx)}
                            hideNotify={() => {
                                this.hideNotify(message);
                            }}
                            text={message.text}
                            type={message.type}
                        />
                    ))}
            </div>
        );
    }
}

const mapDispatchToProps = {
    showNotify,
    hideNotify,
};

const mapStateToProps = (state) => ({
    app: state.app,
});

export const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
