import RoutesPaths from '../../../routes/RoutesPaths';

export const sectionNames = [
    { path: RoutesPaths.SMS, title: 'SMS Stat' },
    { path: RoutesPaths.SMS_UPLOAD, title: 'SMS bulk file upload' },
    { path: RoutesPaths.HOME, title: 'Welcome' },
    { path: RoutesPaths.PAYMENTS, title: 'Payments' },
    { path: RoutesPaths.BALANCES, title: 'Balances' },
    { path: RoutesPaths.SUMMARY, title: 'Summary' },
    { path: RoutesPaths.ACCOUNT_LOG, title: 'Account log' },
    { path: RoutesPaths.CREATE_PAYMENT, title: 'Create payment' },
    { path: RoutesPaths.ADM_ICONS, title: 'POS logos management' },
    { path: RoutesPaths.MERCHANTS_LIST, title: 'Merchants' },
    { path: RoutesPaths.AGENTS, title: 'Agents' },
    { path: RoutesPaths.ADM_GROUP_ROLES, title: 'Roles and group management' },
    { path: RoutesPaths.ADM_USERS, title: 'Users management' },
    { path: RoutesPaths.ACCOUNTS, title: 'Accounts list' },
    { path: RoutesPaths.ADM_CASHIERS, title: 'Cashiers' },
    { path: RoutesPaths.TARIFFS, title: 'Tariffs' },
    { path: RoutesPaths.DASHBOARD, title: 'Dashboard' },
];

export const getSubClients = (id, data) => {
    const subClients = data.filter((el) => el.client === id);
    if (subClients.length) {
        const emptySub = [{ id: '', title: '-' }];
        const dropDownList =
            subClients.map((el) => {
                return {
                    id: el.id,
                    title: el.name,
                };
            }) || [];
        return [...emptySub, ...dropDownList];
    } else {
        return [];
    }
};
