import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { CreateMerchantPanel } from "../../components/shared/Merchant/CreateMerchantPanel/CreateMerchantPanel";

const CreateMerchant = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();
    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="CreateMerchant" />
            <CreateMerchantPanel history={props.history} />
        </div>
    );
};

export default CreateMerchant;
