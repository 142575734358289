import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import RoutesPaths from './RoutesPaths';
import { AuthorizedRoute } from '../components/AuthorizedRoute/AuthorizedRoute';
import { TopNavBar } from '../components/shared/TopNavBar/TopNavBar';
import { Spinner } from '../components/shared/Spinner/Spinner';
import Home from '../pages/Home/Home';
import Summary from '../pages/Summary/Summary';
import Balances from '../pages/Balances/Balances';
import CreatePayment from '../pages/CreatePayment/CreatePayment';
import AdmBrands from '../pages/AdmBrands/AdmBrands';
import AdmRolesGroups from '../pages/AdmRolesGroups/AdmRolesGroups';
import AdmUsers from '../pages/AdmUsers/AdmUsers';
import Agents from '../pages/Agents/Agents';
import Sms from '../pages/Sms/Sms';
import SmsUpload from "../pages/SmsUpload/SmsUpload";
import AccountLog from "../pages/AccountLog/AccountLog";
import Accounts from "../pages/Accounts/Accounts";
import DepositMerchant from "../pages/DepositMerchant/DepositMerchant";
import DepositAgent from "../pages/DepositAgent/DepositAgent";
import SettlementAgent from "../pages/SettlementAgent/SettlementAgent";
import SettlementMerchant from "../pages/SettlementMerchant/SettlementMerchant";
import AdmCashiers from "../pages/AdmCashiers/AdmCashiers";
import Merchant from "../pages/Merchant/Merchant";
import OperationsWrapper from "../pages/OperationsWrapper/OperationsWrapper";
import AgentInfo from '../pages/AgentInfo/AgentInfo';
import CreateMerchant from "../pages/CreateMerchant/CreateMerchant";
import MerchantAdd from '../pages/MerchantAdd/Merchant';
import MerchantsListPage from '../pages/MerchantsList/MerchantsList';
import RolesTablePage from '../pages/RolesTable/RolesTable';
// import Tariffs from '../pages/Tariffs/Tariffs';
import AgentAdd from '../pages/AgentAdd/AgentAdd';
import Agent from '../pages/Agent/Agent';
import AgentCreateNew from '../pages/AgentCreateNew/AgentCreateNew';
import Reports from '../pages/Reports/Reports';
import Dashboard from '../pages/Dashboard/Dashboard';

// const Balances = lazy(() => import('../pages/Balances/Balances'));
// const Users = lazy(() => import('../pages/Users/Users'));
// const NotFound = lazy(() => import('../pages/NotFound/NotFound'));

export const AppRoutes = () => {
    return (
        <>
            <TopNavBar />
            <Switch>
                <Suspense fallback={<Spinner />}>
                    <AuthorizedRoute path={RoutesPaths.HOME} exact={true} component={Home} />
                    <AuthorizedRoute path={RoutesPaths.PAYMENTS} exact={true} component={OperationsWrapper} />
                    <AuthorizedRoute path={RoutesPaths.BALANCES} exact={true} component={Balances} />
                    <AuthorizedRoute path={RoutesPaths.CREATE_PAYMENT} exact={true} component={CreatePayment} />
                    <AuthorizedRoute path={RoutesPaths.SUMMARY} exact={true} component={Summary} />
                    <AuthorizedRoute path={RoutesPaths.ACCOUNT_LOG} exact={true} component={AccountLog} />
                    <AuthorizedRoute path={RoutesPaths.ACCOUNTS} exact={true} component={Accounts} />
                    <AuthorizedRoute path={RoutesPaths.ADM_USERS} exact={true} component={AdmUsers} />
                    <AuthorizedRoute path={RoutesPaths.ADM_CASHIERS} exact={true} component={AdmCashiers} />
                    <AuthorizedRoute path={RoutesPaths.ADM_ICONS} exact={true} component={AdmBrands} />
                    <AuthorizedRoute path={RoutesPaths.ADM_GROUP_ROLES} exact={true} component={AdmRolesGroups} />
                    <AuthorizedRoute path={RoutesPaths.ROLES_TABLE} exact={true} component={RolesTablePage} />
                    <AuthorizedRoute path={RoutesPaths.MERCHANTS_LIST} exact={true} component={MerchantsListPage} />
                    <AuthorizedRoute path={RoutesPaths.MERCHANT} exact={true} component={Merchant} />
                    <AuthorizedRoute path={RoutesPaths.MERCHANT_ADD} exact={true} component={MerchantAdd} />
                    <AuthorizedRoute path={RoutesPaths.CREATE_MERCHANT} exact={true} component={CreateMerchant} />
                    <AuthorizedRoute path={RoutesPaths.SMS} exact={true} component={Sms} />
                    <AuthorizedRoute path={RoutesPaths.SMS_UPLOAD} exact={true} component={SmsUpload} />
                    <AuthorizedRoute path={RoutesPaths.DEPOSIT_MERCHANT} exact={true} component={DepositMerchant} />
                    <AuthorizedRoute path={RoutesPaths.DEPOSIT_AGENT} exact={true} component={DepositAgent} />
                    <AuthorizedRoute path={RoutesPaths.SETTLEMENT_MERCHANT} exact={true} component={SettlementMerchant} />
                    <AuthorizedRoute path={RoutesPaths.SETTLEMENT_AGENT} exact={true} component={SettlementAgent} />
                    <AuthorizedRoute path={RoutesPaths.AGENTS} exact={true} component={Agents} />
                    <AuthorizedRoute path={RoutesPaths.AGENT} exact={true} component={Agent} />
                    <AuthorizedRoute path={RoutesPaths.AGENT_CREATE} exact={true} component={AgentCreateNew} />
                    <AuthorizedRoute path={RoutesPaths.AGENT_ADD} exact={true} component={AgentAdd} />
                    <AuthorizedRoute path={RoutesPaths.AGENT_INFO_EDIT} exact={true} component={AgentInfo} />
                    {/*<AuthorizedRoute path={RoutesPaths.TARIFFS} exact={true} component={Tariffs} />*/}
                    <AuthorizedRoute path={RoutesPaths.REPORTS} exact={true} component={Reports} />
                    <AuthorizedRoute path={RoutesPaths.DASHBOARD} exact={true} component={Dashboard} />
                    {/*<AuthorizedRoute path={RoutesPaths.USERS} exact={true} component={Users} />*/}
                </Suspense>
            </Switch>
        </>
    );
};
