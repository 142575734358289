import React, { useState, useEffect } from 'react';
import { TableHeader } from '../TableHeader/TableHeader';
import { rows } from '../AdmRolesGroupPanel/AdmRolesGroupPanelHelper';
import { TableBody } from '../TableBody/TableBody';
import { getRulesArray, rowsAndDeleteKey } from './GroupsTable.helper';
import cn from 'classnames';
import styles from './GroupsTable.less';
import { ActionButton } from '../ActionButton/ActionButton';
import { Modal } from '../Modal/Modal';

export const GroupsTable = (props) => {
    const [selectedGroup, selectGroup] = useState({ code: '', id: '', title: '' });
    const [showRolesList, toggleRolesList] = useState(false);
    const [selectedRoleId, selectRole] = useState(null);

    const click = () => {
        if (props.click) {
            props.click();
        }
    };

    const selected = (income) => {
        selectGroup(income);
    };

    const deletePress = (role) => {
        const { groupRoles } = props;
        const id = groupRoles.filter((el) => el.groupsID === selectedGroup.id).filter((el) => el.roleID === role.item.id)[0].id;

        if (props.removeRoleAction) {
            props.removeRoleAction(id);
        }
    };

    const addRole = () => {
        toggleRolesList(true);
    };

    const addToGroup = () => {
        const intRoleID = props.roles.filter((el) => el.id === selectedRoleId)[0].id;

        if (props.addRoleAction) {
            props.addRoleAction({
                intGroupID: selectedGroup.id,
                intRoleID,
            });
        }

        toggleRolesList(false);
    };

    const onChangeSelectRole = (event) => {
        const { target } = event;
        let { name, value } = target;
        event.persist();
        selectRole(value.split(':')[0]);
    };

    const rulesId = getRulesArray(props.groupRoles, selectedGroup.id) || [];
    const roles = props.roles.filter((el) => rulesId.indexOf(el.id) !== -1);

    return (
        <div>
            <table className="display table table-striped table-hover dataTable">
                <TableHeader rowNames={rows} hasCollapseSwich={false} />
                <TableBody
                    dataTable={props.groups}
                    rowNames={props.rows}
                    hasCollapseSwich={false}
                    events={props.onDataLineEvents}
                    onSelect={selected}
                />
            </table>
            {selectedGroup.title && (
                <div className={styles.rulesPlace}>
                    <div className={styles.topBar}>
                        <div>
                            <h2 className={styles.bolder}>{selectedGroup.title} roles:</h2>
                        </div>

                        <ActionButton click={addRole}>add role</ActionButton>
                    </div>
                    <table className="display table table-striped table-hover dataTable">
                        <TableHeader rowNames={rowsAndDeleteKey} hasCollapseSwich={false} />
                        <TableBody dataTable={roles} rowNames={rowsAndDeleteKey} hasCollapseSwich={false} events={deletePress} />
                    </table>
                </div>
            )}

            {showRolesList && (
                <Modal
                    faderClick={() => {
                        toggleRolesList(false);
                    }}
                >
                    <div className={cn(styles.popUpPosition, 'col-md-4')}>
                        <div className={cn(styles.card, 'card card-primary bg-primary-gradient')}>
                            <div className="card-body">
                                <h4 className={styles.whiteColor}>Select role</h4>
                                <div className={styles.paddingCont}>
                                    <label htmlFor="exampleFormControlSelect2" className={styles.whiteColor}>
                                        available roles:
                                    </label>
                                    <select
                                        multiple
                                        className={styles.selectGroup}
                                        id="exampleFormControlSelect2"
                                        onChange={onChangeSelectRole}
                                    >
                                        {props.roles.map((el, id) => (
                                            <option key={String(id)}>
                                                {el.id}: {el.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="d-flex justify-content-between pb-4 pt-1">
                                    <button
                                        className={styles.cancelBtn}
                                        onClick={() => {
                                            toggleRolesList(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button className={styles.okBtn} onClick={addToGroup}>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};
