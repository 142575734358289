import React from "react";
import cn from "classnames";
import styles from "./DepositMerchantPanel.less";
import { connect } from "react-redux";
import { getBanksForDropDown } from "./DepositMerchantPanelHelper";
import {
    DEPOSIT_AGENT,
    DEPOSIT_MERCHANT,
    SETTLEMENT_AGENT,
    SETTLEMENT_MERCHANT,
    SMALL_SCREEN
} from "../../../utils/consts/global";
import { DropDown } from "../DropDown/DropDown";

import {
    onCurrenciesFetch,
    onGetBanksFetch,
    onGetCountriesFetch
} from "../../../store/dictionaries/dictionaries.actions";

import {
    onDepositMerchantClear,
    onDepositMerchantFetch, onSettlementMerchantClear,
    onSettlementMerchantFetch
} from "../../../store/paymentOperations/paymentOperations.actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "../Spinner/Spinner";
import { emptyDepositMerchantState } from '../../../utils/helpers/commonHelpers'

export class DepositMerchantPanelComponent extends React.Component {
    state = {
        ...emptyDepositMerchantState
    };

    showConfirm = () => {
        this.setState({isAlertShown: true});
    }

    makeDeposit = () => {
        if (!this.props.operation) return;
        const { account, comment, amount, bank_id } = this.state;

        const query = {
            account, comment, amount, bank_id
        };

        this.props.onDepositMerchantClear();
        this.props.onSettlementMerchantClear();
        switch (this.props.operation) {

            case DEPOSIT_MERCHANT : {
                this.props.onDepositMerchantFetch(query);
                break;
            }

            case SETTLEMENT_MERCHANT : {
                this.props.onSettlementMerchantFetch(query);
                break;
            }
            case DEPOSIT_AGENT : {

                break;
            }

            case SETTLEMENT_AGENT : {
                break;
            }
        }
        this.setState({ isAlertShown: false });

    };

    componentDidMount() {
        this.props.onGetBanksFetch();

        if (!this.props.getCurrencies.success) {
            this.props.onCurrenciesFetch();
        }
    }

    componentDidUpdate(prevProps) {
        // success
        if (JSON.stringify(prevProps.makeDepositMerchant.success) !== JSON.stringify(this.props.makeDepositMerchant.success)
            && this.props.makeDepositMerchant.success
        ) {
            this.setState({ isSuccessShown: true });
        }
        if (JSON.stringify(prevProps.makeSettlementMerchant.success) !== JSON.stringify(this.props.makeSettlementMerchant.success)
            && this.props.makeSettlementMerchant.success
        ) {
            this.setState({ isSuccessShown: true });
        }

        // error
        if (JSON.stringify(prevProps.makeDepositMerchant.errors) !== JSON.stringify(this.props.makeDepositMerchant.errors)
            && this.props.makeDepositMerchant.errors) {
            this.setState({ isErrorShown: true });
        }
        if (JSON.stringify(prevProps.makeSettlementMerchant.errors) !== JSON.stringify(this.props.makeSettlementMerchant.errors)
            && this.props.makeSettlementMerchant.errors) {
            this.setState({ isErrorShown: true });
        }
    }

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    dropDownSelect = (val, name) => {
        this.setState((prev) => ({
            ...prev,
            [name]: val.id
        }));
    };

    formValid = () => {
        let valid = true;
        const { account, comment, amount, bank_id } = this.state;
        if (!account || !comment || !amount || !bank_id) return false;

        return valid;
    };

    onCloseAlert = () => {
        this.setState({ isAlertShown: false });
    };

    clearInputs = () => {
        this.setState(
            {
                ...emptyDepositMerchantState,
                clearDropDown: !this.state.clearDropDown,
            }
        )
    };

    render() {
        const { isAlertShown, isSuccessShown, isErrorShown, account, comment, amount, bank_id,  clearDropDown} = this.state;
        const banksDict = this.props.getBanks.success;
        const banks = getBanksForDropDown(banksDict);
        const { makeDepositMerchant, makeSettlementMerchant } = this.props;
        const errorText = makeDepositMerchant.errors?.errorMessage || makeSettlementMerchant.errors?.errorMessage;
        const fetching = makeDepositMerchant.fetching || makeSettlementMerchant.fetching;

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Accounts</h1>}
                        <div className="page-header">

                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className={cn(styles.cardHeader)}>
                                        {this.props.operation === DEPOSIT_MERCHANT && (
                                            <h1>Bank deposit</h1>)}
                                        {this.props.operation === SETTLEMENT_MERCHANT && (
                                            <h1>Settlement</h1>)}
                                        {this.props.operation === DEPOSIT_AGENT && (<h1>Deposit agent account</h1>)}
                                        {this.props.operation === SETTLEMENT_AGENT && (
                                            <h1>Settlement agent account</h1>)}
                                        <div className={styles.inputElement}>
                                            <span className={styles.label}>*Bank:</span>
                                            <div className={cn(styles.fixedDropWidth, styles.normal)}>
                                                <DropDown options={banks}
                                                          reset = {clearDropDown}
                                                          name="bank_id"
                                                          onSelect={this.dropDownSelect}
                                                >
                                                    Choose Bank
                                                </DropDown>
                                            </div>
                                        </div>
                                        <div className={styles.inputElement}>
                                            <span className={styles.label}>*Account number:</span>
                                            <input type="text"
                                                   className={cn(styles.marginInline, styles.filterInputLong, styles.inputField)}
                                                   id="account"
                                                   name="account"
                                                   value={account}
                                                   placeholder="account number"
                                                   onChange={this.changeInputHandler}
                                            />
                                        </div>
                                        <div className={styles.inputElement}>
                                            <span className={styles.label}>*Amount:</span>
                                            <input type="number"
                                                   className={cn(styles.marginInline, styles.filterInputLong, styles.inputField)}
                                                   id="amount"
                                                   name="amount"
                                                   value={amount}
                                                   placeholder="amount"
                                                   onChange={this.changeInputHandler}
                                            />
                                        </div>
                                        <div className={styles.inputElement}>
                                            <span className={styles.label}>*Comment:</span>
                                            <textarea
                                                className={cn(styles.marginInline, styles.filterInputLong, styles.inputField)}
                                                id="comment"
                                                name="comment"
                                                value={comment}
                                                placeholder="Comment"
                                                onChange={this.changeInputHandler}
                                            />
                                        </div>
                                        <div className={styles.buttonPlace}>
                                            {fetching ? (
                                                <div className={styles.spinnerPlace}>
                                                    <Spinner />
                                                </div>
                                            ):(
                                                <button
                                                    className={cn("btn btn-primary", styles.marginButton)}
                                                    disabled={!this.formValid()}
                                                    onClick={this.showConfirm}>Submit
                                                </button>
                                            )}
                                        </div>
                                        <div className={styles.bottomDescr}>
                                            <p>* - mandatory field</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isErrorShown && (
                    <SweetAlert
                        show={isErrorShown}
                        title="Error!"
                        type="error"
                        text="Transaction unsuccessful"
                        confirmButtonColor="#1572E8"
                        onConfirm={this.clearInputs}
                    ><h4>Transaction unsuccessful: {errorText}</h4></SweetAlert>
                )}
                {isSuccessShown && (
                    <SweetAlert
                        show={isSuccessShown}
                        title="Success!"
                        type="success"
                        text="Transaction successful"
                        confirmButtonColor="#1572E8"
                        onConfirm={this.clearInputs}
                    />
                )}
                {isAlertShown && (
                    <SweetAlert
                        show={isAlertShown}
                        title=""
                        type="info"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.makeDeposit}
                        onCancel={this.onCloseAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>confirmation is required</h1>
                        <div className={styles.ContentString}>
                            <p>Account: <br />
                                <span className={styles.account}>{account}</span>
                            </p>
                            <p>Bank: <br />
                                {banks.filter(el => el.id === bank_id)[0].title || ""}
                            </p>
                            <p>Amount: <br />
                                {amount}
                            </p>
                            <p>Comment: <br />
                                {comment}
                            </p>
                        </div>
                    </SweetAlert>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetBanksFetch,
    onCurrenciesFetch,
    onGetCountriesFetch,
    onDepositMerchantFetch,
    onDepositMerchantClear,
    onSettlementMerchantFetch,
    onSettlementMerchantClear
};

const mapStateToProps = (state) => ({
    makeDepositMerchant: state.payments.makeDepositMerchant,
    makeSettlementMerchant: state.payments.makeSettlementMerchant,
    getBanks: state.dictionaries.getBanks,
    getCountries: state.dictionaries.getCountries,
    getCurrencies: state.dictionaries.getCurrencies
});

export const DepositMerchantPanel = connect(mapStateToProps, mapDispatchToProps)(DepositMerchantPanelComponent);
