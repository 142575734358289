import { PaymentOperations } from './paymentOperations.consts';

/*
 MakePayment C2B
*/
export const onMakePaymentC2BFetch = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_C2B_FETCH,
    payload
});

export const onMakePaymentC2BSuccess = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_C2B_SUCCESS,
    payload
});

export const onMakePaymentC2BError = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_C2B_ERROR,
    payload
});

export const onMakePaymentC2BClear = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_C2B_CLEAR,
    payload
});

/*
 Merchant deposit
*/
export const onDepositMerchantFetch = (payload) => ({
    type: PaymentOperations.MAKE_DEPOSIT_MERCHANT_FETCH,
    payload
});

export const onDepositMerchantSuccess = (payload) => ({
    type: PaymentOperations.MAKE_DEPOSIT_MERCHANT_SUCCESS,
    payload
});

export const onDepositMerchantError = (payload) => ({
    type: PaymentOperations.MAKE_DEPOSIT_MERCHANT_ERROR,
    payload
});

export const onDepositMerchantClear = (payload) => ({
    type: PaymentOperations.MAKE_DEPOSIT_MERCHANT_CLEAR,
    payload
});

/*
/*
 Merchant settlement
*/
export const onSettlementMerchantFetch = (payload) => ({
    type: PaymentOperations.MAKE_SETTLEMENT_MERCHANT_FETCH,
    payload
});

export const onSettlementMerchantSuccess = (payload) => ({
    type: PaymentOperations.MAKE_SETTLEMENT_MERCHANT_SUCCESS,
    payload
});

export const onSettlementMerchantError = (payload) => ({
    type: PaymentOperations.MAKE_SETTLEMENT_MERCHANT_ERROR,
    payload
});

export const onSettlementMerchantClear = (payload) => ({
    type: PaymentOperations.MAKE_SETTLEMENT_MERCHANT_CLEAR,
    payload
});

/*
 MakePayment B2C
*/
export const onMakePaymentB2CFetch = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_B2C_FETCH,
    payload
});

export const onMakePaymentB2CSuccess = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_B2C_SUCCESS,
    payload
});

export const onMakePaymentB2CError = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_B2C_ERROR,
    payload
});

export const onMakePaymentB2CClear = (payload) => ({
    type: PaymentOperations.MAKE_PAYMENT_B2C_CLEAR,
    payload
});

/*
 Get status C2B
*/
export const onGetPaymentC2BStatusFetchProcess = (payload) => ({
    type: PaymentOperations.GET_PAYMENT_STATUS_FETCH_PROCESS,
    payload
});

export const onGetPaymentC2BStatusSuccess = (payload) => ({
    type: PaymentOperations.GET_PAYMENT_STATUS_SUCCESS,
    payload
});

export const onGetPaymentC2BStatusError = (payload) => ({
    type: PaymentOperations.GET_PAYMENT_STATUS_ERROR,
    payload
});

export const onGetPaymentC2BStatusFinish = (payload) => ({
    type: PaymentOperations.GET_PAYMENT_STATUS_FINISH,
    payload
});
