import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { Metric } from "./metric.consts";

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getDashboardData = handleActions(
    {
        [Metric.GET_DASHBOARD_DATA_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Metric.GET_DASHBOARD_DATA_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Metric.GET_DASHBOARD_DATA_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Metric.GET_DASHBOARD_DATA_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getPaymentSum = handleActions(
    {
        [Metric.GET_PAYMENT_SUM_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Metric.GET_PAYMENT_SUM_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Metric.GET_PAYMENT_SUM_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Metric.GET_PAYMENT_SUM_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getTotals = handleActions(
    {
        [Metric.GET_TOTALS_FETCH]: (state) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Metric.GET_TOTALS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Metric.GET_TOTALS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Metric.GET_TOTALS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);


export const metrics = combineReducers({
    getDashboardData,
    getPaymentSum,
    getTotals,
});
