export const rowNames = ['PaymentType', 'Country', 'PaymentMethod', 'MerchantAccount', 'MerchantAccountType', 'Amount', 'Currency'];

export const data = [
    {
        PaymentType: 'Mobile money',
        Country: 'DRC',
        PaymentMethod: 'Orange Money',
        MerchantAccount: '0899539769',
        MerchantAccountType: 'Deposit&Withdrawal',
        Amount: '',
        Currency: 'CDF',
    },
];

export const currencyList = [
    { id: 'CDF', title: 'CDF' },
    { id: 'USD', title: 'USD' },
    { id: 'RUR', title: 'RUB' },
    { id: 'EUR', title: 'Euro' },
];

export const getReadableOperators = (operators) => {
    if (!operators || !operators.length) return [];

    const readable = operators.map((el) => {
        return {
            id: el.id,
            title: el.code,
            currencyList: el.currencies.split(',').map((cur) => ({ id: cur, title: cur })),
        };
    });
    return readable || [];
};
