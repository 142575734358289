import { rowNamesOptions } from "../TableBody/TableBodyHelper";

export const accountsHeaders = [
    { name: "id", title: "id", className: "noWrap", options: "", isSortable: true },
    { name: "account", title: "account", className: "noWrap", options: "", isSortable: true },
    { name: "numberOwner", title: "owner id", className: "noWrap", options: "", isSortable: true },
    { name: "state", title: "status", className: "noWrap", options: "", isSortable: true },

    { name: "numberHoster", title: "hoster", className: "noWrap", options: "", isSortable: true },
    { name: "numberMethod", title: "method", className: "noWrap", options: "", isSortable: true },
    { name: "country", title: "country", className: "noWrap", options: "", isSortable: true },
    { name: "currency", title: "currency", className: "noWrap", options: "", isSortable: true },

    { name: "whitelabel", title: "wl", className: "noWrap", options: "", isSortable: true },
    { name: "created", title: "created", className: "noWrap", options: rowNamesOptions.hidden, isSortable: false },
    { name: "updated", title: "updated", className: "noWrap", options: rowNamesOptions.hidden, isSortable: false }
];

export const emptyFilter = {
    numberAccount: "",
    numberOwner: "",
    intState: "",
    intWhiteLabel: "",
    numberHoster: "",
    intCountry: "",
    intCurrency: "",
    numberMethod: "",
    intItemsPerPage: "10",
    intCurrentPage: "1",
    strOrderBy: ""
};


export const getBanksForDropDown = (arr) => {
    if (!arr) return [];
    return arr.map(el => {
        return { id: el.id, title: el.name };
    });
};


export const formatData = (data, countries, currencies) => {
    if(!data) return [];
    return data.map(el => {
        const country = countries?.find(country => country.codeNum === el.country)?.codeAlpha3;
        const currency = currencies?.find(currency => currency.codeNum === el.currency)?.codeAlpha;

        return {
           ...el,
           country: country?country:el.country,
           currency: currency?currency: el.currency,
        };
    });
}
