import React from 'react';
import { onAccountsFetch, onBalancesFetch, onBalanceAmountFetch } from '../../../store/transactions/transactions.actions';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import { balancesRowNamesDict, getBalancesData, getDataFromAccounts } from './BalancesHelper';
import { TableHeader } from '../TableHeader/TableHeader';
import { TableBody } from '../TableBody/TableBody';
import styles from './BalancesPanel.less';
import { SMALL_SCREEN } from '../../../utils/consts/global';
import { onGetAllDictionaries } from '../../../store/dictionaries/dictionaries.actions';

export class BalancesPanelComponent extends React.Component {
    state = {};

    componentDidMount() {
        this.props.onBalancesFetch({
            strOperator: 'ORANGE',
        });
        this.props.onGetAllDictionaries();
        this.props.onAccountsFetch({});
    }

    componentDidUpdate(prevProps) {
        const { subClientId, clientId } = this.props.app;
        if (prevProps.app.clientId !== clientId || prevProps.app.subClientId !== subClientId) {
            this.props.onBalancesFetch({ strOperator: 'ORANGE' });
            this.props.onAccountsFetch({});
        }

        const prevBalances = prevProps.transactions.getBalances.success;
        const balances = this.props.transactions.getBalances.success?.data;
        if (!prevBalances && balances?.length) {
            for (const balance of balances) {
                if (!balance['amount'] && balance['currency'] && balance['merchantId']) {
                    this.props.onBalanceAmountFetch({
                        strMerchantId: balance['merchantId'],
                        strCurrency: balance['currency'],
                    });
                }
            }
        }
    }

    render() {
        const balances = this.props.transactions.getBalances;
        const accounts = this.props.transactions.getAccountsInfo;
        const balanceAmounts = this.props.transactions.getBalanceAmount;
        const fetching = balances.fetching || accounts.fetching;
        const countries = this.props.getCountries.success;
        const currencies = this.props.getCurrencies.success;
        const gate = this.props.getGate.success;

        const balancesData = getBalancesData(balances.success?.data || [], currencies, countries, balanceAmounts);
        const accountsData = getDataFromAccounts({
            accounts: accounts.success?.data,
            countries,
            currencies,
            gate,
        });

        const dataForTable = [...balancesData, ...accountsData];

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        <div className="col-md-12">
                            {SMALL_SCREEN && <h1>Balances</h1>}
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={balancesRowNamesDict} hasCollapseSwich={false} />
                                                <TableBody
                                                    dataTable={dataForTable}
                                                    rowNames={balancesRowNamesDict}
                                                    hasCollapseSwich={false}
                                                />
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onBalancesFetch,
    onBalanceAmountFetch,
    onAccountsFetch,
    onGetAllDictionaries,
};

const mapStateToProps = (state) => ({
    transactions: state.transactions,
    getCountries: state.dictionaries.getCountries,
    getCurrencies: state.dictionaries.getCurrencies,
    getGate: state.dictionaries.getGate,
    app: state.app,
});

export const BalancesPanel = connect(mapStateToProps, mapDispatchToProps)(BalancesPanelComponent);
