import { AppActions } from "./app.consts";
import { initialSidebar, removeMsg } from "./helpers";
import update from "immutability-helper";

const initialState = {
    messages: [],
    isSidebarShown: true,
    isSidebarMobShown: false,
    modalContent: null,
    clientId: undefined,
    subClientId: undefined,
    sidebarState: {...initialSidebar},
    data: null,
};

export const appReducers = (state = initialState, action) => {
    switch (action.type) {
        case AppActions.APP_SET_ADM_CLI_ID:
            return {
                ...state,
                clientId: action.payload,
                subClientId: undefined,
            };
        case AppActions.APP_SET_ADM_SUB_CLI_ID:
            return {
                ...state,
                subClientId: action.payload,
            };
        case AppActions.APP_NOTIFY_SHOW:
            return {
                ...state,
                messages: state.messages.concat([action.payload]),
            };
        case AppActions.APP_NOTIFY_HIDE:
            return {
                ...state,
                messages: removeMsg(state.messages, action.payload),
            };
        case AppActions.APP_UI_SIDEBAR_SHOW:
            return {
                ...state,
                isSidebarShown: true,
            };
        case AppActions.APP_UI_SIDEBAR_HIDE:
            return {
                ...state,
                isSidebarShown: false,
            };
        case AppActions.APP_UI_SIDEBAR_MOB_SHOW:
            return {
                ...state,
                isSidebarMobShown: true,
            };
        case AppActions.APP_UI_SIDEBAR_MOB_HIDE:
            return {
                ...state,
                isSidebarMobShown: false,
            };
        case AppActions.APP_MODAL_SHOW:
            return {
                ...state,
                modalContent: action.payload,
            };
        case AppActions.APP_MODAL_HIDE:
            return {
                ...state,
                modalContent: null,
            };

        case AppActions.APP_UI_SIDEBAR_SETSTATE:
            return update(state, {
                sidebarState: { $set: action.payload },
            });
        case AppActions.APP_STORE_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case AppActions.APP_CLEAR_DATA:
            return {
                ...state,
                data: null,
            };
        default:
            return state;
    }
};
