import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../../../store/auth/auth.consts';
import { useOutsideAlerter } from '../../../utils/customHooks';
import { NavLink } from 'react-router-dom';
import { AppActions } from '../../../store/app/app.consts';
import { useLocation } from 'react-router-dom';
import { getSubClients, sectionNames } from './TopNavBar.helper';
import RoutesPaths from '../../../routes/RoutesPaths';
import {
    PERM_GET_BALANCE,
    PERM_GET_RECORDS,
    PERM_GET_RECORDS_ADM, PERM_GET_SMS_REPORT,
    PERM_GET_SUMMARY_RECORDS,
    PERM_MAKE_PAYMENT_B2C,
    SMALL_SCREEN,
} from '../../../utils/consts/global';
import { DropDown } from '../DropDown/DropDown';
import { Dictionaries } from '../../../store/dictionaries/dictionaries.consts';
import styles from './TopNavBar.less';
import logo from '../../../assets/img/logos/unipesaLogoBlack.svg';
import cn from 'classnames';
import { getDataForDD, isRoot } from '../../../utils/helpers/commonHelpers';

export const TopNavBar = React.memo(() => {
    const location = useLocation();
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);

    const [isMenuShown, switchMenu] = useState(false);
    const login = useSelector((state) => state.auth.getToken.login);
    const [sectionName, setSectionName] = useState(sectionNames[0].title);
    const [clientId, setClientId] = useState(null);

    const isSidebarShown = useSelector((state) => state.app.isSidebarShown);
    const isSidebarMobShown = useSelector((state) => state.app.isSidebarMobShown);
    const permissions = useSelector((state) => state.auth.permissions.success) || [];
    const clients = useSelector((state) => state.dictionaries.getClients.success) || [];
    const subClients = useSelector((state) => state.dictionaries.getSubClients.success) || [];

    const clientsList = getDataForDD(clients);
    const subClientsList = getSubClients(clientId, subClients);

    useEffect(() => {
        if (permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
            dispatch({
                type: Dictionaries.GET_CLIENTS_FETCH,
                payload: null,
            });
            dispatch({
                type: Dictionaries.GET_SUBCLIENTS_FETCH,
                payload: null,
            });
        }
    }, [permissions]);

    const logoutUser = useCallback(
        () =>
            dispatch({
                type: AuthActions.AUTH_LOGOUT_START,
                payload: null,
            }),
        [dispatch],
    );

    const sidebarMobileShowHide = useCallback(
        (isSidebarMobShown) => {
            return dispatch({
                type: isSidebarMobShown ? AppActions.APP_UI_SIDEBAR_MOB_HIDE : AppActions.APP_UI_SIDEBAR_MOB_SHOW,
                payload: !isSidebarMobShown,
            });
        },
        [dispatch],
    );

    const sidebarShowHide = useCallback(
        (isSidebarShown) => {
            return dispatch({
                type: isSidebarShown ? AppActions.APP_UI_SIDEBAR_HIDE : AppActions.APP_UI_SIDEBAR_SHOW,
                payload: !isSidebarShown,
            });
        },
        [dispatch],
    );

    useEffect(() => {
        setSectionName(sectionNames.find((el) => el.path === location.pathname)?.title || '');
    }, [location]);

    const toggleUserMenu = () => {
        switchMenu(!isMenuShown);
    };

    const closeMenu = () => {
        switchMenu(false);
    };

    const handlerClientSelect = (client) => {
        const { id } = client;
        setClientId(id);

        dispatch({
            type: AppActions.APP_SET_ADM_CLI_ID,
            payload: id,
        });
    };

    const handlerSubClientSelect = (sub) => {
        dispatch({
            type: AppActions.APP_SET_ADM_SUB_CLI_ID,
            payload: sub.id,
        });
    };

    const showClientButtons = () => {
        const disabledLocations = [
            RoutesPaths.CREATE_PAYMENT,
            RoutesPaths.MERCHANTS_LIST,
            RoutesPaths.ADM_ICONS,
            RoutesPaths.ADM_USERS,
            RoutesPaths.ADM_GROUP_ROLES,
            RoutesPaths.ACCESS_DENIED,
            RoutesPaths.SMS,
            RoutesPaths.AGENTS,
            RoutesPaths.SMS_UPLOAD,
            RoutesPaths.ADM_CASHIERS,
            RoutesPaths.HOME,
            RoutesPaths.MERCHANT,
            RoutesPaths.TARIFFS,
            RoutesPaths.REPORTS,
            RoutesPaths.DASHBOARD,
        ];
        return permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1 && disabledLocations.indexOf(location.pathname) === -1;
    };
    const isAgent = sessionStorage.getItem('UserType') === 'AGENT';

    useOutsideAlerter(wrapperRef, closeMenu);
    const isUserRoot = isRoot();

    return (
        <div className={cn(styles.mainHeader, 'main-header')}>
            <div className={cn(styles.headerColor, 'logo-header')}>
                <NavLink to={RoutesPaths.HOME} className={cn(styles.logo, 'logo')}>
                    <span className={styles.logoText}>BACK OFFICE </span>
                    {/*<img src={logo} alt="Unipesa logo" className="navbar-brand" width="130" />*/}
                </NavLink>
                <button
                    className="navbar-toggler sidenav-toggler ml-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span
                        className="navbar-toggler-icon"
                        onClick={() => {
                            sidebarMobileShowHide(isSidebarMobShown);
                        }}
                    >
                        <i className={cn(styles.toggleIcon, 'icon-menu')}> </i>
                    </span>
                </button>
                {/*
                <button className="topbar-toggler more">
                    <i className="icon-options-vertical"> </i>
                </button>
                */}
                <div className="nav-toggle">
                    <button
                        className={cn(styles.toggleIcon, 'btn btn-toggle toggle-sidebar')}
                        onClick={() => {
                            sidebarShowHide(isSidebarShown);
                        }}
                    >
                        <i className="icon-menu"> </i>
                    </button>
                </div>
            </div>

            {SMALL_SCREEN && (
                <button className={cn(styles.signOutButton, 'btn btn-primary')} onClick={logoutUser}>
                    <i className={cn(styles.signIcon, 'fas fa-sign-out-alt')}> </i>
                </button>
            )}

            <nav className="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
                <div className="container-fluid">
                    <h4 className={cn(styles.title)}>{sectionName}</h4>
                    <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                        <li className="nav-item toggle-nav-search hidden-caret">
                            <span
                                className="nav-link"
                                data-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="search-nav"
                            >
                                <i className="fa fa-search"> </i>
                            </span>
                        </li>
                        {showClientButtons() && (
                            <>
                                <li className="nav-item dropdown hidden-caret">
                                    <div className={styles.dropPlace}>
                                        <div className={styles.dropTitle}>Client:</div>
                                        <DropDown
                                            options={clientsList}
                                            onSelect={handlerClientSelect}
                                            className={styles.navButton}
                                            color="white"
                                            allowFilter
                                        >
                                            select client
                                        </DropDown>
                                    </div>
                                </li>
                                {!!subClientsList.length && (
                                    <li className="nav-item dropdown hidden-caret">
                                        <div className={styles.dropPlace}>
                                            <div className={styles.dropTitle}>Sub:</div>
                                            <DropDown
                                                options={subClientsList}
                                                onSelect={handlerSubClientSelect}
                                                className={styles.navButton}
                                                color="white"
                                            >
                                                select sub client
                                            </DropDown>
                                        </div>
                                    </li>
                                )}
                            </>
                        )}

                        <li className="nav-item dropdown hidden-caret" onClick={toggleUserMenu} ref={wrapperRef}>
                            <div className={cn(styles.textCenter, styles.cursorPointer, styles.avatarHover, 'avatar-sm')}>
                                <i className={cn(styles.userIcon, 'fas fa-user')}> </i>
                            </div>

                            <ul className={cn('dropdown-menu dropdown-user animated fadeIn', { ['show']: isMenuShown })}>
                                <div className="dropdown-user-scroll scrollbar-outer">
                                    <li>
                                        <div className="user-box">
                                            <div className="u-text">
                                                <h4>
                                                    User: <b>{login}</b>
                                                </h4>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="dropdown-divider"> </div>
                                        {isAgent && (
                                            <NavLink to={RoutesPaths.AGENT_INFO_EDIT} className="dropdown-item">
                                                Edit profile
                                            </NavLink>
                                        )}
                                        {permissions.indexOf(PERM_GET_SUMMARY_RECORDS) !== -1 && (
                                            <NavLink to={RoutesPaths.SUMMARY} className="dropdown-item">
                                                Summary
                                            </NavLink>
                                        )}
                                        {isUserRoot && (
                                            <NavLink to={RoutesPaths.REPORTS} className="dropdown-item">
                                                Reports
                                            </NavLink>
                                        )}
                                        {permissions.indexOf(PERM_GET_BALANCE) !== -1 && (
                                            <NavLink to={RoutesPaths.BALANCES} className="dropdown-item">
                                                Balances
                                            </NavLink>
                                        )}
                                        {permissions.indexOf(PERM_GET_RECORDS) !== -1 && (
                                            <NavLink to={RoutesPaths.PAYMENTS} className="dropdown-item">
                                                Payments
                                            </NavLink>
                                        )}
                                        {permissions.indexOf(PERM_MAKE_PAYMENT_B2C) !== -1 && (
                                            <NavLink to={RoutesPaths.CREATE_PAYMENT} className="dropdown-item">
                                                Create payment
                                            </NavLink>
                                        )}
                                        {permissions.indexOf(PERM_GET_SMS_REPORT) !== -1 && (
                                        <NavLink to="/sms" className="dropdown-item">
                                            SMS Stat
                                        </NavLink>
                                        )}
                                        <div className="dropdown-divider"> </div>
                                        <span className={cn(styles.cursorPointer, 'dropdown-item')} onClick={logoutUser}>
                                            Logout
                                        </span>
                                    </li>
                                </div>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
});
