import React, { useState } from 'react';
import cn from 'classnames';
import styles from './AgentFields.less';
import { DropDown } from '../../DropDown/DropDown';
import { INPUT_PASSWORD_TYPE, INPUT_TEXT_TYPE } from '../../../../utils/consts/global';

export const AgentFields = (props) => {

    const [passwordType, setPasswordType] = useState(INPUT_PASSWORD_TYPE);

    const {
        selfEdit,
        createAgent,
        enableAgent,
        enabled,
        changeInputHandler,
        handleCountrySelect,
        name,
        legalName,
        countriesDD,
        country,
        city,
        errorFields,
        streetAddress,
        postCode,
        email,
        phone,
        NIF,
        RC,
        description,
        sender_commission,
        sender_bonus_instant,
        recipient_commission,
        recipient_bonus,
        payer_commission_top,
        payer_commission_external,
        agentLogin,
        password,

    } = props;

    const changePasswordType = () => {
        setPasswordType(passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE);
    };

    const getCountryFromDD = (countriesDD, code) => {
        return countriesDD.find(el => el.id === code)?.title;
    };

    return (<form autoComplete="off">
        <div className={styles.fields}>

            <div className={cn(styles.editBlock, styles.one)}>
                {!selfEdit && (<>
                    <div className={cn('form-group form-inline', styles.formInline)}>
                        <label htmlFor="name">*Agent name:</label>
                        <input type="text"
                               id="name"
                               name={'name:' + Math.random()}
                               value={name}
                               className={cn(styles.inputField, { [styles.error]: !errorFields.name })}
                               onChange={changeInputHandler}
                        />
                    </div>
                    <div className={cn('form-group form-inline', styles.formInline)}>
                        <label htmlFor="legalName">*Legal name:</label>
                        <input
                            type="text"
                            className={cn(styles.inputField, { [styles.error]: !errorFields.legalName })}
                            id="legalName"
                            name={'legalName:' + Math.random()}
                            value={legalName}
                            onChange={changeInputHandler}
                        />
                    </div>
                    <div className={cn('form-group form-inline', styles.formInline)}>
                        <label htmlFor="country">*Country:</label>
                        <div className={cn(styles.fixedDropWidth, styles.normal)}>
                            <DropDown
                                className={styles.dropDownField}
                                options={countriesDD}
                                name="country"
                                onSelect={handleCountrySelect}
                                color="grey"
                                buttonStyles={styles.buttonWitdth}
                                // value={countriesDD[0]}
                                reset={country}
                            >
                                {getCountryFromDD(countriesDD, country)}
                            </DropDown>
                        </div>
                    </div>
                </>)}

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="city">*City:</label>
                    <input type="text"
                           id="city"
                           className={cn(styles.inputField, { [styles.error]: !errorFields.city })}
                           value={city}
                           name={'city:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="streetAddress">*Street address:</label>
                    <input type="text"
                           id="streetAddress"

                           className={cn(styles.inputField, { [styles.error]: !errorFields.streetAddress })}
                           value={streetAddress}
                           name={'streetAddress:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>

            </div>


            <div className={cn(styles.editBlock, styles.three)}>

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="postCode">Post code:</label>
                    <input type="text"
                           id="postCode"
                           className={cn(styles.inputField, { [styles.error]: !errorFields.postCode })}
                           value={postCode}
                           name={'postCode:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="email">E-mail:</label>
                    <input id="email" type="text" className={styles.hiddenLogin} defaultValue=" " />
                    <input
                        type="text"
                        className={cn(styles.inputField)}
                        value={email}
                        name={'email:' + Math.random()}
                        onChange={changeInputHandler}
                    />
                </div>

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="phone">Phone:</label>
                    <input type="text"
                           id="phone"
                           className={cn(styles.inputField)}
                           value={phone}
                           name={'phone:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>

                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="NIF">NIF:</label>
                    <input type="text"
                           id="NIF"
                           className={cn(styles.inputField)}
                           value={NIF}
                           name={'NIF:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>
                <div className={cn('form-group form-inline', styles.formInline)}>
                    <label htmlFor="RC">RC:</label>
                    <input type="text"
                           id="RC"
                           className={cn(styles.inputField)}
                           value={RC}
                           name={'RC:' + Math.random()}
                           onChange={changeInputHandler}
                    />
                </div>
            </div>

            {!selfEdit && (
                <div className={cn(styles.editBlock, styles.two)}>
                    <div className={styles.selectedArea}>
                        <div className={cn('form-group form-inline', styles.formInline)}>
                            <label htmlFor="agentLogin">**Agent login:</label>
                            <input type="text"
                                   id="agentLogin"
                                   name={'agentLogin:' + Math.random()}
                                   value={agentLogin}
                                   className={cn(styles.inputField, { [styles.error]: !errorFields.agentLogin })}
                                   onChange={changeInputHandler}
                            />
                        </div>
                        <div className={cn('form-group form-inline', styles.formInline)}>
                            <label htmlFor="name">***Password:</label>
                            <input type="text" className={styles.hiddenLogin} defaultValue=" " />
                            <input type="password" name="password" defaultValue=""
                                   className={styles.hiddenLogin} />
                            <input type={passwordType}
                                   id="password"
                                   className={cn(styles.inputField, { [styles.error]: !errorFields.password })}
                                   value={password}
                                   name={'password:' + Math.random()}
                                   onChange={changeInputHandler}
                            />
                            <i className={cn('icon-eye', styles.iconEye, { [styles.selected]: passwordType !== INPUT_PASSWORD_TYPE })}
                               onClick={changePasswordType}>&nbsp;</i>
                        </div>

                        {!createAgent && (<div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input"
                                       type="checkbox"
                                       name="enabled"
                                       onChange={enableAgent}
                                       checked={enabled}
                                       value={enabled}
                                />
                                <span className="form-check-sign">Enabled</span>
                            </label>
                        </div>)}
                    </div>
                    <div className={cn(styles.formInline)}>
                        <label htmlFor="name" className={styles.textColor}>Description:</label>
                        <textarea
                            id="description"
                            name={'description:' + Math.random()}
                            value={description}
                            className={cn(styles.textarea, { [styles.error]: !errorFields.name })}
                            onChange={changeInputHandler}
                        />
                    </div>
                </div>
            )}
        </div>
    </form>);
};
