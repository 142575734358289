import React from "react";
import { connect } from "react-redux";
import { Spinner } from "../Spinner/Spinner";
import {
    GROUPS_TAB_NAME,
    PERM_GET_GROUP_RULES,
    PERM_GET_PERMISSION_GROUP,
    PERM_GET_PERMISSION_ROLE,
    ROLES_TAB_NAME,
    SMALL_SCREEN
} from "../../../utils/consts/global";

import {
    onAddRoleToGroupFetch,
    onCreateGroupFetch,
    onCreateRoleFetch,
    onDeleteGroupFetch,
    onDeleteRoleFetch,
    onEditGroupFetch,
    onEditRoleFetch,
    onGetGroupRolesFetch,
    onGetGroupsFetch,
    onGetRolesFetch,
    onRemoveRoleFromGroupFetch
} from "../../../store/roleSystem/roleSystem.actions";
import { emptyItem, objToArray, rows } from "./AdmRolesGroupPanelHelper";
import { TabMenu } from "../TabMenu/TabMenu";
import { ActionButton } from "../ActionButton/ActionButton";
import { Modal } from "../Modal/Modal";
import { GroupsTable } from "../GroupsTable/GroupsTable";
import cn from "classnames";
import styles from "./AdmRolesGroupPanel.less";
import SweetAlert from "react-bootstrap-sweetalert";
import { AdmRolesTable } from '../AdmRolesTable/AdmRolesTable';

export class AdmRulesGroupComponent extends React.Component {
    state = {
        activeTab: "Roles",
        editRoleMode: false,
        isEditShown: false,
        isAlertDeleteGroupShown: false,
        isAlertRemoveRoleShown: false,
        selectedGroup: {},
        selectedRole: { id: "" },
        createRoleMode: false,
        selectedRoleId: ""
    };

    componentDidUpdate(prevProps) {
        if (this.props.canReadGroups) {
            if (
                JSON.stringify(prevProps.roleSystem.createGroup.success) !== JSON.stringify(this.props.roleSystem.createGroup.success) ||
                JSON.stringify(prevProps.roleSystem.deleteGroup.success) !== JSON.stringify(this.props.roleSystem.deleteGroup.success) ||
                JSON.stringify(prevProps.roleSystem.editGroup.success) !== JSON.stringify(this.props.roleSystem.editGroup.success)
            ) {
                this.props.onGetGroupsFetch();
            }
        }

        if (this.props.canReadGroupRules) {
            if (
                JSON.stringify(prevProps.roleSystem.addRoleToGroup.success) !==
                JSON.stringify(this.props.roleSystem.addRoleToGroup.success) ||
                JSON.stringify(prevProps.roleSystem.removeRoleFromGroup.success) !==
                JSON.stringify(this.props.roleSystem.removeRoleFromGroup.success)
            ) {
                this.props.onGetGroupRolesFetch();
            }
        }

        if (this.props.canReadRoles) {
            if (
                JSON.stringify(prevProps.roleSystem.createRole.success) !== JSON.stringify(this.props.roleSystem.createRole.success) ||
                JSON.stringify(prevProps.roleSystem.deleteRole.success) !== JSON.stringify(this.props.roleSystem.deleteRole.success) ||
                JSON.stringify(prevProps.roleSystem.editRole.success) !== JSON.stringify(this.props.roleSystem.editRole.success)
            ) {
                this.props.onGetRolesFetch();
            }
        }
    }

    componentDidMount() {
        if (this.props.canReadRoles) {
            this.props.onGetRolesFetch();
        }
        if (this.props.canReadGroups) {
            this.props.onGetGroupsFetch();
        }
        if (this.props.canReadGroupRules) {
            this.props.onGetGroupRolesFetch();
        }
    }

    setTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    addRole = () => {
        this.setState({
            editRoleMode: false,
            isEditShown: true,
            createRoleMode: true,
            selectedRole: { ...emptyItem }
        });
    };

    addGroup = () => {
        this.setState({
            editRoleMode: false,
            isEditShown: true,
            selectedGroup: { ...emptyItem }
        });
    };

    onRolesLineEvents = (e) => {

        this.setState((prev) => ({
            ...prev,
            isAlertRemoveRoleShown: e.type === "remove",
            isEditShown: e.type === "edit",
            editRoleMode: true,
            createRoleMode: false,
            selectedRole: {
                ...e.item
            }
        }));
    };

    onGroupsLineEvents = (e) => {
        this.setState((prev) => ({
            ...prev,
            isAlertDeleteGroupShown: e.type === "remove",
            editRoleMode: false,
            isEditShown: e.type === "edit",
            selectedGroup: {
                ...e.item
            }
        }));
    };

    onCloseRemoveRoleAlert = () => {
        this.setState({
            ...emptyItem,
            isAlertRemoveRoleShown: false
        });
    };

    onConfirmRemoveRole = () => {
        if (this.state.activeTab === ROLES_TAB_NAME) {
            const intID = this.state.selectedRole.id;
            if (!intID) return;
            this.props.onDeleteRoleFetch({ intID });
        } else {
            const intID = this.state.selectedRoleId;
            if (!intID) return;
            this.props.onRemoveRoleFromGroupFetch({ intID });
        }
        this.onCloseRemoveRoleAlert();
    };

    onCloseDeleteGroupAlert = () => {
        this.setState({
            ...emptyItem,
            isAlertDeleteGroupShown: false
        });
    };

    onConfirmDelete = () => {
        this.setState({ isAlertShown: false });
        this.props.onDeleteGroupFetch({
            intGroupID: this.state.selectedGroup.id
        });
        this.onCloseDeleteGroupAlert();
    };

    handleInputsChange = (event) => {
        const isRolesTabShown = this.state.activeTab === ROLES_TAB_NAME;
        const { target } = event;

        let { name, value } = target;
        event.persist();

        if (isRolesTabShown) {
            this.setState((prev) => ({
                ...prev,
                selectedRole: {
                    ...prev.selectedRole,
                    [name]: value
                }
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                selectedGroup: {
                    ...prev.selectedGroup,
                    [name]: value
                }
            }));
        }
    };

    onCancelEdit = () => {
        this.setState({
            ...emptyItem,
            isEditShown: false
        });
    };

    onAddRoleToGroup = ({ intGroupID, intRoleID }) => {
        if (!intGroupID || !intRoleID) return;

        this.props.onAddRoleToGroupFetch({
            intGroupID,
            intRoleID
        });
    };

    onRemoveRoleFromGroup = (id) => {
        this.setState({
            selectedRoleId: id,
            isAlertRemoveRoleShown: true
        });
    };

    onConfirmEdit = () => {
        const isRolesTabShown = this.state.activeTab === ROLES_TAB_NAME;
        const { id } = isRolesTabShown ? this.state.selectedRole : this.state.selectedGroup;

        if (isRolesTabShown) {
            this.state.createRoleMode ? this.createRole(this.state.selectedRole) : this.updateRole(this.state.selectedRole);
        } else {
            id ? this.updateGroup(this.state.selectedGroup) : this.createGroup(this.state.selectedGroup);
        }

        this.setState({ isEditShown: false });
    };

    createGroup = ({ code, title, description }) => {
        this.props.onCreateGroupFetch({
            strCode: code,
            strTitle: title,
            strDescription: description
        });
    };

    updateGroup = ({ id, title, description }) => {
        this.props.onEditGroupFetch({
            intGroupID: id,
            strTitle: title,
            strDescription: description
        });
    };

    createRole = ({ id, code, title, description }) => {
        this.props.onCreateRoleFetch({
            intID: id,
            strCode: code,
            strTitle: title,
            strDescription: description
        });
    };

    updateRole = ({ id, title, description }) => {
        this.props.onEditRoleFetch({
            intID: id,
            strTitle: title,
            strDescription: description
        });
    };

    render() {
        const fetching = this.props.roleSystem.getPermissionRoles.fetching;
        const roles = objToArray(this.props.roleSystem.getPermissionRoles.success || {});
        const groups = objToArray(this.props.roleSystem.getPermissionGroups.success || {});
        const menuItems = [{ name: ROLES_TAB_NAME }, { name: GROUPS_TAB_NAME }];

        const isRolesTabShown = this.state.activeTab === ROLES_TAB_NAME;
        const { id, code, title, description } = isRolesTabShown ? this.state.selectedRole : this.state.selectedGroup;

        return (
            <div className={cn(styles.mainPanel, "main-panel")}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Groups & Roles</h1>}
                        <div className="page-header">&nbsp;</div>
                        <div className="col-md-12">
                            <div className={cn(styles.card, "card")}>
                                <div className={cn("card-body", styles.topPadding)}>
                                    <div className={cn("nav-scroller d-flex", styles.borderBottomLine)}>
                                        <TabMenu menuItems={menuItems} active={this.state.activeTab} onChange={this.setTab} />
                                        <div className="d-flex d-md-inline ml-md-auto py-2 py-md-0">
                                            {isRolesTabShown &&
                                            <ActionButton click={this.addRole}>add role</ActionButton>}
                                            {!isRolesTabShown &&
                                            <ActionButton click={this.addGroup}>add group</ActionButton>}
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <>
                                                {isRolesTabShown && (
                                                    <AdmRolesTable roles={roles} rows={rows}
                                                                onDataLineEvents={this.onRolesLineEvents} />
                                                )}
                                                {!isRolesTabShown && (
                                                    <GroupsTable
                                                        roles={roles}
                                                        groups={groups}
                                                        rows={rows}
                                                        addRoleAction={this.onAddRoleToGroup}
                                                        removeRoleAction={this.onRemoveRoleFromGroup}
                                                        onDataLineEvents={this.onGroupsLineEvents}
                                                        groupRoles={this.props.roleSystem.getGroupsRoles.success || []}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAlertDeleteGroupShown && (
                    <SweetAlert
                        show={this.state.isAlertDeleteGroupShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmDelete}
                        onCancel={this.onCloseDeleteGroupAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>Are you sure you want to delete?</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>id:</span>
                                <span className={styles.bolder}>{id}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>code:</span>
                                <span className={styles.bolder}>{code}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>title: </span>
                                <span className={styles.bolder}>{title}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>description:</span>
                                <span className={styles.bolder}>{description}</span>
                            </div>
                        </div>
                    </SweetAlert>
                )}

                {this.state.isAlertRemoveRoleShown && (
                    <SweetAlert
                        show={this.state.isAlertRemoveRoleShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmRemoveRole}
                        onCancel={this.onCloseRemoveRoleAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>
                            {isRolesTabShown
                                ? "Are you sure you want to remove role?" + this.state.selectedRole.title
                                : "Are you sure you want to remove role from group?"}
                        </h1>
                    </SweetAlert>
                )}

                {this.state.isEditShown && (
                    <Modal faderClick={this.onCancelEdit}>
                        <div className={cn(styles.popUpPosition, "col-md-4")}>
                            <div className="card card-primary bg-primary-gradient">
                                <div className="card-body">
                                    <h4 className="mt-3 b-b1 pb-2 mb-4 fw-bold">
                                        {!isRolesTabShown && (
                                            <>
                                                {!!this.state.selectedGroup.id && "Edit group: " + title}
                                                {!this.state.selectedGroup.id && "Create group"}
                                            </>
                                        )}
                                        {isRolesTabShown && (
                                            <>
                                                {!!this.state.selectedRole.id && "Edit role: " + title}
                                                {!this.state.selectedRole.id && "Create role"}
                                            </>
                                        )}
                                    </h4>
                                    <h4 className={cn("mt-2 pb-3 mb-0 fw-bold")}>
                                        {isRolesTabShown && (<>
                                            {!this.state.editRoleMode && (
                                            <div className="form-group form-inline">
                                                <label
                                                    className={cn("col-md-3 col-form-label", styles.label)}>id:</label>
                                                <div className="col-md-9 p-0">
                                                    <input
                                                        type="text"
                                                        className="form-control input-full"
                                                        placeholder="Enter id"
                                                        name="id"
                                                        value={id}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            )}
                                        </>)}
                                        {!this.state.editRoleMode && (
                                        <div className="form-group form-inline">
                                            <label className={cn("col-md-3 col-form-label", styles.label)}>code:</label>
                                            <div className="col-md-9 p-0">
                                                <input
                                                    type="text"
                                                    className="form-control input-full"
                                                    placeholder="Enter code"
                                                    name="code"
                                                    value={code}
                                                    onChange={this.handleInputsChange}
                                                />
                                            </div>
                                        </div>
                                        )}
                                        <div className="form-group form-inline">
                                            <label
                                                className={cn("col-md-3 col-form-label", styles.label)}>title:</label>
                                            <div className="col-md-9 p-0">
                                                <input
                                                    type="text"
                                                    className="form-control input-full"
                                                    placeholder="Enter title"
                                                    name="title"
                                                    value={title}
                                                    onChange={this.handleInputsChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group form-inline">
                                            <label
                                                className={cn("col-md-3 col-form-label", styles.label)}>description:</label>
                                            <div className="col-md-9 p-0">
                                                <input
                                                    type="text"
                                                    className="form-control input-full"
                                                    placeholder="Enter description"
                                                    name="description"
                                                    value={description}
                                                    onChange={this.handleInputsChange}
                                                />
                                            </div>
                                        </div>
                                    </h4>
                                    <div className="d-flex justify-content-between pb-4 pt-1">
                                        <button className={styles.cancelBtn} onClick={this.onCancelEdit}>
                                            Cancel
                                        </button>
                                        <button className={styles.okBtn} onClick={this.onConfirmEdit}>
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetRolesFetch,
    onCreateRoleFetch,
    onEditRoleFetch,
    onDeleteRoleFetch,

    onGetGroupRolesFetch,
    onAddRoleToGroupFetch,
    onRemoveRoleFromGroupFetch,

    onCreateGroupFetch,
    onGetGroupsFetch,
    onEditGroupFetch,
    onDeleteGroupFetch
};

const mapStateToProps = (state) => ({
    roleSystem: state.roleSystem,
    permissions: state.auth.permissions.success || [],
    canReadRoles: (state.auth.permissions.success || []).indexOf(PERM_GET_PERMISSION_ROLE) !== -1,
    canReadGroups: (state.auth.permissions.success || []).indexOf(PERM_GET_PERMISSION_GROUP) !== -1,
    canReadGroupRules: (state.auth.permissions.success || []).indexOf(PERM_GET_GROUP_RULES) !== -1
});

export const AdmRolesGroupPanel = connect(mapStateToProps, mapDispatchToProps)(AdmRulesGroupComponent);
