import React from 'react';
import cn from 'classnames';
import styles from './TabMenu.less';

export const TabMenu = (props) => {
    const menuItems = props.menuItems || [];

    const changeTab = (tab) => {
        if (props.onChange && !props.disabled) {
            props.onChange(tab);
        }
    };

    return (
        <div
            className={cn("nav nav-line nav-color-info d-flex align-items-center justify-contents-center",  props.className)}
        >
            {menuItems.map((item, id) => (
                <span
                    key={String(id)}
                    className={cn(styles.navLink, { [styles.active]: props.active === item.name })}
                    onClick={() => {
                        changeTab(item.name);
                    }}
                >
                    {item.name}
                </span>
            ))}
        </div>
    );
};
