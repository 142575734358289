import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import RoutesPaths from '../../routes/RoutesPaths';
import { AuthActions, TOKEN } from '../../store/auth/auth.consts';
import { setHeaderToken } from '../../store/auth/auth.service';

const mapDispatchToProps = {};

const AuthorizedRouteComponent = ({ component: Component, storeToken, ...otherProps }) => {
    const dispatch = useDispatch();

    const sessionToken = sessionStorage.getItem(TOKEN);
    let hasToken = !!storeToken;

    if (!storeToken && sessionToken) {
        setHeaderToken(sessionToken);
        hasToken = true;
        dispatch({
            type: AuthActions.AUTH_UPDATE_TOKEN,
            payload: sessionToken,
        });
    }

    const render = (props) => (hasToken ? <Component {...props} /> : <Redirect to={RoutesPaths.LOGIN} />);

    return <Route {...otherProps} render={render} />;
};

const mapStateToProps = (state) => ({
    storeToken: state.auth.getToken.token,
});

export const AuthorizedRoute = React.memo(connect(mapStateToProps, mapDispatchToProps)(AuthorizedRouteComponent));
