import { rowNamesOptions } from "../TableBody/TableBodyHelper";

// after 2022-10-03 (https://modumsystems.atlassian.net/jira/software/projects/PP/boards/4?selectedIssue=PP-92)
export const summaryRowNamesDict = [
    { name: 'country', title: 'Country', className: 'noWrap', options: '', isSortable: true },
    { name: 'paymentType', title: 'Payment Type', className: 'noWrap', options: '', isSortable: true },
    { name: 'operator', title: 'Payment Method', className: 'noWrap', options: '', isSortable: true },
    { name: 'currency', title: 'Currency', className: 'noWrap', options: '', isSortable: true },
    { name: 'deposit', title: 'Total deposit', className: 'noWrap', options: '', isSortable: true },
    { name: 'withdrawal', title: 'Total withdrawal', className: 'noWrap', options: '', isSortable: true },
    { name: 'fee', title: 'Merchant fees', className: 'noWrap', options: '', isSortable: true },
    { name: 'revenue', title: 'Gross Revenue', className: 'noWrap', options: '', isSortable: true },

    { name: 'accountName', title: 'Merchant Account', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: true },
    { name: 'accountOwner', title: 'Account owner', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
    { name: 'accountType', title: 'Account type', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
];

// TODO: Remove after back is ready

//Country	Payment Type	Payment Method	Currency	Total deposit	Total withdrawal	Merchant fees	Gross Revenue	Merchant Account	Acount owner	Account type

// country 			+
// Payment Type		? (Mobile Money)
// Payment Method      + (Orange Money)
// currency    		+
// Total deposit   	+
// Total withdrawal    +
// Merchant fees		+
// Gross revenue 		+
// Merchant Account 	+ (key)
// Acount owner		? (Unipesa)
// Account Type

/* before 2022-10-03
export const summaryRowNamesDict = [
    { name: 'accountName', title: 'Merchant Account', className: 'noWrap', options: '', isSortable: true },
    { name: 'country', title: 'Country', className: 'noWrap', options: '', isSortable: true },
    { name: 'paymentType', title: 'Payment Type', className: 'noWrap', options: '', isSortable: true },
    { name: 'operator', title: 'Payment Method', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: true },
    { name: 'currency', title: 'Currency', className: 'noWrap', options: '', isSortable: true },
    { name: 'deposit', title: 'Total deposit', className: 'noWrap', options: '', isSortable: true },
    { name: 'withdrawal', title: 'Total withdrawal', className: 'noWrap', options: '', isSortable: true },
    { name: 'fee', title: 'Merchant fees', className: 'noWrap', options: '', isSortable: true },
    { name: 'revenue', title: 'Gross Revenue', className: 'noWrap', options: '', isSortable: true },
    { name: 'accountOwner', title: 'Account owner', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
    { name: 'accountType', title: 'Account type', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
];
*/