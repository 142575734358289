import { rowNamesOptions } from '../TableBody/TableBodyHelper';
import { findTitleById } from '../../../utils/helpers/commonHelpers';
import * as moment from 'moment';
import { getCurrentDayEnd } from '../Operations/OperationsHelper';

const DEPOSIT = 17;
const WITHDRAWAL = 16;
const IN_PROGRESS = 1;
const SUCCESS = 2;
const FAIL = 3;
const CANCELED = 4;
const CANCELED_PARTIALLY = 5;
const SUCCESS_HOLD = 6;

export const opNames = [
    'total',
    'total deposits',
    'total withdrawal',
    'successful',
    'successful %',
    'successful deposits',
    'successful withdrawal',
    'unsuccessful',
    'unsuccessful %',
    'unsuccessful deposits',
    'unsuccessful withdrawal',
    'fail',
    'fail %',
    'fail deposit',
    'fail withdrawal',
    'in progress',
    'in progress %',
    'in progress deposits',
    'in progress withdrawal',
];

export const percentOperationNames = [
    'fail %',
    'unsuccessful %',
    'successful %',
    'in progress %',
    'successful deposits',
    'successful withdrawal',
    'unsuccessful deposits',
    'unsuccessful withdrawal',
    'fail deposit',
    'fail withdrawal',
    'in progress deposits',
    'in progress withdrawal',
];

export const periodOptions = [
    { id: 'daily', title: 'Day' },
    { id: 'weekly', title: 'Week' },
    { id: 'monthly', title: 'Month' },
    // { id: 'yearly', title: 'Year' },
];

export const days = {
    daily: 7,
    weekly: 30,
    monthly: 183,   /// in 6 month
    yearly: 365,
};
export const dtGroup = periodOptions[2].id;
export const fieldsToGroup = ['state', 'operation'];
export const initialDates = [moment().subtract(days[dtGroup], 'days').toDate(), getCurrentDayEnd()];

export const initialFiltersState = {
    dtGroup,
    dtRange: `${moment(initialDates[0]).format()};${moment(initialDates[1]).format()}`,
    fieldsToGroup, //: ['state', 'operation'],//'client', 'gate'
    fieldsToSum: ['amount'],
    filterClients: [],  //102
    filterGates: [],
    outTZ: '+03:00',
    method: 'GetPaymentSum',
};

export const formatNumber = (num) => {
    const NBSP = String.fromCharCode(160);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, NBSP);
};

export const fillTotals = (totals) => {
    const obj = { opName: 'Success transactions at period' };
    let total = 0;
    totals?.forEach(el => {
        obj[el.dt] = formatNumber(el.count);
        total += el.count;
    });
    obj.total = formatNumber(total);
    return [obj];
};

export const fillRows = (periods) => {
    const rows = [
        { name: 'client', title: 'name', className: 'noWrap', options: rowNamesOptions.mergeUp, isSortable: false },
        { name: 'opName', title: 'operation name', className: 'noWrap', options: '', isSortable: false },
    ];
    periods?.forEach(el => {
        rows.push(
            { name: el, title: el, className: 'noWrap', options: '', isSortable: false },
        );
    });
    rows.push({ name: 'total', title: 'Total', className: 'noWrap', options: '', isSortable: false });
    return rows;
};

export const fillTotalRows = (periods) => {
    const rows = [
        { name: 'opName', title: '', className: 'noWrap', options: '', isSortable: false },
    ];
    periods?.forEach(el => {
        rows.push(
            { name: el, title: el, className: 'noWrap', options: '', isSortable: false },
        );
    });
    rows.push({ name: 'total', title: 'total', className: 'noWrap', options: '', isSortable: false });
    return rows;
};

export const formatNum = (num, percent) => {
    if (!num) return '-';
    return percent ? formatNumber(num.toFixed(2)) + '%' : formatNumber(num);
};

export const getOpCounter = ({ operation, date, type, status, clientId, gateId }) => {
    if (operation.dt === date) {
        if (status) {
            if (
                operation.state === status &&
                operation.operation === type &&
                (clientId ? operation.client === clientId : true) &&
                (gateId ? operation.gate === gateId : true)
            ) return operation.count;
        } else {
            if (
                operation.operation === type &&
                (clientId ? clientId === operation.client : true) &&
                (gateId ? gateId === operation.gate : true)
            ) return operation.count;
        }
    }
    return 0;
};

export const getPeriodsForClient = (income, periods, ids) => {
    const rowDates = {};
    const clientId = Number(ids.split(':')[0]) || undefined;
    const gateId = Number(ids.split(':')[1]) || undefined;

    periods.forEach(date => {
        let deposits = 0;
        let withdrawal = 0;
        let successfulDeposits = 0;
        let successfulWithdrawal = 0;
        let inProgressDeposits = 0;
        let inProgressWithdrawal = 0;
        let failDeposit = 0;
        let failWithdrawal = 0;
        let cancelled = 0;
        let cancelledPartially = 0;
        let successfulHold = 0;

        income.forEach(operation => {
            deposits += getOpCounter({ operation, date, type: DEPOSIT, clientId, gateId });
            withdrawal += getOpCounter({ operation, date, type: WITHDRAWAL, clientId, gateId });
            failDeposit += getOpCounter({ operation, date, type: DEPOSIT, clientId, status: FAIL, gateId });
            failWithdrawal += getOpCounter({ operation, date, type: WITHDRAWAL, clientId, status: FAIL, gateId });
            successfulDeposits += getOpCounter({ operation, date, type: DEPOSIT, clientId, status: SUCCESS, gateId });
            successfulWithdrawal += getOpCounter({
                operation,
                date,
                type: WITHDRAWAL,
                clientId,
                status: SUCCESS,
                gateId,
            });
            inProgressDeposits += getOpCounter({
                operation,
                date,
                type: DEPOSIT,
                clientId,
                status: IN_PROGRESS,
                gateId,
            });
            inProgressWithdrawal += getOpCounter({
                operation,
                date,
                type: WITHDRAWAL,
                clientId,
                status: IN_PROGRESS,
                gateId,
            });
            cancelled += getOpCounter({ operation, date, type: WITHDRAWAL, clientId, status: CANCELED, gateId });
            cancelledPartially += getOpCounter({
                operation,
                date,
                type: WITHDRAWAL,
                clientId,
                status: CANCELED_PARTIALLY,
                gateId,
            });
            successfulHold += getOpCounter({
                operation,
                date,
                type: WITHDRAWAL,
                clientId,
                status: SUCCESS_HOLD,
                gateId,
            });
        });

        const fail = failDeposit + failWithdrawal;
        const successful = successfulDeposits + successfulWithdrawal;
        const inProgress = inProgressDeposits + inProgressWithdrawal;
        const total = withdrawal + deposits;
        const unsuccessful = total - successful;

        rowDates[date] = {
            total,
            'total deposits': deposits,
            'total withdrawal': withdrawal,
            successful,
            'successful %': successful / (total / 100),
            'successful deposits': successfulDeposits * 100 / deposits,
            'successful withdrawal': successfulWithdrawal * 100 / withdrawal,
            'unsuccessful': unsuccessful,
            'unsuccessful %': unsuccessful / (total / 100),
            'unsuccessful deposits': (deposits - successfulDeposits) * 100 / deposits,
            'unsuccessful withdrawal': (withdrawal - successfulWithdrawal) * 100 / withdrawal,
            fail,
            'fail %': fail / (total / 100),
            'fail deposit': failDeposit * 100 / deposits,
            'fail withdrawal': failWithdrawal * 100 / withdrawal,
            'in progress': inProgress,
            'in progress %': inProgress / (total / 100),
            'in progress deposits': inProgressDeposits * 100 / (deposits - successfulDeposits),
            'in progress withdrawal': inProgressWithdrawal * 100 / (withdrawal - successfulWithdrawal),
        };
    });

    return { ...rowDates };
};

const getClientOperatorName = (params) => {
    const { clientsList, gatesList, id } = params;
    const [clientId, gateId] = id.split(':');
    const name = [];
    if (clientId) name.push(findTitleById(clientsList, clientId));
    if (gateId) name.push(findTitleById(gatesList, gateId));
    const strName = name.join('/');

    return strName ? strName:'total values';
};

export const arrayMapByName = (params) => {
    const { income, periods, clients, clientsList, gatesList, gates } = params;

    const iteratorArr = [...new Set(income.map(el => `${el.client || ''}:${el.gate || ''}`))];

    // ['10:2','10:3','11:2'];
    return iteratorArr?.map(id => {
        const periodsFilled = getPeriodsForClient(income, periods, id);
        return opNames.map(opName => {
            let total = 0;
            const line = {
                client: getClientOperatorName({ clientsList, gatesList, id }),
                opName,
            };
            periods.forEach((period) => {
                const num = periodsFilled[period][opName];
                line[period] = percentOperationNames.indexOf(opName) === -1 ? num : formatNum(num, true);
                total += num;
            });
            return {
                ...line,
                total: percentOperationNames.indexOf(opName) === -1 ? total || '-' : '-',
            };
        });
    });
};

export const getTableData = (params) => {
    const { income, periods, clients, clientsList, gatesList, gates } = params;
    if (!income?.length) return [];
    return arrayMapByName({ income, periods, clients, clientsList, gatesList, gates });
};
