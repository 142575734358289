import React from 'react';
import cn from 'classnames';
import styles from './SquareBlock.less';
/*
negative="-1%"
value="27.3"
text="max withdrawal"
*/
export const SquareBlock = (props) => {
    const currency = props.currency === ' ' ? 'USD' : props.currency;
    return (
        <div className={styles.pieWidget}>
            <div className={styles.arrow}>
                {props.positive && (
                    <div className={styles.arrowTop}>
                        {props.positive}&nbsp;<i className="fas fa-angle-up"> </i>
                    </div>
                )}
                {props.negative && (
                    <div className={styles.arrowBottom}>
                        {props.negative}&nbsp;<i className="fas fa-angle-down"> </i>
                    </div>
                )}
            </div>
            <div className={cn(styles.amountSum, styles.widgetName)}>
                {props.value}
            </div>
            <div className={cn(styles.widgetName)}>{props.text}</div>
        </div>

    );
};
