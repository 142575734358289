import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { DashboardPanel } from '../../components/shared/DashboardPanel/DashboardPanel';

const Dashboard = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active={"Dashboard"} />
            <DashboardPanel history={props.history}/>
        </div>
    );
};

export default Dashboard;
