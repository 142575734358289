import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { Agents } from './agents.consts';
import { Sms } from '../sms/sms.consts';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getAgents = handleActions(
    {
        [Agents.GET_AGENTS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENTS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENTS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Agents.GET_AGENTS_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const getAgentInfo = handleActions(
    {
        [Agents.GET_AGENT_INFO_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENT_INFO_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENT_INFO_ERROR]: (state, action) =>{
            return update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            })
        } ,
        [Agents.GET_AGENT_INFO_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getAgentDetails = handleActions(
    {
        [Agents.GET_AGENT_DETAILS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENT_DETAILS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.GET_AGENT_DETAILS_ERROR]: (state, action) =>{
            return update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            })
        } ,
        [Agents.GET_AGENT_DETAILS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const getCashiers = handleActions(
    {
        [Agents.GET_CASHIERS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.GET_CASHIERS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.GET_CASHIERS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Agents.GET_CASHIERS_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const createAgent = handleActions(
    {
        [Agents.CREATE_AGENT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.CREATE_AGENT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.CREATE_AGENT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Agents.CREATE_AGENT_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const editAgent = handleActions(
    {
        [Agents.EDIT_AGENT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.EDIT_AGENT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.EDIT_AGENT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Agents.EDIT_AGENT_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const deleteAgent = handleActions(
    {
        [Agents.DELETE_AGENT_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.DELETE_AGENT_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.DELETE_AGENT_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const createCashier = handleActions(
    {
        [Agents.CREATE_CASHIER_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.CREATE_CASHIER_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.CREATE_CASHIER_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);

export const editCashier = handleActions(
    {
        [Agents.EDIT_CASHIER_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.EDIT_CASHIER_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload.data },
                errors: { $set: null },
            });
        },
        [Agents.EDIT_CASHIER_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
    },
    defaultState,
);


export const uploadFile = handleActions(
    {
        [Agents.FILE_SEND_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Agents.FILE_SEND_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Agents.FILE_SEND_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Agents.FILE_SEND_CLEAR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            }),
    },
    defaultState,
);


export const agents = combineReducers({
    getCashiers,
    createCashier,
    getAgents,
    createAgent,
    editCashier,
    editAgent,
    deleteAgent,
    getAgentInfo,
    getAgentDetails,
    uploadFile,
});
