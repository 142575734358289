import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import styles from './AgentsEdit.less';
import {
    accountsListHeaders,
    addCurrenciesName,
    commissionFields,
    emptyAgent,
    noErrorsFields,
    requiredFields,
    tabNames,
} from './AgentsEditHelper';
import { TabMenu } from '../../TabMenu/TabMenu';
import { TableHeader } from '../../TableHeader/TableHeader';
import { TableBody } from '../../TableBody/TableBody';
import { onCurrenciesFetch, onGetCountriesFetch } from '../../../../store/dictionaries/dictionaries.actions';
import { INPUT_PASSWORD_TYPE, LOGIN_MIN_LENGTH, PWD_REGEXP } from '../../../../utils/consts/global';
import { getCountriesForDD } from '../../../../utils/helpers/commonHelpers';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { AgentFields } from '../AgentFields/AgentFields';


export class AgentsEditComponent extends React.Component {

    state = {
        selectedAgent: this.props.createAgent ? { ...emptyAgent } : this.props.agent,
        passwordType: INPUT_PASSWORD_TYPE,
        passwordType2: INPUT_PASSWORD_TYPE,
        activeTab: tabNames.AGENT_TAB,
        errorFields: { ...noErrorsFields() },
        menuItems: [{ name: tabNames.AGENT_TAB }], //{ name: tabNames.ACCOUNT_TAB }, { name: tabNames.CASHIERS_TAB }
    };

    componentDidMount() {
        if (!this.props.currencies) {
            this.props.onCurrenciesFetch();
        }
        if (!this.props.countries) {
            this.props.onGetCountriesFetch();
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.agent) !== JSON.stringify(this.props.agent)) {
            const { id, name } = this.props.agent;
            this.setState({
                selectedAgent: {
                    ...emptyAgent,
                    ...this.props.agent,
                    name: name || '',
                    id,
                },
            });
        }
    }

    /*    changePasswordType = () => {
            this.setState({
                passwordType: this.state.passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE
            });
        };*/

    changeInputHandler = (event) => {
        event.persist();
        const name = event.target.name.split(':')[0];
        const value = event.target.value;
        let {
            selectedAgent: {
                agentLogin,
                cashierLogin,
                password,
            },
            errorFields,
        } = this.state;

        const errors = { ...errorFields };

        if (commissionFields.indexOf(name) !== -1) {
            errors[name] = value && (value >= 0 && value <= 1);
        }

        if (name === 'password') {
            if (this.props.createAgent || value.length) {
                errors[name] = !!value.match(PWD_REGEXP);
            } else {
                errors[name] = true;
            }
        }

        if (name === 'agentLogin') {
            errors[name] = value && value.length > LOGIN_MIN_LENGTH;
        }

        if (requiredFields.indexOf(name) !== -1) {
            errors[name] = value && value.length;
        }

        this.setState((prev) => ({
            ...prev,
            errorFields: errors,
            selectedAgent: {
                ...this.state.selectedAgent,
                [name]: value,
            },
        }));
    };

    onCancelEdit = () => {
        if (this.props.onChange) {
            this.props.onChange({
                agent: null,
            });
        }
    };

    onConfirmEdit = () => {
        if (this.props.onChange) {
            this.props.onChange({
                agent: this.state.selectedAgent,
            });
        }
    };

    isFormValid = () => {

        const requiredFields = [
            'name', 'legalName', 'country', 'agentLogin',
            'city', 'streetAddress'];
        const { selectedAgent, errorFields } = this.state;
        let valid = true;

        if (this.props.createAgent) {
            requiredFields.push('password');
        }

        if (selectedAgent.password.length > 0 && !selectedAgent.password.match(PWD_REGEXP)) {
            return false;
        }

        requiredFields.forEach(el => {
            if (!selectedAgent[el] || !selectedAgent[el]?.length) {
                valid = false;
            }
        });

        for (const key in errorFields) {
            if (!errorFields[key]) {
                valid = false;
            }
        }

        if (selectedAgent.agentLogin.length <= LOGIN_MIN_LENGTH) {
            // if ((selectedAgent.cashierLogin.length <= LOGIN_MIN_LENGTH) || (selectedAgent.agentLogin.length <= LOGIN_MIN_LENGTH)) {
            valid = false;
        }

        return valid;
    };

    setTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    handleCountrySelect = (selected) => {
        this.setState((prev) => ({
            ...prev,
            selectedAgent: {
                ...this.state.selectedAgent,
                'country': selected.id,
            },
        }));
    };


    enableAgent = () => {
        const { enabled } = this.state.selectedAgent;
        this.setState((prev) => ({
            ...prev,
            selectedAgent: {
                ...this.state.selectedAgent,
                enabled: !enabled,
            },
        }));
    };

    onDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete(this.state.selectedAgent);
        }
    };

    render() {
        const { createAgent, currencies } = this.props;
        const { errorFields } = this.state;
        const {
            name, legalName, country,
            postCode, city, streetAddress,
            phone, email, NIF, RC,
            sender_commission,
            sender_bonus_instant,
            recipient_commission,
            recipient_bonus,
            payer_commission_top,
            payer_commission_external,
            agentLogin,
            password,
            description,
        } = this.state.selectedAgent;

        const isDisabled = !this.isFormValid();
        const accounts = addCurrenciesName(this.props.agent?.accounts || [], currencies || []);
        const countriesDD = getCountriesForDD(this.props.countries || []);
        const enabled = this.state.selectedAgent.enabled || false;
        // const editing = props.editAgent;

        const params = {
            enableAgent: this.enableAgent,
            changeInputHandler: this.changeInputHandler,
            handleCountrySelect: this.handleCountrySelect,
            createAgent,
            enabled,
            name,
            legalName,
            countriesDD,
            country,
            city,
            errorFields,
            streetAddress,
            postCode,
            email,
            phone,
            NIF,
            RC,
            description,
            sender_commission,
            sender_bonus_instant,
            recipient_commission,
            recipient_bonus,
            payer_commission_top,
            payer_commission_external,
            agentLogin,
            password,
        };
        return (
            <>
                <TabMenu
                    className={styles.tabMargin}
                    menuItems={this.state.menuItems}
                    active={this.state.activeTab}
                    onChange={this.setTab}
                />
                {this.state.activeTab === tabNames.AGENT_TAB && (<>
                    <div className={styles.agentDetails}>
                        <AgentFields {...params} />
                    </div>

                    <ButtonsOkCancelBlock
                        onCancelEdit={this.onCancelEdit}
                        onConfirmEdit={this.onConfirmEdit}
                        okIsDisabled={isDisabled}
                        onDelete={!createAgent && this.onDelete}
                    />

                    <div className={styles.bottomDescr}>
                        <p>* - mandatory field</p>
                        <p>** - mandatory, length > {LOGIN_MIN_LENGTH} characters</p>
                        <p>*** - mandatory, length: 8-30 and 1+ lowercase, 1+ uppercase, 1+ digit, 1+ special
                            character</p>
                    </div>
                </>)}
                {this.state.activeTab === tabNames.ACCOUNT_TAB && (
                    <div className={cn(styles.sideMargin)}>
                        {!!accounts.length && (
                            <table
                                className={cn('display table table-striped table-hover dataTable', styles.autoWidth)}>
                                <TableHeader
                                    rowNames={accountsListHeaders}
                                    hasCollapseSwich={false}
                                />
                                <TableBody
                                    dataTable={accounts}
                                    rowNames={accountsListHeaders}
                                    hasCollapseSwich={false} />
                            </table>
                        )}
                    </div>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    onCurrenciesFetch,
    onGetCountriesFetch,
};

const mapStateToProps = (state) => ({
    getAgents: state.agents.getAgents,
    currencies: state.dictionaries.getCurrencies.success,
    countries: state.dictionaries.getCountries.success,
});

export const AgentsEdit = connect(mapStateToProps, mapDispatchToProps)(AgentsEditComponent);
