import { call, put, takeEvery } from "redux-saga/effects";
import { Sms } from "./sms.consts";
import { errorNotify } from "../helpers/commonHelpers";
import { onHandleErrors } from "../errors/errors.actions";
import { fetchSmsService, sendBulkSmsFile } from "./sms.services";
import {
    onCancelScheduledError, onCancelScheduledSuccess,
    onSmsBulkError,
    onSmsBulkSuccess,
    onSmsFileSendError,
    onSmsFileSendSuccess,
    onSmsReportError, onSmsReportHistoryError, onSmsReportHistorySuccess,
    onSmsReportSuccess,
    onSmsReportSummaryError,
    onSmsReportSummarySuccess,
    onSmsReportUndeliveredError,
    onSmsReportUndeliveredSuccess, onSmsScheduledError, onSmsScheduledSuccess,
} from './sms.actions';

export function* sagaSmsReportFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "GetSmsReport"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSmsReportError(error));
        } else if (response) {
            yield put(onSmsReportSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsReportError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsReportError(error));
    }
}

export function* sagaSmsReportHistoryFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "GetSmsReportV2History"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSmsReportHistoryError(error));
        } else if (response) {
            yield put(onSmsReportHistorySuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsReportHistoryError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsReportHistoryError(error));
    }
}


export function* sagaSmsScheduledFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "GetSmsReportV2Scheduled"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSmsScheduledError(error));
        } else if (response) {
            yield put(onSmsScheduledSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsScheduledError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsScheduledError(error));
    }
}


export function* sagaSmsCancelScheduledFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "CancelScheduledBatch"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCancelScheduledError(error));
        } else if (response) {
            yield put(onCancelScheduledSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onCancelScheduledError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCancelScheduledError(error));
    }
}


export function* sagaSmsReportSummaryFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "GetSmsReportV2Summary"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSmsReportSummaryError(error));
        } else if (response) {
            yield put(onSmsReportSummarySuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsReportSummaryError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsReportSummaryError(error));
    }
}

export function* sagaSmsReportUndeliveredFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "GetSmsReportV2Undelivered"
            }
        );

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSmsReportUndeliveredError(error));
        } else if (response) {
            yield put(onSmsReportUndeliveredSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsReportUndeliveredError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsReportUndeliveredError(error));
    }
}

/*
* FORM DATA FOR CHECK FORM POST DATA
*/
export function* sagaSmsBulkFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            sendBulkSmsFile, payload
        );
        if (error) {
            // yield put(onHandleErrors(error));
            yield put(onSmsBulkError(error));
        } else if (response) {
            yield put(onSmsBulkSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsBulkError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsBulkError(error));
    }
}

/*
* FORM DATA FOR SEND FORM POST DATA
*/
export function* sagaSendSmsFileFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            fetchSmsService,
            {
                ...payload,
                method: "SendSmsBatch"
            }
        );
        if (error) {
            // yield put(onHandleErrors(error));
            yield put(onSmsFileSendError(error));
        } else if (response) {
            yield put(onSmsFileSendSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSmsFileSendError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSmsFileSendError(error));
    }
}

export function* smsSaga() {
    yield takeEvery(Sms.SMS_REPORT_FETCH, sagaSmsReportFetch);
    yield takeEvery(Sms.SMS_REPORT_HISTORY_FETCH, sagaSmsReportHistoryFetch);
    yield takeEvery(Sms.SMS_BULK_FETCH, sagaSmsBulkFetch);
    yield takeEvery(Sms.SMS_FILE_SEND_FETCH, sagaSendSmsFileFetch);
    yield takeEvery(Sms.GET_SMS_REPORT_UNDELIVERED_FETCH, sagaSmsReportUndeliveredFetch);
    yield takeEvery(Sms.GET_SMS_REPORT_SUMMARY_FETCH, sagaSmsReportSummaryFetch);
    yield takeEvery(Sms.SMS_SCHEDULED_FETCH, sagaSmsScheduledFetch);
    yield takeEvery(Sms.SMS_CANCEL_SCHEDULED_BATCHES_FETCH, sagaSmsCancelScheduledFetch);
}
