/*
 Add leading zeros to number(num), size = count of numbers // pad(109, 12) = '000000000109'
*/
export const pad = (num, size) => {
    const s = '000000000000000000' + num;
    return s.substr(s.length - size);
};

export const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.startsWith(' ')) {
            c = c.substring(1);
        }
        if (c.startsWith(name)) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
};

export function setCookie(name, value, options) {
    options = options || {};

    let { expires } = options;

    if (typeof expires === 'number' && expires) {
        const d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires?.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = `${name}=${value}; path=/`;

    for (const propName in options) {
        updatedCookie += `; ${propName}`;
        const propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += `=${propValue}`;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie(name) {
    setCookie(name, '', {
        expires: -1,
    });
}

export const sortingClass = {
    asc: 'asc',
    desc: 'desc',
};

/*
 * dataTable = [ {columnName: '...', ...}, ...]
 * sortType = sortingClass.asc/desc
 * columnName: string
 */

export const sortTable = ({ dataTable, sortParams }) => {
    if (!sortParams) return dataTable || [];
    const { sortType } = sortParams;
    const columnName = sortParams.sortingByColumnName.name;

    if (!dataTable?.length || !sortType || !columnName) return dataTable || [];
    const table = [...dataTable];

    return table.sort((a, b) => {
        const aVal = a[columnName];
        const bVal = b[columnName];
        if (isNumber(aVal) && isNumber(bVal)) {
            return checkValues(parseFloat(aVal), parseFloat(bVal), sortType);
        } else {
            return checkValues(String(aVal)?.toLowerCase(), String(bVal)?.toLowerCase(), sortType);
        }
    });
};

/*
 * callback sort array function for strings compare
 * a, b : number|string
 * sortType = sortingClass
 */
function checkValues(a, b, sortType) {
    if (sortType === sortingClass.asc) {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    } else {
        if (a < b) {
            return 1;
        }
        if (a > b) {
            return -1;
        }
        return 0;
    }
}


/*
* in: any Object
* Out: new object with not nulled values.
* method not support included obj.
*/

export const getNotNullableFieldsFromObj = (obj) => {
    const newObj = {};
    for (let key in obj) {
        if (obj[key]) newObj[key] = obj[key];
    }
    return newObj;
};

export const getSearchParams = (searchStr) => {
    const pairs = searchStr.substring(1).split('&'),
        obj = {};
    let pair, i;

    for (i in pairs) {
        if (pairs[i] === '') continue;

        pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
};
