export const emptyContact = {
    managerName: '',
    managerPhone: '',
    managerEmail: '',
};

export const emptyDocument = {
    title: '',
    fileId: '',
};

const allowedExtension = ['.png', '.jpg', '.pdf', '.bmp', '.xls', '.doc', '.docx'];

export const isExtensionsValid = (target) => {
    let valid = false;

    for (let i = 0; i < target.files?.length; i++) {
        let file = target.files[i];

        if (file.name.endsWith(allowedExtension[0]) || file.name.endsWith(allowedExtension[1])) {
            valid = true;
        }
    }
    return valid;
};
