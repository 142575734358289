import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { DepositMerchantPanel } from "../../components/shared/DepositMerchantPanel/DepositMerchantPanel";
import { SETTLEMENT_AGENT  } from "../../utils/consts/global";

const SettlementAgent = () => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active={SETTLEMENT_AGENT} />
            <DepositMerchantPanel operation={SETTLEMENT_AGENT}/>
        </div>
    );
};

export default SettlementAgent;
