import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './MerchantDetails.less';
import { useDispatch, useSelector } from 'react-redux';
import { SMALL_SCREEN } from '../../../../utils/consts/global';
import { TabMenu } from '../../TabMenu/TabMenu';
import { useHistory } from 'react-router-dom';
import { getSearchParams } from '../../../../utils/helpers/commonFunctions';
import { Dictionaries } from '../../../../store/dictionaries/dictionaries.consts';
import { MerchantSummaryTab } from '../MerchantSummaryTab/MerchantSummaryTab';
import { getDataForDD, getDataForDropDown } from '../../../../utils/helpers/commonHelpers';
import { menuItems, NEW_LEGAL } from './MerchantDetails.helper';
import { MerchantDetailTab } from '../MerchantDetailTab/MerchantDetailTab';
import { Merchants } from '../../../../store/merchants/merchants.consts';
import { MerchantCard } from '../MerchantCard/MerchantCard';
import { emptyLegal } from '../MerchantDetailTab/MerchantDetailTab.helper';
import { Spinner } from '../../Spinner/Spinner';
import { LegalPaymentMethods } from '../LegalPaymentMethods/LegalPaymentMethods';
import SweetAlert from 'react-bootstrap-sweetalert/dist';

export const MerchantDetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tabId = getSearchParams(history.location.search)?.tabId || 0;
    const [activeTab, setActiveTab] = useState(menuItems[tabId].name);
    const [newLegalName, setNewLegalName] = useState(null);

    const [createMode, setCreateMode] = useState(getSearchParams(history.location.search)?.createMode === 'true');
    const intMerchantID = getSearchParams(history.location.search)?.merchantId;

    const getGate = useSelector((state) => state.dictionaries.getGate.success);
    const getCountries = useSelector((state) => state.dictionaries.getCountries.success);
    const allBranches = useSelector((state) => state.dictionaries.getBranches.success);
    const getCurrencies = useSelector((state) => state.dictionaries.getCurrencies.success);
    const getMerchantLegal = useSelector((state) => state.merchants.getMerchantLegal.success);
    const addMerchantLegal = useSelector((state) => state.merchants.addMerchantLegal);
    const getMerchantCard = useSelector((state) => state.merchants.getMerchantCard.success);
    const setLegalPaymentMethods = useSelector((state) => state.merchants.setLegalPaymentMethods.success);
    const getWL = useSelector((state) => state.dictionaries.getWL.success);
    const getBanks = useSelector((state) => state.dictionaries.getBanks.success);
    const publishLegal = useSelector((state) => state.merchants.publishLegal);

    const currencies = getDataForDropDown(getCurrencies || [], 'codeAlpha', true) || [];
    const wl = getDataForDD(getWL || []);
    const banks = getDataForDD(getBanks || []);

    const merchant = { ...getMerchantCard || {} };
    const gates = getDataForDD(getGate || []);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);


    const [legalsList, setLegalList] = useState(getDataForDD(getMerchantCard?.legals || [], 'merchantLegalName'));
    const [selectedLegal, setSelectedLegal] = useState(createMode ? null : legalsList[0]);
    const legal = createMode ? { ...emptyLegal || {} } : { ...getMerchantLegal || {} };

    if (getMerchantCard?.paramsJSON) {
        merchant.params = getMerchantCard.paramsJSON ? JSON.parse(getMerchantCard.paramsJSON) : {};
    }

    if (getMerchantLegal?.paramsJSON && !createMode) {
        legal.params = getMerchantLegal.paramsJSON;
    }

    useEffect(() => {
        const intID = intMerchantID;

        dispatch({
            type: Dictionaries.GET_GATE_FETCH,
            payload: {},
        });

        if (intID) {
            dispatch({
                type: Merchants.MERCHANT_GET_CARD_FETCH,
                payload: {
                    intID,
                },
            });
        }

        if (!allBranches?.length) {
            dispatch({
                type: Dictionaries.GET_BRANCHES_FETCH,
                payload: {},
            });
        }
        if (!getWL?.length) {
            dispatch({
                type: Dictionaries.GET_WL_FETCH,
                payload: {},
            });
        }
        if (!getBanks?.length) {
            dispatch({
                type: Dictionaries.GET_BANKS_FETCH,
                payload: {},
            });
        }

        if (!getCurrencies) {
            dispatch({
                type: Dictionaries.GET_CURRENCIES_FETCH,
                payload: {},
            });
        }
        if (!getCountries) {
            dispatch({
                type: Dictionaries.GET_COUNTRIES_FETCH,
                payload: {},
            });
        }

        return () => {
            setSelectedLegal(null);
        };
    }, []);

    const getLegalInfo = () => {
        if (!selectedLegal) return;
        const intID = selectedLegal?.id;

        if (intID && intMerchantID) {
            dispatch({
                type: Merchants.MERCHANT_GET_LEGAL_FETCH,
                payload: {
                    intID,
                    intMerchantID,
                },
            });
        }
    };

    useEffect(() => {
        if (publishLegal?.success) {
            getLegalInfo();
            setShowSuccess(true);
            dispatch({
                type: Merchants.LEGAL_PUBLISH_CLEAR,
                payload: null,
            });
        }
    }, [publishLegal]);

    useEffect(() => {
        if (getMerchantCard) {
            const legalsList = getDataForDD(getMerchantCard?.legals || [], 'merchantLegalName');
            setLegalList(legalsList);
            if (getMerchantCard?.legals.length) {
                setSelectedLegal(legalsList[0]);
            }
        }
    }, [getMerchantCard]);

    useEffect(() => {
        if (setLegalPaymentMethods) {
            setShowSuccess(true);
            dispatch({ type: Merchants.LEGAL_SET_METHODS_CLEAR });
        }
    }, [setLegalPaymentMethods]);

    useEffect(() => {
        if (addMerchantLegal.success) {
            const newLegal = { id: addMerchantLegal.success.id, title: newLegalName };
            const newLegalList = [
                ...legalsList,
                newLegal,
            ];
            setLegalList(newLegalList);
            setSelectedLegal(newLegal);
            if (createMode) {
                setActiveTab(menuItems[2].name);
                setCreateMode(false);
            }
        }
    }, [addMerchantLegal.success]);

    useEffect(() => {
        getLegalInfo();
    }, [selectedLegal]);


    const submitMethods = (methods) => {
        setNewLegalName(null);

        const arrMethods = methods.filter(el => el.on).map(el => {
            return {
                gate: el.gateId,
                currencies: el.currencies.selected,
                flag: el.flag.selected[0] || '',
                proxygate: el.proxygate.selected || null,
            };
        });

        const payload = {
            intLegalID: selectedLegal?.id,
            intMerchantID: intMerchantID,
            arrMethods,
        };

        dispatch({
            type: Merchants.LEGAL_SET_METHODS_FETCH,
            payload,
        });
    };

    const onSelectLegal = (value, name) => {
        const { id } = value;
        setSelectedLegal({ [name]: id });
        setCreateMode(false);
    };

    const addLegal = () => {
        setCreateMode(true);
        setActiveTab(menuItems[1].name);
    };

    const onCancelLegal = () => {
        setCreateMode(false);
        setActiveTab(menuItems[0].name);
    };

    const onConfirmLegal = (payload) => {
        let type = createMode ? Merchants.MERCHANT_ADD_LEGAL_FETCH : Merchants.MERCHANT_EDIT_LEGAL_FETCH;

        dispatch({
            type,
            payload,
        });

        setNewLegalName(payload.strMerchantLegalName);
    };

    const closeModal = () => {
        setShowSuccess(false);
    };

    const onCancelPublish = () => {
        setShowConfirm(false);
    };

    const onPublishLegal = () => {
        const intLegalID = selectedLegal?.id;

        if (intLegalID && intMerchantID) {
            dispatch({
                type: Merchants.LEGAL_PUBLISH_FETCH,
                payload: {
                    intLegalID,
                    intMerchantID,
                },
            });
        }
        setShowConfirm(false);
    };

    const onConfirmPublish = () => {
        setShowConfirm(true);
    };

    const dict = {
        branchesFull: allBranches,
        wl,
        banks,
        currencies,
        gates,
    };

    return (
        <div className={cn(styles.mainPanel, 'main-panel')}>
            <div className="container">
                <div className="page-inner">
                    {SMALL_SCREEN && <h1>Merchants list</h1>}
                    <div className="col-md-12">
                        <div className={cn(styles.card, 'card')}>
                            <div className={cn(styles.cardWrapper)}>
                                <MerchantCard
                                    merchant={merchant}
                                    onChangeCreate={false}
                                    legalsList={legalsList}
                                    onSelectLegal={onSelectLegal}
                                    selectedLegal={selectedLegal}
                                    onCreate={addLegal}
                                    createMode={createMode}
                                    legalName={newLegalName}
                                    dict={dict}
                                />

                                {((getMerchantLegal && selectedLegal) || createMode) && (
                                    <div>
                                        <div className={styles.borderBottomLine}>
                                            <TabMenu
                                                className={styles.tabMargin}
                                                menuItems={menuItems}
                                                active={activeTab}
                                                onChange={setActiveTab}
                                                disabled={legal.state === NEW_LEGAL}
                                            />
                                        </div>

                                        {addMerchantLegal.fetching && (
                                            <div className={styles.spinnerPlace}>
                                                <Spinner />
                                            </div>
                                        )}

                                        {activeTab === menuItems[0].name && (
                                            <MerchantSummaryTab
                                                legal={legal}
                                                onPublish={onConfirmPublish}
                                            />
                                        )}

                                        {activeTab === menuItems[1].name && (
                                            <MerchantDetailTab
                                                merchant={merchant}
                                                legal={legal}
                                                createMode={createMode}
                                                dict={dict}
                                                allBranches={allBranches}
                                                onCancel={onCancelLegal}
                                                onConfirm={onConfirmLegal}
                                            />
                                        )}

                                        {activeTab === menuItems[2].name && (
                                            <LegalPaymentMethods
                                                merchant={merchant}
                                                legal={legal}
                                                dict={dict}
                                                createMode={createMode}
                                                onSubmit={submitMethods}
                                                showNext={false}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSuccess && (
                <SweetAlert
                    show={showSuccess}
                    title="Success!"
                    type="success"
                    text="Operation successful"
                    confirmButtonColor="#1572E8"
                    onConfirm={closeModal}
                />
            )}
            {showConfirm && (
                <SweetAlert
                    show={showConfirm}
                    title=""
                    type="warning"
                    cancelBtnBsStyle="primary"
                    onConfirm={onPublishLegal}
                    onCancel={onCancelPublish}
                    showCancel
                >
                    <h1 className={styles.heading}>Are you sure you want to publish</h1>
                    <div>
                        <h1>{legal?.merchantLegalName}?</h1>
                    </div>
                </SweetAlert>
            )}
        </div>
    );
};
