import { Transactions } from './transactions.consts';

/*
 TRANSACTIONS
*/
export const onTransactionsFetch = (payload) => ({
    type: Transactions.TRA_OPERATIONS_FETCH,
    payload,
});

export const onTransactionsSuccess = (payload) => ({
    type: Transactions.TRA_OPERATIONS_SUCCESS,
    payload,
});

export const onTransactionsError = (payload) => ({
    type: Transactions.TRA_OPERATIONS_ERROR,
    payload,
});

export const onTransactionsClear = (payload) => ({
    type: Transactions.TRA_OPERATIONS_CLEAR,
    payload,
});

/*
 GetPaymentState
*/
export const onGetPaymentStateFetch = (payload) => ({
    type: Transactions.GET_PAYMENT_STATE_FETCH,
    payload,
});

export const onGetPaymentStateSuccess = (payload) => ({
    type: Transactions.GET_PAYMENT_STATE_SUCCESS,
    payload,
});

export const onGetPaymentStateError = (payload) => ({
    type: Transactions.GET_PAYMENT_STATE_ERROR,
    payload,
});

export const onGetPaymentStateClear = (payload) => ({
    type: Transactions.GET_PAYMENT_STATE_CLEAR,
    payload,
});

/*
 TRANSIT LOG
*/
export const onTransitLogFetch = (payload) => ({
    type: Transactions.TRA_TRANSIT_LOG_FETCH,
    payload,
});

export const onTransitLogSuccess = (payload) => ({
    type: Transactions.TRA_TRANSIT_LOG_SUCCESS,
    payload,
});

export const onTransitLogError = (payload) => ({
    type: Transactions.TRA_TRANSIT_LOG_ERROR,
    payload,
});

export const onTransitLogClear = (payload) => ({
    type: Transactions.TRA_TRANSIT_LOG_CLEAR,
    payload,
});

/*
 BALANCES
*/
export const onBalancesFetch = (payload) => ({
    type: Transactions.TRA_BALANCES_FETCH,
    payload,
});

export const onBalancesSuccess = (payload) => ({
    type: Transactions.TRA_BALANCES_SUCCESS,
    payload,
});

export const onBalancesError = (payload) => ({
    type: Transactions.TRA_BALANCES_ERROR,
    payload,
});

export const onBalancesClear = (payload) => ({
    type: Transactions.TRA_BALANCES_CLEAR,
    payload,
});

/*
 BALANCE AMOUNT
*/
export const onBalanceAmountFetch = (payload) => ({
    type: Transactions.TRA_BALANCE_AMOUNT_FETCH,
    payload,
});

export const onBalanceAmountSuccess = (payload) => ({
    type: Transactions.TRA_BALANCE_AMOUNT_SUCCESS,
    payload,
});

/*
 ACCOUNTS INFO
*/
export const onAccountsFetch = (payload) => ({
    type: Transactions.TRA_ACCOUNTS_INFO_FETCH,
    payload,
});

export const onAccountsSuccess = (payload) => ({
    type: Transactions.TRA_ACCOUNTS_INFO_SUCCESS,
    payload,
});

export const onAccountsError = (payload) => ({
    type: Transactions.TRA_ACCOUNTS_INFO_ERROR,
    payload,
});

export const onAccountsClear = (payload) => ({
    type: Transactions.TRA_ACCOUNTS_INFO_CLEAR,
    payload,
});

/*
 SUMMARY
*/
export const onSummaryFetch = (payload) => ({
    type: Transactions.TRA_SUMMARY_FETCH,
    payload,
});

export const onSummarySuccess = (payload) => ({
    type: Transactions.TRA_SUMMARY_SUCCESS,
    payload,
});

export const onSummaryError = (payload) => ({
    type: Transactions.TRA_SUMMARY_ERROR,
    payload,
});

export const onSummaryClear = (payload) => ({
    type: Transactions.TRA_SUMMARY_CLEAR,
    payload,
});


/*
 SUMMARY ACCOUNT LOGS
*/
export const onGetAccountLogFetch = (payload) => ({
    type: Transactions.TRA_GET_ACCOUNT_LOG_FETCH,
    payload,
});

export const onGetAccountLogSuccess = (payload) => ({
    type: Transactions.TRA_GET_ACCOUNT_LOG_SUCCESS,
    payload,
});

export const onGetAccountLogError = (payload) => ({
    type: Transactions.TRA_GET_ACCOUNT_LOG_ERROR,
    payload,
});

export const onGetAccountLogClear = (payload) => ({
    type: Transactions.TRA_GET_ACCOUNT_LOG_CLEAR,
    payload,
});
