import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootReducer } from './store/reducers.js';
import { rootSaga } from './store/sagas.js';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import * as moment from 'moment';

export const start = (initial, ...middlewares) => {
    moment.locale('ru');
    const history = createBrowserHistory({
        basename: BASE_URL,
    });

    history.listen((_) => {
        window.scrollTo(0, 0);
    });

    const routeMiddleware = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();
    const allMiddlewares = [...middlewares, routeMiddleware, sagaMiddleware];

    let enhancer = null;


    if (process.env.NODE_ENV === 'development') {
        const composeEnhancers =
            typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                      trace: true,
                      traceLimit: 25,
                  })
                : compose;
        allMiddlewares.push(logger);
        enhancer = composeEnhancers(applyMiddleware(...allMiddlewares));
    } else {
        enhancer = compose(applyMiddleware(...allMiddlewares));
    }

    const store = createStore(rootReducer(history), initial, enhancer);

    sagaMiddleware.run(rootSaga);
    return (Root) => (
        <Provider store={store}>
            <Root history={history} />
        </Provider>
    );
};
