import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './CreateAgent.less';
import { useDispatch, useSelector } from 'react-redux';
import { INPUT_PASSWORD_TYPE, INPUT_TEXT_TYPE } from '../../../../utils/consts/global';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { emptyAgent } from '../AgentsEdit/AgentsEditHelper';
import { Agents } from '../../../../store/agents/agents.consts';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import { useHistory } from 'react-router-dom';
import RoutesPaths from '../../../../routes/RoutesPaths';
import { AppActions } from '../../../../store/app/app.consts';

export const CreateAgent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [agent, setAgent] = useState({ ...emptyAgent });
    const [alertShown, setAlertShown] = useState(false);
    const [passwordType, setPasswordType] = useState(INPUT_PASSWORD_TYPE);
    const createAgent = useSelector((state) => state.agents.createAgent);

    useEffect(() => {
        const { success } = createAgent;
        if (success) {
            dispatch({
                type: Agents.CREATE_AGENT_CLEAR,
                payload: null,
            });
            history.push({
                pathname: RoutesPaths.AGENT,
                search: '?id=' + (success?.response?.clientID || 0) + '&agentUserID=' + (success?.user || 0) + '&tabId=1&createMode=true&newAgent=true',
            });
        }

        if (createAgent.error) {
            setAlertShown(true);
        }

    }, [createAgent]);

    const changePasswordType = () => {
        setPasswordType(passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE);
    };

    const changeInputHandler = (event) => {
        event.persist();
        setAgent({
            ...agent,
            [event.target.name]: event.target.value,
        });
    };

    const onConfirmEdit = () => {
        const payload = {
            ...agent,
            enabled: true,
            refID: Number(sessionStorage.getItem('ParentRefID')),
            refType: Number(sessionStorage.getItem('ParentRefType')),
            agentInfo: {
                contacts:
                    {
                        managerName: agent.name,
                        managerPhone: agent.phone,
                        managerEmail: agent.email,
                    },
                legals: [],
            },
        };

        dispatch({
            type: AppActions.APP_STORE_DATA,
            payload,
        });

        dispatch({
            type: Agents.CREATE_AGENT_FETCH,
            payload,
        });
    };

    const onCloseAlert = () => {
        setAlertShown(false);
    };

    return (
        <div className="main-panel">
            <div className="container">
                <div className="page-inner">
                    <div className={cn(styles.card)}>

                        <div className={cn(styles.createMerch)}>
                            <div className={styles.fillTitle}>
                                Agent information
                            </div>
                            <div className={styles.inputColumn}>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strMerchantBrandingName">Agent name:</label>
                                    <input type="text"
                                           id="agentName"
                                           name={'agentName'}
                                           value={agent.agentName}
                                           className={cn(styles.inputField)}
                                           onChange={changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactName">Contact name:</label>
                                    <input type="text"
                                           id="strContactName"
                                           name={'name'}
                                           value={agent.name}
                                           className={cn(styles.inputField)}
                                           onChange={changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactPhone">Contact phone:</label>
                                    <input type="text"
                                           id="phone"
                                           name={'phone'}
                                           value={agent.phone}
                                           className={cn(styles.inputField)}
                                           onChange={changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactEmail">Contact e-mail:</label>
                                    <input type="text"
                                           id="email"
                                           name={'email'}
                                           value={agent.email}
                                           className={cn(styles.inputField)}
                                           onChange={changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strLogin">Login:</label>
                                    <input type="text"
                                           id="agentLogin"
                                           name={'agentLogin'}
                                           value={agent.agentLogin}
                                           className={cn(styles.inputField)}
                                           onChange={changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strLogin">Password:</label>
                                    <div className={cn(styles.relative)}>
                                        <input
                                            type={passwordType}
                                            className={cn(styles.inputField)}
                                            name="password"
                                            value={agent.password}
                                            onChange={changeInputHandler}
                                        />
                                        <i className={cn('icon-eye', styles.iconEye, { [styles.selected]: passwordType !== INPUT_PASSWORD_TYPE })}
                                           onClick={changePasswordType}>&nbsp;</i>
                                    </div>
                                </div>

                                <div className={cn(styles.buttonPlace)}>
                                    <ButtonsOkCancelBlock
                                        id="next"
                                        hideCancelButton={true}
                                        okIsDisabled={false}
                                        onConfirmEdit={onConfirmEdit}
                                        className={styles.minButtonWidth}
                                        okText="Save"
                                    />
                                </div>

                            </div>
                        </div>


                        {alertShown && (
                            <SweetAlert
                                show={alertShown}
                                title=""
                                type="danger"
                                cancelBtnBsStyle="primary"
                                onConfirm={onCloseAlert}
                            >
                                <h1 className={styles.heading}>Unable to create {agent.legalName}</h1>
                                <div className={styles.deleteString}>
                                    <div className={styles.line}>
                                <span
                                    className={styles.bolder}>{createAgent.errors?.errorMessage?.split('|')[1] || 'unknown error'}</span>
                                    </div>
                                </div>
                            </SweetAlert>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};
