export const Transactions = {
    GET_PAYMENT_STATE_FETCH: 'GET_PAYMENT_STATE_FETCH',
    GET_PAYMENT_STATE_SUCCESS: 'GET_PAYMENT_STATE_SUCCESS',
    GET_PAYMENT_STATE_ERROR: 'GET_PAYMENT_STATE_ERROR',
    GET_PAYMENT_STATE_CLEAR: 'GET_PAYMENT_STATE_CLEAR',

    TRA_OPERATIONS_FETCH: 'OPERATIONS_FETCH',
    TRA_OPERATIONS_SUCCESS: 'TRA_OPERATIONS_SUCCESS',
    TRA_OPERATIONS_ERROR: 'TRA_OPERATIONS_ERROR',
    TRA_OPERATIONS_CLEAR: 'TRA_OPERATIONS_CLEAR',

    // GetTransitLogAdm
    TRA_TRANSIT_LOG_FETCH: 'TRA_TRANSIT_LOG_FETCH',
    TRA_TRANSIT_LOG_SUCCESS: 'TRA_TRANSIT_LOG_SUCCESS',
    TRA_TRANSIT_LOG_ERROR: 'TRA_TRANSIT_LOG_ERROR',
    TRA_TRANSIT_LOG_CLEAR: 'TRA_TRANSIT_LOG_CLEAR',

    TRA_BALANCES_FETCH: 'BALANCES_FETCH',
    TRA_BALANCES_SUCCESS: 'TRA_BALANCES_SUCCESS',
    TRA_BALANCES_ERROR: 'TRA_BALANCES_ERROR',
    TRA_BALANCES_CLEAR: 'TRA_BALANCES_CLEAR',

    TRA_BALANCE_AMOUNT_FETCH: 'TRA_BALANCE_AMOUNT_FETCH',
    TRA_BALANCE_AMOUNT_SUCCESS: 'TRA_BALANCE_AMOUNT_SUCCESS',

    TRA_ACCOUNTS_INFO_FETCH: 'ACCOUNTS_INFO_FETCH',
    TRA_ACCOUNTS_INFO_SUCCESS: 'TRA_ACCOUNTS_INFO_SUCCESS',
    TRA_ACCOUNTS_INFO_ERROR: 'TRA_ACCOUNTS_INFO_ERROR',
    TRA_ACCOUNTS_INFO_CLEAR: 'TRA_ACCOUNTS_INFO_CLEAR',

    TRA_SUMMARY_FETCH: 'SUMMARY_FETCH',
    TRA_SUMMARY_SUCCESS: 'TRA_SUMMARY_SUCCESS',
    TRA_SUMMARY_ERROR: 'TRA_SUMMARY_ERROR',
    TRA_SUMMARY_CLEAR: 'TRA_SUMMARY_CLEAR',

    TRA_GET_ACCOUNT_LOG_FETCH: 'TRA_GET_ACCOUNT_LOG_FETCH',
    TRA_GET_ACCOUNT_LOG_SUCCESS: 'TRA_GET_ACCOUNT_LOG_SUCCESS',
    TRA_GET_ACCOUNT_LOG_ERROR: 'TRA_GET_ACCOUNT_LOG_ERROR',
    TRA_GET_ACCOUNT_LOG_CLEAR: 'TRA_GET_ACCOUNT_LOG_CLEAR',
};
