import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Spinner } from '../Spinner/Spinner';
import {
    INPUT_PASSWORD_TYPE,
    INPUT_TEXT_TYPE, PERM_USER_ADD,
    PERM_GET_ALL_USERS,
    PERM_GET_DIRECT_USER_ROLES,
    PERM_GET_PERMISSION_GROUP,
    PERM_GET_PERMISSION_ROLE,
    PERM_GET_USER_GROUPS,
    SMALL_SCREEN,
    PERM_GET_GATE_ADM, PERM_GET_GATE,
} from '../../../utils/consts/global';

import {
    onAddDirectUserRoleFetch,
    onAddUserFetch,
    onAddUserGroupFetch,
    onEditUserFetch,
    onEnableUserFetch, onGetAllowedRRTFetch,
    onGetAllUsersFetch,
    onGetGroupsFetch,
    onGetRolesFetch,
    onGetUserGroupsFetch,
    onGetUserRolesFetch,
} from '../../../store/roleSystem/roleSystem.actions';
import { emptyUser, getNotEmpty, objToArray, rows, userTypes } from './AdmUsersPanelHelper';
import { ActionButton } from '../ActionButton/ActionButton';
import { Modal } from '../Modal/Modal';
import cn from 'classnames';
import styles from './AdmUsersPanel.less';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TableHeader } from '../TableHeader/TableHeader';
import { TableBody } from '../TableBody/TableBody';
import { DropDown } from '../DropDown/DropDown';
import { getDataForDD } from '../../../utils/helpers/commonHelpers';

import {
    onBranchesFetch,
    onGetGateAdmFetch,
    onGetGateFetch,
    onWLFetch,
} from '../../../store/dictionaries/dictionaries.actions';

export class AdmUsersComponent extends React.Component {
    state = {
        isEditShown: false,
        isAlertShown: false,
        selectedGroup: {},
        intClientID: null,
        intSubClientID: '',
        selectedUser: { ...emptyUser },
        createRoleMode: false,
        selectedUserId: '',
        selectedLkType: {},
        passwordType: INPUT_PASSWORD_TYPE,
    };

    MERCHANT_REF_TYPE = '1';
    AGENT_REF_TYPE = '10';
    SUPERAGENT_REF_TYPE = '11';

    componentDidUpdate(prevProps) {
        const users = this.props.roleSystem.getAllUsers.success;
        if (
            this.props.canReadUsers &&
            users === null &&
            !this.props.roleSystem.getAllUsers.fetching &&
            !this.props.roleSystem.getAllUsers.errors
        ) {
            this.props.onGetAllUsersFetch();
            if (this.props.canReadRoles && !this.props.roleSystem.getPermissionRoles.success) {
                this.props.onGetRolesFetch();
            }
            if (this.props.canReadGroups && !this.props.roleSystem.getPermissionGroups.success) {
                this.props.onGetGroupsFetch();
            }
            if (this.props.canReadDirectUserRoles && !this.props.roleSystem.getDirectUsersRoles.success) {
                this.props.onGetUserRolesFetch();
            }
            if (this.props.canReadDirectUserGroups && !this.props.roleSystem.getPermissionGroups.success) {
                this.props.onGetUserGroupsFetch();
            }
        }
    }

    componentDidMount() {
        this.props.onGetAllowedRRTFetch({});

        if (this.props.canReadRoles && !this.props.roleSystem.getPermissionRoles.success) {
            this.props.onGetRolesFetch();
        }
        if (this.props.canReadGroups && !this.props.roleSystem.getPermissionGroups.success) {
            this.props.onGetGroupsFetch();
        }
        if (this.props.canReadUsers) {
            this.props.onGetAllUsersFetch();
        }
        if (this.props.canReadDirectUserRoles) {
            this.props.onGetUserRolesFetch();
        }
        if (this.props.canReadDirectUserGroups) {
            this.props.onGetUserGroupsFetch();
        }
        if (!this.props.dictionaries.getWL.success) {
            this.props.onWLFetch();
        }
        if (!this.props.dictionaries.getBranches.success) {
            this.props.onBranchesFetch();
        }

        if (this.props.canReadGate) {
            // if(this.props.canReadGateAdm){
            //     this.props.onGetGateAdmFetch();
            // } else {
            this.props.onGetGateFetch();
            // }
        }
    }

    addUser = () => {
        this.setState({
            isEditShown: true,
            selectedUser: { ...emptyUser },
        });
    };

    onCloseAlert = () => {
        this.setState({ isAlertShown: false });
    };

    onConfirmDelete = () => {
        const { id, enabled } = this.state.selectedUser;
        const payload = {
            intUserID: id,
            boolEnabled: enabled === '0',
        };
        this.props.onEnableUserFetch(payload);
        this.onCloseAlert();
    };

    handleInputsChange = (event) => {
        const { target } = event;
        let { name, value } = target;
        event.persist();

        this.setState((prev) => ({
            ...prev,
            selectedUser: {
                ...emptyUser,
                ...prev.selectedUser,
                [name]: value,
            },
        }));
    };

    onCancelEdit = () => {
        this.setState({
            ...emptyUser,
            isEditShown: false,
        });
    };

    onConfirmEdit = () => {
        this.setState({
            isEditShown: false,
        });
        const { strPassword } = this.state.selectedUser;
        const { intClientID, intSubClientID } = this.state;

        const user = {
            ...this.state.selectedUser,
        };

        if (intClientID) {
            user.arrParams = {
                ...user.arrParams,
                intClientID
            };
        }

        if (intSubClientID) {
            user.arrParams = {
                ...user.arrParams,
                intSubClientID,
            };
        }

        if (strPassword) {
            user.strPassword = strPassword;
        }

        const payload = {
            ...user,
        };

        this.props.onAddUserFetch(payload);
    };

    onDataLineEvents = (e) => {
        if (e.type === 'edit') {
            const fullRoles = getNotEmpty(e.item.fullRoles);
            const fullGroups = getNotEmpty(e.item.fullGroups);
            this.setState({
                isEditShown: true,
                selectedUser: {
                    ...emptyUser,
                    ...e.item,
                    fullRoles,
                    fullGroups,
                    strEmail: e.item.email,
                },
            });
        }

        if (e.type === 'remove') {
            this.setState({
                isEditShown: false,
                isAlertShown: true,
                selectedUser: {
                    ...emptyUser,
                    ...e.item,
                    strEmail: e.item.email,
                },
            });
        }
    };

    getFullUsers = (users) => {
        const userGroups = this.props.roleSystem.getUsersGroups.success || {};
        const groupsObj = this.props.roleSystem.getPermissionGroups.success || {};
        const directUserRoles = this.props.roleSystem.getDirectUsersRoles.success || {};
        const rolesObj = this.props.roleSystem.getPermissionRoles.success || {};

        return (
            users.map((user) => {
                const userId = user.id;

                if (userGroups[userId]) {
                    const groups = [...Object.keys(userGroups[userId]).map((groupId) => groupsObj[groupId])] || [];
                    user.groups = groups.map((group) => group?.title);
                    user.fullGroups = groups;
                } else {
                    user.groups = [];
                    user.fullGroups = [];
                }

                if (directUserRoles[userId]) {
                    const roles = Object.keys(directUserRoles[userId]).map((roleId) => rolesObj[roleId]) || [];
                    user.roles = roles.map((role) => role?.title);
                    user.fullRoles = roles;
                } else {
                    user.roles = [];
                    user.fullRoles = [];
                }

                return user;
            }) || []
        );
    };


    handleRolesSelect = (item, name) => {
        this.setState((prev) => ({
            ...prev,
            arrClientIDs: [],
            intSubClientID: '',
            selectedUser: {
                ...prev.selectedUser,
                [name]: item.id,
            },
        }));
    };

    handleDDSelect = ({ item, name }) => {
        this.setState((prev) => ({
            ...prev,
            selectedUser: {
                ...prev.selectedUser,
                arrParams: {
                    ...prev.selectedUser.arrParams,
                    [name]: item.id,
                },
            },
        }));
    };

    handleClientSelect = (selected) => {
        this.setState((prev) => ({
            ...prev,
            intClientID: selected.id,
            intSubClientID: '',
        }));
    };

    handlerSubClientSelect = (sub) => {
        const { id } = sub;
        this.setState((prev) => ({
            ...prev,
            intSubClientID: id,
        }));
    };

    changePasswordType = () => {
        this.setState({
            passwordType: this.state.passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE,
        });
    };

    makeFlatDD = (obj) => {
        return obj.map(el => {
            return { id: el, title: el };
        });
    };

    render() {
        const { selectedUser, intClientID, intSubClientID } = this.state;
        const { strRoleType, strLogin, strPassword, strEmail, arrParams, strNote } = selectedUser;
        const fetching = this.props.roleSystem.getAllUsers.fetching;
        const users = objToArray(this.props.roleSystem.getAllUsers.success || {});
        const usersFull = this.getFullUsers(users);
        const clients = this.props.dictionaries.getClients.success || [];
        const getGateAdm = this.props.dictionaries.getGateAdm.success || this.props.dictionaries.getGate.success || [];
        const subClients = this.props.dictionaries.getSubClients.success || [];
        const whiteLabels = this.props.dictionaries.getWL.success || [];
        const getBranches = (this.props.dictionaries.getBranches.success || []).filter(el => el?.parent === arrParams?.intWhiteLabelID);
        const wlList = getDataForDD(whiteLabels);
        const branchesList = getDataForDD(getBranches);
        const gatesList = getDataForDD(getGateAdm.filter(el => el.type === this.SUPERAGENT_REF_TYPE));
        const merchantClients = getDataForDD(clients.filter(el => el.type === this.MERCHANT_REF_TYPE));
        const agentClients = getDataForDD(clients.filter(el => el.type === this.AGENT_REF_TYPE));
        const subClientsList = getDataForDD(subClients.filter(el => el.client === intClientID));


        const rrt = this.props.roleSystem.getAllowedRRT.success || {};
        const typesArr = Object.keys(rrt);
        const useType = typesArr.length > 1;
        const lkTypes = this.makeFlatDD(typesArr);
        const selectedRoles = useType ? rrt[strRoleType] || [] : rrt[typesArr[0]] || [];
        const roleTypes = this.makeFlatDD(selectedRoles);
        const selectedClient = clients.find((el) => intClientID === el.id)?.name || `select  ${strRoleType}`;

        return (
            <div className={cn(styles.mainPanel, 'main-panel')}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Users</h1>}
                        <div className="col-md-12">
                            <div className={cn(styles.card, 'card')}>
                                <div className={cn('card-body', styles.topPadding)}>
                                    <div className={cn('nav-scroller d-flex', styles.borderBottomLine)}>
                                        <h1>Users</h1>
                                        {this.props.permissions.indexOf(PERM_USER_ADD) !== -1 && (
                                            <div className="d-flex d-md-inline ml-md-auto py-2 py-md-0">
                                                <ActionButton click={this.addUser}>add User</ActionButton>
                                            </div>
                                        )}
                                    </div>

                                    <div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={rows} hasCollapseSwich={true} />
                                                <TableBody
                                                    dataTable={usersFull}
                                                    rowNames={rows}
                                                    hasCollapseSwich={true}
                                                    // events={this.onDataLineEvents}
                                                />
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onConfirmDelete}
                        onCancel={this.onCloseAlert}
                        showCancel
                    >
                        <h1 className={styles.heading}>Are you sure you want to disable?</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>user</span>
                                <span className={styles.bolder}>{strLogin}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>note</span>
                                <span className={styles.bolder}>{strNote}</span>
                            </div>
                            <div className={styles.line}>
                                <span className={styles.leftTitle}>email </span>
                                <span className={styles.bolder}>{strEmail}</span>
                            </div>
                        </div>
                    </SweetAlert>
                )}

                {this.state.isEditShown && (
                    <Modal faderClick={this.onCancelEdit} className={styles.centerModal}>
                        <div className={cn(styles.popUpPosition)}>
                            <div className="card card-primary bg-primary-gradient">
                                <div className="card-body">
                                    <h4 className={cn('mt-3 pb-3 mb-3 fw-bold')}>User details</h4>
                                    <div className={styles.sideBlocks}>
                                        <div className={styles.leftSide}>
                                            <div className={cn('form-group form-inline', styles.justCenter)}>
                                                <label
                                                    className={cn('col-md-3 col-form-label', styles.label)}>login:</label>
                                                <div className="col-md-7 p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, 'form-control input-full')}
                                                        placeholder="login"
                                                        name="strLogin"
                                                        value={strLogin}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className={cn('form-group form-inline', styles.justCenter)}>
                                                <label
                                                    className={cn('col-md-3 col-form-label', styles.label)}>password:</label>
                                                <div className={cn(styles.relative, 'col-md-7 p-0')}>
                                                    <input
                                                        type={this.state.passwordType}
                                                        className={cn(styles.inputElement, 'form-control input-full')}
                                                        placeholder="password"
                                                        name="strPassword"
                                                        value={strPassword}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                    <i className={cn('icon-eye', styles.iconEye, { [styles.selected]: this.state.passwordType !== INPUT_PASSWORD_TYPE })}
                                                       onClick={this.changePasswordType}>&nbsp;</i>
                                                </div>
                                            </div>
                                            <div className={cn('form-group form-inline', styles.justCenter)}>
                                                <label
                                                    className={cn('col-md-3 col-form-label', styles.label)}>e-mail:</label>
                                                <div className="col-md-7  p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, 'form-control input-full')}
                                                        placeholder="e-mail"
                                                        name="strEmail"
                                                        value={strEmail}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className={cn('form-group form-inline', styles.justCenter)}>
                                                <label
                                                    className={cn('col-md-3 col-form-label', styles.label)}>note:</label>
                                                <div className="col-md-7  p-0">
                                                    <input
                                                        type="text"
                                                        className={cn(styles.inputElement, 'form-control input-full')}
                                                        placeholder="note"
                                                        name="strNote"
                                                        value={strNote}
                                                        onChange={this.handleInputsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        {/*RIGHT SIDE*/}
                                        <div className={styles.rightSide}>

                                            {useType && (
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            options={lkTypes}
                                                            name="strRoleType"
                                                            onSelect={this.handleRolesSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select back office type
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            )}

                                            {!!roleTypes.length && (
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            name="strRole"
                                                            options={roleTypes}
                                                            onSelect={this.handleRolesSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select user type
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            )}


                                            {('whitelabel' === strRoleType || 'branch' === strRoleType) && (
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            options={wlList}
                                                            line={true}
                                                            name="intWhiteLabelID"
                                                            onSelect={this.handleDDSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select whitelabel
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            )}

                                            {('branch' === strRoleType && !!arrParams?.intWhiteLabelID) && (
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            line={true}
                                                            name="intBranchID"
                                                            options={branchesList}
                                                            onSelect={this.handleDDSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select branch
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            )}

                                            {'superagent' === strRoleType && (
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            line={true}
                                                            name="intGateID"
                                                            options={gatesList}
                                                            onSelect={this.handleDDSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select gate
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            )}

                                            {'merchant' === strRoleType && (<>
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            options={merchantClients}
                                                            onSelect={this.handleClientSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                            reset={selectedClient}
                                                        >
                                                            {selectedClient}
                                                        </DropDown>
                                                    </div>
                                                </div>

                                                {intClientID && !!subClientsList.length && (
                                                    <div className={cn('form-group form-inline', styles.justCenter)}>
                                                        <div className="col-md-8 p-0">
                                                            <DropDown
                                                                options={subClientsList}
                                                                onSelect={this.handlerSubClientSelect}
                                                                className={styles.navButton}
                                                                color="white"
                                                            >
                                                                {subClientsList.find((el) => intSubClientID === el.id)?.title || ''}
                                                            </DropDown>
                                                        </div>
                                                    </div>
                                                )}
                                            </>)}

                                            {'agent' === strRoleType && (<>
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            options={agentClients}
                                                            onSelect={this.handleClientSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                            reset={selectedClient}
                                                        >
                                                            {selectedClient}
                                                        </DropDown>
                                                    </div>
                                                </div>

                                                {intClientID && !!subClientsList.length && (
                                                    <div className={cn('form-group form-inline', styles.justCenter)}>
                                                        <div className="col-md-8 p-0">
                                                            <DropDown
                                                                options={subClientsList}
                                                                onSelect={this.handlerSubClientSelect}
                                                                className={styles.navButton}
                                                                color="white"
                                                            >
                                                                {subClientsList.find((el) => intSubClientID === el.id)?.title || ''}
                                                            </DropDown>
                                                        </div>
                                                    </div>
                                                )}
                                            </>)}

                                            {'cashier' === strRoleType && (<>
                                                <div className={cn('form-group form-inline', styles.justCenter)}>
                                                    <div className="col-md-8 p-0">
                                                        <DropDown
                                                            options={agentClients}
                                                            onSelect={this.handleClientSelect}
                                                            className={styles.navButton}
                                                            color="white"
                                                        >
                                                            select to add client
                                                        </DropDown>
                                                    </div>
                                                </div>
                                            </>)}
                                        </div>
                                    </div>

                                    <div className={cn(styles.paddingKeys)}>
                                        <button className={styles.cancelBtn} onClick={this.onCancelEdit}>
                                            Cancel
                                        </button>
                                        <button className={styles.okBtn} onClick={this.onConfirmEdit}>
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetRolesFetch,
    onGetGroupsFetch,
    onGetAllUsersFetch,
    onGetUserGroupsFetch,
    onGetUserRolesFetch,
    onEditUserFetch,
    onAddUserFetch,
    onAddDirectUserRoleFetch,
    onAddUserGroupFetch,
    onEnableUserFetch,
    onWLFetch,
    onBranchesFetch,
    onGetGateFetch,
    onGetGateAdmFetch,
    onGetAllowedRRTFetch,
};

const mapStateToProps = (state) => ({
    roleSystem: state.roleSystem,
    dictionaries: state.dictionaries,
    permissions: state.auth.permissions.success || [],
    canReadUsers: (state.auth.permissions.success || []).indexOf(PERM_GET_ALL_USERS) !== -1,
    canReadGroups: (state.auth.permissions.success || []).indexOf(PERM_GET_PERMISSION_GROUP) !== -1,
    canReadRoles: (state.auth.permissions.success || []).indexOf(PERM_GET_PERMISSION_ROLE) !== -1,
    canReadDirectUserRoles: (state.auth.permissions.success || []).indexOf(PERM_GET_DIRECT_USER_ROLES) !== -1,
    canReadDirectUserGroups: (state.auth.permissions.success || []).indexOf(PERM_GET_USER_GROUPS) !== -1,
    canReadGateAdm: (state.auth.permissions.success || []).indexOf(PERM_GET_GATE_ADM) !== -1,
    canReadGate: (state.auth.permissions.success || []).indexOf(PERM_GET_GATE) !== -1,
});

export const AdmUsersPanel = connect(mapStateToProps, mapDispatchToProps)(AdmUsersComponent);
