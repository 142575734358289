export const PaymentOperations = {
    MAKE_PAYMENT_C2B_FETCH: 'MAKE_PAYMENT_C2B_FETCH',
    MAKE_PAYMENT_C2B_SUCCESS: 'MAKE_PAYMENT_C2B_SUCCESS',
    // MAKE_PAYMENT_C2B_PENDING: 'MAKE_PAYMENT_C2B_PENDING',
    MAKE_PAYMENT_C2B_ERROR: 'MAKE_PAYMENT_C2B_ERROR',
    MAKE_PAYMENT_C2B_CLEAR: 'MAKE_PAYMENT_C2B_CLEAR',

    MAKE_PAYMENT_B2C_FETCH: 'MAKE_PAYMENT_B2C_FETCH',
    MAKE_PAYMENT_B2C_SUCCESS: 'MAKE_PAYMENT_B2C_SUCCESS',
    MAKE_PAYMENT_B2C_ERROR: 'MAKE_PAYMENT_B2C_ERROR',
    MAKE_PAYMENT_B2C_CLEAR: 'MAKE_PAYMENT_B2C_CLEAR',

    GET_PAYMENT_STATUS_FETCH: 'GET_PAYMENT_STATUS_FETCH',
    GET_PAYMENT_STATUS_FETCH_PROCESS: 'GET_PAYMENT_STATUS_FETCH_PROCESS',
    GET_PAYMENT_STATUS_SUCCESS: 'GET_PAYMENT_STATUS_SUCCESS',
    GET_PAYMENT_STATUS_ERROR: 'GET_PAYMENT_STATUS_ERROR',
    GET_PAYMENT_STATUS_FINISH: 'GET_PAYMENT_STATUS_FINISH',
    GET_PAYMENT_STATUS_CLEAR: 'GET_PAYMENT_STATUS_CLEAR',

    MAKE_DEPOSIT_MERCHANT_FETCH: 'MAKE_DEPOSIT_MERCHANT_FETCH',
    MAKE_DEPOSIT_MERCHANT_SUCCESS: 'MAKE_DEPOSIT_MERCHANT_SUCCESS',
    MAKE_DEPOSIT_MERCHANT_ERROR: 'MAKE_DEPOSIT_MERCHANT_ERROR',
    MAKE_DEPOSIT_MERCHANT_CLEAR: 'MAKE_DEPOSIT_MERCHANT_CLEAR',
    
    MAKE_SETTLEMENT_MERCHANT_FETCH: 'MAKE_SETTLEMENT_MERCHANT_FETCH',
    MAKE_SETTLEMENT_MERCHANT_SUCCESS: 'MAKE_SETTLEMENT_MERCHANT_SUCCESS',
    MAKE_SETTLEMENT_MERCHANT_ERROR: 'MAKE_SETTLEMENT_MERCHANT_ERROR',
    MAKE_SETTLEMENT_MERCHANT_CLEAR: 'MAKE_SETTLEMENT_MERCHANT_CLEAR',
};
