import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { getAccounts } from './accounts.services';
import { errorNotify } from '../helpers/commonHelpers';
import {
    onAccError,
    onAccSuccess
} from "./accounts.actions";
import { onHandleErrors } from '../errors/errors.actions';
import { Accounts } from "./accounts.consts";


export function* sagaGetAccFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAccountsPage',
        };
        const { response, error } = yield call(getAccounts, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onAccError(error));
        } else if (response) {
            yield put(onAccSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onAccError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onAccError(error));
    }
}

export function* accountsSaga() {
    yield takeEvery(Accounts.ACC_ACCOUNTS_FETCH, sagaGetAccFetch);
}
