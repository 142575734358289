import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './BreadCrumbs.less';

export const BreadCrumbs = (props) => {
    const crumbs = props.crumbs || [];
    const lastIdx = crumbs.length - 1;

    const onClick = (e) => {
        props.onClick(crumbs.find(el => String(el.id) === e.target.id));
    };

    return crumbs.map((el, idx) => {
            return idx === lastIdx ? (
                <span key={String(idx)} className={styles.last}>{el.title}</span>
            ) : (
                <React.Fragment key={String(idx)}>
                    <span className={styles.link} onClick={onClick} id={el.id}>{el.title}</span>
                    <i className={cn(styles.chevron, 'fas fa-chevron-right')}> </i>
                </React.Fragment>
            );
        },
    );
};

