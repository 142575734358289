import { handleActions } from 'redux-actions';
import { AuthActions } from './auth.consts';
import update from 'immutability-helper';
import { combineReducers } from 'redux';

export const getToken = handleActions(
    {
        [AuthActions.AUTH_UPDATE_TOKEN]: (state, action) => {
            return update(state, {
                token: { $set: action.payload },
            });
        },
        [AuthActions.AUTH_LOGIN_FETCH]: (state, action) => {
            return update(state, {
                token: { $set: null },
                login: { $set: action.payload.login },
                success: { $set: action.payload },
                fetching: { $set: true },
                errors: { $set: null },
            });
        },
        [AuthActions.AUTH_LOGIN_SUCCESS]: (state, action) => {
            return update(state, {
                token: { $set: action.payload.data.data },
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [AuthActions.AUTH_LOGIN_ERROR]: (state, action) =>
            update(state, {
                token: { $set: null },
                login: { $set: null },
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [AuthActions.AUTH_LOGOUT_FETCH]: (state, action) => {
            return update(state, {
                login: { $set: null },
                token: { $set: null },
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            });
        },
    },
    {
        token: null,
        login: null,
        user: null,
        fetching: false,
        success: null,
        errors: null,
    },
);

export const permissions = handleActions(
    {
        [AuthActions.AUTH_PERMISSIONS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [AuthActions.AUTH_PERMISSIONS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [AuthActions.AUTH_PERMISSIONS_ERROR]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: null },
                errors: { $set: action.payload },
            });
        },
        [AuthActions.AUTH_PERMISSIONS_CLEAR]: (state) => {
            return update(state, {
                fetching: { $set: false },
                errors: { $set: null },
                success: { $set: null },
            });
        },
    },
    {
        fetching: false,
        success: null,
        errors: null,
    },
);

export const auth = combineReducers({
    getToken,
    permissions,
});
