import { call, put, takeEvery } from "redux-saga/effects";
import { errorNotify } from "../helpers/commonHelpers";
import { onHandleErrors } from "../errors/errors.actions";
import { storageService } from './selfStorage.services';
import { onGetStorageError, onGetStorageSuccess, onSetStorageError, onSetStorageSuccess } from './selfStorage.actions';
import { SelfStorage } from './selfStorage.consts';

export function* sagaSetItemFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "SetItem",
            setter: true,
        };
        const { response, error } = yield call(storageService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onSetStorageError(error));
        } else if (response) {
            yield put(onSetStorageSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onSetStorageError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onSetStorageError(error));
    }
}

export function* sagaGetItemFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "GetItem",
        };
        const { response, error } = yield call(storageService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetStorageError(error));
        } else if (response) {
            yield put(onGetStorageSuccess(response.data?.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onGetStorageError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetStorageError(error));
    }
}

export function* sagaRemoveItemFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: "RemoveItem",
            setter: true,
        };
        const { response, error } = yield call(storageService, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetStorageError(error));
        } else if (response) {
            yield put(onGetStorageSuccess(response.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onGetStorageError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetStorageError(error));
    }
}

export function* selfStorageSaga() {
    yield takeEvery(SelfStorage.SET_ITEM_FETCH, sagaSetItemFetch);
    yield takeEvery(SelfStorage.GET_ITEM_FETCH, sagaGetItemFetch);
    yield takeEvery(SelfStorage.REMOVE_ITEM_FETCH, sagaRemoveItemFetch);
}
