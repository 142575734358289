import { GATE_CODE, GATE_CODE1 } from "../../../utils/consts/global";

export const balancesRowNamesDict = [
    { name: 'paymentType', title: 'Payment Type', className: 'noWrap', options: '' },
    { name: 'country', title: 'Country', className: 'noWrap', options: '' },
    { name: 'paymentMethod', title: 'Payment Method', className: '', options: '' },
    { name: 'type', title: 'Type', className: '', options: '' },
    { name: 'merchantAccount', title: 'Merchant Account', className: 'smallFont', options: '' },
    { name: 'amount', title: 'Amount', className: 'smallFont', options: '' },
    { name: 'currency', title: 'Currency', className: 'noWrap', options: '' },
];

export const flatIncomeData = (data) => {
    if (data?.length) {
        return [
            data.reduce(function (acc, cur) {
                return { ...acc, ...cur };
            }),
        ];
    } else {
        return [];
    }
};

export const getBalancesData = (data, currencies, countries, balanceAmount) => {
    return data.map((el) => {
        return {
            ...el,
            merchantAccount: el.merchantId,
            type: el.merchantAccountType,
            currencyTitle: currencies?.find((cur) => cur.codeAlpha === el.currency)?.name || '',
            countryTitle: countries?.find((country) => country.codeAlpha3 === el.country)?.name || '',
            amount: balanceAmount[`${el.merchantId}:${el.currency}`]?.success?.balance || '',
        };
    });
};

export const types = {
    0: 'Deposit & Withdrawal',
    1: 'Withdrawal',
    2: 'Deposit',
};

export const methods = {};

export const getDataFromAccounts = (data) => {
    const { accounts, currencies, countries, gate } = data;
    if (!accounts || !currencies || !countries || !gate) return [];
    return accounts.map((el) => {
        return {
            paymentType: el.paymentType || 'Mobile Money',
            country: countries.find((country) => country.codeNum === el.country)?.codeAlpha3 || '',
            countryTitle: countries.find((country) => country.codeNum === el.country)?.name || '',
            paymentMethod: (el.methodType === GATE_CODE || el.methodType === GATE_CODE1) ? gate.find((ge) => ge.id === el.method)?.name : el.method,
            merchantAccount: el.account,
            amount: el.balance,
            currency: currencies.find((cur) => cur.codeNum === el.currency)?.codeAlpha || '',
            currencyTitle: currencies.find((cur) => cur.codeNum === el.currency)?.name || '',
            type: types[el.inOut] || '',
        };
    });
};
