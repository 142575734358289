import { rowNamesOptions } from '../../TableBody/TableBodyHelper';
import { getDataForDD } from '../../../../utils/helpers/commonHelpers';
import { PROXY_GATE_TYPES } from '../../../../utils/consts/global';

const incomeLegalMethods = {
    'dataType': 'Merchants',
    'data': [
        { 'gate': '9', 'currencies': [840, 976], 'flag': ['TECH'] },
        {
            'gate': '10',
            'currencies': [840, 976],
            'flag': ['TECH', 'FIN'],
            'selected': {
                'currencies': '840',
                'flag': 'FIN',
                'proxygate': '123',
            },
        },
        {
            'gate': '17',
            'currencies': [840, 976],
            'flag': ['TECH', 'FIN'],
        },
        {
            'gate': '19',
            'currencies': [840, 976],
            'flag': ['TECH', 'FIN'],
        },
        {
            'gate': '501',
            'currencies': [840, 976],
            'flag': ['TECH'],
        },
        {
            'gate': '1003',
            'currencies': [840, 976],
            'flag': ['TECH'],
        },
    ],
};

export const rows = [
    { name: 'enabled', title: 'enabled', className: 'noWrap', options: rowNamesOptions.enableBlock, isSortable: false },
    { name: 'gateName', title: 'payment method', className: 'noWrap', options: '', isSortable: false },
    {
        name: 'proxygate',
        title: 'proxy gate',
        className: 'smallWidth',
        options: rowNamesOptions.dropDown,
        isSortable: false,
    },
    {
        name: 'currencies',
        title: 'currencies',
        className: 'noWrap',
        options: rowNamesOptions.checkBoxBlock,
        isSortable: false,
    },
    {
        name: 'flag',
        title: 'aggregator',
        className: 'noWrap',
        options: rowNamesOptions.checkBoxBlock,
        isSortable: false,
    },
];


export const makeHumanReadable = ({ getLegalPaymentMethods, currenciesDict, disabled, gates }) => {
    if (!getLegalPaymentMethods?.data || !getLegalPaymentMethods?.data.length) return [];

    const all = getDataForDD(getLegalPaymentMethods?.proxyGates || gates || []);

    return getLegalPaymentMethods.data.map(el => {
        const avlCurrArr = el.currencies?.length ? el.currencies : [];
        const selectedCurr = el.selected?.currencies?.length ? el.selected.currencies : [];
        const avlFlag = el.flag?.length ? el.flag : [];
        const selectedFlag = el.selected?.flag ? [el.selected?.flag] : [];

        return {
            on: !!el.selected,
            currencies: { all: avlCurrArr, selected: selectedCurr, disabled },
            flag: { all: avlFlag, selected: selectedFlag, disabled },
            gateName: el.name,
            gateId: el.gate,
            proxygate: {
                all,
                selected: el.selected?.proxygate,
                disabled: disabled,
                hidden: PROXY_GATE_TYPES.indexOf(el.type) === -1,
            },
            disabled,
        };
    });
};
