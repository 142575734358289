import { RoleSystem } from './roleSystem.consts';

/*
  GET ALL USERS
*/
export const onGetAllUsersFetch = (payload) => ({
    type: RoleSystem.GET_ALL_USERS_FETCH,
    payload,
});

export const onGetAllUsersSuccess = (payload) => ({
    type: RoleSystem.GET_ALL_USERS_SUCCESS,
    payload,
});

export const onGetAllUsersError = (payload) => ({
    type: RoleSystem.GET_ALL_USERS_ERROR,
    payload,
});

/*
  GET GROUP & ROLES table
*/
export const onGetGroupRolesFetch = (payload) => ({
    type: RoleSystem.GET_GROUP_ROLES_FETCH,
    payload,
});

export const onGetGroupRolesSuccess = (payload) => ({
    type: RoleSystem.GET_GROUP_ROLES_SUCCESS,
    payload,
});

export const onGetGroupRolesError = (payload) => ({
    type: RoleSystem.GET_GROUP_ROLES_ERROR,
    payload,
});

/*
  Add ROLES to Group
*/
export const onAddRoleToGroupFetch = (payload) => ({
    type: RoleSystem.ADD_PERM_GROUP_ROLE_FETCH,
    payload,
});

export const onAddRoleToGroupSuccess = (payload) => ({
    type: RoleSystem.ADD_PERM_GROUP_ROLE_SUCCESS,
    payload,
});

export const onAddRoleToGroupError = (payload) => ({
    type: RoleSystem.ADD_PERM_GROUP_ROLE_ERROR,
    payload,
});

/*
  Remove ROLES from Group
*/
export const onRemoveRoleFromGroupFetch = (payload) => ({
    type: RoleSystem.REMOVE_PERM_GROUP_ROLE_FETCH,
    payload,
});

export const onRemoveRoleFromGroupSuccess = (payload) => ({
    type: RoleSystem.REMOVE_PERM_GROUP_ROLE_SUCCESS,
    payload,
});

export const onRemoveRoleFromGroupError = (payload) => ({
    type: RoleSystem.REMOVE_PERM_GROUP_ROLE_ERROR,
    payload,
});

/*
  CREATE GROUP
*/
export const onCreateGroupFetch = (payload) => ({
    type: RoleSystem.CREATE_PERM_GROUP_FETCH,
    payload,
});

export const onCreateGroupSuccess = (payload) => ({
    type: RoleSystem.CREATE_PERM_GROUP_SUCCESS,
    payload,
});

export const onCreateGroupError = (payload) => ({
    type: RoleSystem.CREATE_PERM_GROUP_ERROR,
    payload,
});

/*
  EDIT GROUP
*/
export const onEditGroupFetch = (payload) => ({
    type: RoleSystem.EDIT_PERM_GROUP_FETCH,
    payload,
});

export const onEditGroupSuccess = (payload) => ({
    type: RoleSystem.EDIT_PERM_GROUP_SUCCESS,
    payload,
});

export const onEditGroupError = (payload) => ({
    type: RoleSystem.EDIT_PERM_GROUP_ERROR,
    payload,
});

/*
  DELETE GROUP
*/
export const onDeleteGroupFetch = (payload) => ({
    type: RoleSystem.DELETE_PERM_GROUP_FETCH,
    payload,
});

export const onDeleteGroupSuccess = (payload) => ({
    type: RoleSystem.DELETE_PERM_GROUP_SUCCESS,
    payload,
});

export const onDeleteGroupError = (payload) => ({
    type: RoleSystem.DELETE_PERM_GROUP_ERROR,
    payload,
});

/*
  GET ROLES
*/
export const onGetRolesFetch = (payload) => ({
    type: RoleSystem.GET_PERMISSION_ROLES_FETCH,
    payload,
});

export const onGetRolesSuccess = (payload) => ({
    type: RoleSystem.GET_PERMISSION_ROLES_SUCCESS,
    payload,
});

export const onGetRolesError = (payload) => ({
    type: RoleSystem.GET_PERMISSION_ROLES_ERROR,
    payload,
});

/*
  CREATE ROLE
*/
export const onCreateRoleFetch = (payload) => ({
    type: RoleSystem.CREATE_PERMISSION_ROLE_FETCH,
    payload,
});

export const onCreateRoleSuccess = (payload) => ({
    type: RoleSystem.CREATE_PERMISSION_ROLE_SUCCESS,
    payload,
});

export const onCreateRoleError = (payload) => ({
    type: RoleSystem.CREATE_PERMISSION_ROLE_ERROR,
    payload,
});

/*
  EDIT ROLE
*/
export const onEditRoleFetch = (payload) => ({
    type: RoleSystem.EDIT_PERMISSION_ROLE_FETCH,
    payload,
});

export const onEditRoleSuccess = (payload) => ({
    type: RoleSystem.EDIT_PERMISSION_ROLE_SUCCESS,
    payload,
});

export const onEditRoleError = (payload) => ({
    type: RoleSystem.EDIT_PERMISSION_ROLE_ERROR,
    payload,
});

/*
  DELETE ROLE
*/
export const onDeleteRoleFetch = (payload) => ({
    type: RoleSystem.DELETE_PERMISSION_ROLE_FETCH,
    payload,
});

export const onDeleteRoleSuccess = (payload) => ({
    type: RoleSystem.DELETE_PERMISSION_ROLE_SUCCESS,
    payload,
});

export const onDeleteRoleError = (payload) => ({
    type: RoleSystem.DELETE_PERMISSION_ROLE_ERROR,
    payload,
});

/*
  GET GROUPS
*/
export const onGetGroupsFetch = (payload) => ({
    type: RoleSystem.GET_PERMISSION_GROUPS_FETCH,
    payload,
});

export const onGetGroupsSuccess = (payload) => ({
    type: RoleSystem.GET_PERMISSION_GROUPS_SUCCESS,
    payload,
});

export const onGetGroupsError = (payload) => ({
    type: RoleSystem.GET_PERMISSION_GROUPS_ERROR,
    payload,
});

/*
  GET USER DIRECT ROLES
*/
export const onGetUserRolesFetch = (payload) => ({
    type: RoleSystem.GET_DIRECT_USER_ROLES_FETCH,
    payload,
});

export const onGetUserRolesSuccess = (payload) => ({
    type: RoleSystem.GET_DIRECT_USER_ROLES_SUCCESS,
    payload,
});

export const onGetUserRolesError = (payload) => ({
    type: RoleSystem.GET_DIRECT_USER_ROLES_ERROR,
    payload,
});

export const onGetUserRolesClear = (payload) => ({
    type: RoleSystem.GET_DIRECT_USER_ROLES_CLEAR,
    payload,
});

/*
  GET USER GROUPS
*/
export const onGetUserGroupsFetch = (payload) => ({
    type: RoleSystem.GET_USER_GROUPS_FETCH,
    payload,
});

export const onGetUserGroupsSuccess = (payload) => ({
    type: RoleSystem.GET_USER_GROUPS_SUCCESS,
    payload,
});

export const onGetUserGroupsError = (payload) => ({
    type: RoleSystem.GET_USER_GROUPS_ERROR,
    payload,
});

export const onGetUserGroupsClear = (payload) => ({
    type: RoleSystem.GET_USER_GROUPS_CLEAR,
    payload,
});

/*
  EDIT USER
*/
export const onEditUserFetch = (payload) => ({
    type: RoleSystem.EDIT_USER_FETCH,
    payload,
});

export const onEditUserSuccess = (payload) => ({
    type: RoleSystem.EDIT_USER_SUCCESS,
    payload,
});

export const onEditUserError = (payload) => ({
    type: RoleSystem.EDIT_USER_ERROR,
    payload,
});

/*
  ADD USER
*/
export const onAddUserFetch = (payload) => ({
    type: RoleSystem.ADD_USER_FETCH,
    payload,
});

export const onAddUserSuccess = (payload) => ({
    type: RoleSystem.ADD_USER_SUCCESS,
    payload,
});

export const onAddUserError = (payload) => ({
    type: RoleSystem.ADD_USER_ERROR,
    payload,
});

/*
  ENABLE USER
*/
export const onEnableUserFetch = (payload) => ({
    type: RoleSystem.ENABLE_USER_FETCH,
    payload,
});

export const onEnableUserSuccess = (payload) => ({
    type: RoleSystem.ENABLE_USER_SUCCESS,
    payload,
});

export const onEnableUserError = (payload) => ({
    type: RoleSystem.ENABLE_USER_ERROR,
    payload,
});

/*
  Add Direct User Role
*/
export const onAddDirectUserRoleFetch = (payload) => ({
    type: RoleSystem.ADD_DIRECT_USER_ROLE_FETCH,
    payload,
});

export const onAddDirectUserRoleSuccess = (payload) => ({
    type: RoleSystem.ADD_DIRECT_USER_ROLE_SUCCESS,
    payload,
});

export const onAddDirectUserRoleError = (payload) => ({
    type: RoleSystem.ADD_DIRECT_USER_ROLE_ERROR,
    payload,
});

/*
  Add User Group
*/
export const onAddUserGroupFetch = (payload) => ({
    type: RoleSystem.ADD_USER_GROUP_FETCH,
    payload,
});

export const onAddUserGroupSuccess = (payload) => ({
    type: RoleSystem.ADD_USER_GROUP_SUCCESS,
    payload,
});

export const onAddUserGroupError = (payload) => ({
    type: RoleSystem.ADD_USER_GROUP_ERROR,
    payload,
});

/*
  SET Direct User Role
*/
export const onSetDirectUserRoleFetch = (payload) => ({
    type: RoleSystem.SET_DIRECT_USER_ROLE_FETCH,
    payload,
});

export const onSetDirectUserRoleSuccess = (payload) => ({
    type: RoleSystem.SET_DIRECT_USER_ROLE_SUCCESS,
    payload,
});

export const onSetDirectUserRoleError = (payload) => ({
    type: RoleSystem.SET_DIRECT_USER_ROLE_ERROR,
    payload,
});

/*
  Set User Group
*/
export const onSetUserGroupsFetch = (payload) => ({
    type: RoleSystem.SET_USER_GROUPS_FETCH,
    payload,
});

export const onSetUserGroupsSuccess = (payload) => ({
    type: RoleSystem.SET_USER_GROUPS_SUCCESS,
    payload,
});

export const onSetUserGroupsError = (payload) => ({
    type: RoleSystem.SET_USER_GROUPS_ERROR,
    payload,
});

/*
  GET FUNCTION_ROLE
*/
export const onGetFunctionRoleFetch = (payload) => ({
    type: RoleSystem.GET_FUNCTION_ROLE_FETCH,
    payload,
});

export const onGetFunctionRoleSuccess = (payload) => ({
    type: RoleSystem.GET_FUNCTION_ROLE_SUCCESS,
    payload,
});

export const onGetFunctionRoleError = (payload) => ({
    type: RoleSystem.GET_FUNCTION_ROLE_ERROR,
    payload,
});

export const onGetFunctionRoleClear = (payload) => ({
    type: RoleSystem.GET_FUNCTION_ROLE_CLEAR,
    payload,
});

/*
  GET ALLOWED RRT
*/
export const onGetAllowedRRTFetch = (payload) => ({
    type: RoleSystem.GET_ALLOWED_RRT_FETCH,
    payload,
});

export const onGetAllowedRRTSuccess = (payload) => ({
    type: RoleSystem.GET_ALLOWED_RRT_SUCCESS,
    payload,
});

export const onGetAllowedRRTError = (payload) => ({
    type: RoleSystem.GET_ALLOWED_RRT_ERROR,
    payload,
});

export const onGetAllowedRRTClear = (payload) => ({
    type: RoleSystem.GET_ALLOWED_RRT_CLEAR,
    payload,
});

/*
  SET FUNCTION_ROLE
*/
export const onSetFunctionRoleFetch = (payload) => ({
    type: RoleSystem.SET_FUNCTION_ROLE_FETCH,
    payload,
});

export const onSetFunctionRoleSuccess = (payload) => ({
    type: RoleSystem.SET_FUNCTION_ROLE_SUCCESS,
    payload,
});

export const onSetFunctionRoleError = (payload) => ({
    type: RoleSystem.SET_FUNCTION_ROLE_ERROR,
    payload,
});

export const onSetFunctionRoleClear = (payload) => ({
    type: RoleSystem.SET_FUNCTION_ROLE_CLEAR,
    payload,
});
