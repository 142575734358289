import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AgentDetailTab.less';

import { useDispatch, useSelector } from 'react-redux';
import { emptyContact, emptyDocument, isExtensionsValid } from './AgentDetailTab.helper';
import { LEGAL_IN_DRAFT, PUBLISHED } from '../../Merchant/MerchantDetails/MerchantDetails.helper';
import { DropDown } from '../../DropDown/DropDown';
import { findTitleById, getDataForDropDown } from '../../../../utils/helpers/commonHelpers';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import {
    API_URL,
    DATE_FORMAT_DDMMYYYY,
    FILE_STORE_URL, GET_FILES_URL,
} from '../../../../utils/consts/global';
import { ButtonsOkCancelBlock } from '../../ButtonsOkCancelBlock/ButtonsOkCancelBlock';
import { AppActions } from '../../../../store/app/app.consts';
import { Agents } from '../../../../store/agents/agents.consts';
import { Spinner } from '../../Spinner/Spinner';


export const AgentDetailTab = (props) => {
    const dispatch = useDispatch();
    const { agent, createMode } = props;
    const countriesDict = useSelector((state) => state.dictionaries.getCountries.success);
    const uploadFile = useSelector((state) => state.agents.uploadFile);
    const countries = getDataForDropDown(countriesDict || [], 'codeAlpha3', true);

    const currencies = props.currencies;
    const agentInfo = agent?.agentInfo;
    const accounts = agentInfo?.accounts;
    const firstContact = { ...agentInfo?.contacts } || {};
    const [showContactsFields, setShowContactsFields] = useState(false);
    const [showDocumentsFields, setShowDocumentsFields] = useState(false);
    const [contact, setContact] = useState({ ...emptyContact });
    const [document, setDocument] = useState({ ...emptyDocument });
    const [legal, setLegal] = useState(props.legal);

    const [contacts, setContacts] = useState(legal?.contacts || [{ ...firstContact }]);
    const [documents, setDocuments] = useState(legal?.documents || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const formDisabled = !(createMode || legal.legalStatus !== PUBLISHED);

    useEffect(() => {
        const legal = props.legal;
        if (legal) {
            setLegal(legal);
            setContacts(legal?.contacts || [{ ...firstContact }]);
            setDocuments(legal?.documents || []);
        }
    }, [props.legal]);


    useEffect(() => {
        if (uploadFile.success) {
            const docs = [...documents];

            docs.push({
                ...document,
                fileId: uploadFile.success.uploads[0],
            });
            setDocuments(docs);

            dispatch({
                type: Agents.FILE_SEND_CLEAR,
                payload: null,
            });
        }
    }, [uploadFile.success]);

    const showAddFields = () => {
        setContact({ ...emptyContact });
        setShowContactsFields(true);
    };

    const showAddDocument = () => {
        setDocument({ ...emptyDocument });
        setShowDocumentsFields(true);
    };

    const changeInputHandler = (event) => {
        event.persist();
        setLegal({
            ...legal,
            [event.target.name]: event.target.value,
        });
    };

    const changeReceiptHandler = (event) => {
        event.persist();
        setLegal({
            ...legal,
            receipt: {
                ...legal.receipt,
                [event.target.name]: event.target.value,
            },
        });
    };

    const changeContactsInputHandler = (event) => {
        event.persist();
        setContact({
            ...contact,
            [event.target.name]: event.target.value,
        });
    };

    const changeDocumentsInputHandler = (event) => {
        event.persist();
        setDocument({
            ...document,
            [event.target.name]: event.target.value,
        });
    };

    const saveContact = () => {
        setContacts([...contacts, contact]);
        setShowContactsFields(false);
    };


    const deleteDocuments = (event) => {
        const idx = Number(event.target.getAttribute('data-idx'));
        const newDocs = [...documents];
        newDocs.splice(idx, 1);
        setDocuments(newDocs);
    };

    const deleteContact = (event) => {
        const idx = Number(event.target.getAttribute('data-idx'));
        const newContacts = [...contacts];
        newContacts.splice(idx, 1);
        setContacts(newContacts);
    };

    const closeContact = () => {
        setShowContactsFields(false);
    };

    const dropDownSelect = (val, name) => {
        setLegal({
            ...legal,
            [name]: val.id,
        });
    };

    const onDateChanges = (date) => {
        changeInputHandler({
            target: {
                name: 'dtContractDate',
                value: new Date(date),
            },
            persist: () => {
            },
        });
    };

    const onDeleteLegal = () => {
        if (props.onDelete) {
            props.onDelete(legal);
        }
    };

    const onCancelEdit = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const saveLegal = () => {
        const newLegal = {
            ...legal,
            legalStatus: LEGAL_IN_DRAFT,
            contacts,
            documents,
        };

        const dataToSend = {
            ...newLegal,
            intID: legal.intID || (new Date).getTime(),
        };

        props.onConfirm({ ...dataToSend });
    };

    const showStatusPopUp = ({ type, text }) => {
        dispatch({
            type: AppActions.APP_NOTIFY_SHOW,
            payload: { type, text },
        });
    };

    const changeHandler = (event) => {
        // setAllowNTFY(true);

        if (!document.title) {
            showStatusPopUp({ type: 'msg', text: 'You need to fill the Document title first.' });
            return;
        }

        if (0) {
        // if (!isExtensionsValid(event.target)) {
            setSelectedFile(null);

            const fileInput = window.document.getElementById('file-upload');
            fileInput.value = '';
            showStatusPopUp({ type: 'error', text: 'Unsupported file selected.' });

        } else {
            if (event.target.files[0]) {
                setSelectedFile(event.target.files[0]);
                const fd = new FormData();
                fd.append('upload', event.target.files[0]);
                fd.append('method', 'UploadFile');

                dispatch({
                    type: Agents.FILE_SEND_FETCH,
                    payload: fd,
                });

                setShowDocumentsFields(false);
            }
        }
    };


    return (<>
        <div className={styles.branchSection}>
            <div className={styles.leftSideInfo}>
                <div className={cn(styles.line, styles.spacesTop)}>
                    <div className={styles.firstColumn}>Company name:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               name={'strLegalName'}
                               value={legal?.strLegalName}
                               className={cn(styles.inputField)}
                               disabled={formDisabled}
                               onChange={changeInputHandler}
                        />
                    </div>
                </div>

                <div className={cn(styles.line, styles.doubleSpacesTop)}>
                    <div className={cn(styles.firstColumn, styles.bolder)}>Legal info:</div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Country:</div>
                    <div className={styles.secondColumn}>
                        <DropDown
                            options={countries}
                            onSelect={dropDownSelect}
                            name="intCountry"
                            value={{ id: null, title: '' }}
                            disabled={formDisabled}
                            className={cn({ [styles.leftShift]: formDisabled })}
                            reset={legal?.intCountry}
                        >
                            {findTitleById(countries, legal?.intCountry)}&nbsp;
                        </DropDown>
                        {/*{agentInfo?.country}*/}
                    </div>
                </div>

{/*                {formDisabled && (
                    <div className={cn(styles.line)}>
                        <div className={styles.firstColumn}>Currencies:</div>
                        <div className={styles.secondColumn}>
                            {currencyStringList}
                        </div>
                    </div>
                )}
*/}

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Address</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               name={'strContactAddress'}
                               value={legal?.strContactAddress}
                               className={cn(styles.inputField)}
                               disabled={formDisabled}
                               onChange={changeInputHandler}
                        />
                    </div>
                </div>
                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contact email:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactEmail"
                               name={'strContactEmail'}
                               value={legal?.strContactEmail}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>
            </div>


            <div className={styles.rightSideInfo}>
                <div className={cn(styles.line, styles.spacesTop)}>
                    <div className={styles.firstColumn}>Company phone:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               id="strContactPhone"
                               name={'strContactPhone'}
                               value={legal?.strContactPhone}
                               className={cn(styles.inputField)}
                               onChange={changeInputHandler}
                               disabled={formDisabled}
                        />
                    </div>
                </div>

                <div className={cn(styles.line, styles.rightTop)}>
                    <div className={styles.firstColumn}>Reg number:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               name={'regNumber'}
                               value={legal?.regNumber}
                               className={cn(styles.inputField)}
                               disabled={formDisabled}
                               onChange={changeInputHandler}
                        />
                    </div>
                </div>
                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Licence number:</div>
                    <div className={styles.secondColumn}>
                        <input type="text"
                               name={'licenceNumber'}
                               value={legal?.licenceNumber}
                               className={cn(styles.inputField)}
                               disabled={formDisabled}
                               onChange={changeInputHandler}
                        />
                    </div>
                </div>

                <div className={cn(styles.line)}>
                    <div className={styles.firstColumn}>Contract date:</div>
                    <div className={styles.secondColumn}>
                        {!formDisabled ? (<Datetime
                            className={styles.calendar}
                            value={moment(legal?.dtContractDate)}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={formDisabled ? null : onDateChanges}
                            closeOnSelect={true}
                            disabled={formDisabled}
                        />) : (
                            <div className={styles.likeInput}>{moment(legal?.dtContractDate).format(DATE_FORMAT_DDMMYYYY)}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>

        <div className={cn(styles.branchSection, styles.contacts)}>
            <div className={styles.leftSideInfo}>
                <div className={cn(styles.line)}>
                    <div className={cn(styles.firstColumn, styles.bolder)}>Contacts:</div>
                    <div className={styles.secondColumn}>

                    </div>
                    <div className={styles.thirdColumn}>
                    </div>
                </div>
                {contacts.map((el, idx) => (
                    <div className={styles.contactsSection} key={String(idx)}>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager name:</div>
                            <div className={styles.secondColumn}>
                                {el.managerName}
                            </div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager phone:</div>
                            <div className={styles.secondColumn}>
                                {el.managerPhone}
                            </div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager email:</div>
                            <div className={cn(styles.secondColumn)}>
                                <span>{el.managerEmail}</span>
                            </div>
                            {!formDisabled && (
                                <ButtonsOkCancelBlock
                                    className={styles.showHover}
                                    idx={idx}
                                    isDisabled={formDisabled}
                                    onDelete={deleteContact}
                                    hideCancelButton
                                    hideOkButton
                                    small
                                />
                            )}
                        </div>
                    </div>),
                )}
            </div>

            {!formDisabled && (
                <div className={styles.rightSideInfo}>
                    <div className={cn(styles.line)}>
                        <div className={cn(styles.firstColumn)}>
                            <span className={styles.link} onClick={showAddFields}>add contact</span>
                        </div>
                    </div>

                    {showContactsFields && (<>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager name:</div>
                            <div className={styles.secondColumn}>
                                <input
                                    placeholder="manager name"
                                    type="text"
                                    className={cn(styles.inputField)}
                                    name={'managerName'}
                                    value={contact.managerName}
                                    onChange={changeContactsInputHandler}
                                />
                            </div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager phone:</div>
                            <div className={styles.secondColumn}>
                                <input
                                    placeholder="manager phone"
                                    type="text"
                                    className={cn(styles.inputField)}
                                    id="managerPhone"
                                    name={'managerPhone'}
                                    value={contact.managerPhone}
                                    onChange={changeContactsInputHandler}
                                />
                            </div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Manager email:</div>
                            <div className={styles.secondColumn}>
                                <div className={cn(styles.formInline)}>
                                    <input
                                        placeholder="manager email"
                                        type="text"
                                        className={cn(styles.inputField)}
                                        name={'managerEmail'}
                                        value={contact.managerEmail}
                                        onChange={changeContactsInputHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cn(styles.line)}>
                            <div className={styles.addButtonsWrapper}>
                                <ButtonsOkCancelBlock
                                    onCancelEdit={closeContact}
                                    onConfirmEdit={saveContact}
                                    okIsDisabled={formDisabled}
                                    okText="Save"
                                    small
                                />
                            </div>
                        </div>
                    </>)}
                </div>
            )}
        </div>

        <div className={cn(styles.branchSection, styles.contacts)}>
            <div className={styles.leftSideInfo}>
                <div className={cn(styles.line)}>
                    <div className={cn(styles.firstColumn, styles.bolder)}>Documents:</div>
                    <div className={styles.secondColumn}>
                    </div>
                    <div className={styles.thirdColumn}>
                    </div>
                </div>
                {documents.map((el, idx) => (
                    <div className={cn(styles.contactsSection, styles.slim)} key={String(idx)}>
                        <div className={cn(styles.line, styles.nopad)}>
                            <div className={styles.firstColumn}>
                                <a href={API_URL + GET_FILES_URL + el.fileId} target="_blank">{el.title}</a>
                            </div>
                            {!formDisabled && (
                                <ButtonsOkCancelBlock
                                    className={styles.showHover}
                                    idx={idx}
                                    onDelete={deleteDocuments}
                                    hideCancelButton
                                    hideOkButton
                                    small
                                />
                            )}
                        </div>
                    </div>),
                )}
            </div>
            {!formDisabled && (
                <div className={styles.rightSideInfo}>
                    <div className={cn(styles.line)}>
                        {uploadFile.fetching ? (
                            <div className={cn(styles.firstColumn)}>
                                <Spinner />
                            </div>
                        ) : (
                            <div className={cn(styles.firstColumn)}>
                                <span className={styles.link} onClick={showAddDocument}>add document</span>
                            </div>
                        )}
                    </div>
                    {showDocumentsFields && (<>
                        <div className={cn(styles.line)}>
                            <div className={styles.firstColumn}>Document title:</div>
                            <div className={styles.secondColumn}>
                                <input
                                    placeholder="Document title"
                                    type="text"
                                    className={cn(styles.inputField)}
                                    name={'title'}
                                    value={document.title}
                                    onChange={changeDocumentsInputHandler}
                                />
                            </div>
                        </div>
                        {document.title && (
                            <div className={cn(styles.line)}>
                                <div className={styles.firstColumn}>Select file:</div>
                                <div className={styles.secondColumn}>
                                    <input
                                        id="file-upload"
                                        name="file"
                                        type="file"
                                        // accept=".csv, text/plain"
                                        onChange={changeHandler}
                                        className={styles.hide} />
                                </div>
                            </div>
                        )}
                    </>)}
                </div>
            )}
        </div>

        <div className={cn(styles.branchSection, styles.contacts)}>
            <div className={styles.leftSideInfo}>
                <div className={cn(styles.line)}>
                    <div className={cn(styles.firstColumn, styles.bolder)}>Receipt details:</div>
                    <div className={styles.secondColumn}>
                    </div>
                    <div className={styles.thirdColumn}>
                    </div>
                </div>

                <div className={cn(styles.contactsSection, styles.noBorder)}>
                    <div className={cn(styles.line)}>
                        <div className={styles.firstColumn}>Agent name:</div>
                        <div className={styles.secondColumn}>
                            <input type="text"
                                   name="strAgentName"
                                   value={legal?.receipt?.strAgentName}
                                   className={cn(styles.inputField)}
                                   disabled={formDisabled}
                                   onChange={changeReceiptHandler}
                            />
                        </div>
                    </div>
                    <div className={cn(styles.line)}>
                        <div className={styles.firstColumn}>VAT number:</div>
                        <div className={styles.secondColumn}>
                            <input type="text"
                                   name="strVatNumber"
                                   value={legal?.receipt?.strVatNumber}
                                   className={cn(styles.inputField)}
                                   disabled={formDisabled}
                                   onChange={changeReceiptHandler}
                            />
                        </div>
                    </div>
                    <div className={cn(styles.line)}>
                        <div className={styles.firstColumn}>Text on receipt:</div>
                        <div className={cn(styles.secondColumn)}>
                            <textarea
                                className={cn('form-control success', styles.textArea)}
                                name="strReceiptText"
                                value={legal?.receipt?.strReceiptText}
                                disabled={formDisabled}
                                onChange={changeReceiptHandler}
                                maxLength={255}
                                rows="5"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {!formDisabled && (
            <div className={styles.buttonsRight}>
                <ButtonsOkCancelBlock
                    onDelete={onDeleteLegal}
                    onCancelEdit={onCancelEdit}
                    onConfirmEdit={saveLegal}
                    okIsDisabled={formDisabled}
                    okText="Save"
                />
            </div>
        )}
    </>);
};
