import styles from './Operations.less';
import * as moment from 'moment';
import { rowNamesOptions, rowNamesTypes } from "../TableBody/TableBodyHelper";

/*
 * Returns Initial date one week from now in format:
 * '2020-01-01T00:00:00-08:00;2021-02-23T23:59:59-08:00'
 */
export const getWeekBackFromNow = () => `${moment(getWeekBack()).format()};${moment(getCurrentDayEnd()).format()}`;

export const getWeekBack = () => {
    return moment().startOf('day').subtract(7, 'days').toDate();
};

export const getCurrentDayEnd = () => {
    return moment().endOf('day').toDate();
};


export const isParamsTheSame = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
};

export const operationsStatus = [
    { id: null, title: 'all operations' },
    { id: '0', title: 'initiated' },
    { id: '1', title: 'in progress' },
    { id: '2', title: 'success', style: styles.successColor },
    { id: '3', title: 'fail', style: styles.errorColor },
    { id: '4', title: 'cancelled' },
    { id: '5', title: 'cancelled partially' },
    { id: '6', title: 'success hold' },
];

export const rowCount = [
    { id: '10', title: 'Show 10 rows' },
    { id: '20', title: 'Show 20 rows' },
    { id: '50', title: 'Show 50 rows' },
    { id: '100', title: 'Show 100 rows' },
];

export const operationsRowNamesDict = (allowLogs) => {
    let adm = [];
    const generic = [
        { name: 'created', title: 'Created', className: 'noWrap', options: '', isSortable: true },
        { name: 'paymentMethod', title: 'Payment method', className: 'noWrap', options: '', isSortable: false },
        { name: 'operationType', title: 'Type', className: 'noWrap', options: '', isSortable: false },
        { name: 'status', title: 'Status', className: 'noWrap', options: '', isSortable: false },
        { name: 'amount', title: 'Amount', className: 'noWrap', options: '', isSortable: false },
        { name: 'currency', title: 'Currency', className: 'noWrap', options: '', isSortable: false },
        { name: 'customerAccount', title: 'Customer account', className: 'noWrap', options: '', isSortable: false },
        { name: 'merchantAccount', title: 'Merchant account', className: 'noWrap', options: '', isSortable: false },
        { name: 'id', title: 'Id', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'internal_ID', title: 'Internal ID', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'method_ID', title: 'Method ID', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'merchant_ID', title: 'Merchant ID', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'retRefNumber', title: 'Ref Number', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'gateResultMessage', title: 'Message 1', className: '', options: rowNamesOptions.hidden, isSortable: false },
        { name: 'gateResultMessage2', title: 'Message 2', className: '', options: rowNamesOptions.hidden, isSortable: false },];

    if (allowLogs) {
        adm = [
            { name: 'Log', title: 'Log', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'initiator', title: 'Initiator', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'internalComment', title: 'Internal', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'logServer', title: 'log Server', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'logTable', title: 'log Table', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'logId', title: 'log Id', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'logRefsJSON', title: 'log RefsJSON', className: 'noWrap', options: rowNamesOptions.hidden, isSortable: false },
            { name: 'showDetails', title: 'show transit log', className: 'noWrap', options: rowNamesOptions.hidden, type:rowNamesTypes.link, isSortable: false },
            { name: 'getPaymentState', title: 'get payment state', className: 'noWrap', options: rowNamesOptions.hidden, type:rowNamesTypes.custom, isSortable: false },
        ];
    }

    return [...generic, ...adm];
};

