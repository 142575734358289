import { Agents } from './agents.consts';
import { Sms } from '../sms/sms.consts';

/*
 GET PAYMENT AGENTS
*/
export const onGetAgentsFetch = (payload) => ({
    type: Agents.GET_AGENTS_FETCH,
    payload,
});

export const onGetAgentsSuccess = (payload) => ({
    type: Agents.GET_AGENTS_SUCCESS,
    payload,
});

export const onGetAgentsError = (payload) => ({
    type: Agents.GET_AGENTS_ERROR,
    payload,
});

export const onGetAgentsClear = (payload) => ({
    type: Agents.GET_AGENTS_CLEAR,
    payload,
});

/*
 GET CASHIER AGENTS
*/
export const onGetCashiersFetch = (payload) => ({
    type: Agents.GET_CASHIERS_FETCH,
    payload,
});

export const onGetCashiersSuccess = (payload) => ({
    type: Agents.GET_CASHIERS_SUCCESS,
    payload,
});

export const onGetCashiersError = (payload) => ({
    type: Agents.GET_CASHIERS_ERROR,
    payload,
});

export const onGetCashiersClear = (payload) => ({
    type: Agents.GET_CASHIERS_CLEAR,
    payload,
});

/*
 CREATE  AGENTS
*/
export const onCreateAgentFetch = (payload) => ({
    type: Agents.CREATE_AGENT_FETCH,
    payload,
});

export const onCreateAgentSuccess = (payload) => ({
    type: Agents.CREATE_AGENT_SUCCESS,
    payload,
});

export const onCreateAgentError = (payload) => ({
    type: Agents.CREATE_AGENT_ERROR,
    payload,
});

export const onCreateAgentClear = (payload) => ({
    type: Agents.CREATE_AGENT_CLEAR,
    payload,
});


/*
 EDIT  AGENTS
*/
export const onEditAgentFetch = (payload) => ({
    type: Agents.EDIT_AGENT_FETCH,
    payload,
});

export const onEditAgentSuccess = (payload) => ({
    type: Agents.EDIT_AGENT_SUCCESS,
    payload,
});

export const onEditAgentError = (payload) => ({
    type: Agents.EDIT_AGENT_ERROR,
    payload,
});

export const onEditAgentClear = (payload) => ({
    type: Agents.EDIT_AGENT_CLEAR,
    payload,
});


/*
 DELETE AGENTS
*/
export const onDeleteAgentFetch = (payload) => ({
    type: Agents.DELETE_AGENT_FETCH,
    payload,
});

export const onDeleteAgentSuccess = (payload) => ({
    type: Agents.DELETE_AGENT_SUCCESS,
    payload,
});

export const onDeleteAgentError = (payload) => ({
    type: Agents.DELETE_AGENT_ERROR,
    payload,
});


/*
 CREATE  CASHIER
*/
export const onCreateCashierFetch = (payload) => ({
    type: Agents.CREATE_CASHIER_FETCH,
    payload,
});

export const onCreateCashierSuccess = (payload) => ({
    type: Agents.CREATE_CASHIER_SUCCESS,
    payload,
});

export const onCreateCashierError = (payload) => ({
    type: Agents.CREATE_CASHIER_ERROR,
    payload,
});

/*
 EDIT  CASHIER
*/
export const onEditCashierFetch = (payload) => ({
    type: Agents.EDIT_CASHIER_FETCH,
    payload,
});

export const onEditCashierSuccess = (payload) => ({
    type: Agents.EDIT_CASHIER_SUCCESS,
    payload,
});

export const onEditCashierError = (payload) => ({
    type: Agents.EDIT_CASHIER_ERROR,
    payload,
});


/*
 Load Agent Info
*/
export const onGetAgentInfoFetch = (payload) => ({
    type: Agents.GET_AGENT_INFO_FETCH,
    payload,
});

export const onGetAgentInfoSuccess = (payload) => ({
    type: Agents.GET_AGENT_INFO_SUCCESS,
    payload,
});

export const onGetAgentInfoError = (payload) => ({
    type: Agents.GET_AGENT_INFO_ERROR,
    payload,
});

export const onGetAgentInfoClear = (payload) => ({
    type: Agents.GET_AGENT_INFO_CLEAR,
    payload,
});

/*
 GET Agent Details
*/
export const onGetAgentDetailsFetch = (payload) => ({
    type: Agents.GET_AGENT_DETAILS_FETCH,
    payload,
});

export const onGetAgentDetailsSuccess = (payload) => ({
    type: Agents.GET_AGENT_DETAILS_SUCCESS,
    payload,
});

export const onGetAgentDetailsError = (payload) => ({
    type: Agents.GET_AGENT_DETAILS_ERROR,
    payload,
});

export const onGetAgentDetailsClear = (payload) => ({
    type: Agents.GET_AGENT_DETAILS_CLEAR,
    payload,
});


/** File upload */
export const onFileUploadFetch = (payload) => ({
    type: Agents.FILE_SEND_FETCH,
    payload,
});

export const onFileUploadSuccess = (payload) => ({
    type: Agents.FILE_SEND_SUCCESS,
    payload,
});

export const onFileUploadError = (payload) => ({
    type: Agents.FILE_SEND_ERROR,
    payload,
});

