import { rowNamesOptions } from '../Operations/OperationsHelper';

export const accountLogHeaders = [
    { name: 'createDate', title: 'create date', className: 'noWrap', options: '', isSortable: true },
    { name: 'srcAccount', title: 'src account', className: 'noWrap', options: '', isSortable: true },
    { name: 'srcAmount', title: 'src amount', className: 'noWrap', options: '', isSortable: true },
    { name: 'dstAccount', title: 'dst account', className: 'noWrap', options: '', isSortable: true },
    { name: 'dstAmount', title: 'dst amount', className: 'noWrap', options: '', isSortable: true },
    { name: 'state', title: 'state', className: 'noWrap', options: '', isSortable: true },
];
export const emptyFilter = {
    intID: "",
    intPayment: ""
}
