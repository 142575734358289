import { handleActions } from 'redux-actions';

import update from 'immutability-helper';
import { Accounts } from './accounts.consts';
import { combineReducers } from 'redux';

const defaultState = {
    fetching: false,
    success: null,
    errors: null,
};

export const getAccounts = handleActions(
    {
        [Accounts.ACC_ACCOUNTS_FETCH]: (state, action) => {
            return update(state, {
                fetching: { $set: true },
                success: { $set: null },
                errors: { $set: null },
            });
        },
        [Accounts.ACC_ACCOUNTS_SUCCESS]: (state, action) => {
            return update(state, {
                fetching: { $set: false },
                success: { $set: action.payload },
                errors: { $set: null },
            });
        },
        [Accounts.ACC_ACCOUNTS_ERROR]: (state, action) =>
            update(state, {
                fetching: { $set: false },
                errors: { $set: action.payload },
                success: { $set: null },
            }),
        [Accounts.ACC_ACCOUNTS_CLEAR]: (state) =>
            update(state, {
                success: { $set: null },
                fetching: { $set: false },
                errors: { $set: null },
            }),
    },
    defaultState,
);

export const accounts = combineReducers({
    getAccounts,
});
