import React from "react";
import cn from "classnames";
import styles from "./CreateMerchantPanel.less";
import RoutesPaths from "../../../../routes/RoutesPaths";

import { connect } from 'react-redux';
import { emptyMerchant } from "./CreateMerchantPanelHelper";
import { INPUT_PASSWORD_TYPE, INPUT_TEXT_TYPE } from "../../../../utils/consts/global";
import { findTitleById, getCurrentRights, getDataForDD } from '../../../../utils/helpers/commonHelpers';
import { withRouter } from "react-router";
import { DropDown } from "../../DropDown/DropDown";
import { ButtonsOkCancelBlock } from "../../ButtonsOkCancelBlock/ButtonsOkCancelBlock";
import { onGetMerchantTypesFetch, onWLFetch } from '../../../../store/dictionaries/dictionaries.actions';
import { onMerchantAddCardFetch } from "../../../../store/merchants/merchants.actions";


export class GetMerchantsPanelComponent extends React.Component {
    state = {
        merchant: { ...emptyMerchant },
        passwordType: INPUT_PASSWORD_TYPE,
        showWL: getCurrentRights(),
    };

    componentDidMount() {
        if (!this.props.getMerchantTypes.success) {
            this.props.onGetMerchantTypesFetch({});
        }
        if (!this.props.getWL.success) {
            this.props.onWLFetch();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { success } = this.props.addMerchantCard;
        if (success && success !== prevProps.addMerchantCard.success) {

            this.props.history.push({
                pathname: RoutesPaths.MERCHANT,
                search: "?merchantId=" + success?.data?.id || 0 + "&tabId=1&createMode=true",
            });
        }
    }

    addMerchant = () => {
        this.setState({
            merchant: {
                ...emptyMerchant,
                arrPaymentMethods: []
            },
            createMode: true
        });
    };

    changePasswordType = () => {
        this.setState({
            passwordType: this.state.passwordType === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE
        });
    };

    changeInputHandler = (event) => {
        event.persist();
        this.setState((prev) => ({
            merchant: {
                ...prev.merchant,
                [event.target.name]: event.target.value
            }
        }));
    };

    ddSelect = (value, name) => {
        const { id } = value;

        this.setState({
            merchant: {
                ...this.state.merchant,
                [name]: id
            }
        });

    };

    onConfirmEdit = () => {
        this.props.onMerchantAddCardFetch(this.state.merchant);
    };

    render() {
        const { merchant, showWL } = this.state;
        const merchantTypes = this.props.getMerchantTypes.success || [];
        const getWL = this.props.getWL.success || [];
        const wl = getDataForDD(getWL || []);

        return (
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        <div className={cn(styles.card)}>

                        <div className={cn(styles.createMerch)}>
                            <div className={styles.fillTitle}>
                                Merchant information
                            </div>
                            <div className={styles.inputColumn}>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strMerchantBrandingName">Branding name:</label>
                                    <input type="text"
                                           id="strMerchantBrandingName"
                                           name={"strMerchantBrandingName"}
                                           value={merchant.strMerchantBrandingName}
                                           className={cn(styles.inputField)}
                                           onChange={this.changeInputHandler}
                                    />
                                </div>
                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="intMerchantType">Type of merchant:</label>
                                    <div className={cn(styles.fixedDropWidth)}>
                                        <DropDown
                                            color="grey"
                                            id="intMerchantType"
                                            options={merchantTypes}
                                            onSelect={this.ddSelect}
                                            name="intMerchantType">
                                            {findTitleById(merchantTypes, this.props.merchant?.intMerchantType)}&nbsp;
                                        </DropDown>
                                    </div>
                                </div>
                                {showWL && (
                                    <div className={cn(styles.formInline)}>
                                        <label htmlFor="intMerchantType">Whitelabel:</label>
                                        <div className={cn(styles.fixedDropWidth)}>
                                            <DropDown
                                                color="grey"
                                                id="intWhitelabel"
                                                options={wl}
                                                onSelect={this.ddSelect}
                                                name="intWhitelabel">
                                                {findTitleById(wl, this.props.merchant?.intWhitelabel)}&nbsp;
                                            </DropDown>
                                        </div>
                                    </div>
                                )}

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactName">Contact name:</label>
                                    <input type="text"
                                           id="strContactName"
                                           name={"strContactName"}
                                           value={merchant.strContactName}
                                           className={cn(styles.inputField)}
                                           onChange={this.changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactPhone">Contact phone:</label>
                                    <input type="text"
                                           id="strContactPhone"
                                           name={"strContactPhone"}
                                           value={merchant.strContactPhone}
                                           className={cn(styles.inputField)}
                                           onChange={this.changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strContactEmail">Contact e-mail:</label>
                                    <input type="text"
                                           id="strContactEmail"
                                           name={"strContactEmail"}
                                           value={merchant.strContactEmail}
                                           className={cn(styles.inputField)}
                                           onChange={this.changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strLogin">Login:</label>
                                    <input type="text"
                                           id="strLogin"
                                           name={"strLogin"}
                                           value={merchant.strLogin}
                                           className={cn(styles.inputField)}
                                           onChange={this.changeInputHandler}
                                    />
                                </div>

                                <div className={cn(styles.formInline)}>
                                    <label htmlFor="strLogin">Password:</label>
                                    <div className={cn(styles.relative)}>
                                        <input
                                            type={this.state.passwordType}
                                            className={cn(styles.inputField)}
                                            name="strPassword"
                                            value={merchant.strPassword}
                                            onChange={this.changeInputHandler}
                                        />
                                        <i className={cn("icon-eye", styles.iconEye, { [styles.selected]: this.state.passwordType !== INPUT_PASSWORD_TYPE })}
                                           onClick={this.changePasswordType}>&nbsp;</i>
                                    </div>
                                </div>

                                <div className={cn(styles.buttonPlace)}>
                                    <ButtonsOkCancelBlock
                                        id="next"
                                        hideCancelButton={true}
                                        okIsDisabled={false}
                                        onConfirmEdit={this.onConfirmEdit}
                                        className={styles.minButtonWidth}
                                        okText="Save"
                                    />
                                </div>

                            </div>
                        </div>

{/*
                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onCloseAlert}
                    >
                        <h1 className={styles.heading}>Unable to create {merchant.legalName}</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span
                                    className={styles.bolder}>{this.props.addMerchant.errors?.errorMessage?.split("|")[1] || "unknown error"}</span>
                            </div>
                        </div>
                    </SweetAlert>
                )}
*/}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    onGetMerchantTypesFetch,
    onMerchantAddCardFetch,
    onWLFetch,
};

const mapStateToProps = (state) => ({
    getWL:  state.dictionaries.getWL,
    getMerchantTypes: state.dictionaries.getMerchantTypes,
    addMerchantCard: state.merchants.addMerchantCard,
});

export const CreateMerchantPanel = connect(mapStateToProps, mapDispatchToProps)(withRouter(GetMerchantsPanelComponent));
