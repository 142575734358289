import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Paginator.less';
import { SMALL_SCREEN } from '../../../utils/consts/global';

export const defaultPaginatorValues = { currentPage: 1, totalPages: 1 };

export const Paginator = (props) => {
    const { totalPages, currentPage } = props.pagesData ? props.pagesData : defaultPaginatorValues;
    const controls = {
        pdRange: 3,
        mdRange: 2,
        prev: 'Previous',
        next: 'Next',
    };

    if (SMALL_SCREEN) {
        controls.prev = '<';
        controls.next = '>';
        controls.pdRange = 1;
        controls.mdRange = 1;
    }

    return (
        <ReactPaginate
            pageCount={totalPages}
            forcePage={currentPage - 1}
            pageRangeDisplayed={controls.pdRange}
            marginPagesDisplayed={controls.mdRange}
            onPageChange={props.changePage}
            previousLabel={controls.prev}
            nextLabel={controls.next}
            breakLabel={'...'}
            breakClassName={styles.paginatorPage}
            containerClassName={'pagination'}
            pageClassName={styles.paginatorPage}
            activeClassName={styles.pagPageActive}
            previousClassName={styles.paginatorPage}
            nextClassName={styles.paginatorPage}
        />
    );
};
