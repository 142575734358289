import React from 'react';
import { rowNamesOptions } from '../TableBody/TableBodyHelper';
import * as moment from 'moment';
import { DAYTIME_FORMAT_HUMAN_WS } from '../../../utils/consts/global';

export const scheduleRows = [
    { name: 'scheduled_at', title: 'Scheduled at', className: '', options: '', isSortable: false },
    { name: 'user_file_name', title: 'Filename', className: '', options: '', isSortable: false },
    { name: 'datetime', title: 'Date added', className: '', options: '', isSortable: false },
    { name: 'batch_id', title: 'Batch id', className: '', options: '', isSortable: false },
    {
        name: 'operations',
        title: ' ',
        className: 'rightAlign',
        options: rowNamesOptions.removeBlock,
        isSortable: false,
    },
];

export const historyRows = [
    { name: 'datetime', title: 'Date', className: '', options: '', isSortable: false },
    { name: 'user_file_name', title: 'File', className: '', options: '', isSortable: false },
    { name: 'status', title: 'Status', className: '', options: '', isSortable: false },
    { name: 'num_msgs', title: 'Requested', className: '', options: '', isSortable: false },
    { name: 'num_msgs_filtered', title: 'Unsubscribed. Not sent.', className: '', options: '', isSortable: false },
    { name: 'num_msgs_sent', title: 'Sent', className: '', options: '', isSortable: false },
    { name: 'num_msgs_delivered', title: 'Delivered', className: '', options: '', isSortable: false },
    { name: 'num_msgs_waiting', title: 'Waiting Status from Operator', className: '', options: '', isSortable: false },
];

export const oneManyRadios = [
    {
        name: 'one',
        checked: true,
        title: '',
    },
    {
        name: 'two',
        checked: false,
        title: '',
    },
];


export const sendDelayRadios = [
    {
        name: 'now',
        checked: true,
        title: 'Send now',
    },
    {
        name: 'delayed',
        checked: false,
        title: (<>Delayed send</>),
    },
];


export const scheduledMapper = (arr) => {
    return arr.map(el => {
        return {
            ...el,
            datetime: moment(el.datetime).format(DAYTIME_FORMAT_HUMAN_WS),
            scheduled_at: moment(el.scheduled_at).format(DAYTIME_FORMAT_HUMAN_WS),
        };
    });
};

export const historyMapper = (arr) => {
    return arr.map(el => {
        return {
            ...el,
            datetime: moment(el.datetime).format(DAYTIME_FORMAT_HUMAN_WS),
        };
    });
};

export const historyFilterInitialState = {
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day'),
    intCurrentPage: 1,
    intItemsPerPage: 15,
};
