export const SelfStorage = {
    SET_ITEM_FETCH: 'SET_ITEM_FETCH',
    SET_ITEM_SUCCESS: 'SET_ITEM_SUCCESS',
    SET_ITEM_ERROR: 'SET_ITEM_ERROR',
    SET_ITEM_CLEAR: 'SET_ITEM_CLEAR',

    GET_ITEM_FETCH: 'GET_ITEM_FETCH',
    GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
    GET_ITEM_ERROR: 'GET_ITEM_ERROR',
    GET_ITEM_CLEAR: 'GET_ITEM_CLEAR',

    REMOVE_ITEM_FETCH: 'REMOVE_ITEM_FETCH',
    REMOVE_ITEM_SUCCESS: 'REMOVE_ITEM_SUCCESS',
    REMOVE_ITEM_ERROR: 'REMOVE_ITEM_ERROR',
    REMOVE_ITEM_CLEAR: 'REMOVE_ITEM_CLEAR',
};
