import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';
import { AdmUsersPanel } from '../../components/shared/AdmUsersPanel/AdmUsersPanel';

const AdmUsers = (props) => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();

    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="AdmUsers" />
            <AdmUsersPanel />
        </div>
    );
};

export default AdmUsers;
