import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import cn from "classnames";
import styles from "./MerchantsList.less";
import { connect } from "react-redux";
import { Spinner } from "../../Spinner/Spinner";
import { TableBody } from "../../TableBody/TableBody";
import { emptyMerchant, menuItems, rows } from "./MerchantsListHelper";
import { TableHeader } from "../../TableHeader/TableHeader";
import { INPUT_PASSWORD_TYPE, SMALL_SCREEN } from "../../../../utils/consts/global";
import { ActionButton } from "../../ActionButton/ActionButton";
import {
    onLegalSetMethodsClear,
    onMerchantGetCardClear, onMerchantGetLegalClear, onMerchantsGetFetch,
} from '../../../../store/merchants/merchants.actions';
import { withRouter } from "react-router";
import RoutesPaths from "../../../../routes/RoutesPaths";


export class GetMerchantsPanelComponent extends React.Component {
    state = {
        createMode: false,
        activeTab: menuItems[0].name,
        merchant: { ...emptyMerchant },
        passwordType: INPUT_PASSWORD_TYPE,
        showLoginNameAlert: false,
        isAlertShown: false,
    };

    componentDidMount() {
        this.props.onMerchantsGetFetch({});
        this.props.onMerchantGetCardClear({});
        this.props.onMerchantGetLegalClear(null);
    }

    componentDidUpdate(prevProps) {
        const addMerchant = this.props.addMerchant;

        if (addMerchant.errors && JSON.stringify(prevProps.addMerchant.errors) !== JSON.stringify(addMerchant.errors)) {
            this.setState({
                isAlertShown: true
            });
        }

        if (addMerchant.success && JSON.stringify(prevProps.addMerchant.success) !== JSON.stringify(addMerchant.success)) {
            this.props.onGetMerchantsFetch();

            this.setState({
                createMode: false
            });
        }
    }

    addMerchant = () => {
        this.props.history.push({
            pathname: RoutesPaths.MERCHANT_ADD,
            search: '',
        });
    };

    onCloseAlert = () => {
        this.setState({
            isAlertShown: false
        });
    };

    onMerchantClick = (e) => {
        this.props.history.push({
            pathname: RoutesPaths.MERCHANT,
            search: "?merchantId=" + e?.id
        });
    };

    render() {
        const fetching = this.props.getMerchantsList.fetching;
        const merchantsList = this.props.getMerchantsList.success?.data || [];
        const { createMode, merchant } = this.state;

        return (
            <div className={cn(styles.mainPanel, "main-panel")}>
                <div className="container">
                    <div className="page-inner">
                        {SMALL_SCREEN && <h1>Merchants list</h1>}
                        <div className="page-header">&nbsp;</div>
                        <div className="col-md-12">
                            <div className={cn(styles.card, "card")}>
                                <div className={cn("card-body", styles.topPadding)}>
                                    <div className={cn("nav-scroller d-flex", styles.borderBottomLine)}>
                                        <div className={styles.topBar}>
                                            {createMode && (<div className={styles.fillTitle}>
                                                Merchant information
                                            </div>)}
                                            {!createMode && (<>
                                                <div>&nbsp;</div>
                                                <div className={styles.buttonAddMerch}>
                                                    <ActionButton click={this.addMerchant}>add Merchant</ActionButton>
                                                </div>
                                            </>)}
                                        </div>
                                    </div>
                                    {!createMode && (<div className="table-responsive">
                                        {fetching ? (
                                            <div className={styles.center}>
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <table className="display table table-striped table-hover dataTable">
                                                <TableHeader rowNames={rows} hasCollapseSwich={false} />
                                                <TableBody
                                                    dataTable={merchantsList}
                                                    rowNames={rows}
                                                    hasCollapseSwich={false}
                                                    events={this.onDataLineEvents}
                                                    onSelect={this.onMerchantClick}
                                                />
                                            </table>
                                        )}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAlertShown && (
                    <SweetAlert
                        show={this.state.isAlertShown}
                        title=""
                        type="danger"
                        cancelBtnBsStyle="primary"
                        onConfirm={this.onCloseAlert}
                    >
                        <h1 className={styles.heading}>Unable to create {merchant.legalName}</h1>
                        <div className={styles.deleteString}>
                            <div className={styles.line}>
                                <span
                                    className={styles.bolder}>{this.props.addMerchant.errors?.errorMessage?.split("|")[1] || "unknown error"}</span>
                            </div>
                        </div>
                    </SweetAlert>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    onMerchantGetCardClear,
    onLegalSetMethodsClear,
    onMerchantGetLegalClear,
    onMerchantsGetFetch,
};

const mapStateToProps = (state) => ({
    getMerchantsList: state.merchants.getMerchantsList,
    getMerchantTypes: state.dictionaries.getMerchantTypes,
    addMerchant: state.merchants.addMerchant
});

export const MerchantsList = connect(mapStateToProps, mapDispatchToProps)(withRouter(GetMerchantsPanelComponent));
