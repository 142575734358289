import axios from 'axios';
import { API_URL, headers, ROLESYSTEM_URL, ROLESYSTEM_V2_URL } from '../../utils/consts/global';
import { getBusinessError } from '../helpers/commonHelpers';

export function fetchRoleSystem(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    return axios({
        method: 'post',
        url: `${API_URL}${ROLESYSTEM_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}

export function fetchRoleSystem2(payload) {
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
    };
    return axios({
        method: 'post',
        url: `${API_URL}${ROLESYSTEM_V2_URL}`,
        data: payload,
        headers,
    })
        .then(
            (response) => {
                return getBusinessError(response);
            },
            (error) => {
                return { error, response: null };
            },
        )
        .catch((error) => {
            return { error, response: null };
        });
}
