import { takeEvery } from '@redux-saga/core/effects';
import { AppActions } from './app.consts';

export function* addNotification({ payload }) {
    // const notifyState = yield select((state) => state.app);
    // console.log('saga notifyState', notifyState, payload);
}

export function* appSagas() {
    yield takeEvery(AppActions.APP_NOTIFY_SHOW, addNotification);
}
