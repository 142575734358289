import { rowNamesOptions } from "../TableBody/TableBodyHelper";

export const getRulesArray = (groupRules, id) => {
    return groupRules.reduce(function (newArr, el) {
        if (el.groupsID === id) {
            newArr.push(el.roleID);
        }
        return newArr;
    }, []);
};

export const rowsAndDeleteKey = [
    { name: 'id', title: 'id', className: 'noWrap', options: '', isSortable: false },
    { name: 'code', title: 'code', className: 'noWrap', options: '', isSortable: false },
    { name: 'title', title: 'title', className: 'noWrap', options: '', isSortable: false },
    { name: 'description', title: 'description', className: 'noWrap', options: '', isSortable: false },
    { name: 'operations', title: 'actions', className: 'rightAlign', options: rowNamesOptions.removeBlock, isSortable: false },
];
