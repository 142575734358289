import { call, put, takeEvery } from 'redux-saga/effects';
import { errorNotify } from '../helpers/commonHelpers';
import { onHandleErrors } from '../errors/errors.actions';

import { fetchAgents, sendFile } from './agents.services';
import {
    onCreateAgentError,
    onCreateAgentSuccess,
    onCreateCashierError,
    onCreateCashierSuccess,
    onDeleteAgentError,
    onDeleteAgentSuccess,
    onEditAgentError,
    onEditAgentSuccess,
    onEditCashierError,
    onEditCashierSuccess, onFileUploadError,
    onFileUploadSuccess,
    onGetAgentDetailsError,
    onGetAgentDetailsSuccess,
    onGetAgentInfoError,
    onGetAgentInfoSuccess,
    onGetAgentsError,
    onGetAgentsSuccess,
    onGetCashiersError,
    onGetCashiersSuccess,
} from './agents.actions';
import { Agents } from './agents.consts';
import { sendBulkSmsFile } from '../sms/sms.services';
import { onSmsBulkError, onSmsBulkSuccess } from '../sms/sms.actions';

export function* sagaGetAgentsFetch(action) {
    try {

        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAgents',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAgentsError(error));
        } else if (response) {
            yield put(onGetAgentsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAgentsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAgentsError(error));
    }
}

export function* sagaGetAgentInfoFetch(action) {
    try {

        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAgent',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAgentInfoError(error));
        } else if (response) {
            yield put(onGetAgentInfoSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAgentInfoError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAgentInfoError(error));
    }
}
// params:
//     "refID": integer
//     "refType": integer
export function* sagaGetAgentDetailsFetch(action) {
    try {

        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetAgentDetailed',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetAgentDetailsError(error));
        } else if (response) {
            yield put(onGetAgentDetailsSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetAgentDetailsError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetAgentDetailsError(error));
    }
}

export function* sagaGetCashiersFetch(action) {
    try {

        const { payload } = action;
        const params = {
            ...payload,
            method: 'GetCashiers',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onGetCashiersError(error));
        } else if (response) {
            yield put(onGetCashiersSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onGetCashiersError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onGetCashiersError(error));
    }
}

export function* sagaCreateAgentFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'AddAgent',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCreateAgentError(error));
        } else if (response) {
            yield put(onCreateAgentSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCreateAgentError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCreateAgentError(error));
    }
}

export function* sagaEditAgentFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'EditAgent',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEditAgentError(error));
        } else if (response) {
            yield put(onEditAgentSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEditAgentError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEditAgentError(error));
    }
}


export function* sagaDeleteAgentFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'DeleteAgent',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onDeleteAgentError(error));
        } else if (response) {
            yield put(onDeleteAgentSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onDeleteAgentError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onDeleteAgentError(error));
    }
}


export function* sagaCreateCashierFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'AddCashier',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onCreateCashierError(error));
        } else if (response) {
            yield put(onCreateCashierSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onCreateCashierError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onCreateCashierError(error));
    }
}

export function* sagaEditCashierFetch(action) {
    try {
        const { payload } = action;
        const params = {
            ...payload,
            method: 'EditCashier',
        };

        const { response, error } = yield call(fetchAgents, params);

        if (error) {
            yield put(onHandleErrors(error));
            yield put(onEditCashierError(error));
        } else if (response) {
            yield put(onEditCashierSuccess(response.data));
        } else {
            yield call(errorNotify, 'unknown error occurred');
            yield put(onEditCashierError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onEditCashierError(error));
    }
}


/*
* FORM DATA FOR CHECK FORM POST DATA
*/
export function* sagaFileUploadFetch(action) {
    try {
        const { payload } = action;
        const { response, error } = yield call(
            sendFile, payload
        );
        if (error) {
            yield put(onFileUploadError(error));
        } else if (response) {
            yield put(onFileUploadSuccess(response.data.data));
        } else {
            yield call(errorNotify, "unknown error occurred");
            yield put(onFileUploadError(error));
        }
    } catch (error) {
        yield call(errorNotify, error);
        yield put(onFileUploadError(error));
    }
}


export function* agentsSaga() {
    yield takeEvery(Agents.GET_AGENTS_FETCH, sagaGetAgentsFetch);
    yield takeEvery(Agents.EDIT_AGENT_FETCH, sagaEditAgentFetch);
    yield takeEvery(Agents.DELETE_AGENT_FETCH, sagaDeleteAgentFetch);
    yield takeEvery(Agents.GET_AGENT_INFO_FETCH, sagaGetAgentInfoFetch);
    yield takeEvery(Agents.GET_AGENT_DETAILS_FETCH, sagaGetAgentDetailsFetch);
    yield takeEvery(Agents.CREATE_AGENT_FETCH, sagaCreateAgentFetch);
    yield takeEvery(Agents.GET_CASHIERS_FETCH, sagaGetCashiersFetch);
    yield takeEvery(Agents.CREATE_CASHIER_FETCH, sagaCreateCashierFetch);
    yield takeEvery(Agents.EDIT_CASHIER_FETCH, sagaEditCashierFetch);
    yield takeEvery(Agents.FILE_SEND_FETCH, sagaFileUploadFetch);
}
