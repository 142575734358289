import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./TransitLogPanel.less";
import { useDispatch, useSelector } from "react-redux";
import { TabMenu } from "../TabMenu/TabMenu";
import { menuItems } from "./TransitLogPanel.helper";
import { Transactions } from "../../../store/transactions/transactions.consts";
import { PERM_GET_RECORDS_ADM } from "../../../utils/consts/global";
import { Spinner } from "../Spinner/Spinner";

export const TransitLogPanel = (props) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(menuItems[0].name);
    const getTrasitLog = useSelector((state) => state.transactions.getTransitLog.success)?.data;
    const permissions = useSelector((state) => state.auth.permissions.success) || [];

    useEffect(() => {
            if (permissions.indexOf(PERM_GET_RECORDS_ADM) !== -1) {
                dispatch({
                    type: Transactions.TRA_TRANSIT_LOG_FETCH,
                    payload: {
                        intID: props.id
                    }
                });
            }
    }, []);
    const fetching = useSelector((state) => state.transactions.getTransitLog.fetching);
    const transitLog = getTrasitLog || {};
    const {
        clientIP,
        requestDT,
        requestURL,
        requestHeaders,
        requestData,
        requestInfo,
        sendDT, sendURL, sendHeaders, sendData, sendInfo,
        receiveDT, receiveURL, receiveHeaders, receiveData, receiveInfo,
        responseDT, responseURL, responseHeaders, responseData, responseInfo
    } = transitLog;

    const isJsonValid = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const beautifyJSON = (text) => {
        return JSON.stringify(JSON.parse(text), null, 4);
    };

    const requestValid = isJsonValid(requestData);
    const requestDataBeauty = requestValid ? beautifyJSON(requestData) : requestData;

    const sendValid = isJsonValid(sendData);
    const sendDataBeauty = sendValid ? beautifyJSON(sendData) : sendData;

    const receiveValid = isJsonValid(receiveData);
    const receiveDataBeauty = receiveValid ? beautifyJSON(receiveData) : receiveData;

    const responseValid = isJsonValid(responseData);
    const responseDataBeauty = responseValid ? beautifyJSON(responseData) : responseData;

    return (
        <div className={cn(styles.card)}>
            <div className={cn("card-body", styles.topPadding)}>
                {fetching ? (<Spinner />) : (
                    <div className="table-responsive">
                        <div className={styles.borderBottomLine}>
                            <TabMenu
                                className={styles.tabMargin}
                                menuItems={menuItems}
                                active={activeTab}
                                onChange={setActiveTab}
                            />
                        </div>
                        {activeTab === menuItems[0].name && (
                            <div className={styles.tabPadding}>
                                <div className={styles.line}>
                                    <span className={styles.label}>clientIP:</span>
                                    <span className={styles.value}>{clientIP}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>requestDT:</span>
                                    <span className={styles.value}>{requestDT}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>requestURL:</span>
                                    <span className={styles.value}>{requestURL}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>requestHeaders:</span>
                                    <span className={styles.value}>{requestHeaders}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>requestData:</span>
                                    <span className={cn(styles.value)}>
                                    <textarea
                                        className={cn({ [styles.okBg]: requestValid, [styles.errBg]: !requestValid })}
                                        value={requestDataBeauty}
                                    />
                                </span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>requestInfo:</span>
                                    <span className={styles.value}>{requestInfo}</span>
                                </div>
                            </div>
                        )}
                        {activeTab === menuItems[1].name && (
                            <div className={styles.tabPadding}>
                                <div className={styles.line}>
                                    <span className={styles.label}>sendDT:</span>
                                    <span className={styles.value}>{sendDT}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>sendURL:</span>
                                    <span className={styles.value}>{sendURL}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>sendHeaders:</span>
                                    <span className={styles.value}>{sendHeaders}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>sendData:</span>
                                    <span className={cn(styles.value)}>
                                    <textarea
                                        className={cn({ [styles.okBg]: sendValid, [styles.errBg]: !sendValid })}
                                        value={sendDataBeauty}
                                    />
                                </span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>sendInfo:</span>
                                    <span className={styles.value}>{sendInfo}</span>
                                </div>
                            </div>
                        )}
                        {activeTab === menuItems[2].name && (
                            <div className={styles.tabPadding}>
                                <div className={styles.line}>
                                    <span className={styles.label}>receiveDT:</span>
                                    <span className={styles.value}>{receiveDT}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>receiveURL:</span>
                                    <span className={styles.value}>{receiveURL}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>receiveHeaders:</span>
                                    <span className={styles.value}>{receiveHeaders}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>receiveData:</span>
                                    <span className={cn(styles.value)}>
                                    <textarea
                                        className={cn({ [styles.okBg]: receiveValid, [styles.errBg]: !receiveValid })}
                                        value={receiveDataBeauty}
                                    />
                                </span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>receiveInfo:</span>
                                    <span className={styles.value}>{receiveInfo}</span>
                                </div>
                            </div>
                        )}
                        {activeTab === menuItems[3].name && (
                            <div className={styles.tabPadding}>
                                <div className={styles.line}>
                                    <span className={styles.label}>responseDT:</span>
                                    <span className={styles.value}>{responseDT}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>responseURL:</span>
                                    <span className={styles.value}>{responseURL}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>responseHeaders:</span>
                                    <span className={styles.value}>{responseHeaders}</span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>responseData:</span>
                                    <span className={styles.value}>
                                    <textarea
                                        className={cn({ [styles.okBg]: responseValid, [styles.errBg]: !responseValid })}
                                        value={responseDataBeauty}
                                    />
                                </span>
                                </div>
                                <div className={styles.line}>
                                    <span className={styles.label}>responseInfo:</span>
                                    <span className={styles.value}>{responseInfo}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>

    );
};
