import React, { useState } from 'react';
import cn from 'classnames';
import styles from './TableBody.less';
import Switch from 'react-switch';
import { getVisible, rowNamesOptions, rowNamesTypes } from './TableBodyHelper';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import { DropDown } from '../DropDown/DropDown';
import { findTitleById } from '../../../utils/helpers/commonHelpers';

export const TableBody = (props) => {
    const [expandCurrent, changeExpanded] = useState([]);

    const onChangeExpandedButton = (e) => {
        const index = Number(e.target.dataset.idx);
        let arr = [...expandCurrent];
        arr[index] = !expandCurrent[index];
        changeExpanded(arr);
    };

    const editButtonPressed = (item) => {
        if (props.events) {
            props.events({ type: 'edit', item });
        }
    };

    const switchButtonPressed = (e, item) => {
        if (props.events) {
            e.stopPropagation();
            props.events({ type: 'switch', item });
        }
    };

    const removeButtonPressed = (item) => {
        if (props.events) {
            props.events({ type: 'remove', item });
        }
    };
    const onClickCustomLink = (item) => {
        if (props.onClickCustomLink) {
            props.onClickCustomLink(item);
        }
    };

    const onDetailsClick = (item) => {
        if (props.onDetailsClick) {
            props.onDetailsClick(item);
        }
    };

    const lineClick = (e, line) => {
        if (e.target.classList.contains('react-switch-handle')) return;
        if (e.target.classList.contains('fa-chevron-down')) return;
        if (e.target.classList.contains('collapseCell')) return;

        if (props.onSelect) {
            props.onSelect(line);
        }
    };

    const checkBoxClicked = (data) => {
        if (props.onCheckBoxChanged) {
            props.onCheckBoxChanged(data);
        }
    };

    const ddSelect = (data) => {
        if (props.onDDChanged) {
            props.onDDChanged(data);
        }
    };

    const visible = getVisible(props.rowNames);
    const hidden = props.rowNames.filter((el) => el.options === rowNamesOptions.hidden);
    const hiddenJSX = props.rowNames.filter((el) => el.options === rowNamesOptions.hiddenJSX);

    const tdList = (line, tableIndex) => {
        return visible.map((element, index) => {
            return (
                <td key={String(index)} className={styles[element.className]}
                    title={line[element.name + 'Title'] || undefined}>

                    {element.options === rowNamesOptions.image &&
                    <img src={line[element.name]} height="30" alt="image" />}

                    {element.options === rowNamesOptions.editOnlyBlock && line.options !== rowNamesOptions.noEdit && (
                        <>
                            <button
                                className="btn btn-primary btn-border btn-round btn-sm"
                                onClick={() => editButtonPressed(line)}>
                                <i className={cn('fa fa-pen')}> </i>
                            </button>
                        </>
                    )}
                    {element.options === rowNamesOptions.editBlock && line.options !== rowNamesOptions.noEdit && (
                        <>
                            <button
                                className="btn btn-primary btn-border btn-round btn-sm"
                                onClick={() => editButtonPressed(line)}>
                                <i className={cn('fa fa-pen')}> </i>
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-primary btn-border btn-round btn-sm"
                                onClick={() => removeButtonPressed(line)}>
                                <i className={cn('fa fa-trash')}> </i>
                            </button>
                        </>
                    )}
                    {element.options === rowNamesOptions.removeBlock && line.options !== rowNamesOptions.noEdit && (
                        <button className="btn btn-icon btn-round btn-primary"
                                onClick={() => removeButtonPressed(line)}>
                            <i className={cn('fa fa-trash')}> </i>
                        </button>
                    )}
                    {element.options === rowNamesOptions.enableBlock && line.options !== rowNamesOptions.noEdit && (
                        <Switch onChange={(checked, event) => switchButtonPressed(event, line)}
                                disabled={!!line.disabled}
                                checked={!!line.on}
                                onColor="#28a745"
                                height={20} width={40}
                                checkedIcon={false}
                        />
                    )}
                    {element.options === rowNamesOptions.singleCheckBox && line.options !== rowNamesOptions.noEdit && (
                        <CustomCheckbox
                            line={line}
                            el={element.name}
                            checked={line[element.name]}
                            onChange={checkBoxClicked}
                            noLabel
                        />)
                    }
                    {element.options === rowNamesOptions.checkBoxBlock && line.options !== rowNamesOptions.noEdit && (
                        <div className={styles.flex}>
                            {line[element.name].all.map((el, idx) => {
                                return (
                                    <CustomCheckbox
                                        key={String(idx)}
                                        line={line}
                                        name={element.name}
                                        el={el}
                                        checked={line[element.name].selected.indexOf(el) !== -1}
                                        onChange={checkBoxClicked}
                                        disabled={line[element.name].disabled}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {(element.options === rowNamesOptions.dropDown && !line[element.name].hidden) && line.options !== rowNamesOptions.noEdit && (
                        <div className={styles.flex}>
                            <DropDown
                                line={line}
                                dropListStyle={styles.tableDropDownList}
                                options={line[element.name]?.all || []}
                                onSelect={ddSelect}
                                disabled={!line.on || line[element.name]?.disabled}
                                name={element.name}
                                color="grey"
                            >
                                {findTitleById(line[element.name]?.all, line[element.name]?.selected)}&nbsp;
                            </DropDown>
                        </div>
                    )}
                    {element.options === rowNamesOptions.arrayBlock && <>{line[element.name].map((el) => el).join(', ')}</>}
                    {element.options === rowNamesOptions.mergeUp && <>
                        {(tableIndex - 1 >= 0 && dataTable[tableIndex-1][element.name] !== line[element.name]) ? line[element.name]:''}
                        {tableIndex === 0 && line[element.name]}
                    </>}
                    {!element.options && <>{line[element.name]}</>}
                </td>
            );
        });
    };

    const ulList = (line) => {
        const useJSX = !!hiddenJSX.length;
        const elements = useJSX ? hiddenJSX : hidden;

        return elements.map((element, index) => {
            const content = (typeof line[element.name] === 'object' && !useJSX) ? JSON.stringify(line[element.name]) : line[element.name];
            return (
                <li key={String(index)} className={cn(styles.hiddenLine, styles[element.className])}>
                    {element.type === rowNamesTypes.link && (
                        <span
                            className={cn(styles.hiddenHeader, styles.link, styles[element.className])}
                            onClick={() => onDetailsClick(line)}
                        >{element.title}</span>
                    )}
                    {element.type === rowNamesTypes.custom && !!Number(line.subClientID) && (
                        <span
                            className={cn(styles.hiddenHeader, styles.link, styles[element.className])}
                            onClick={() => onClickCustomLink(line)}
                        >{element.title}</span>
                    )}

                    {element.type !== rowNamesTypes.link && element.type !== rowNamesTypes.custom && (<>
                        <span className={cn(styles.hiddenHeader, styles[element.className])}>{element.title}:</span>
                        <span className={styles[element.className]}>{content}</span>
                    </>)}
                </li>
            );
        });
    };

    const dataTable = props.dataTable || [];
    return (
        <tbody>
        {dataTable?.map((line, idx) => (
            <React.Fragment key={String(idx)}>
                <tr
                    className={cn([line.className], { [styles.cursorPointer]: !!props.onSelect })}
                    onClick={(e) => {
                        lineClick(e, line);
                    }}
                >
                    {!!props.hasCollapseSwich && (
                        <td data-idx={idx} onClick={onChangeExpandedButton}
                            className={cn('collapseCell', styles.arrowPlace, styles.cursorPointer)}>
                            <i
                                data-idx={idx}
                                onClick={onChangeExpandedButton}
                                className={cn(styles.expandIcon, 'fas fa-chevron-down', {
                                    [styles.expanded]: !!expandCurrent[idx],
                                })}
                            >
                                {' '}
                            </i>
                        </td>
                    )}
                    {tdList(line, idx)}
                </tr>
                {expandCurrent[idx] && (
                    <tr className={cn(styles.trExtend)}>
                        <td colSpan={visible.length + 1}>
                            <ul className={styles.listValues}>{ulList(line)}</ul>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        ))}
        </tbody>
    );
};

