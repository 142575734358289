import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { auth } from './auth/auth.reducer';
import { appReducers } from './app/app.reducer';
import { transactions } from './transactions/transactions.reducers';
import { dictionaries, getAllowedOperators } from './dictionaries/dictionaries.reducers';
import { payments } from './paymentOperations/paymentOperations.reducers';
import { roleSystem } from './roleSystem/roleSystem.reducers';
import { sms } from './sms/sms.reducers';
import { agents } from './agents/agents.reducers';
import { accounts } from "./accounts/accounts.reducers";
import { merchants } from "./merchants/merchants.reducers";
import { metrics } from './metric/metric.reducers';
import { selfStorage } from './selfStorage/selfStorage.reducers';

export const rootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        app: appReducers,
        getAllowedOperators,
        transactions,
        payments,
        auth,
        dictionaries,
        roleSystem,
        sms,
        agents,
        accounts,
        merchants,
        metrics,
        selfStorage,
    });
};
