import { call, put, take, takeEvery } from 'redux-saga/effects';
import { Errors } from './errors.consts';
import { onAuthEjectUser } from '../auth/auth.actions';
import { errorNotify } from '../helpers/commonHelpers';
import { ejectUser } from '../auth/auth.saga';

export function* errorHandlerSaga(action) {
    // try {
    const { errorCode } = action.payload;
    if (errorCode) {
        const errMsg = action.payload?.errorMessage;
        switch (errorCode) {
            case 13601: {
                console.error('PERMISSION DENIED!', action.payload);
                break;
            }
            case 13602: {
                console.error('TOKEN EXPIRED OR NOT VALID!', action.payload);
                yield call(ejectUser, action);
                break;
            }
            case 10600: {
                console.error(errMsg);
                break;
            }
        }
        yield call(errorNotify, errMsg);
    } else {
        yield call(errorNotify, action.payload);
    }
}

export function* errorsSaga() {
    yield takeEvery(Errors.ERR_HANDLE_ERRORS, errorHandlerSaga);
}
