import React, { useState } from 'react';
import { RadioButton } from '../RadioButton/RadioButton';
import cn from 'classnames';

/*
* Params:
* [
*
* ]
*/
export const RadioButtonGroup = (props) => {
    const [radios, setRadios] = useState(props.radios || []);

    const onChange = (val) => {
        const newRadios = radios.map(el => {
            return {
                ...el,
                checked: val.name === el.name,
            };
        });
        setRadios(newRadios);
        if (props.onChange) {
            props.onChange(val);
        }
    };
    return (
        <div className={cn(props.className)}>
            {radios.map((el, idx) => {
                return (
                    <RadioButton
                        {...el}
                        key={idx}
                        onChange={onChange}
                    />);
            })
            }
        </div>);
};
