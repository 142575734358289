import cn from 'classnames';
import styles from './TableHeader.less';
import React, { useEffect, useState } from 'react';
import { sortingClass } from '../../../utils/helpers/commonFunctions';
import { getVisible, rowNamesOptions } from '../TableBody/TableBodyHelper';

/**
 * PROPS:
 * rowNames: [string];
 * resort(columnName, isSortingAsc );
 * returns:
 * JSX
 */

export const TableHeaderComponent = (props) => {
    const [sortingByColumn, setSortingColumn] = useState(props.rowNames[0]);
    const [isSortingAsc, sortingAsc] = useState(true);

    const resort = (column) => {
        sortingAsc(!isSortingAsc);
        setSortingColumn(column);
    };

    useEffect(() => {
        if (props.onSoftSortChange) {
            props.onSoftSortChange({
                sortType: isSortingAsc ? sortingClass.asc : sortingClass.desc,
                sortingByColumnName: sortingByColumn,
            });
        }
    }, [sortingByColumn, isSortingAsc]);

    const getSortType = isSortingAsc ? sortingClass.asc : sortingClass.desc;
    const visible = getVisible(props.rowNames);
    const sortEnabled = !!props.onSoftSortChange;

    return (
        <thead>
            <tr>
                {props.hasCollapseSwich && <th> </th>}
                {visible?.map((el, idx) => {
                    return (
                        <React.Fragment key={String(idx)}>
                            {el.isSortable ? (
                                <th
                                    className={cn(styles.tableHeader, styles[el.className], sortEnabled && el === sortingByColumn && getSortType)}
                                    onClick={() => {
                                        resort(el);
                                    }}
                                >
                                    {el.title}
                                </th>
                            ) : (
                                <th className={cn(styles.tableHeaderSimple, styles[el.className])}>{el.title}</th>
                            )}
                        </React.Fragment>
                    );
                })}
            </tr>
        </thead>
    );
};

export const TableHeader = React.memo(TableHeaderComponent);
