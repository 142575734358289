import React from 'react';
import { LeftSideBar } from '../../components/shared/LeftSideBar/LeftSideBar';
import { Operations } from '../../components/shared/Operations/Operations';
import { getSideBarVisibility } from '../../utils/customHooks';
import cn from 'classnames';

const OperationsWrapper = () => {
    const { isSidebarShown, isSidebarMobShown } = getSideBarVisibility();
    return (
        <div
            className={cn('wrapper', {
                ['sidebar_minimize']: !isSidebarShown,
                ['nav_open']: isSidebarMobShown,
            })}
        >
            <LeftSideBar active="payments" />
            <Operations />
        </div>
    );
};

export default OperationsWrapper;
