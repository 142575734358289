import { Merchants } from "./merchants.consts";

/*
GET MERCHANT
*/
export const onMerchantsGetFetch = (payload) => ({
    type: Merchants.MERCHANTS_LIST_GET_FETCH,
    payload,
});

export const onMerchantsGetSuccess = (payload) => ({
    type: Merchants.MERCHANTS_LIST_GET_SUCCESS,
    payload,
});

export const onMerchantsGetError = (payload) => ({
    type: Merchants.MERCHANTS_LIST_GET_ERROR,
    payload,
});

export const onMerchantsGetClear = (payload) => ({
    type: Merchants.MERCHANTS_LIST_GET_CLEAR,
    payload,
});

/*
ADD MERCHANT
*/
export const onMerchantAddFetch = (payload) => ({
    type: Merchants.MERCHANT_ADD_FETCH,
    payload,
});

export const onMerchantAddSuccess = (payload) => ({
    type: Merchants.MERCHANT_ADD_SUCCESS,
    payload,
});

export const onMerchantAddError = (payload) => ({
    type: Merchants.MERCHANT_ADD_ERROR,
    payload,
});

export const onMerchantAddClear = (payload) => ({
    type: Merchants.MERCHANT_ADD_CLEAR,
    payload,
});

/*
ADD LEGAL
*/
export const onMerchantAddLegalFetch = (payload) => ({
    type: Merchants.MERCHANT_ADD_LEGAL_FETCH,
    payload,
});

export const onMerchantAddLegalSuccess = (payload) => ({
    type: Merchants.MERCHANT_ADD_LEGAL_SUCCESS,
    payload,
});

export const onMerchantAddLegalError = (payload) => ({
    type: Merchants.MERCHANT_ADD_LEGAL_ERROR,
    payload,
});

export const onMerchantAddLegalClear = (payload) => ({
    type: Merchants.MERCHANT_ADD_LEGAL_CLEAR,
    payload,
});

/*
EDIT LEGAL
*/
export const onMerchantEditLegalFetch = (payload) => ({
    type: Merchants.MERCHANT_EDIT_LEGAL_FETCH,
    payload,
});

export const onMerchantEditLegalSuccess = (payload) => ({
    type: Merchants.MERCHANT_EDIT_LEGAL_SUCCESS,
    payload,
});

export const onMerchantEditLegalError = (payload) => ({
    type: Merchants.MERCHANT_EDIT_LEGAL_ERROR,
    payload,
});

export const onMerchantEditLegalClear = (payload) => ({
    type: Merchants.MERCHANT_EDIT_LEGAL_CLEAR,
    payload,
});

/*
GET LEGAL
*/
export const onMerchantGetLegalFetch = (payload) => ({
    type: Merchants.MERCHANT_GET_LEGAL_FETCH,
    payload,
});

export const onMerchantGetLegalSuccess = (payload) => ({
    type: Merchants.MERCHANT_GET_LEGAL_SUCCESS,
    payload,
});

export const onMerchantGetLegalError = (payload) => ({
    type: Merchants.MERCHANT_GET_LEGAL_ERROR,
    payload,
});

export const onMerchantGetLegalClear = (payload) => ({
    type: Merchants.MERCHANT_GET_LEGAL_CLEAR,
    payload,
});

/*
GET PAYMENT METHODS
*/
export const onMerchantGetMethodsFetch = (payload) => ({
    type: Merchants.LEGAL_GET_METHODS_FETCH,
    payload,
});

export const onMerchantGetMethodsSuccess = (payload) => ({
    type: Merchants.LEGAL_GET_METHODS_SUCCESS,
    payload,
});

export const onMerchantGetMethodsError = (payload) => ({
    type: Merchants.LEGAL_GET_METHODS_ERROR,
    payload,
});

export const onMerchantGetMethodsClear = (payload) => ({
    type: Merchants.MERCHANT_GET_METHODS_CLEAR,
    payload,
});

/*
ADD CARD
*/
export const onMerchantAddCardFetch = (payload) => ({
    type: Merchants.MERCHANT_ADD_CARD_FETCH,
    payload,
});

export const onMerchantAddCardSuccess = (payload) => ({
    type: Merchants.MERCHANT_ADD_CARD_SUCCESS,
    payload,
});

export const onMerchantAddCardError = (payload) => ({
    type: Merchants.MERCHANT_ADD_CARD_ERROR,
    payload,
});

export const onMerchantAddCardClear = (payload) => ({
    type: Merchants.MERCHANT_ADD_CARD_CLEAR,
    payload,
});

/*
GET MERCHANT CARD
*/
export const onMerchantGetCardFetch = (payload) => ({
    type: Merchants.MERCHANT_GET_CARD_FETCH,
    payload,
});

export const onMerchantGetCardSuccess = (payload) => ({
    type: Merchants.MERCHANT_GET_CARD_SUCCESS,
    payload,
});

export const onMerchantGetCardError = (payload) => ({
    type: Merchants.MERCHANT_GET_CARD_ERROR,
    payload,
});

export const onMerchantGetCardClear = (payload) => ({
    type: Merchants.MERCHANT_GET_CARD_CLEAR,
    payload,
});

/*
SET LEGAL PAYMENT METHODS
*/
export const onLegalSetMethodsFetch = (payload) => ({
    type: Merchants.LEGAL_SET_METHODS_FETCH,
    payload,
});

export const onLegalSetMethodsSuccess = (payload) => ({
    type: Merchants.LEGAL_SET_METHODS_SUCCESS,
    payload,
});

export const onLegalSetMethodsError = (payload) => ({
    type: Merchants.LEGAL_SET_METHODS_ERROR,
    payload,
});

export const onLegalSetMethodsClear = (payload) => ({
    type: Merchants.LEGAL_SET_METHODS_CLEAR,
    payload,
});

/*
    LEGAL PUBLISH
*/
export const onLegalPublishFetch = (payload) => ({
    type: Merchants.LEGAL_PUBLISH_FETCH,
    payload,
});

export const onLegalPublishSuccess = (payload) => ({
    type: Merchants.LEGAL_PUBLISH_SUCCESS,
    payload,
});

export const onLegalPublishError = (payload) => ({
    type: Merchants.LEGAL_PUBLISH_ERROR,
    payload,
});

export const onLegalPublishClear = (payload) => ({
    type: Merchants.LEGAL_PUBLISH_CLEAR,
    payload,
});
